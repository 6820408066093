/** @format */

import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import Images from "../../../../Helper/Images";
import "./Step1.css";
import { Link, useNavigate } from "react-router-dom";
import Screen from "../../../../Helper/Screen";

const Step1 = () => {
  const navigate = useNavigate();
  const handleSignUpClick = () => {
    localStorage.setItem("role", "Tutor");
    window.localStorage.removeItem("@userToken");
    navigate("/step2", { replace: true });
    window.location.reload();
  };
  const handleSignUpClick1 = () => {
    localStorage.setItem("role", "Parent");
    window.localStorage.removeItem("@userToken");
    navigate("/step2", { replace: true });
    window.location.reload();
  };
  return (
    <div
      style={{
        backgroundColor: "#fff",
        // minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          backgroundColor: "#ebe6f5",
          padding: "50px",
          borderRadius: "8px",
          maxWidth: "800px",
          width: "100%",
          position: "relative",
        }}
      >
        <Container>
          <Row className="justify-content-center text-center">
            <Col>
              <h2>It's time to create your profile</h2>
              <p>
                Welcome! Please follow the steps to create your profile. We need
                a few details to get started.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col xs={12} md={6} className="text-center">
              <img
                src={Images.sign4tut}
                alt="Profile Step 1"
                className="img-fluid"
              />
              <Button
                className="mt-3 custom-button"
                variant="primary"
                onClick={handleSignUpClick}
              >
                Sign up as a tutor
              </Button>
            </Col>

            <Col xs={12} md={6} className="text-center mob">
              <img
                src={Images.sign4par}
                alt="Profile Step 2"
                className="img-fluid"
              />
              <Button
                className="mt-3 custom-button"
                style={{ margin: 0 }}
                variant="primary"
                onClick={handleSignUpClick1}
              >
                Sign up as a parent/student
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Step1;
