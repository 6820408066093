const Images = {
  HomeBanner: require("../Assets/Images/lash.png"),
  Explore: require("../Assets/Images/img1.png"),
  sign4tut: require("../Assets/Images/img2.png"),
  sign4par: require("../Assets/Images/img3.png"),
  FindMyTutor: require("../Assets/Images/Newl.PNG"),
  Step2img: require("../Assets/Images/newimg.png"),
  FindMyTutorF: require("../Assets/Images/logoNew.png"),
  family: require("../Assets/Images/family.png"),
  request: require("../Assets/Images/img5.png"),
  BecomeTutor: require("../Assets/Images/img6.png"),
  sliderimg: require("../Assets/Images/img7.png"),
  homeimg: require("../Assets/Images/img8.png"),
  pen: require("../Assets/Images/pen.png"),
  arrow: require("../Assets/Images/arrow.png"),
  star: require("../Assets/Images/star.png"),
  banner: require("../Assets/Images/img9.png"),
  slide2: require("../Assets/Images/img10.png"),
  slide3: require("../Assets/Images/img11.png"),
  slide4: require("../Assets/Images/img12.png"),
  Lock: require("../Assets/Images/Lock.png"),
  MagnifyingGlass: require("../Assets/Images/MagnifyingGlass.png"),
  mail: require("../Assets/Images/mail.png"),
  phone: require("../Assets/Images/phone.png"),
  location: require("../Assets/Images/location.png"),
  fb: require("../Assets/Images/fb.png"),
  web: require("../Assets/Images/web.png"),
  linkdin: require("../Assets/Images/linkdin.png"),
  be: require("../Assets/Images/be.png"),
  insta: require("../Assets/Images/insta.png"),
  Sparkle: require("../Assets/Images/Sparkle.png"),
  dot: require("../Assets/Images/dot.png"),
  navRightImg: require("../Assets/Images/navRightImg.png"),
  line: require("../Assets/Images/line.png"),
  minus: require("../Assets/Images/minus.png"),
  plus: require("../Assets/Images/plus.png"),
  view: require("../Assets/Images/view.png"),
  botim: require("../Assets/Images/botim.png"),
  bt: require("../Assets/Images/bt.png"),
  Anna: require("../Assets/Images/Anna.png"),
  Hand: require("../Assets/Images/Hand.png"),
  cardicon1: require("../Assets/Images/cardicon1.png"),
  cardicon2: require("../Assets/Images/cradicon2.png"),
  cardicon3: require("../Assets/Images/cardicon3.png"),
  user: require("../Assets/Images/user.png"),
  time: require("../Assets/Images/time.png"),
  conn: require("../Assets/Images/conn.png"),
  subscript: require("../Assets/Images/subscript.png"),
  subTick: require("../Assets/Images/subTick.png"),
  mangifier: require("../Assets/Images/mangifier.png"),
  viewArrow: require("../Assets/Images/viewArrow.png"),
  Drop: require("../Assets/Images/Drop.png"),
  line1: require("../Assets/Images/line1.png"),
  WhyImg: require("../Assets/Images/whyImage.jpg"),
  Benifit: require("../Assets/Images/benifit.jpg"),
  Class: require("../Assets/Images/class.png"),
  Exp: require("../Assets/Images/exp.png"),
  Sub: require("../Assets/Images/sub.png"),
  HighRated: require("../Assets/Images/highRated.png"),
  Mustafa: require("../Assets/Images/mustafa.png"),

  Daniel: require("../Assets/Images/Daniel.png"),
  john: require("../Assets/Images/john.png"),
  maria: require("../Assets/Images/maria.png"),
  Lujain: require("../Assets/Images/Lujain.png"),

  harry: require("../Assets/Images/harry.png"),
  Faizah: require("../Assets/Images/Faizah.png"),
  card: require("../Assets/Images/card.png"),
};
export default Images;
