import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Images from "../../../../Helper/Images";
import Screen from "../../../../Helper/Screen";
import "./OtpVerify.css";
import ApiEndPoints from "../../../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../../../Network_Call/apiservices";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CircularProgress } from "@mui/material";

const OtpVerify = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const email = useLocation().state.email;
  const [showPassword, setShowPassword] = useState(false);
  const [load, setload] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = () => {
    // if (validateForm(data)) {
    VerifyOtpEmail();
    // }
  };

  // const validateForm = (data) => {
  //   if (data.email === "") {
  //     setError("email", { type: "required", message: "Email is required" });
  //     return false;
  //   }
  //   return true;
  // };
  const VerifyOtpEmail = async () => {
    setload(true);
    const payload = {
      email: email,
      password: password,
      reset_otp: JSON.parse(otp),
    };
    console.log("pppp", payload);
    apiCallNew("post", payload, ApiEndPoints.ResetPassword)
      .then((response: any) => {
        console.log("rrr", response);
        if (response.success === true) {
          setload(false);
          toast(response.msg);
          navigate("/login");
        } else {
          setload(false);
          toast(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
        setload(false);
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        // minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          borderRadius: "8px",
          maxWidth: "800px",
          width: "100%",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              style={{
                backgroundColor: "#ebe6f5",
                textAlign: "left",
                padding: "20px",
                borderRadius: "8px",
              }}
              xs={12}
              md={6}
            >
              {load && (
                <div style={styles.backdrop}>
                  <CircularProgress style={styles.loader} />
                </div>
              )}
              <p className="fw-bold">
                Please enter the one time password to verify your email
              </p>
              <p>
                A code has been sent to{" "}
                <span className=" textemail">{email}</span>
              </p>
              <br />
              <Form onSubmit={handleSubmit(onSubmit)}>
                <label style={{ marginLeft: 2 }}>
                  OTP<span style={{ color: "red" }}> *</span>
                </label>
                <Form.Group controlId="otp" className="mb-3">
                  <Form.Control
                    {...register("otp", {
                      required: "OTP is required",
                      minLength: {
                        value: 4,
                        message: "OTP must be at least 4 characters",
                      },
                      maxLength: {
                        value: 4,
                        message: "OTP must be at least 4 characters",
                      },
                    })}
                    type="text"
                    placeholder="Enter OTP"
                    onChange={(e) => setOtp(e.target.value)}
                    className={errors.otp ? "is-invalid" : ""}
                    style={{ backgroundImage: "none" }}
                  />
                  {errors.otp && (
                    <p className="" style={{ color: "red" }}>
                      {errors.otp.message}
                    </p>
                  )}
                </Form.Group>
                <label style={{ marginLeft: 2 }}>
                  New Password<span style={{ color: "red" }}> *</span>
                </label>
                <Form.Group controlId="password" className="mb-3">
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password must be at least 8 characters",
                        },
                        validate: (value) => {
                          const hasNumber = /[0-9]/.test(value);
                          const hasChar = /[a-zA-Z]/.test(value);
                          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(
                            value
                          );
                          if (!hasNumber) {
                            return "Password must contain at least one number";
                          }
                          if (!hasChar) {
                            return "Password must contain at least one alphabetic character";
                          }
                          if (!hasSpecialChar) {
                            return "Password must contain at least one special character";
                          }
                          return true;
                        },
                      })}
                      type={showPassword ? "text" : "password"}
                      placeholder="New Password"
                      onChange={(e) => setPassword(e?.target?.value)}
                    />
                    {!showPassword ? (
                      <AiOutlineEyeInvisible
                        className="password-icon"
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          top: errors.password ? "30%" : "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <AiOutlineEye
                        className="password-icon"
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          top: errors.password ? "30%" : "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    {errors.password && (
                      <p className="" style={{ color: "red" }}>
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                </Form.Group>
                <Button
                  type="submit"
                  variant="primary"
                  className="mt-3 w-100 "
                  style={{ margin: "auto" }}
                >
                  Submit
                </Button>
              </Form>
            </Col>
            <Col
              style={{
                background:
                  "linear-gradient(242.03deg, #6D39E9 -3.34%, #CCBAF7 72.34%)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative", // Added for relative positioning
              }}
              xs={12}
              md={6}
            >
              <img
                src={Images.Step2img}
                alt="Profile Step 1"
                className="img-fluid"
                style={{ borderRadius: "8px" }} // Ensures image also has rounded corners
              />
              <div className="alreadyBtn">
                <span style={{ marginRight: "10px" }}>Already a Member</span>
                <Link to={"/step1"}>
                  <Button style={{ width: "100px" }} variant="primary">
                    Sign In
                  </Button>
                </Link>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px", // Position at the top right of the Col
                  right: "10px",
                  // backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
                  padding: "5px 10px",
                  borderRadius: "8px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                {/* <span style={{ color: "white" }}>Step 5/5</span> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OtpVerify;

const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};
