import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Card } from "react-bootstrap";
import { apiCallNew } from "../../Network_Call/apiservices";
import { toast } from "react-toastify";
import ApiEndPoints from "../../Network_Call/ApiEndPoints";
import { IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FiPlus } from "react-icons/fi";
import { EditOutlined } from "@mui/icons-material";
import Swal from "sweetalert2";
import TimeSetComponent from "./TimeSetComponent";
import EditSlotModel from "./EditSlotModel";
const Availability = ({ showParentModal1, handleClose }) => {
  const [showNestedModal, setShowNestedModal] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [editItemId, setEditItemId] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [currentInput, setCurrentInput] = useState({
    days: "",
    customDays: "",
  });

  const handleOpenNestedModal = ({ id, typ }) => {
    setEditItemId(id);
    setType(typ);
    setShowNestedModal(true);
    handleClose();
  };
  const handleCloseNestedModal = () => setShowNestedModal(false);
  //   const handleDropdownChange = (e) => setSelectedOption(e.target.value);

  useEffect(() => {
    // GetTimeSlot();
    const handleFocus = () => {
      GetTimeSlot();
    };
    window.addEventListener("focus", handleFocus);
    return () => {
      setDataArray([]);
      window.removeEventListener("focus", handleFocus);
    };
  }, [status]);
  const GetTimeSlot = () => {
    apiCallNew("get", null, ApiEndPoints.GetTutorSlot)
      .then((response: any) => {
        if (response.success === true) {
          setDataArray(response.result);
          setStatus(response.success);
        } else {
          toast(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
      });
  };

  const DeleteSlot = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this slot!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9A54FF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        apiCallNew("post", null, `${ApiEndPoints.DeleteTutorSlot}${id}`).then(
          (response) => {
            if (response.success === true) {
              GetTimeSlot();
              handleCloseNestedModal();
              Swal.fire("Deleted!", response.msg, "success");
            } else {
              Swal.fire("Error!", response.msg, "error");
            }
          }
        );
      }
    });
  };
  return (
    <>
      <div>
        <Modal show={showParentModal1} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Slot List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ color: "#06241B", fontWeight: "500" }}>
              Your Availability
            </h6>
            <div>
              {dataArray &&
                dataArray?.map((dataSet, index) => {
                  return (
                    <Card
                      key={index}
                      style={{
                        width: "100%",
                        backgroundColor: "#F6F6F6",
                        border: "0.5px solid #fff",
                        borderRadius: "25px 0px 25px 0px",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{}}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // backgroundColor: "red",
                          }}
                        >
                          <h5
                            style={{
                              color: "#35185A",
                              marginTop: 5,
                              fontWeight: "bolder",
                            }}
                          >
                            Slot-{index + 1}
                          </h5>
                          <div>
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() =>
                                handleOpenNestedModal({
                                  id: dataSet.id,
                                  typ: "edit",
                                })
                              }
                            >
                              <EditOutlined
                                fontSize="inherit"
                                style={{ color: "#6d39e9" }}
                              />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => DeleteSlot(dataSet.id)}
                            >
                              <DeleteIcon
                                fontSize="inherit"
                                style={{ color: "#6d39e9" }}
                              />
                            </IconButton>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: -10,
                          }}
                        >
                          <h6
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                            }}
                          >
                            Days:{" "}
                          </h6>
                          <p
                            style={{
                              color: "#6d39e9",
                              marginLeft: 5,
                              fontSize: 12,
                            }}
                          >
                            {dataSet?.day_slot}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: -10,
                          }}
                        >
                          <h6
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                            }}
                          >
                            Time:{" "}
                          </h6>
                          <p
                            style={{
                              color: "#6d39e9",
                              marginLeft: 5,
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            {dataSet?.start_time}
                          </p>
                          <p
                            style={{
                              fontSize: 12,
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginRight: 2,
                            }}
                          >
                            to
                          </p>
                          <p
                            style={{
                              color: "#6d39e9",
                              marginLeft: 5,
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            {dataSet?.end_time}
                          </p>
                        </div>
                      </div>
                    </Card>
                  );
                })}
            </div>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="custom-button"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              variant="secondary"
              className="custom-button"
              onClick={handleOpenNestedModal}
            >
              Add Slot
            </Button>
            {/* <Button variant="primary" onClick={handleOpenNestedModal}>
            Open Nested Modal
          </Button> */}
          </Modal.Footer>
        </Modal>
        <EditSlotModel
          showNestedModal1={showNestedModal}
          handleClose={handleCloseNestedModal}
          id={editItemId}
          type={type}
          getSlote={GetTimeSlot}
        />
      </div>
    </>
  );
};
export default Availability;
