/** @format */

import React from "react";
import "./ContactUs.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Images from "../../../Helper/Images";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../../Network_Call/apiservices";
import { CircularProgress } from "@mui/material";
const ContactUS = () => {
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [load, setload] = React.useState(false);
  // const [subject, setSubject] = React.useState("");

  const ContactDetail = ({ iconColor, title, detail, img }) => (
    // <div className="row">
    //   <div
    //     className="col-lg-2 contact-icon"
    //     style={{
    //       backgroundColor: iconColor,
    //       height: "50px",
    //       width: "50px",
    //       marginLeft: "13px",
    //     }}
    //   >
    //     {" "}
    //     <img
    //       style={{ marginTop: "10px", width: "30px" }}
    //       src={img}
    //       alt={title}
    //     />{" "}
    //   </div>
    //   <div className="col-lg-8">
    //     <h6>{title}</h6>
    //     <p>{detail}</p>
    //   </div>
    //   <div className="col-lg-8"></div>
    // </div>
    <div className="row align-items-center mb-4">
      <div
        className="col-lg-2 col-md-2 col-sm-3 d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: iconColor,
          height: "60px",
          width: "60px",
          // Making the icon container circular
        }}
      >
        <img style={{ width: "30px", height: "30px" }} src={img} alt={title} />
      </div>
      <div className="col-lg-10 col-md-10 col-sm-9">
        <h6 style={{ marginBottom: "5px", fontWeight: "bold" }}>{title}</h6>
        <p
          style={{ margin: "0", color: "#555" }}
          dangerouslySetInnerHTML={{ __html: detail }}
        ></p>
      </div>
    </div>
  );
  const ContactDetail1 = ({ iconColor, title, detail, img }) => (
    <div className="row align-items-center mb-4">
      <div
        className="col-lg-2 col-md-2 col-sm-3 d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: iconColor,
          height: "60px",
          width: "60px",
          marginTop: "-26px",
        }}
      >
        <img style={{ width: "30px", height: "30px" }} src={img} alt={title} />
      </div>
      <div className="col-lg-10 col-md-10 col-sm-9">
        <h6 style={{ marginBottom: "5px", fontWeight: "bold" }}>{title}</h6>
        <p
          style={{ margin: "0", color: "#555" }}
          dangerouslySetInnerHTML={{ __html: detail }}
        ></p>
      </div>
    </div>
  );
  const validateForm = () => {
    const newErrors = {};

    // Validate name
    if (!name.trim()) {
      newErrors.name = "Name is required";
    }

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!emailPattern.test(email)) {
      newErrors.email = "Invalid email address";
    }

    // Validate mobile number
    const mobilePattern = /^\d{8,}$/;
    if (!mobile) {
      newErrors.mobile = "Phone number is required";
    } else if (!mobilePattern.test(mobile)) {
      newErrors.mobile = "It should be at least 8 digits.";
    }

    // Validate message
    if (!msg.trim()) {
      newErrors.msg = "Message is required";
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("email", email);
  //   formData.append("name", name);
  //   formData.append("message", msg);
  //   formData.append("mobile_number", mobile);
  //   formData.append("subject", "subject");

  //   apiCallNew("post", formData, ApiEndPoints.ContactUs)
  //     .then((response: any) => {
  //       console.log(">>>>", response);
  //       if (response.success === true) {
  //         toast.success(
  //           "Thank you for contacting us, we will contact you shortly."
  //         );
  //         setEmail("");
  //         setName("");
  //         setMsg("");
  //         setMobile("");
  //       } else {
  //         toast.error(response.msg);
  //       }
  //     })
  //     .finally((er) => {
  //       toast(er);
  //       // setload(false);
  //     });
  // };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("name", name);
      formData.append("message", msg);
      formData.append("mobile_number", mobile);
      formData.append("subject", "subject");
      try {
        setload(true);
        apiCallNew("post", formData, ApiEndPoints.ContactUs)
          .then((response: any) => {
            console.log(">>>>", response);
            if (response.success === true) {
              setload(false);
              toast.success(
                "Thank you for contacting us, we will contact you shortly."
              );
              setEmail("");
              setName("");
              setMsg("");
              setMobile("");
            } else {
              toast.error(response.msg);
              setload(false);
            }
          })
          .finally((er) => {
            toast(er);
            setload(false);
          });
      } catch (error) {
        console.log("error", error);
        setload(false);
      }
    } else {
      // Validation failed, do not proceed with API call
      console.log("Validation failed");
      setload(false);
    }
  };
  return (
    <div className="body">
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <ToastContainer />
      <div className="header-container">
        <h2 className="header-title1">Contact FindMyTutor</h2>
      </div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mt-5">
              <h3>Contact Us</h3>
              <h2>Get In Touch With Us</h2>
              <p>
                If you have any questions or require support, contact us and one
                of our specialised team members will get back to you.
              </p>
              <ContactDetail
                iconColor="#6D39E9"
                title="Phone Number"
                detail="+971502475166"
                img={Images.phone}
              />
              <ContactDetail
                iconColor="#6D39E9"
                title="Email Address"
                detail="support@findmytutoruae.com"
                img={Images.mail}
              />
              <ContactDetail1
                iconColor="#6D39E9"
                title="Address"
                detail={`P.O Box: Rolex Tower 9th Floor, Office 9B, Dubai, SRTIP Accelerator PO Box 117397<br />
              Office Address: Block C, VL05-054 Sharjah Research Technology and Innovation Park Free Zone Sharjah, UAE`}
                img={Images.location}
              />
            </div>

            <div className="col-lg-5">
              <div className="contact-form-wrapper d-flex justify-content-center">
                <form className="contact-form" onSubmit={handleSubmit}>
                  <div>
                    <input
                      type="text"
                      className="form-control rounded border-white mb-2 form-input"
                      id="Name"
                      placeholder="Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  {errors.name && (
                    <div className="text-white">{errors.name}</div>
                  )}

                  <div>
                    <input
                      className="form-control rounded border-white mb-2 form-input"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <div className="text-white">{errors.email}</div>
                    )}
                  </div>
                  <div>
                    <input
                      type="number"
                      className="form-control rounded border-white mb-2 form-input"
                      placeholder="Your Phone"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                    {errors.mobile && (
                      <div className="text-white">{errors.mobile}</div>
                    )}
                  </div>
                  <div>
                    <textarea
                      id="message"
                      className="form-control rounded border-white mb-2 form-text-area"
                      rows="5"
                      cols="30"
                      placeholder="Your Message"
                      value={msg}
                      onChange={(e) => setMsg(e.target.value)}
                    ></textarea>
                    {errors.msg && (
                      <div className="text-white">{errors.msg}</div>
                    )}
                  </div>
                  <div className="submit-button-wrapper">
                    <input type="submit" value="Send Message" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default ContactUS;
