/** @format */

import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Container } from "react-bootstrap";
import Images from "../../../Helper/Images";
import { apiCallNew } from "../../../Network_Call/apiservices";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { toast } from "react-toastify";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const SubscribPkgList = () => {
  const [subSctinList, setSubSctinList] = useState([]);
  const [pkgStatus, setPkgStatus] = useState();
  const [load, setload] = React.useState(false);
  console.log("pkgStatus", subSctinList);

  useEffect(() => {
    SubscriptionList();
    Get_Pkg_Status();
  }, []);

  const SubscriptionList = () => {
    setload(true);
    const payload = {
      page: 0,
    };
    apiCallNew("post", payload, ApiEndPoints.SubscriptionList).then(
      (response) => {
        console.log("rrrr", response);
        if (response.success == true) {
          console.log("rrrrdd", response);
          setload(false);
          setSubSctinList(response.result);
        } else {
          setload(false);
        }
      }
    );
  };
  const Get_Pkg_Status = async () => {
    const response = await apiCallNew("get", null, ApiEndPoints.Pkg_Status);
    setPkgStatus(response?.result);
  };
  const handlePayment = async (item) => {
    console.log("item", item);
    setload(true);
    const payload = {
      package_id: item?.package_id,
      boost_profile: 0,
      // message: item?.description,
    };
    try {
      const res = await apiCallNew("post", payload, ApiEndPoints.BuyPackage);
      console.log("item", res);

      if (res.success === true) {
        toast.success("Please proceed to make payment.");
        setload(false);
        if (res?.result?.url) {
          window.location.href = res?.result?.url;
        }
      } else {
        setload(false);
        toast.error(res?.msg);
      }
    } catch (error) {
      setload(false);
      toast.error("An error occurred while processing your payment.");
      console.error("Payment error:", error);
    }
  };
  const handleBoostPayment = async (item) => {
    console.log("item", item);
    setload(true);

    // Parse the dates
    const packageEndDate = moment(item.package_end_date);
    const currentDate = moment();

    // Check if the current date is less than the package end date
    if (currentDate.isBefore(packageEndDate)) {
      const payload = {
        package_id: item?.package_id,
        boost_profile: 1,
        // message: item?.description,
      };
      console.log("payload>>>>", payload);
      localStorage.setItem("payload", JSON.stringify(payload));

      try {
        const res = await apiCallNew("post", payload, ApiEndPoints.BuyPackage);
        console.log("item", res);

        if (res.success) {
          toast.success("Please proceed to make payment.");
          setload(false);
          if (res?.result?.url) {
            window.location.href = res?.result?.url;
          }
        } else {
          setload(false);
          toast.error(res?.msg);
        }
      } catch (error) {
        setload(false);
        toast.error("An error occurred while processing your payment.");
        console.error("Payment error:", error);
      }
    } else {
      setload(false);
      toast.error("The package has expired and cannot be renewed.");
    }
  };
  // const today = new Date();
  // const packageEndDate = new Date(pkgStatus?.package_end_date);
  // const remainingDays = Math.ceil(
  //   (packageEndDate - today) / (1000 * 60 * 60 * 24)
  // );
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize to compare only the date
  const packageEndDate = new Date(pkgStatus?.package_end_date);
  packageEndDate.setHours(0, 0, 0, 0); // Normalize to compare only the date

  const remainingDays = Math.ceil(
    (packageEndDate - today) / (1000 * 60 * 60 * 24)
  );
  return (
    <Container>
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <h1>Current Plan</h1>

      {/* {subSctinList && subSctinList.length > 0 ? (
        subSctinList?.map((item) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Normalize to midnight for date-only comparison

          const packageEndDate = new Date(item?.package_end_date);
          packageEndDate.setHours(0, 0, 0, 0); // Normalize to midnight for date-only comparison

          const remainingDays = Math.ceil(
            (packageEndDate - today) / (1000 * 60 * 60 * 24)
          );

          return (
            item?.payment_status === "success" && (
              <Card className="my-2 p-3 rounded shadow">
                <Card.Body style={{ backgroundColor: "#e9ecef" }}>
                  <Row className="align-items-center">
                    <Col
                      xs={12}
                      md={2}
                      className="d-flex justify-content-center mb-3 mb-md-0"
                    >
                      <img
                        src={Images.subscript}
                        alt="Subscription"
                        style={{ height: "80px", width: "80px" }}
                      />
                    </Col>
                    <Col xs={12} md={3} className="mb-3 mb-md-0">
                      <div className="d-flex flex-column">
                        <Card.Title>
                          Plan:{" "}
                          <span>
                            {item?.package_boost_profile === 1
                              ? "Booster Pack"
                              : item.title}
                          </span>
                        </Card.Title>
                        <div
                          className="text-white rounded px-2 py-1"
                          style={{
                            backgroundColor:
                              item?.status === 1 ? "green" : "#DED724",
                            width: "fit-content",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.status === 1 ? "active" : "inactive"}
                        </div>
                        <Card.Text className="text-black mt-2">
                          Days:{" "}
                          <span className="text-secondary">
                            {item?.package_no_days}
                          </span>
                        </Card.Text>
                      </div>
                    </Col>
                    <Col xs={12} md={4} className="text-md-right mb-3 mb-md-0">
                      <Card.Text className="text-black">
                        Purchase Date:{" "}
                        <span className="text-secondary">
                          {moment(item?.created_at).format("DD-MM-YYYY")}
                        </span>
                      </Card.Text>
                      <Card.Text className="text-black">
                        Start Date:{" "}
                        <span className="text-secondary">
                          {moment(item?.package_start_date).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </Card.Text>
                      <Card.Text className="text-black">
                        Expire Date:{" "}
                        <span className="text-secondary">
                          {moment(item?.package_end_date).format("DD-MM-YYYY")}
                        </span>
                      </Card.Text>
                    </Col>
                    <Col xs={12} md={3} className="text-center">
                      {item?.package_boost_profile !== 1 &&
                        item?.status === 1 &&
                        remainingDays <= 1 && (
                          <button
                            className="pro fw-bold"
                            onClick={() => handlePayment(item)}
                          >
                            Renew
                          </button>
                        )}
                      {item?.package_type === 2 && item?.status === 1 && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              You can enhance your profile with a AED 7.50
                              upgrade, gaining priority status for 7 days
                            </Tooltip>
                          }
                        >
                          <button
                            className="pro mt-2 fw-bold"
                            onClick={() => handleBoostPayment(item)}
                          >
                            Profile Boost
                          </button>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )
          );
        })
      ) : (
        <div className="text-center my-5">
          <p>You have no subscription</p>
        </div>
      )} */}
      {pkgStatus?.length >= 0 ? (
        <div className="text-center my-5">
          <p>You have no subscription</p>
        </div>
      ) : (
        subSctinList &&
        subSctinList.length > 0 &&
        subSctinList.map((item) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Normalize to midnight for date-only comparison

          const packageEndDate = new Date(item?.package_end_date);
          packageEndDate.setHours(0, 0, 0, 0); // Normalize to midnight for date-only comparison

          const remainingDays = Math.ceil(
            (packageEndDate - today) / (1000 * 60 * 60 * 24)
          );
          console.log("remainingDays>>", remainingDays);

          if (item?.payment_status === "success") {
            return (
              // <Card key={item.id} className="my-2 p-3 rounded shadow">
              //   <Card.Body style={{ backgroundColor: "#e9ecef" }}>
              //     <Row className="align-items-center">
              //       <Col
              //         xs={12}
              //         md={2}
              //         className="d-flex justify-content-center mb-3 mb-md-0"
              //       >
              //         <img
              //           src={Images.subscript}
              //           alt="Subscription"
              //           style={{ height: "80px", width: "80px" }}
              //         />
              //       </Col>
              //       <Col xs={12} md={3} className="mb-3 mb-md-0">
              //         <div className="d-flex flex-column">
              //           <Card.Title>
              //             Plan:{" "}
              //             <span>
              //               {item?.package_boost_profile === 1
              //                 ? "Booster Pack"
              //                 : item.title}
              //             </span>
              //           </Card.Title>
              //           <div
              //             className="text-white rounded px-2 py-1"
              //             style={{
              //               backgroundColor:
              //                 item?.status === 1 ? "green" : "#dc3545",
              //               width: "fit-content",
              //               fontWeight: "bold",
              //             }}
              //           >
              //             {item?.status === 1 ? "Active" : "Inactive"}
              //           </div>
              //           <Card.Text className="text-black mt-2">
              //             Days:{" "}
              //             <span className="text-secondary">
              //               {item?.package_no_days}
              //             </span>
              //           </Card.Text>
              //         </div>
              //       </Col>
              //       <Col xs={12} md={4} className="text-md-right mb-3 mb-md-0">
              //         <Card.Text className="text-black">
              //           Purchase Date:{" "}
              //           <span className="text-secondary">
              //             {moment(item?.created_at).format("DD-MM-YYYY")}
              //           </span>
              //         </Card.Text>
              //         <Card.Text className="text-black">
              //           Start Date:{" "}
              //           <span className="text-secondary">
              //             {moment(item?.package_start_date).format(
              //               "DD-MM-YYYY"
              //             )}
              //           </span>
              //         </Card.Text>
              //         <Card.Text className="text-black">
              //           Expire Date:{" "}
              //           <span className="text-secondary">
              //             {moment(item?.package_end_date).format("DD-MM-YYYY")}
              //           </span>
              //         </Card.Text>
              //       </Col>
              //       <Col xs={12} md={3} className="text-center">
              //         {item?.package_boost_profile !== 1 &&
              //           item?.status === 1 &&
              //           remainingDays <= 1 && (
              //             <button
              //               className="pro fw-bold"
              //               onClick={() => handlePayment(item)}
              //             >
              //               Renew
              //             </button>
              //           )}
              //         {item?.package_boost_profile !== 1 &&
              //           item?.status === 0 &&
              //           remainingDays <= 0 && (
              //             <button
              //               style={{
              //                 cursor: "not-allowed",
              //                 color: "#fff",
              //                 padding: "10px 20px",
              //                 fontSize: "16px",
              //                 fontWeight: "bold",
              //                 borderRadius: "5px",
              //                 border: "none",
              //                 backgroundColor: "grey",
              //               }}
              //               disabled={remainingDays === 0}
              //               className="fw-bold"
              //             >
              //               Expired
              //             </button>
              //           )}
              //         {/* {item?.package_boost_profile !== 1 &&
              //           item?.status === 1 &&
              //           (remainingDays <= 1 ? (
              //             <button
              //               className="pro"
              //               onClick={() =>
              //                 remainingDays > 0 && handlePayment(item)
              //               }
              //               disabled={remainingDays === 0}
              //               style={{
              //                 cursor:
              //                   remainingDays === 0 ? "not-allowed" : "pointer",
              //                 backgroundColor:
              //                   remainingDays === 0 ? "grey" : "green",
              //                 color: "#fff",
              //                 padding: "10px 20px",
              //                 fontSize: "16px",
              //                 fontWeight: "bold",
              //                 borderRadius: "5px",
              //                 border: "none",
              //                 transition:
              //                   "background-color 0.3s ease, transform 0.3s ease",
              //                 ...(remainingDays === 0 && {
              //                   transform: "none",
              //                   boxShadow: "none",
              //                 }),
              //               }}
              //             >
              //               {remainingDays === 0 ? "Expired" : "Renew"}
              //             </button>
              //           ) : null)} */}

              //         {/* <button className="pro fw-bold">Expired</button> */}

              //         {item?.package_type === 2 && item?.status === 1 && (
              //           <OverlayTrigger
              //             placement="top"
              //             overlay={
              //               <Tooltip id={`tooltip-top`}>
              //                 You can enhance your profile with an AED 7.50
              //                 upgrade, gaining priority status for 7 days
              //               </Tooltip>
              //             }
              //           >
              //             <button
              //               className="pro mt-2 fw-bold"
              //               onClick={() => handleBoostPayment(item)}
              //             >
              //               Profile Boost
              //             </button>
              //           </OverlayTrigger>
              //         )}
              //       </Col>
              //     </Row>
              //   </Card.Body>
              // </Card>
              <Card key={item.id} className="my-2 p-3 rounded shadow">
                <Card.Body style={{ backgroundColor: "#e9ecef" }}>
                  <Row className="align-items-center">
                    <Col
                      xs={12}
                      md={2}
                      className="d-flex justify-content-center mb-3 mb-md-0"
                    >
                      <img
                        src={Images.subscript}
                        alt="Subscription"
                        style={{ height: "80px", width: "80px" }}
                      />
                    </Col>
                    <Col xs={12} md={3} className="mb-3 mb-md-0">
                      <div className="d-flex flex-column">
                        <Card.Title>
                          Plan:{" "}
                          <span>
                            {item?.package_boost_profile === 1
                              ? "Booster Pack"
                              : item.title}
                          </span>
                        </Card.Title>
                        <div
                          className="status-label text-white text-center rounded px-1 py-1"
                          style={{
                            backgroundColor:
                              item?.status === 1 ? "green" : "#dc3545",
                            fontWeight: "bold",
                            width: "80px",
                            marginTop: "5px",
                            fontSize: "11px",
                          }}
                        >
                          {item?.status === 1 ? "Active" : "Inactive"}
                        </div>
                        <Card.Text className="text-black mt-2">
                          Days:{" "}
                          <span className="text-secondary">
                            {item?.package_no_days}
                          </span>
                        </Card.Text>
                      </div>
                    </Col>
                    <Col xs={12} md={4} className="text-md-right mb-3 mb-md-0">
                      <Card.Text className="text-black">
                        Purchase Date:{" "}
                        <span className="text-secondary">
                          {moment(item?.created_at).format("DD-MM-YYYY")}
                        </span>
                      </Card.Text>
                      <Card.Text className="text-black">
                        Start Date:{" "}
                        <span className="text-secondary">
                          {moment(item?.package_start_date).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </Card.Text>
                      <Card.Text className="text-black">
                        Expire Date:{" "}
                        <span className="text-secondary">
                          {moment(item?.package_end_date).format("DD-MM-YYYY")}
                        </span>
                      </Card.Text>
                    </Col>
                    <Col xs={12} md={3} className="text-center">
                      {item?.package_boost_profile !== 1 &&
                        item?.status === 1 &&
                        remainingDays <= 1 && (
                          <button
                            className="pro fw-bold"
                            onClick={() => handlePayment(item)}
                          >
                            Renew
                          </button>
                        )}
                      {item?.package_boost_profile !== 1 &&
                        item?.status === 0 &&
                        remainingDays <= 0 && (
                          <button
                            style={{
                              cursor: "not-allowed",
                              color: "#fff",
                              padding: "10px 20px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              border: "none",
                              backgroundColor: "grey",
                            }}
                            disabled={remainingDays === 0}
                            className="fw-bold"
                          >
                            Expired
                          </button>
                        )}
                      {item?.package_type === 2 && item?.status === 1 && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              You can enhance your profile with an AED 7.50
                              upgrade, gaining priority status for 7 days
                            </Tooltip>
                          }
                        >
                          <button
                            className="pro mt-2 fw-bold"
                            onClick={() => handleBoostPayment(item)}
                          >
                            Profile Boost
                          </button>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          }
          return null;
        })
      )}
    </Container>
  );
};

const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default SubscribPkgList;
