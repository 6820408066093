/** @format */

import React from "react";
import "./Help.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Images from "../../../Helper/Images";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../../Network_Call/apiservices";
import { CircularProgress } from "@mui/material";
const Help = () => {
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [load, setload] = React.useState(false);
  // const [subject, setSubject] = React.useState("");

  const ContactDetail = ({ iconColor, title, detail, img }) => (
    <div className="row">
      <div
        className="col-lg-2 contact-icon"
        style={{
          backgroundColor: iconColor,
          height: "50px",
          width: "50px",
          marginLeft: "13px",
        }}
      >
        {" "}
        <img
          style={{ marginTop: "10px", width: "30px" }}
          src={img}
          alt={title}
        />{" "}
      </div>
      <div className="col-lg-5 col-md-4">
        <h6>{title}</h6>
        <p>{detail}</p>
      </div>
      <div className="col-lg-8"></div>
    </div>
  );
  const validateForm = () => {
    const newErrors = {};

    // Validate name
    if (!name.trim()) {
      newErrors.name = "Name is required";
    }

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!emailPattern.test(email)) {
      newErrors.email = "Invalid email address";
    }

    // Validate mobile number
    const mobilePattern = /^\d{8,}$/;
    if (!mobile) {
      newErrors.mobile = "Phone number is required";
    } else if (!mobilePattern.test(mobile)) {
      newErrors.mobile = "It should be at least 8 digits.";
    }

    // Validate message
    if (!msg.trim()) {
      newErrors.msg = "Message is required";
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("email", email);
  //   formData.append("name", name);
  //   formData.append("message", msg);
  //   formData.append("mobile_number", mobile);
  //   formData.append("subject", "subject");

  //   apiCallNew("post", formData, ApiEndPoints.ContactUs)
  //     .then((response: any) => {
  //       console.log(">>>>", response);
  //       if (response.success === true) {
  //         toast.success(
  //           "Thank you for contacting us, we will contact you shortly."
  //         );
  //         setEmail("");
  //         setName("");
  //         setMsg("");
  //         setMobile("");
  //       } else {
  //         toast.error(response.msg);
  //       }
  //     })
  //     .finally((er) => {
  //       toast(er);
  //       // setload(false);
  //     });
  // };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("name", name);
      formData.append("message", msg);
      formData.append("mobile_number", mobile);
      formData.append("subject", "subject");
      try {
        setload(true);
        apiCallNew("post", formData, ApiEndPoints.ContactUs)
          .then((response: any) => {
            console.log(">>>>", response);
            if (response.success === true) {
              setload(false);
              toast.success(
                "Thank you for contacting us, we will contact you shortly."
              );
              setEmail("");
              setName("");
              setMsg("");
              setMobile("");
            } else {
              toast.error(response.msg);
              setload(false);
            }
          })
          .finally((er) => {
            toast(er);
            setload(false);
          });
      } catch (error) {
        console.log("error", error);
        setload(false);
      }
    } else {
      // Validation failed, do not proceed with API call
      console.log("Validation failed");
      setload(false);
    }
  };
  return (
    <div className="body">
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <ToastContainer />
      <div className="help-container">
        <h2 className="help-title1">Help</h2>
      </div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mt-5">
              <div className="row">
                <div className="col-md-12">
                  <div className="spacer"></div>
                </div>
              </div>
              <h3>Help</h3>
              <h2>Get In Touch With Us</h2>
              <div className="row">
                <div className="col-lg-11">
                  <p>
                    If you have any questions or require support, contact us and
                    one of our specialised team members will get back to you
                  </p>
                </div>
                <div className="col-lg-1"></div>
              </div>

              <ContactDetail
                iconColor="#6D39E9"
                title="Phone Number"
                detail="+971502475166"
                img={Images.phone}
              />
              <ContactDetail
                iconColor="#6D39E9"
                title="Email Address"
                detail="support@findmytutoruae.com"
                img={Images.mail}
              />
            </div>

            <div className="col-lg-5">
              <div className="contact-form-wrapper d-flex justify-content-center">
                <form className="contact-form" onSubmit={handleSubmit}>
                  <div>
                    <input
                      type="text"
                      className="form-control rounded border-white mb-2
                       form-input"
                      id="Name"
                      placeholder="Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  {errors.name && (
                    <div className="error-message">{errors.name}</div>
                  )}
                  <div>
                    <input
                      className="form-control rounded border-white mb-2
                       form-input"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  {errors.email && (
                    <div className="error-message">{errors.email}</div>
                  )}
                  <div>
                    <input
                      type="number"
                      className="form-control rounded border-white mb-2
                       form-input"
                      placeholder="Your Phone"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </div>
                  {errors.mobile && (
                    <div className="error-message">{errors.mobile}</div>
                  )}

                  <div>
                    <textarea
                      id="message"
                      className="form-control rounded border-white mb-2
                       form-text-area"
                      rows="5"
                      cols="30"
                      placeholder="Your Message"
                      value={msg}
                      onChange={(e) => setMsg(e.target.value)}
                    ></textarea>
                  </div>
                  {errors.msg && (
                    <div className="error-message">{errors.msg}</div>
                  )}
                  <div className="submit-button-wrapper">
                    <input type="submit" value="Send Message" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default Help;
