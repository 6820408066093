/** @format */

import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import SubscriptionDetails from "../Screens/TutorScreen/SubscriptionDetails/SubscriptionDetails";
import EditProfile from "../Screens/TutorScreen/EditProfile/EditProfile";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DashDrawer from "../Components/DashDrawer/DashDrawer";
import TutorDashBoard from "../Screens/TutorScreen/TutorDashBoard/TutorDashBoard";
import FindTutor from "../Screens/TutorScreen/FindTutor/FindTutor";
import DashHeader from "../Components/DashHeader/DashHeader";
import MessageList from "../Screens/PublicScreen/Message/MessageList";
import MessageView from "../Screens/PublicScreen/Message/MessageView";
import AdvertisePost from "../Screens/ParentsScreen/Advertisment/AdvertisePost/AdvertisePost";
import AdvertisePostList from "../Screens/ParentsScreen/Advertisment/AdvertisePostList/AdvertisePostList";
import ViewAllRequest from "../Screens/TutorScreen/ViewAllRequest/ViewAllRequest";
import Help from "../Screens/PublicScreen/Help/Help";

const ParentsRoute = () => {
  const [currentComponent, setCurrentComponent] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const ParentStep = localStorage.getItem("@parentStep");
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  console.log("ParentStep>>", ParentStep);
  return (
    <div style={{}}>
      <DashDrawer
        open={drawerOpen}
        toggleDrawer={toggleDrawer}
        variant={isMobile ? "temporary" : "permanent"}
        setCurrentComponent={setCurrentComponent}
      />
      <main
        style={{
          flexGrow: 1,
          padding: "16px",
          marginTop: isMobile ? "0px" : "0px",
          marginLeft: isMobile ? "0" : "230px",
          transition: "margin-left 0.3s",
        }}
      >
        <DashHeader
          currentComponent={currentComponent}
          setCurrentComponent={setCurrentComponent}
          toggleDrawer={toggleDrawer}
          isMobile={isMobile}
        />
        <ToastContainer autoClose={1000} />
        <Routes>
          <Route path="/" element={<TutorDashBoard />} />
          <Route path="/parent" element={<TutorDashBoard />} />
          <Route path="/subscription" element={<SubscriptionDetails />} />
          <Route path={"/editProfile"} element={<EditProfile />} />
          <Route path={"/findTutor"} element={<FindTutor />} />
          <Route path={"/messageList"} element={<MessageList />} />
          <Route path={"/messageView"} element={<MessageView />} />
          <Route path={"/advertise-post"} element={<AdvertisePost />} />
          <Route path={"/view-request"} element={<ViewAllRequest />} />
          <Route
            path={"/advertise-post-list"}
            element={<AdvertisePostList />}
          />
          <Route path={"/help"} element={<Help />} />
        </Routes>
      </main>
    </div>
  );
};

export default ParentsRoute;
