import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  BsEnvelope,
  BsFacebook,
  BsLinkedin,
  BsTelephone,
  BsTwitterX,
  BsWhatsapp,
} from "react-icons/bs";
import "./Footer.css";
import Images from "../Helper/Images";
import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Screen from "../Helper/Screen";
import { apiCallNew } from "../Network_Call/apiservices";
import ApiEndPoints from "../Network_Call/ApiEndPoints";
import { toast } from "react-toastify";
import axios from "axios";

const Footer = () => {
  const location = useLocation();
  const [email, setEmail] = React.useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [citytList, setCitytList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const path = location.pathname;
  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  useEffect(() => {
    GetSubjectList();
    GetCityList();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const validateEmail = (email) => {
    // Regular expression for email validation
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    const domainPart = email.split("@")[1];

    // Function to count dots in the domain part
    const countDots = (str) => (str.match(/\./g) || []).length;

    // Additional checks to ensure no consecutive dots and no dots at the beginning or end of the local part or domain,
    // and only one dot in the domain part
    return (
      re.test(String(email).toLowerCase()) &&
      !email.includes("..") &&
      !/^\./.test(email.split("@")[0]) &&
      !/\.$/.test(email.split("@")[0]) &&
      !/^\./.test(email.split("@")[1]) &&
      !/\.$/.test(email.split("@")[1]) &&
      countDots(domainPart) === 1
    );
  };

  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      setValidationMessage("Please enter a valid email address.");
      return;
    }

    setValidationMessage("");
    setIsSubmitting(true); // Disable the button
    try {
      const response = await fetch(`${ApiEndPoints.Subscriber}email=${email}`, {
        method: "GET",
      });
      const result = await response.json();
      if (result.success === true) {
        toast.success("Thank you for subscribing!");
        setEmail("");
      } else {
        toast.error(result?.result?.email[0] + "!");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };
  const GetSubjectList = async () => {
    apiCallNew("get", null, ApiEndPoints.SubjectTypeList)
      .then((response: any) => {
        if (response.success === true) {
          console.log("Footer", response);
          setSubjectList(response.result);
          // toast(response.msg);
        } else {
          toast(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
      });
  };
  const GetCityList = async () => {
    try {
      const response = await apiCallNew("get", null, ApiEndPoints.CityList);
      if (response.success) {
        setCitytList(response.result);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("An error occurred while fetching the subject list.");
    }
  };
  return (
    <footer className="footer-section">
      <div className="container fluid">
        <div className="footer-content" style={{ alignItems: "center" }}>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <img
                    src={Images.FindMyTutorF}
                    className="img-fluid"
                    alt="logo"
                    width={200}
                  />
                </div>
                <h5>Contact Us</h5>
                <p>Call : +971502475166</p>
                <p>Company name: FindMyTutor (FZE)</p>
                <div className=" w-75">
                  <p>
                    Office Address: Block C, VL05-054 Sharjah Research
                    Technology and Innovation Park Free Zone Sharjah, UAE
                    <br />
                    <br />
                    P.O Box: Rolex Tower 9th Floor, Office 9B, Dubai, SRTIP
                    Accelerator PO Box 117397
                  </p>
                  <p>Email: support@findmytutoruae.com</p>
                </div>
                <div className="footer-social-icon">
                  <a
                    href="https://www.facebook.com/profile.php?id=61563804316359"
                    className="facebook-bg custom-tooltip"
                  >
                    <Image
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "white",
                        // borderRadius: 5,
                      }}
                      src={Images.fb}
                    />
                    <span className="tooltip-text">Facebook</span>
                  </a>
                  <a
                    href="https://findmytutoruae.com/"
                    className="twitter-bg custom-tooltip"
                  >
                    <Image
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "white",
                      }}
                      src={Images.web}
                    />
                    <span className="tooltip-text">website</span>
                  </a>
                  {/* <a href="#" className="google-bg custom-tooltip">
                    <Image
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "white",
                      }}
                      src={Images.linkdin}
                    />
                    <span className="tooltip-text">Linkedin</span>
                  </a> */}

                  <a
                    href="https://www.instagram.com/findmytutoruae/"
                    className="google-bg custom-tooltip"
                  >
                    <Image
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "white",
                      }}
                      src={Images.insta}
                    />
                    <span className="tooltip-text">Instagram</span>
                  </a>
                  {/* <a className="google-bg custom-tooltip">
                    <Image
                      style={{
                        width: "30px",
                        height: "30px",
                        // borderRadius: "5px",
                      }}
                      src={Images.botim}
                    />
                    <span className="tooltip-text">Botim</span>
                  </a> */}
                </div>
              </div>
            </div>
            <div
              className="col-xl-2 col-lg-2 col-md-2 mb-30"
              style={{
                justifyContent: "center",
              }}
            >
              <div className="footer-widget">
                <div className="">
                  <h5 style={{ margin: 0 }}>Explore</h5>
                </div>
                <br />
                <ul className="ulsh" style={{ display: "grid" }}>
                  <Link to={"/"} style={{ textDecoration: "none" }}>
                    <li>
                      <a href="#">Home</a>
                    </li>
                  </Link>
                  <Link className="text-decoration-none" to={"/about"}>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                  </Link>

                  <Link className="text-decoration-none" to={"/ourBlogs"}>
                    {" "}
                    <li>
                      <a href="#">Blog</a>
                    </li>
                  </Link>
                  <Link to={"/contactUs"} style={{ textDecoration: "none" }}>
                    <li>
                      <a href="#">Contact Us</a>
                    </li>
                  </Link>
                  <Link
                    to={"/product-delivery-policy"}
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      <a href="#">Product Delivery Policy</a>
                    </li>
                  </Link>
                  <Link
                    to={"/cancellation-policy"}
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      <a href="#">Cancellation Policy</a>
                    </li>
                  </Link>
                  <Link
                    to={"/refund-policy"}
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      <a href="#">Refund Policy</a>
                    </li>
                  </Link>
                  <Link
                    to={"/privacyPolicy"}
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                  </Link>
                  <Link
                    to={"/termsndConditions"}
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      <a href="#">Terms & Conditions</a>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
            {/* <div className="col-xl-2 col-lg-2 col-md-4 mb-30">
              <div className="footer-widget">
                <div className="">
                  <h5 style={{ margin: 0 }}>Popular Subjects</h5>
                </div>
                <br />
                <ul className="ulsh" style={{ display: "grid" }}>
                  {subjectList &&
                    subjectList?.map((i) => {
                      return (
                        <Link className="text-white no-pointer">
                          <li className="">{i?.subject_type}</li>
                        </Link>
                      );
                    })}{" "}
                </ul>
              </div>
            </div> */}
            <div className="col-xl-2 col-lg-2 col-md-4 mb-30">
              <div className="footer-widget">
                <div className="">
                  <h5 style={{ margin: 0 }}>Popular Subjects</h5>
                </div>
                <br />
                <ul className="ulsh" style={{ display: "grid" }}>
                  {subjectList &&
                    subjectList
                      .slice(0, showAll ? subjectList.length : 9) // Show all if showAll is true, otherwise show only 10
                      .map((i, index) => {
                        return (
                          // <Link key={index} className="text-white no-pointer">
                          <li className="text-white no-pointer">
                            {i?.subject_type}
                          </li>
                          // </Link>
                        );
                      })}
                  <button
                    className="text-start"
                    onClick={handleShowMore}
                    style={{
                      marginTop: "10px",
                      color: "orange",
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    {showAll ? "Show Less" : "Show More"}
                  </button>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-4 mb-30">
              <div className="footer-widget">
                <div className="">
                  <h5 style={{ margin: 0 }}>UAE Cities</h5>
                </div>
                <br />
                <ul className="ulsh" style={{ display: "grid" }}>
                  {citytList?.map((res) => {
                    return (
                      // <Link className="text-white no-pointer">
                      <li className="text-white no-pointer">
                        {res?.city_name}
                      </li>
                      // </Link>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-4 mb-50">
              <div className="footer-widget">
                <div className="">
                  <h5>Subscribe</h5>
                </div>
                {/* <br /> */}
                <div className="footer-text mt-2 mb-25">
                  <p>
                    Don’t miss to subscribe to our new feeds, kindly fill the
                    form below.
                  </p>
                </div>
                <div className="subscribe-form">
                  <form action="#">
                    <input
                      type="text"
                      className="input-placeholder-white "
                      name="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      style={{
                        backgroundColor: "#aa91ea",
                        borderWidth: "0px",
                        color: "white",
                        borderRadius: 10,
                        outline: "none",
                        border: "none",
                      }}
                      placeholder="Email here"
                    />
                  </form>
                </div>
                {validationMessage && (
                  <div style={{ color: "yellow", marginTop: "5px" }}>
                    {validationMessage}
                  </div>
                )}

                <br />

                <button
                  type="button"
                  className={`subscribe-button ${
                    isSubmitting ? "clicked" : ""
                  }`}
                  disabled={isSubmitting}
                  // class=" bg-white"
                  style={{
                    color: "#6d39e9",
                    margin: 0,
                    borderWidth: 0,
                    backgroundColor: "white",
                    padding: "10px 20px",
                    borderRadius: "10px",
                  }}
                  onClick={handleSubscribe}
                >
                  Subscribe Now
                </button>
                <div className="  mt-3">
                  <img
                    src={Images.card}
                    alt="Subscribe Image"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <p>
                <span className="fw-bold">DISCLAIMER:</span> FindMyTutor
                operates as an online tutoring marketplace and platform that
                facilitates connections between students and tutors. We do not
                provide or endorse tutoring services, nor do we endorse any
                specific tutors or students. FindMyTutor does not verify the
                accuracy or authenticity of information provided by tutors or
                students.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
  // }
};

export default Footer;
