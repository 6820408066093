import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import "./Discover.css";
import Slider from "react-slick";
import { Col, Image, Row } from "react-bootstrap";
import Images from "../../../Helper/Images";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const Discover = () => {
  const benefits = [
    {
      title: "No Commission Fees: Keep 100% of Your Earnings",
      description:
        "Unlike many platforms, we do not charge commission on any of your tutoring sessions. You earn what you deserve.",
    },
    {
      title:
        "Affordable Membership: Enjoy the most affordable membership options offered in the UAE that give you access to a wide range of students.",
      description:
        "100% our tutors who find students make their monthly payment back from delivering just 1-2 sessions to a student that they have found from FindMyTutor",
    },
    {
      title: "Pay a one-time fee:",
      description:
        "As a tutor on FindMyTutor, you only need to pay a single fee to access all student contact details and messaging system for a duration of your choice. Once your selected period ends, you have the flexibility to renew your membership and continue connecting with potential students whenever you need.",
    },
    {
      title:
        "Simple and User-Friendly: Easy to Use for Both Tutors and Students",
      description:
        "Our platform is designed to be straightforward and intuitive, ensuring a seamless experience for tutors and students alike.",
    },
    {
      title: "Wide Reach: Connect with Students All Across the UAE",
      description:
        "Reach a diverse student base seeking expertise in various subjects and levels of education in Dubai, Abu Dhabi, Sharjah, Al-Ain and many more!",
    },
    {
      title: "Profile Management: Showcase Your Expertise",
      description:
        "Create a comprehensive profile highlighting your qualifications, experience, and teaching style to attract potential students.",
    },
    {
      title: "Direct Communication: Connect Directly with Students",
      description:
        " Communicate directly with students to understand their needs and tailor your tutoring approach accordingly.",
    },
    {
      title: "Community Support: Dedicated Customer Support",
      description:
        "Benefit from our responsive customer support team ready to assist you with any questions or issues.",
    },
  ];
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow`}
        style={{ ...style, display: "block", right: "-25px", color: "red" }}
        onClick={onClick}
      >
        <FaArrowRight />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow`}
        style={{ ...style, display: "block", left: "-25px" }}
        onClick={onClick}
      >
        <FaArrowLeft />
      </div>
    );
  };
  const tutors = [
    {
      name: "Mustafa Ahmed",
      stream: "Mathematics, Dubai",
      image: Images.Mustafa, // Add your tutor images here
      rating: 5,
      review:
        "Joining FindMyTutor was the best decision I have made in my tutoring career, it has allowed me to find multiple students and best of all, it doesn’t take any commission in comparison to other websites which I have had experience with that takes a huge fee.",
    },
    {
      name: "John Slater",
      stream: "Science, Abu Dhabi",
      image: Images.john,
      rating: 5,
      review:
        "FindMyTutor has increased my tutor portfolio vastly, there’s always new students looking for tuition and I am now fully booked up! I like that I can cancel whenever I want and I don’t have to pay anymore until I rejoin as a premium member if I am looking for any more students in the future.",
    },
    {
      name: "Faizah Begum",
      stream: "Mathematics, Sharjah",
      image: Images.Faizah,
      rating: 5,
      review:
        "As a math tutor, this platform has been a game-changer for me. I've found committed students who want to improve. It's a great way to earn and make a real difference in students' lives.",
    },
    {
      name: "Lujain Salmah",
      stream: "Art, Dubai",
      image: Images.Lujain,
      rating: 5,
      review:
        "This platform has been exactly what I’ve been looking for in order to find new students. I love that payments are handled by me directly, I made my monthly membership fee back in just one session, highly recommend.",
    },

    {
      name: "Harry Eccersely",
      stream: "English, Abu Dhabi",
      image: Images.harry,
      rating: 5,
      review:
        "Teaching English online to students found on this platform has been fantastic. I've connected with lots of students in the UAE, helping them improve their English skills and prepare for exams. It's a flexible way to earn very well while making a meaningful impact.",
    },
    {
      name: "Daniel Chen",
      stream: "Computer science, Dubai",
      image: Images.Daniel,
      rating: 5,
      review:
        "I am a qualified software engineer and I joined this platform as a programming tutor and it has been highly rewarding. I've helped students master coding skills, and the platform's ability to match me with serious students ensures a steady income. It's a great opportunity to grow professionally and financially.",
    },
    {
      name: "Maria Lopez",
      stream: "Physics, Fujairah",
      image: Images.maria,
      rating: 5,
      review:
        "This is the best platform I have found in the UAE for tutors as no commission is taken and is actually affordable, I literally made my monthly membership fee back in 1.5 sessions that I scheduled from the website. Would definitely recommend.",
    },
    // Add more tutor objects here
  ];
  const settingss = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const Benefits = () => {
    return (
      <Container className="mt-1">
        {/* <h2 variant="h4" gutterBottom>
          Why Join FindMyTutor?
        </h2> */}
        <div className="image-containerd">
          <Image
            style={{ width: "100%", height: "auto" }}
            src={Images.Benifit}
            alt="Benefits"
            layout="responsive"
            objectFit="cover"
            className="imgd"
          />
        </div>
        <h5 className="mt-2" variant="h6" gutterBottom>
          At FindMyTutor, we prioritise your success and ease of tutoring.
          Here’s why joining our platform is beneficial for tutors:
        </h5>
        <List>
          {benefits.map((benefit, index) => (
            <ListItem key={index} alignItems="flex-start">
              <CheckCircleOutline
                color="primary"
                style={{ marginRight: "10px" }}
              />
              <Box>
                <h6 variant="h6">{benefit.title}</h6>
                <p variant="body1">{benefit.description}</p>
              </Box>
            </ListItem>
          ))}
        </List>
        <h5 variant="h6" gutterBottom>
          Read through some of our platform’s tutor reviews to learn about their
          experiences with FindMyTutor.
        </h5>
        <h5 variant="h6" gutterBottom>
          Join FindMyTutor today and start making a difference in students'
          lives while maximising your tutoring opportunities.
        </h5>
      </Container>
    );
  };
  return (
    <Container className="container-fluid bg-light" fixed>
      <Container className=" ">
        {" "}
        <div className="header-container">
          <h2 className="header-title4">Discover our benefits for tutors</h2>
        </div>
        <Benefits />
        <div className="mt-5 mb-5">
          <Row className="justify-content-between position-relative">
            <Col className="position-relative" lg={6}>
              <h1 className="rating">
                <span className="tutors-text" style={{ color: "#6d39e9" }}>
                  {" "}
                  Tutor
                </span>{" "}
                Rating & Reviews
                <div className="image-container-rating">
                  <img
                    src={Images.line}
                    alt="Small Image 2"
                    className="small-image-rating"
                  />
                </div>
              </h1>
            </Col>
            <Col lg={3}>
              <Image className="mb-2" src={Images.star}></Image>
              <Image className="mb-2" src={Images.star}></Image>
              <Image className="mb-2" src={Images.star}></Image>
              <Image className="mb-2" src={Images.star}></Image>
              <Image className="mb-2" src={Images.star}></Image>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                <span style={{ color: "#6d39e9", fontWeight: "bold" }}>
                  {" "}
                  4.8/5
                </span>{" "}
                Average Rating
              </span>
            </Col>
            <Col lg={10} className="mt-4">
              <p>
                Read some of our reviews from tutors on our platform and learn
                about their student connection experiences on FindMyTutor.
              </p>
            </Col>
          </Row>
          <Slider {...settingss}>
            {tutors.map((tutor, index) => (
              <div key={index} className="slid">
                <div className="card  tutor-review d-flex flex-column justify-content-center align-items-center text-center p-4">
                  <div style={{ textAlign: "left" }}>
                    <div className="d-flex align-items-center mb-3">
                      <img
                        src={tutor.image}
                        alt={tutor.name}
                        className="rounded-circle"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "15px",
                        }}
                      />
                      <div className="text-left">
                        <h5 className="mb-1">{tutor.name}</h5>
                        <p className="text-muted mb-3 stream-description">
                          {tutor.stream}
                        </p>
                      </div>
                    </div>

                    <p className="mb-3 review-description">{tutor.review}</p>
                  </div>

                  <div className="d-flex justify-content-end mt-auto w-100">
                    {[...Array(tutor.rating)].map((_, i) => (
                      <img
                        key={i}
                        src={Images.star} // Add your star image here
                        alt="star"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </Container>
  );
};

export default Discover;
