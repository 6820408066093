/** @format */

import { CircularProgress, Container, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { set } from "react-hook-form";
import { FiChevronDown, FiPlus } from "react-icons/fi";
import "./EditProfile.css";
import { apiCallNew } from "../../../Network_Call/apiservices";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { toast } from "react-toastify";
import Select from "react-select";
import { EditOutlined, PlusOneTwoTone } from "@mui/icons-material";
import Availability from "../../../Components/Model/Availability";

import { useNavigate } from "react-router-dom";
import { Logout } from "../../../Helper/Storage";
var first = "";
const EditProfile = () => {
  const [fullnameError, setFullnameError] = useState("");
  const [fullname, setFullname] = useState("");
  const [qualification, setQualification] = useState("");
  const [qualificationError, setQualificationError] = useState("");
  const [experience, setExperience] = useState("");
  const [experienceError, setExperienceError] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [availability, setAvailability] = useState("");
  const [availabilityError, setAvailabilityError] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [fileName, setFileName] = useState(null);
  const [email, setEmail] = useState("");
  const [grades, setGrades] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [gradeError, setGradeError] = useState("");
  const [gradeTypeError, setGradeTypeError] = useState("");
  const [fileError, setFileError] = useState("");
  const [showParentModal, setShowParentModal] = useState(false);
  const [genderError, setGenderError] = useState("");
  const [gender, setGender] = useState("");
  const [load, setload] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [subcat, setSubcat] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [catType, setCatType] = useState();
  const [gradeType, setGradeType] = useState();
  const [showSubj, setShowSubj] = useState(false);
  const [subgrade, setSubgrade] = useState([]);
  const [selectedSubgrade, setSelectedSubgrades] = useState([]);
  const [usersubcat, setusersubcat] = useState([]);
  const [usersubgrade, setusersubgrade] = useState([]);
  const [contact, setContact] = useState("");
  const [contactError, setContactError] = useState("");
  const [SubSubsubjectError, setSubSubsubjectError] = useState("");
  const [SubgradeError, setSubgradeError] = useState("");

  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  console.log("usersubgrade", usersubgrade);
  console.log("usersubgrade", usersubgrade);

  const handleOpenParentModal = () => {
    setShowParentModal(true);
  };
  const handleCloseParentModal = () => setShowParentModal(false);
  useEffect(() => {
    GetTutorProfile();
    GetSubjectList();
    GetGradeList();
    GetTimeSlot();
    // sfirst();
  }, []);

  const toggleSubjOptions = () => {
    setShowSubj(!showSubj);
  };
  const handleFullnameChange = (e) => {
    const value = e.target.value;
    const maxLength = 50; // Define the maximum length for the full name

    if (/^[a-zA-Z\s]*$/.test(value)) {
      if (value.length <= maxLength) {
        setFullname(value);
        setFullnameError("");
      } else {
        setFullnameError(
          `Full Name should not exceed ${maxLength} characters.`
        );
      }
    } else {
      setFullnameError(
        "Full Name should contain only alphabetical characters (A to Z)."
      );
    }
  };
  const handleQualiChange = (e) => {
    console.log("EEEEEE", e);
    const value = e.target.value;
    setQualification(value);
    if (!value) {
      setQualificationError("Qualification is required.");
    } else if (/^[a-zA-Z\s]*$/.test(value)) {
      setQualification(value);
      setQualificationError("");
    } else {
      setQualificationError(
        "Qualification should contain only alphabetical characters(A to Z)."
      );
    }
  };
  const handleExpChange = (e) => {
    const value = e.target.value;
    setExperience(value);
    if (!value) {
      setExperienceError("Experience is required.");
    } else {
      setExperienceError("");
    }
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setCatType(value);
    getSubjecList(value);
    setSubcat([]);
  };
  const handleGradeChange = (event) => {
    const value = event.target.value;
    setGradeType(value);
    getGradList(value);
    setSubgrade([]);
  };
  const getSubjecList = async (id) => {
    console.log("id", id);
    const response = await apiCallNew(
      "get",
      null,
      `${ApiEndPoints.SubjectList}/${id}`
    );
    if (response.success === true) {
      setSubcat(response.result);
    }
  };

  const getGradList = async (id) => {
    const response = await apiCallNew(
      "get",
      null,
      `${ApiEndPoints.GradeList}/${id}`
    );
    console.log("RESPONSEOFGrade", response);
    if (response.success === true) {
      setSubgrade(response.result);
    }
  };
  const subcatOptions = subcat.map((subject) => ({
    value: subject?.id,
    label: subject?.title,
  }));
  const subgradeOptions = subgrade.map((grade) => ({
    value: grade.id,
    label: grade.class_name,
  }));
  const handleSubCategoryChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setusersubcat(selectedValues);
  };
  const handleSubgradeChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setusersubgrade(selectedValues);
  };

  const filteredData1 = subcat.filter((element1) =>
    usersubcat?.some((element2) => element1.id == element2)
  );
  const filteredData2 = subgrade.filter((element1) =>
    usersubgrade?.some((element2) => element1.id == element2)
  );
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        setFileError("Only PNG, JPEG, and JPG files are allowed.");
        return;
      } else {
        setSelectedFile(file); // Set selected file
        setFileName(file.name);
      }
      // Update state with the file name
    } else {
      setSelectedFile(null); // Reset selected file
      setFileName(""); // Reset state if no file is selected
    }
    setFileError("");
  };
  const truncateFileName = (fileName, maxLength) => {
    if (fileName?.length <= maxLength) {
      return fileName;
    } else {
      return fileName?.slice(0, maxLength) + "...";
    }
  };
  const truncateDays = (days, maxLength) => {
    if (days?.length <= maxLength) {
      return days;
    } else {
      return days.slice(0, maxLength) + "...";
    }
  };
  const handleGenderChange = (e) => {
    const value = e.target.value;
    setGender(value);

    if (!value) {
      setGenderError("Gender is required.");
    } else {
      setGenderError("");
    }
  };
  const GetSubjectList = async () => {
    apiCallNew("get", null, ApiEndPoints.SubjectTypeList)
      .then((response: any) => {
        // console.log("subject>>>>", response);
        if (response.success === true) {
          setSubjectList(response.result);
          // toast(response.msg);
        } else {
          toast(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
      });
  };
  const GetGradeList = async () => {
    apiCallNew("get", null, ApiEndPoints.GradeTypeList)
      .then((response: any) => {
        if (response.success === true) {
          console.log("response??>", response);
          setGradeList(response?.result);
        } else {
          toast(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
      });
  };
  const GetTutorProfile = () => {
    setload(true);
    const response = apiCallNew(
      "get",
      null,
      role == "Tutor"
        ? ApiEndPoints.Get_Tutor_Profile
        : ApiEndPoints.Get_Parent_Profile
    ).then((response) => {
      console.log("Response", response);
      if (response.success === true) {
        setload(false);
        setFullname(response?.result?.name);
        setContact(response?.result?.mobile_number);
        setQualification(response?.result?.education);
        setExperience(response?.result?.experience);
        setSubject(response?.result?.subject_type_id);
        // setAvailability(response?.result?.availability)
        setGender(response.result.gender);
        setEmail(response?.result?.email);
        setGrades(response?.result?.grade_ids_arr);
        setusersubcat(response?.result?.subject_ids_arr);
        setusersubgrade(response?.result?.grade_ids_arr);

        setCatType(response?.result?.subject_type_id);
        setGradeType(response?.result?.grade_type_id);
        getSubjecList(response?.result.subject_type_id);
        getGradList(response?.result.grade_type_id);

        setSelectedFile(response?.result?.profile_image);
        setFileName(response?.result?.profile_image_name);
      } else {
        if (response?.code == 259) {
          Logout();
        }
        setload(false);
      }
    });
  };
  const validateForm = async () => {
    if (role === "Tutor") {
      let isValid = true;
      if (!fullname) {
        setFullnameError("Full Name is required");
        isValid = false;
      }
      if (!experience) {
        setExperienceError("Experience is required");
        isValid = false;
      }
      if (!qualification) {
        setQualificationError("Qualification is requireds");
        isValid = false;
      }
      if (!catType) {
        setSubjectError("Please select a Subject type.");
        isValid = false;
      }
      if (!gradeType) {
        setGradeTypeError("Please select a Grade Type.");
        isValid = false;
      }
      if (grades?.length === 0) {
        setGradeError("Grade is required");
        isValid = false;
      }
      if (catType && usersubcat?.length === 0) {
        setSubSubsubjectError("Please select at least one sub-subject.");
        isValid = false;
      }
      if (gradeType && usersubgrade?.length === 0) {
        setSubgradeError("Please select at least one sub-grade.");
        isValid = false;
      }

      return isValid;
    } else {
      let isValid = true;
      if (!fullname) {
        setFullnameError("Full Name is required");
        isValid = false;
      }
      if (!gender) {
        setGenderError("Gender is required");
        isValid = false;
      }
      if (!contact) {
        setContactError("Contact is required");
        isValid = false;
      }

      return isValid;
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = await validateForm();
    console.log("isFormValid", isFormValid);

    if (isFormValid) {
      if (role === "Tutor") {
        UpdateTutorProfile();
      } else {
        UpdateParentrofile();
      }
    }
  };

  const UpdateTutorProfile = () => {
    if (validateForm() === false) return;
    setload(true);
    const formdata = new FormData();
    usersubgrade?.forEach((subcat) => {
      formdata.append("grade_ids[]", subcat);
    });
    usersubcat?.forEach((subcat) => {
      formdata.append("subject_ids[]", subcat);
    });
    formdata.append("name", fullname);
    formdata.append("education", qualification);
    formdata.append("experience", experience);
    formdata.append("subject_id", subject);
    formdata.append("email", email);
    formdata.append("gender", "Male");
    formdata.append("intro_yourself", "heloo");
    formdata.append("hourly_rate", "100");
    formdata.append("subject_type_id", catType);
    formdata.append("grade_type_id", gradeType);
    // formdata.append("grade_ids[]", grades);
    if (selectedFile) {
      formdata.append("profile_image", selectedFile);
    }
    apiCallNew("post", formdata, ApiEndPoints.Update_Tutor_Profile).then(
      (response) => {
        console.log("response###", response);
        if (response.success === true) {
          setload(false);
          // navigate("/tutor");
          // window.location.reload();

          GetTutorProfile();
          toast.success(response.msg);
        } else {
          setload(false);
          toast.error(response.msg);
        }
      }
    );
  };
  const UpdateParentrofile = () => {
    setload(true);
    const formdata = new FormData();
    // usersubgrade.forEach((subcat) => {
    //   usersubcat.forEach((subcat) => {
    //     formdata.append("subject_ids[]", subcat);
    //   });
    //   formdata.append("subject_ids[]", subcat);
    // });
    usersubgrade?.forEach((subcat) => {
      formdata.append("grade_ids[]", "2");
    });
    usersubcat?.forEach((subcat) => {
      formdata.append("subject_ids[]", "2");
    });
    formdata.append("grade_ids[]", "2");
    formdata.append("subject_ids[]", "2");
    formdata.append("name", fullname);
    formdata.append("grade_type_id", "2");
    formdata.append("subject_type_id", "2");
    formdata.append("mobile_number", contact);
    // formdata.append("subject_id", subject);
    formdata.append("gender", gender);
    // formdata.append("grade_ids[]", grades);
    if (selectedFile) {
      formdata.append("profile_image", selectedFile);
    }
    apiCallNew("post", formdata, ApiEndPoints.Update_Parent_Profile).then(
      (response) => {
        console.log("parent response ", response);
        if (response.success === true) {
          // navigate("/parent");
          setload(false);

          // window.location.reload();
          toast.success(response.msg);
        } else {
          setload(false);
          toast.error(response.msg);
        }
      }
    );
  };
  const GetTimeSlot = () => {
    apiCallNew("get", null, ApiEndPoints.GetTutorSlot)
      .then((response: any) => {
        if (response.success === true) {
          setDataArray(response.result[0]);
        } else {
          toast(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
      });
  };
  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "rgb(233,236,239)", // Change this to your desired background color
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "black", // Change this to your desired text color
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "black", // Change this to your desired remove icon color
      ":hover": {
        backgroundColor: "#6D39E8", // Change this to your desired hover background color
        color: "white", // Change this to your desired hover text color
      },
    }),
  };

  return (
    <Container className="contain">
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <br />
      <div className="col-md-6" style={{ backgroundColor: "red" }}></div>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <h3 style={{}}>Update Profile</h3>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={1}></Col>
        <Col md={10} className="Col">
          <div
            className="card2 card2-shadow"
            style={{
              borderWidth: 0,
              backgroundColor: "#ffffff",
            }}
          >
            <Form onSubmit={onSubmit}>
              <div className="card2-body row justify-content-around">
                <Col lg={5} md={6} sm={12} xs={12}>
                  <Form.Group controlId="fullname" className="mb-3">
                    <label style={{ marginLeft: 2 }}>
                      Full Name<span style={{ color: "red" }}> *</span>
                    </label>
                    <Form.Control
                      type="text"
                      placeholder="Full Name"
                      value={fullname}
                      onChange={handleFullnameChange}
                      onFocus={() => setFullnameError("")}
                    />
                    {fullnameError && (
                      <span style={{ color: "red", fontSize: "smaller" }}>
                        {fullnameError}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={5} md={6} sm={12} xs={12}>
                  <Form.Group controlId="email" className="mb-3">
                    <label style={{ marginLeft: 2 }}>Email</label>
                    <span style={{ color: "red" }}> *</span>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      value={email}
                      disabled={true}
                      className="custom-disabled"
                      style={{ backgroundColor: "#fff" }}
                    />
                  </Form.Group>
                </Col>
                {role === "Tutor" && (
                  <>
                    <Col lg={5} md={6} sm={12} xs={12}>
                      <Form.Group controlId="qualification" className="mb-3">
                        <label style={{ marginLeft: 2 }}>
                          Qualification<span style={{ color: "red" }}> *</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Qualification"
                          value={qualification}
                          onFocus={() => setQualificationError("")}
                          onChange={handleQualiChange}
                        />
                        {qualificationError && (
                          <span style={{ color: "red", fontSize: "smaller" }}>
                            {qualificationError}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={5} md={6} sm={12} xs={12}>
                      <Form.Group controlId="experience" className="mb-3">
                        <label style={{ marginLeft: 2 }}>
                          Experience<span style={{ color: "red" }}> *</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Experience"
                          value={experience}
                          onChange={handleExpChange}
                          onFocus={() => setExperienceError("")}
                        />
                        {experienceError && (
                          <span style={{ color: "red", fontSize: "smaller" }}>
                            {experienceError}
                          </span>
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={5} md={6} sm={12} xs={12}>
                      <Form.Group controlId="subject" className="mb-3">
                        <label style={{ marginLeft: 2 }}>
                          Subject<span style={{ color: "red" }}> *</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <Form.Control
                            as="select"
                            onChange={handleCategoryChange}
                            value={catType}
                            onFocus={() => setSubjectError("")}
                          >
                            <option value="">Select</option>
                            {subjectList &&
                              subjectList?.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.subject_type}
                                </option>
                              ))}
                          </Form.Control>
                          <FiChevronDown
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "10px",
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                            }}
                          />
                        </div>
                        {subjectError && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "smaller",
                              marginLeft: "4px",
                            }}
                          >
                            {subjectError}
                          </span>
                        )}
                        {/* {catType && ( */}
                        <div style={{ marginTop: "1rem" }}>
                          <Select
                            options={subcatOptions}
                            value={filteredData1.map((item) => ({
                              value: item.id,
                              label: item.title,
                            }))}
                            isDisabled={!catType}
                            isMulti
                            onFocus={() => setSubSubsubjectError("")}
                            onChange={handleSubCategoryChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select sub-subject..."
                            // style={{ width: "100%", height: 40 }}
                          />
                        </div>
                        {/* )} */}
                        {SubSubsubjectError && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "smaller",
                              marginLeft: "4px",
                            }}
                          >
                            {SubSubsubjectError}
                          </span>
                        )}
                      </Form.Group>
                    </Col>

                    {/* {role === "Tutor" && (
                  <Col lg={5} md={6} sm={12} xs={12}>
                    <Form.Group controlId="availability" className="mb-3">
                      <label style={{ marginLeft: 2 }}>
                        Availability<span style={{ color: "red" }}> *</span>
                      </label>

                      <div style={{ position: "relative" }}>
                        <FiPlus
                          style={{
                            position: "absolute",
                            top: "50%",
                            // left: "260px",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={handleOpenParentModal}
                        />
                        <Form.Control
                          type="text"
                          disabled={true}
                          value={`Days: ${truncateFileName(
                            dataArray?.day_slot,
                            15
                          )} Time: ${dataArray?.start_time} to ${
                            dataArray?.end_time
                          }`}
                          onChange={handleAvailChange}
                          placeholder="Update Availability"
                          className="custom-disabled"
                          style={{ backgroundColor: "#fff", fontSize: 12 }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                )} */}
                    <Col lg={5} md={6} sm={12} xs={12}>
                      <Form.Group controlId="grade" className="mb-3">
                        <label style={{ marginLeft: 2 }}>
                          Grade<span style={{ color: "red" }}> *</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <Form.Control
                            as="select"
                            value={gradeType}
                            onChange={handleGradeChange}
                            // onClick={toggleSubjOptions}
                            onFocus={() => setGradeTypeError("")}
                          >
                            <option value="">Select</option>

                            {gradeList?.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.grade_type}
                              </option>
                            ))}
                          </Form.Control>
                          <FiChevronDown
                            className="gender-icon"
                            onClick={toggleSubjOptions}
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "10px",
                              transform: "translateY(-50%)",
                            }}
                          />
                        </div>
                        {/* <div style={{}}>
                      <Select
                        options={gradeOptions}
                        value={filteredData.map((item) => ({
                          value: item.id,
                          label: item.class_name,
                        }))}
                        isMulti
                        onChange={handleGradeChange}
                        onBlur={handleBlur}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select grades..."
                        styles={customStyles}
                      />
                    </div> */}
                        {gradeTypeError && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "smaller",
                              marginLeft: "4px",
                            }}
                          >
                            {gradeTypeError}
                          </span>
                        )}

                        {/* {gradeType && ( */}
                        <div style={{ marginTop: "1rem" }}>
                          <Select
                            options={subgradeOptions}
                            isMulti
                            isDisabled={!gradeType}
                            onChange={handleSubgradeChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select sub-grades..."
                            value={filteredData2.map((item) => ({
                              value: item.id,
                              label: item.class_name,
                            }))}
                            onFocus={() => setSubgradeError("")}
                            // style={{ width: "100%", height: 40 }}
                          />
                        </div>
                        {/* )} */}
                        {SubgradeError && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "smaller",
                              marginLeft: "4px",
                            }}
                          >
                            {SubgradeError}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </>
                )}

                {role === "Parent" && (
                  <>
                    <Col lg={5} md={6} sm={12} xs={12}>
                      <Form.Group controlId="gender" className="mb-3">
                        <label style={{ marginLeft: 2 }}>
                          Gender<span style={{ color: "red" }}> *</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <Form.Control
                            as="select"
                            onChange={handleGenderChange}
                            value={gender}
                            onFocus={() => setGenderError("")}
                          >
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Form.Control>
                          <FiChevronDown
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "10px",
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                            }}
                          />
                        </div>
                        {genderError && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "smaller",
                              marginLeft: "4px",
                            }}
                          >
                            {genderError}
                          </span>
                        )}
                      </Form.Group>
                    </Col>{" "}
                    <Col lg={5} md={6} sm={12} xs={12}>
                      <Form.Group controlId="fullname" className="mb-3">
                        <label style={{ marginLeft: 2 }}>
                          Contact Number<span style={{ color: "red" }}> *</span>
                        </label>
                        <Form.Control
                          type="tel"
                          maxLength={13}
                          placeholder="Contact Number"
                          value={contact}
                          onFocus={() => setContactError("")}
                          onChange={(e) => setContact(e.target.value)}
                        />
                        {contactError && (
                          <span style={{ color: "red", fontSize: "smaller" }}>
                            {contactError}
                          </span>
                        )}
                      </Form.Group>
                    </Col>{" "}
                  </>
                )}

                <Col lg={5} md={6} sm={12} xs={12}>
                  <Form.Group controlId="formFile">
                    <label>Upload Profile</label>
                    {/* <span style={{ color: "red" }}> *</span> */}
                    <div className="d-flex justify-content-between">
                      <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        className=" w-50"
                        style={{ display: "none" }}
                        id="file-input"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          border: "1px solid lightgray",

                          borderRadius: "4px",
                        }}
                      >
                        <label
                          style={{
                            padding: "5px",
                            borderRadius: "4px 0px 0px 4px",
                            // backgroundColor: "red",
                          }}
                        >
                          {fileName
                            ? truncateFileName(fileName, 20)
                            : "No file chosen"}
                        </label>
                        <label
                          htmlFor="file-input"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#e9ecef",
                            padding: "6px 4px 6px 4px",
                            alignSelf: "center",
                            borderRadius: "0px 4px 4px 0px",
                          }}
                        >
                          {" "}
                          Choose File
                        </label>
                      </div>
                    </div>

                    {fileError && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "smaller",
                          marginLeft: "4px",
                        }}
                      >
                        {fileError}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={5} md={6} sm={12} xs={12} />

                <div className="col-md-11 mt-2" style={{ padding: 0 }}>
                  <Button type="submit" className="btn  btn-primary ">
                    Update
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Col>
        <Col md={1}></Col>
        <Availability
          showParentModal1={showParentModal}
          handleClose={handleCloseParentModal}
        />
      </Row>
    </Container>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};
export default EditProfile;
