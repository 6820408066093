/** @format */

import React, { useEffect, useState } from "react";
import "./FindTutor.css";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import Images from "../../../Helper/Images";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../../Network_Call/apiservices";
import { CircularProgress, Pagination } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { getToken } from "../../../Helper/Storage";
import { FaCrown } from "react-icons/fa";
import Select from "react-select";
const TruncateText = ({ text, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };
  const shouldTruncate = text?.length > maxLength;
  const truncatedText = shouldTruncate
    ? text?.slice(0, maxLength) + "..."
    : text;

  return (
    <div>
      {isTruncated ? truncatedText : text}
      {text?.length > maxLength && (
        <span
          onClick={toggleTruncate}
          style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
        >
          {isTruncated ? "Show More" : "Show Less"}
        </span>
      )}
    </div>
  );
};
const FindTutor = () => {
  const itemsPerPage = 20;
  const subName = useLocation();
  const sname = subName?.state;
  const [tutorList, setTutorList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [standard, setStandard] = useState([]);
  const [subject, setSubject] = useState([]);
  const [experience, setExperience] = useState("");
  const [availability, setAvailability] = useState("");
  const [search, setSearch] = useState("");
  const [price, setPrice] = useState("");
  const [grade, setGrade] = useState("");
  const [errors, setErrors] = useState({});
  const [load, setload] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const pages = page - 1;
  const token = getToken();
  console.log("tutorList", tutorList);
  useEffect(() => {
    FindTutorList();
  }, [page, pages, search, subject, grade]);

  useEffect(() => {
    GetSubjectList();
    GetGradeList();
  }, []);

  const handleApplyFilter = () => {
    FindTutorList();
  };

  const FindTutorList = async () => {
    // setload(true);
    const formData = new FormData();
    formData.append("page", pages);
    subject.forEach((sub) => formData.append("subject_ids[]", sub.value));
    formData.append("experience", experience);
    formData.append("hourly_rate", price);
    formData.append("education", "");
    formData.append("grade_id", grade);
    formData.append("keyword", search);
    try {
      const response = await fetch(`${ApiEndPoints.FindTutor}`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      console.log("resss>>", data.result);
      if (data.success === true) {
        setload(false);
        setTutorList(data.result);
        setCount(data?.count);
      } else {
        setload(false);
      }
    } catch (error) {
      setload(false);
      // toast.error(error.message);
    }
  };
  const FindTutorList1 = async () => {
    // setload(true);
    const formData = new FormData();
    formData.append("page", pages);
    subject.forEach((sub) => formData.append("subject_id[]", sub.value));
    formData.append("experience", experience);
    formData.append("hourly_rate", price);
    formData.append("education", "");
    formData.append("grade_id", grade);
    formData.append("keyword", search);
    try {
      const response = await fetch(`${ApiEndPoints.FindTutor}`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      console.log("resss>>", data.result);
      if (data.success === true) {
        setload(false);
        setTutorList(data.result);
        setCount(data?.count);
        setSubject("");

        setExperience("");
        setAvailability("");
        setPrice("");
        setGrade("");
        setErrors({});
      } else {
        setload(false);
      }
    } catch (error) {
      setload(false);
      // toast.error(error.message);
    }
  };
  const handleClearFilter = async () => {
    FindTutorList1();
    setSubject([]);
  };
  const iconClick = () => {
    setload(true);
    FindTutorList();
  };
  const GetSubjectList = async () => {
    try {
      const response = await apiCallNew("get", null, ApiEndPoints.SubjectList);
      if (response.success) {
        setSubjectList(response.result);
      } else {
        // toast.error(response.msg);
      }
    } catch (error) {
      toast.error("An error occurred while fetching the subject list.");
    }
  };
  const GetGradeList = async () => {
    apiCallNew("get", null, ApiEndPoints.GradeList)
      .then((response) => {
        if (response.success === true) {
          // console.log("sdasss", response);
          setStandard(response?.result);
        } else {
          // toast(response.msg);
        }
      })
      .finally((er) => {
        // toast(er);
      });
  };
  const truncateFileName = (fileName, maxLength) => {
    if (fileName?.length <= maxLength) {
      return fileName;
    } else {
      return fileName?.slice(0, maxLength) + "...";
    }
  };
  const handleChange = (event, value) => {
    setPage(value);
    FindTutorList();
  };
  const currentItems = tutorList?.slice(page - 1);

  return (
    <>
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <Container fluid style={{ padding: "0px 40px" }}>
        <div className="header-container7 mt-3">
          <h2 className="header-title7">Find Tutor</h2>
        </div>

        {/* <div
          className="shadow rounded"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <input
            type="search"
            id="nav-toggle"
            placeholder="What are you looking for?"
            className="search-input"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <button
            className="search-button"
            style={{ margin: 6 }}
            onClick={FindTutorList}
          >
            Search Now
          </button>
        </div> */}
        <Row className="justify-content-center mt-2">
          <Col sm={12} md={12} lg={4} xs={12}>
            <div className="search-container4">
              <input
                type="text"
                className="search-input1"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Image
                onClick={iconClick}
                className="cursor-pointer"
                src={Images.mangifier}
              />
              {/* {searchList.length > 0 && showParentModal && (
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <Paper
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 20,
                      right: 0,
                      zIndex: 1,
                      height: 200,
                      width: "22%",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      style={{
                        height: "100%",
                        overflowY: "auto",
                      }}
                    >
                      <List>
                        {searchList?.map((result, index) => (
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "#6d39e9",
                            }}
                            state={result?.subject_ids}
                            to={"/findTutor"}
                          >
                            <ListItem
                              button
                              key={index}
                              onClick={handleClickAway}
                            >
                              <ListItemText primary={result?.subject_names} />
                            </ListItem>
                          </Link>
                        ))}
                      </List>
                    </Box>
                  </Paper>
                </div>
              </ClickAwayListener>
            )} */}
            </div>
            <div className="filter-form mt-2 shadow pb-4">
              <h2 className="filter-title">Subject & level</h2>
              {/* <select
                className={`filter-select ${errors?.subject ? "error" : ""}`}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              >
                <option value="">Select Subject</option>
                {subjectList.map((it) => (
                  <option key={it?.id} value={it?.id}>
                    {it?.subject_name}
                  </option>
                ))}
              </select> */}
              <Select
                isMulti
                value={subject}
                onChange={(selectedOptions) => setSubject(selectedOptions)}
                options={subjectList.map((it) => ({
                  value: it.id,
                  label: it.subject_name,
                }))}
                classNamePrefix="filter-select"
              />

              <h2 className="filter-title">Study Grade</h2>
              <select
                className={`filter-select ${errors.grade ? "error" : ""}`}
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
              >
                <option value="">Select Grade</option>
                {standard?.length > 0 &&
                  standard?.map((it) => (
                    <option key={it?.id} value={it?.id}>
                      {it?.class_name}
                    </option>
                  ))}
              </select>
              {/* {errors.grade && (
                  <span className="error-text">{errors.grade}</span>
                )} */}

              {/* <button
                className="apply-filter-button mt-4"
                onClick={handleApplyFilter}
              >
                Apply filter
              </button> */}
              <button
                className="clear-filter-button shadow mt-1 rounded"
                onClick={() => {
                  handleClearFilter();
                  FindTutorList();
                }}
              >
                Clear filter
              </button>
            </div>
          </Col>

          <Col sm={12} md={12} lg={8} xs={12}>
            {!load && currentItems.length == 0 ? (
              <div className="text-center h-100 d-flex justify-content-center align-items-center">
                {" "}
                <h6> No Data Found</h6>
              </div>
            ) : (
              currentItems?.map((item, index) => {
                return (
                  <>
                    <div className="tutor-card-f shadow">
                      <Row className="tutor-card-header">
                        <Col md={2} lg={2} xl={3} sm={12} xs={4}>
                          {item?.profile_image ? (
                            <img
                              src={item?.profile_image}
                              alt="Profile"
                              className="tutor-profile-pic shadow"
                              style={{ width: "80px", height: "80px" }}
                            />
                          ) : (
                            <div
                              className="tutor-profile-placeholder shadow"
                              style={{
                                width: "80px",
                                height: "80px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#f0f0f0",
                                borderRadius: "50%",
                                fontSize: "24px",
                                fontWeight: "bold",
                                color: "#333",
                              }}
                            >
                              {item?.name?.charAt(0).toUpperCase()}
                            </div>
                          )}
                          {item?.subscribe_package_type === 1 && (
                            <div className="premium-badge">
                              {" "}
                              <FaCrown className="icon" /> Premium
                            </div>
                          )}
                        </Col>
                        <Col
                          md={6}
                          lg={6}
                          xl={6}
                          sm={12}
                          xs={8}
                          className="tutor-info mt-2"
                        >
                          <h2 className="tutor-name">{item?.name}</h2>
                          <p style={{ color: "black" }}>
                            Gender:
                            <span className=" ms-1 tutor-location">
                              {item?.gender}
                            </span>
                          </p>
                          <div className="tutor-stats">
                            {item?.class_names && (
                              <div className="tutor-stat ">
                                <img src={Images.Class} className="icon" />
                                <i className="icon-class"></i>
                                <TruncateText
                                  text={item?.class_names}
                                  maxLength={70}
                                />
                              </div>
                            )}
                            {item?.subject_names && (
                              <div className="tutor-stat">
                                <img src={Images.Sub} className="icon" />
                                <i className="icon-mathematics"></i>
                                <TruncateText
                                  text={item?.subject_names}
                                  maxLength={70}
                                />
                              </div>
                            )}
                          </div>
                          <div className="tutor-stats mt-2">
                            {item?.experience && (
                              <div className="tutor-stat">
                                <img src={Images.Exp} className="icon" />
                                <i className="icon-experience"></i>
                                {/* {item?.experience} */}
                                <TruncateText
                                  text={item?.experience}
                                  maxLength={130}
                                />
                              </div>
                            )}
                            {/* {item?.grade_ids && (
                                <div className="tutor-stat">
                                  <img
                                    src={Images.clenderfind}
                                    className="icon"
                                  />
                                  <i className="icon-availability"></i>
                                  {item?.grade_ids}
                                </div>
                              )} */}
                          </div>
                        </Col>
                        <Col
                          md={4}
                          lg={4}
                          sm={12}
                          xl={4}
                          xs={12}
                          className="tutor-price  mt-2"
                        >
                          <h2 className="tutor-name">Starting from</h2>
                          <p className="price">
                            AED {item?.hourly_rate ? item?.hourly_rate : 0}/hr
                          </p>
                          <Link
                            state={{
                              users: item?.id,
                              img: item?.profile_image,
                              name: item?.name,
                            }}
                            to={token ? "/messageView" : "/login"}
                          >
                            <button className="message-button mt-2">
                              Send Message
                            </button>
                          </Link>
                        </Col>
                        {token &&
                        (item?.subscribe_package_type === 1 ||
                          item?.subscribe_package_type === 2) ? (
                          <>
                            {" "}
                            <Col className="text-end" lg={6} xl={12}>
                              Email : {item?.email}
                            </Col>
                            <Col className="text-end " lg={6} xl={12}>
                              <span className="me-2">
                                {" "}
                                Phone : {item?.mobile_number}
                              </span>
                            </Col>
                          </>
                        ) : null}

                        <Row className="justtify-content-start mt-2">
                          <Col className="test-start">
                            {" "}
                            {/* <div className="tutor-card-body">
                              <div className="tutor-bio" />
                              <div className="underbody">
                                <p className=" ms-2   tutor-title">
                                  <span className="fw-bold"> Desc:</span> Lorem
                                  ipsum dolor sit amet consectetur adipisicing
                                  elit. Aspernatur, cumque nihil dolorem qui
                                  consequuntur delectus repudiandae recusandae
                                  error quidem officia.
                                </p>
                                <p className="tutor-description">
                                  {item?.intro_yourself_to_student}
                                </p>
                              </div>
                            </div> */}
                            <p>
                              {" "}
                              <span className="fw-bold me-1">
                                Description:
                              </span>{" "}
                              <TruncateText
                                text={item?.intro_yourself_to_student}
                                maxLength={180}
                              />
                            </p>
                          </Col>
                        </Row>
                      </Row>
                    </div>
                    <br />
                  </>
                );
              })
            )}
          </Col>
        </Row>
        <Pagination
          count={Math.ceil(count / itemsPerPage)}
          page={page}
          onChange={handleChange}
          shape="rounded"
          className="d-flex justify-content-end mt-4"
        />
      </Container>
    </>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default FindTutor;
