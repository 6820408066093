import React, { useEffect, useState } from "react";
import "./Subjects.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../../Network_Call/apiservices";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const Subjects = () => {
  const [formdata, setFormData] = useState([]);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    GetSubjectList();
  }, []);
  console.log("formdata", formdata);
  const subjectCategories = [
    {
      title: "STEM (Science, Technology, Engineering, Mathematics)",
      subjects: [
        "Mathematics",
        "Science",
        "General Science",
        "Physics",
        "Chemistry",
        "Biology",
        "Environmental Science",
        "Earth Science",
        "Astronomy",
        "Geology",
        "Meteorology",
        "Oceanography",
        "Computer Science",
        "Programming (Python, Java, C++, etc.)",
        "Web Development (HTML, CSS, JavaScript)",
        "Mobile App Development",
        "UI/UX Design",
        "Data Science",
        "Machine Learning",
        "Artificial Intelligence",
        "Cybersecurity",
        "Cryptography",
        "Blockchain Technology",
        "Game Design and Development",
        "Virtual Reality (VR) Development",
      ],
    },
    {
      title: "Business and Economics",
      subjects: [
        "Economics",
        "Business Studies",
        "Business Management",
        "Entrepreneurship",
        "Business Ethics",
        "Human Resource Management",
        "Accounting",
        "Financial Accounting",
        "Managerial Accounting",
        "Finance",
        "Corporate Finance",
        "Personal Finance",
        "Investment Banking",
        "Financial Planning",
        "Marketing",
        "Digital Marketing",
        "Social Media Marketing",
        "Content Marketing",
        "Search Engine Optimization (SEO)",
        "Market Research",
      ],
    },
    {
      title: "Humanities",
      subjects: [
        "History",
        "World History",
        "American History",
        "European History",
        "Ancient History",
        "Medieval History",
        "Modern History",
        "Geography",
        "Political Science",
        "International Relations",
        "Political Theory",
      ],
    },
  ];
  const GetSubjectList = async () => {
    setLoad(true);
    apiCallNew("get", null, ApiEndPoints.SubjectTypeList)
      .then((response: any) => {
        if (response.success === true) {
          setLoad(false);
          setFormData(response?.result);

          // toast(response.msg);
        } else {
          toast(response.msg);

          setLoad(false);
        }
      })
      .finally((er) => {
        toast(er);

        setLoad(false);
      });
  };
  return (
    <Container>
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      {formdata?.map((category, index) => (
        <Card key={index} className="my-3">
          <Card.Header as="h5">{category?.subject_type}</Card.Header>
          <Card.Body>
            <Row>
              {category?.subjects?.map((subject, subIndex) => (
                <Col xs={12} md={6} lg={4} key={subIndex} className="my-2">
                  <Card.Text>{subject?.subject_name}</Card.Text>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      ))}
    </Container>
    // <Container>
    //   {subjectCategories?.map((category, index) => (
    //     <Card key={index} className="my-3">
    //       <Card.Header as="h5">{category?.title}</Card.Header>
    //       <Card.Body>
    //         <Row>
    //           {category?.subjects?.map((subject, subIndex) => (
    //             <Col xs={12} md={6} lg={4} key={subIndex} className="my-2">
    //               <Card.Text>{subject}</Card.Text>
    //             </Col>
    //           ))}
    //         </Row>
    //       </Card.Body>
    //     </Card>
    //   ))}
    // </Container>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default Subjects;
