import React, { useEffect, useState } from "react";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../../Network_Call/apiservices";
import { Container } from "react-bootstrap";

const RefundPolicy = () => {
  const [termsCondition, setTermsCondition] = useState("");

  useEffect(() => {
    TermsConditionApi();
  }, []);

  const TermsConditionApi = () => {
    apiCallNew("get", null, ApiEndPoints.RefundPolicy).then((res) => {
      console.log("TermsCondition", res);
      setTermsCondition(res?.result);
    });
  };
  return (
    <div className="bodyprivacy">
      <div className="header-privacy">
        <h2 className="header-privacy">Refund Policy</h2>
      </div>
      <br />
      <Container className="mt-5">
        {/* <div className="row">
<div className="col-lg-1"></div>
<div className="col-lg-10" style={{ textAlign: "center" }}>
<p>
  {termsCondition?.content
    ?.replace(/<[^>]+>/g, "")
    .replace(/&nbsp;/g, " ")}
</p>
</div>
<div className="col-lg-1"></div>
</div> */}
        <div dangerouslySetInnerHTML={{ __html: termsCondition?.content }} />
      </Container>
    </div>
  );
};

export default RefundPolicy;
