import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
const TimeSetComponent = ({
  show,
  startTime,
  endTime,
  startTimeError,
  endTimeError,
  handleStartTimeChange,
  handleEndTimeChange,
  handleModalToggle,
  handleAvailability,
  handleModalSubmit,
  cardOpen,
}) => {
  const timeFormatRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;

  return (
    <div>
      <Modal onHide={handleAvailability} show={show}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="startTime">
            <Form.Label>Start Time (HH:MM AM/PM)*</Form.Label>
            <Form.Control
              type="time"
              value={startTime}
              onChange={handleStartTimeChange}
              isInvalid={startTimeError}
              style={{ backgroundImage: "none" }}
            />
            <Form.Control.Feedback type="invalid">
              {startTimeError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="endTime">
            <Form.Label>End Time (HH:MM AM/PM)*</Form.Label>
            <Form.Control
              type="time"
              value={endTime}
              onChange={handleEndTimeChange}
              isInvalid={endTimeError}
              style={{ backgroundImage: "none" }}
            />
            <Form.Control.Feedback type="invalid">
              {endTimeError}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="custom-button"
            onClick={handleAvailability}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="custom-button"
            onClick={handleModalSubmit}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TimeSetComponent;
