/** @format */

import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Images from "../../../../Helper/Images";
import { useForm, Controller } from "react-hook-form";
// import { Button, Typography } from "@mui/material";
import { IoIosArrowRoundBack } from "react-icons/io";
import "./Step7.css";

import Screen from "../../../../Helper/Screen";
import { CircularProgress, TextField, Typography } from "@mui/material";
import ApiEndPoints from "../../../../Network_Call/ApiEndPoints";
import { toast } from "react-toastify";
import { apiCallNew } from "../../../../Network_Call/apiservices";
import { getToken } from "../../../../Helper/Storage";
import axios from "axios";
import { FaInfoCircle } from "react-icons/fa";
import Swal from "sweetalert2";

const Step7 = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [userName, setUserName] = useState("Pohn Doe");
  const role = localStorage.getItem("role");
  const [error, setError] = useState("");
  const [load, setload] = useState(false);

  // const UploadProfile = async () => {
  //   setload(true);
  //   if (!selectedFile) {
  //     setError("The upload image is required.");
  //     setload(false);
  //     return;
  //   }
  //   try {
  //     const formData = new FormData();
  //     formData.append("profile_image", selectedFile);
  //     const token = getToken();
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };
  //     const response = await axios.post(
  //       ApiEndPoints.UpdateProfileImage,
  //       formData,
  //       config
  //     );
  //     if (response.data.code === 200) {
  //       localStorage.removeItem("selectedGrades");
  //       localStorage.removeItem("formData");
  //       localStorage.removeItem("dataArray");
  //       window.localStorage.removeItem("@userToken");
  //       setload(false);
  //       toast.success(response.data.msg);
  //       navigate("/login");
  //       console.log("Upload response:", response.data);
  //     } else {
  //       setload(false);
  //       toast.error(response.data.msg);
  //     }
  //   } catch (error) {
  //     setload(false);
  //     console.error("Error uploading profile image:", error);
  //   }
  // };

  const UploadProfile = async () => {
    setload(true);
    if (!selectedFile) {
      setError("The upload image is required.");
      setload(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("profile_image", selectedFile);
      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        ApiEndPoints.UpdateProfileImage,
        formData,
        config
      );
      if (response.data.code === 200) {
        // Clear local storage
        localStorage.removeItem("selectedGrades");
        localStorage.removeItem("formData");
        localStorage.removeItem("dataArray");
        window.localStorage.removeItem("@userToken");

        // Show SweetAlert popup
        Swal.fire({
          title: "Profile has been created.",
          text: "Click here to sign in.",
          icon: "success",
          confirmButtonText: "Sign In",
          confirmButtonColor: "#6d39e9",
          willClose: () => {
            navigate("/login");
          },
        });

        console.log("Upload response:", response.data);
      } else {
        setload(false);
        toast.error(response.data.msg);
      }
    } catch (error) {
      setload(false);
      console.error("Error uploading profile image:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        setError("Only PNG, JPEG, and JPG files are allowed.");
        return;
      }
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setError("");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("profileImage", selectedFile);

    try {
      const response = await fetch("your-server-endpoint", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Profile image uploaded successfully!");
      } else {
        alert("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading the image:", error);
      alert("Error uploading the image");
    }
  };
  const getColorFromName = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  };
  const handleSkip = () => {
    Swal.fire({
      title: "Profile has been created.",
      text: "Click here to sign in.",
      icon: "success",
      confirmButtonText: "Sign In",
      confirmButtonColor: "#6d39e9",
      willClose: () => {
        navigate("/login");
      },
    });
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        // minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {" "}
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <div
        style={{
          borderRadius: "8px",
          maxWidth: "800px",
          width: "100%",
        }}
      >
        <Container style={{ width: "100%", padding: 0 }}>
          <Row className="justify-content-center">
            <Col
              style={{
                backgroundColor: "#ebe6f5",
                textAlign: "left",
                padding: "20px",
                borderRadius: "8px",
              }}
              xs={12}
              md={6}
            >
              <div className="mt-2">
                <p>
                  This section can be completed later on in your profile details
                  once your account has been created if required
                </p>
                <h6 className="headss">
                  Add a photo of yourself that parents/students can view
                </h6>
              </div>
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {preview ? (
                    <img
                      src={preview}
                      alt="Profile Preview"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "cover",
                        borderRadius: "100px",
                        borderWidth: 5,
                        marginTop: 20,
                        borderColor: "#fff",
                      }}
                    />
                  ) : (
                    <>
                      <div
                        style={{
                          width: "200px",
                          height: "200px",
                          backgroundColor: getColorFromName(userName),
                          borderRadius: "50%",
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "100px",
                          color: "white",
                          textTransform: "uppercase",
                          marginTop: 20,
                        }}
                      >
                        {userName
                          ? userName.charAt(0).toUpperCase()
                          : "Shubham"}
                      </div>
                    </>
                  )}
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="profile"
                  />
                </div>
                {error && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "smaller",
                      margin: "10px 0",
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </p>
                )}
                <p className="experience-description ">
                  <FaInfoCircle style={{ marginRight: "5px" }} /> Tutors with a
                  profile picture are more likely to attract more students so it
                  is recommended to add a professional picture
                </p>
                <Row className="justify-content-between">
                  <Col lg={4}>
                    {" "}
                    <Button
                      onClick={() => {
                        UploadProfile();
                      }}
                      className="custom-button mt-4"
                    >
                      {/* <Button onClick={handleSubmit(onSubmit)} className="next-button"> */}
                      Next
                    </Button>
                  </Col>
                  <Col lg={4}>
                    {" "}
                    <Button onClick={handleSkip} className="custom-button mt-4">
                      {/* <Button onClick={handleSubmit(onSubmit)} className="next-button"> */}
                      Skip
                    </Button>
                  </Col>
                </Row>

                <br />

                <div className="col-md-12 ram">
                  <a onClick={() => navigate(-1)}>
                    <div className="prevBtn">
                      <IoIosArrowRoundBack
                        onClick={() => {}}
                        className="pricon"
                      />
                      <h1 className="prText mt-2">Previous</h1>
                    </div>
                  </a>
                  {/* <Link to={Screen.Login}> */}

                  {/* </Link> */}
                </div>
              </form>
            </Col>
            <Col
              style={{
                background:
                  "linear-gradient(242.03deg, #6D39E9 -3.34%, #CCBAF7 72.34%)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative", // Added for relative positioning
              }}
              xs={12}
              md={6}
            >
              <img
                src={role == "Tutor" ? Images.Step2img : Images.family}
                alt="Profile Step 1"
                className="img-fluid"
                style={{ borderRadius: "8px" }} // Ensures image also has rounded corners
              />
              <div className="alreadyBtn">
                <span style={{ marginRight: "10px" }}>Already a Member</span>
                <Link to={"/login"}>
                  <Button variant="primary" className="custom-button">
                    Sign In
                  </Button>
                </Link>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",

                  padding: "5px 10px",
                  borderRadius: "8px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <span style={{ color: "white" }}>
                  {role == "Tutor" ? "Step 7/7" : "Step 4/4"}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default Step7;
