/** @format */

import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Images from "../../../../Helper/Images";
import Screen from "../../../../Helper/Screen";
import "./ForgetPassword.css";
import ApiEndPoints from "../../../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../../../Network_Call/apiservices";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { CircularProgress } from "@mui/material";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [load, setload] = useState(false);
  const role = localStorage.getItem("role");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit = (data) => {
    if (validateForm(data)) {
      ForgetPasswordApi();
    }
  };
  const validateForm = (data) => {
    if (data.email === "") {
      setError("email", { type: "required", message: "Email is required" });
      return false;
    }
    return true;
  };
  const ForgetPasswordApi = async () => {
    setload(true);
    const payload = {
      email: email,
    };
    console.log("payload", payload);
    apiCallNew("post", payload, ApiEndPoints.ForgetPassword)
      .then((response: any) => {
        if (response.success == true) {
          setload(false);

          toast.success(response.msg);
          navigate("/otpVerify", { state: { email: email } });
        } else {
          setload(false);
          toast.error(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        // minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}>
      <div
        style={{
          borderRadius: "8px",
          maxWidth: "800px",
          width: "100%",
        }}>
        <Container>
          <Row className="justify-content-center">
            {load && (
              <div style={styles.backdrop}>
                <CircularProgress style={styles.loader} />
              </div>
            )}
            <Col
              style={{
                backgroundColor: "#ebe6f5",
                textAlign: "left",
                padding: "20px",
                borderRadius: "8px",
              }}
              xs={12}
              md={6}>
              <div className="mt-2">
                <h3>Forget Password</h3>
              </div>
              <br />
              <br />
              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* <Form.Group controlId="email" className="mb-3">
                  <Form.Control
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                        message: "Email address is invalid'",
                      },
                    })}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email"
                    required
                    value={email}
                  />
                  {errors.email && (
                    <span className="text-danger mt-3">
                      {errors.email.message}
                    </span>
                  )}
                </Form.Group> */}
                <label style={{ marginLeft: 2 }}>
                  Email<span style={{ color: "red" }}> *</span>
                </label>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Control
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                        message: "Email address is invalid",
                      },
                    })}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    className={errors.email ? "is-invalid" : ""}
                    style={{ backgroundImage: "none" }}
                    // Add class for invalid state
                  />
                  {errors.email && (
                    <p style={{ color: "red" }} className="mt-1">
                      {errors.email.message}
                    </p>
                  )}
                </Form.Group>
                <Button
                  type="submit"
                  variant="primary"
                  className="mt-3 w-100 "
                  style={{ margin: "auto" }}>
                  Send
                </Button>
              </Form>
            </Col>
            <Col
              style={{
                background:
                  "linear-gradient(242.03deg, #6D39E9 -3.34%, #CCBAF7 72.34%)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative", // Added for relative positioning
              }}
              xs={12}
              md={6}>
              <img
                src={Images.Step2img}
                alt="Profile Step 1"
                className="img-fluid"
                style={{ borderRadius: "8px" }} // Ensures image also has rounded corners
              />
              <div className="alreadyBtn">
                <span>Already a Member</span>
                <Link to={"/step1"}>
                  <Button style={{ width: "100px" }} variant="primary">
                    Sign In
                  </Button>
                </Link>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px", // Position at the top right of the Col
                  right: "10px",
                  // backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
                  padding: "5px 10px",
                  borderRadius: "8px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}>
                {/* <span style={{ color: "white" }}>Step 5/5</span> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default ForgetPassword;
