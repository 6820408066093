/** @format */

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { getToken } from "./Helper/Storage";
import { ToastContainer, toast } from "react-toastify";
import { apiCallNew } from "./Network_Call/apiservices";
import ApiEndPoints from "./Network_Call/ApiEndPoints";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import TutorRoute from "./Router/TutorRoute";
import ParentsRoute from "./Router/ParentsRoute";
import PublicRouter from "./Router/PublicRouter";
import axios from "axios";

const role = localStorage.getItem("role");
const token = getToken();

function App() {
  const [profileStep, setProfileStep] = useState(
    localStorage.getItem("@tutorStep")
  );

  const [parentStep, setParentStep] = useState(
    localStorage.getItem("@parentStep")
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const [currentComponent, setCurrentComponent] = useState("");

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const response = await apiCallNew(
        "get",
        null,
        role === "Tutor"
          ? ApiEndPoints.Tutor_Profile_Step
          : ApiEndPoints.Parent_Profile_Step
      );

      if (response.success) {
        localStorage.setItem("role", response?.result.user_type);
        console.log("getProfile", response);

        localStorage.setItem(
          "@parentStep",
          response?.result.parent_profile_stage
        );
        localStorage.setItem(
          "@tutorStep",
          response?.result.tutor_profile_stage
        );
        setProfileStep(response?.result?.tutor_profile_stage);
        setParentStep(response?.result?.parent_profile_stage);
      } else {
        // toast.error(response.msg);
      }
    } catch (error) {
      // toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // const getRoutes = () => {
  //   if ((role === "Tutor" && profileStep === 6) || profileStep === 4) {
  //     return <TutorRoute />;
  //   } else if (role === "Parent" && parentStep === 2) {
  //     return <ParentsRoute />;
  //   } else {
  //     return <PublicRouter />;
  //   }
  // };
  const getRoutes = () => {
    if (
      (role === "Tutor" && profileStep === 6) ||
      (role === "Tutor" && profileStep === 5) ||
      (role === "Tutor" && profileStep === 3) ||
      (role === "Tutor" && profileStep === 4) ||
      (role === "Tutor" && profileStep === 2) ||
      (role === "Tutor" && profileStep === 1) ||
      (role === "Tutor" && profileStep === null && token)
    ) {
      return <TutorRoute />;
    } else if (
      (role === "Parent" && parentStep === 3) ||
      (role === "Parent" && parentStep === 2) ||
      (role === "Parent" && parentStep === 1) ||
      (role === "Parent" && parentStep === null && token)
    ) {
      return <ParentsRoute />;
    } else if (profileStep === null) {
      return <PublicRouter />;
    } else {
      return <PublicRouter />;
    }
  };
  const PaymentSuccessListener = () => {
    const location = useLocation();

    useEffect(() => {
      if (location.pathname === "/payment-success") {
        const query = new URLSearchParams(location.search);
        const id = query.get("id");
        console.log("id", id);
        console.log("query", query);

        if (id) {
          const token = getToken(); // Get the token from storage
          const postData = {
            subscribe_id: id,
            payment_status: "success",
          };

          axios
            .post(ApiEndPoints.Payment_Response, postData, {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the headers
              },
            })
            .then((response) => {
              console.log("API Success Response:", response.data);
            })
            .catch((error) => {
              console.error("API Error:", error);
            });
        }
      } else if (location.pathname === "/payment-cancel") {
        const query = new URLSearchParams(location.search);
        const id = query.get("id");
        console.log("id", id);
        console.log("query", query);

        if (id) {
          const token = getToken(); // Get the token from storage
          const postData = {
            subscribe_id: id,
            payment_status: "cancel",
          };

          axios
            .post(ApiEndPoints.Payment_Response, postData, {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the headers
              },
            })
            .then((response) => {
              console.log("API Cancel Response:", response.data);
            })
            .catch((error) => {
              console.error("API Error:", error);
            });
        }
      } else if (location.pathname === "/payment-declined") {
        const query = new URLSearchParams(location.search);
        const id = query.get("id");
        console.log("id", id);
        console.log("query", query);

        if (id) {
          const token = getToken(); // Get the token from storage
          const postData = {
            subscribe_id: id,
            payment_status: "declined",
          };

          axios
            .post(ApiEndPoints.Payment_Response, postData, {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the headers
              },
            })
            .then((response) => {
              console.log("API Declined Response:", response.data);
            })
            .catch((error) => {
              console.error("API Error:", error);
            });
        }
      }
    }, [location]);

    return null; // This component doesn't render anything
  };

  return (
    <BrowserRouter>
      <PaymentSuccessListener />
      <ToastContainer
        bodyClassName="custom-toast"
        toastClassName="custom-toast"
        autoClose={1500}
      />
      {getRoutes()}
    </BrowserRouter>
  );
}

export default App;
