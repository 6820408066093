/** @format */

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import DashBoard from "../Screens/ParentsScreen/DashBoard/DashBoard";
import Step2 from "../Screens/PublicScreen/LoginFlow/Step2/Step2";
import Step3 from "../Screens/PublicScreen/LoginFlow/Step3/Step3";
import Step4 from "../Screens/PublicScreen/LoginFlow/Step4/Step4";
import Step6 from "../Screens/PublicScreen/LoginFlow/Step6/Step6";
import Step5 from "../Screens/PublicScreen/LoginFlow/Step5/Step5";
import Step7 from "../Screens/PublicScreen/LoginFlow/Step7/Step7";
import EditProfile from "../Screens/TutorScreen/EditProfile/EditProfile";
import Home from "../Screens/PublicScreen/Home/Home";
import Login from "../Screens/PublicScreen/LoginFlow/LogIn/LogIn";
import OtpVerify from "../Screens/PublicScreen/LoginFlow/OtpVerify/OtpVerify";
import About from "../Screens/PublicScreen/About/About";
import ForgetPassword from "../Screens/PublicScreen/LoginFlow/ForgetPassword/ForgetPassword";
import ContactUS from "../Screens/PublicScreen/ContactUs/ContactUS";
import Step1 from "../Screens/PublicScreen/LoginFlow/Step1/Step1";
import { apiCallNew } from "../Network_Call/apiservices";
import ApiEndPoints from "../Network_Call/ApiEndPoints";
import { toast } from "react-toastify";
import Footer from "../Screens/Footer";
import { Header } from "../Screens/Header";
import TutorRoute from "./TutorRoute";
import OurBlogs from "../Screens/PublicScreen/OurBlogs/OurBlogs";
import SingleBlog from "../Screens/PublicScreen/OurBlogs/SingleBlog";
import WhyChoose from "../Screens/PublicScreen/WhyChooseTutor/WhyChoose";
import Discover from "../Screens/PublicScreen/Discover/Discover";
import PrivacyPolicy from "../Screens/PublicScreen/PrivacyPolicy/PrivacyPolicy";

import FindTutor from "../Screens/TutorScreen/FindTutor/FindTutor";
import WhyBcomeTutor from "../Screens/PublicScreen/WhyBcomeTutor/WhyBcomeTutor";
import TermsndConditions from "../Screens/PublicScreen/TermsndConditions/TermsndConditions";
import { Help } from "@mui/icons-material";
import Subjects from "../Screens/PublicScreen/Subjects/Subjects";
import CancelPolicy from "../Screens/PublicScreen/CancelPolicy/CancelPolicy";
import ProductDelivery from "../Screens/PublicScreen/ProductDeliveryPolicy/ProductDelivery";
import RefundPolicy from "../Screens/PublicScreen/RefundPolicy/RefundPolicy";
import CookiesPolicy from "../Screens/Cookie/CookiesPolicy";
import CookieConsent from "../Screens/Cookie/CookieConsent ";
import Metatags from "../Components/Metatags/Metatags";

const PublicRouter = () => {
  const location = useLocation();
  const [profileStep, setProfileStep] = useState("");
  const [parentStep, setParentStep] = useState("");
  const shouldHideHeaderFooter = location.pathname.startsWith("/tutor");
  const shouldHideHeaderFooter1 = location.pathname.startsWith("/parent");
  const shouldHideHeaderFooter2 = location.pathname.startsWith("/editProfile");
  const shouldHideHeaderFooter3 =
    location.pathname.startsWith("/payment-cancel");
  const shouldHideHeaderFooter4 =
    location.pathname.startsWith("/payment-success");
  const shouldHideHeaderFooter5 =
    location.pathname.startsWith("/payment-declined");
  const shouldHideHeaderFooter6 = location.pathname.startsWith("/subjects");
  const shouldHideHeaderFooter7 = location.pathname.startsWith("/messageList");
  const shouldHideHeaderFooter8 =
    location.pathname.startsWith("/advertise-list");
  const shouldHideHeaderFooter9 = location.pathname.startsWith("/view-request");
  const shouldHideHeaderFooter10 = location.pathname.startsWith("/help");
  const shouldHideHeaderFooter12 =
    location.pathname.startsWith("/advertise-post");
  useEffect(() => {
    TutorProfile();
  }, [profileStep]);
  const role = localStorage.getItem("role");

  const TutorProfile = async () => {
    apiCallNew(
      "get",
      null,
      role === "Tutor"
        ? ApiEndPoints.Tutor_Profile_Step
        : ApiEndPoints.Parent_Profile_Step
    )
      .then((response: any) => {
        if (response.success === true) {
          localStorage.setItem("role", response?.result.user_type);
          setProfileStep(response?.result?.tutor_profile_stage);
          setParentStep(response?.result?.parent_profile_stage);
        } else {
          // toast.error(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
      });
  };
  return (
    <>
      {!(
        shouldHideHeaderFooter ||
        shouldHideHeaderFooter1 ||
        shouldHideHeaderFooter2 ||
        shouldHideHeaderFooter3 ||
        shouldHideHeaderFooter4 ||
        shouldHideHeaderFooter5 ||
        shouldHideHeaderFooter6 ||
        shouldHideHeaderFooter7 ||
        shouldHideHeaderFooter8 ||
        shouldHideHeaderFooter9 ||
        shouldHideHeaderFooter10 ||
        shouldHideHeaderFooter12
      ) && <Header />}
      <div className="ks">
        {" "}
        <CookieConsent />
        <Metatags />
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />

          <Route path={"/login"} element={<Login />} />
          <Route path={"/otpVerify"} element={<OtpVerify />} />
          <Route path={"/about"} element={<About />} />
          <Route path={"/forgetPassword"} element={<ForgetPassword />} />
          <Route path={"/contactUs"} element={<ContactUS />} />
          <Route path={"/ourBlogs"} element={<OurBlogs />} />
          <Route path={"/singleBlog"} element={<SingleBlog />} />
          <Route path={"/step1"} element={<Step1 />} />
          <Route path={"/step2"} element={<Step2 />} />
          <Route path={"/step3"} element={<Step3 />} />
          <Route path={"/step4"} element={<Step4 />} />
          <Route path={"/step5"} element={<Step5 />} />
          <Route path={"/step6"} element={<Step6 />} />
          <Route path={"/step7"} element={<Step7 />} />
          <Route path={"/about"} element={<About />} />
          <Route path={"/whyChooseUs"} element={<WhyChoose />} />
          <Route path={"/Discover"} element={<Discover />} />
          <Route path={"/Discover"} element={<Discover />} />
          <Route path={"/privacyPolicy"} element={<PrivacyPolicy />} />
          <Route path={"/findTutor"} element={<FindTutor />} />
          <Route path={"/whyBecomeTutor"} element={<WhyBcomeTutor />} />
          <Route path={"/termsndConditions"} element={<TermsndConditions />} />
          <Route path={"/subjects"} element={<Subjects />} />
          <Route path={"/cancellation-policy"} element={<CancelPolicy />} />
          <Route
            path={"/product-delivery-policy"}
            element={<ProductDelivery />}
          />
          <Route path={"/refund-policy"} element={<RefundPolicy />} />
        </Routes>
      </div>
      {!(
        shouldHideHeaderFooter ||
        shouldHideHeaderFooter1 ||
        shouldHideHeaderFooter2 ||
        shouldHideHeaderFooter3 ||
        shouldHideHeaderFooter4 ||
        shouldHideHeaderFooter5 ||
        shouldHideHeaderFooter6 ||
        shouldHideHeaderFooter7 ||
        shouldHideHeaderFooter8 ||
        shouldHideHeaderFooter9 ||
        shouldHideHeaderFooter10 ||
        shouldHideHeaderFooter12
      ) && <Footer />}
    </>
  );
};

export default PublicRouter;
