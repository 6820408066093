/** @format */

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./MessageList.css";
import { apiCallNew } from "../../../Network_Call/apiservices";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { CircularProgress } from "@mui/material";
import Upper from "../../../Components/Uppecase";
const MessageList = () => {
  const [activeMessageId, setActiveMessageId] = useState(null);
  const [messageData, setMessageData] = useState([]);
  const [packageData, setPackageData] = useState({ package_view_users: 0 });
  const [load, setload] = useState(false);
  const getInitial1 = (name) => name?.charAt(0).toUpperCase() + name.slice(1);
  const getInitial = (name) => name?.charAt(0).toUpperCase();
  const role = localStorage.getItem("role");

  const handleClick = (id) => {
    setActiveMessageId(id);
  };
  useEffect(() => {
    MessageList();
    getPkgStatus();
  }, []);
  const getPkgStatus = async () => {
    const response = await apiCallNew("get", null, ApiEndPoints.Pkg_Status);
    console.log("package status", response?.result);
    if (response?.success) {
      setPackageData(response.result);
    }
  };
  const MessageList = async () => {
    const payload = {
      page: 0,
    };
    setload(true);
    await apiCallNew("POST", payload, ApiEndPoints.Message_User_List)
      .then((res) => {
        if (res.success == true) {
          setMessageData(res?.result);
          setload(false);
        } else {
          setload(false);
        }
      })
      .catch((error) => {
        setload(false);
        console.log("error", error);
      })
      .finally(() => {
        setload(false);
      });
  };
  const truncateUserMsg = (userMsg) => {
    const maxLength = 20;
    if (userMsg?.length > maxLength) {
      return `${userMsg?.slice(0, maxLength)}...`;
    }
    return userMsg;
  };
  const limitedMessageData =
    packageData.package_all_contact_view === 1
      ? messageData
      : messageData.slice(0, packageData.package_view_users);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize to compare only the date
  const packageEndDate = new Date(packageData?.package_end_date);
  packageEndDate.setHours(0, 0, 0, 0); // Normalize to compare only the date

  const remainingDays = Math.ceil(
    (packageEndDate - today) / (1000 * 60 * 60 * 24)
  );
  console.log("remainingDays", remainingDays);

  return (
    // <Container className="mobile-container">
    //   {load && (
    //     <div style={styles.backdrop}>
    //       <CircularProgress style={styles.loader} />
    //     </div>
    //   )}
    //   <Row>
    //     <Col md={12} style={{ padding: "0px 40px" }}>
    //       <h4>Message</h4>
    //       {load ? (
    //         <div className="card shadow mt-3">
    //           {messageData?.map((message) => (
    //             <>
    //               {/* <div
    //                 key={message.user_id}
    //                 className={`message-item  ${
    //                   activeMessageId === message?.user_id ? "active" : ""
    //                 }`}
    //                 onClick={() => handleClick(message?.id)}
    //               >

    //                 <Link
    //                   state={{
    //                     users: message?.user_id,
    //                     img: message?.profile_image_path,
    //                     name: message?.name,
    //                   }}
    //                   to={`/messageView`}
    //                   className="message-link"
    //                 >
    //                   <div
    //                     style={{
    //                       display: "flex",
    //                       flexDirection: "row",
    //                       alignItems: "center",
    //                       justifyContent: "space-between", // This will push the "count" to the end
    //                     }}
    //                   >
    //                     <div style={{ display: "flex", alignItems: "center" }}>
    //                       {message?.profile_image_path ? (
    //                         <img
    //                           src={message?.profile_image_path}
    //                           alt="user img"
    //                           style={{
    //                             margin: 0,
    //                             width: 50,
    //                             height: 50,
    //                             borderRadius: 100,
    //                           }}
    //                         />
    //                       ) : (
    //                         <div className="circle m-0">
    //                           {getInitial(message?.name)}
    //                         </div>
    //                       )}
    //                       <div style={{ marginLeft: 15 }}>
    //                         <p className="message-sender">
    //                           <Upper name={message?.name} />
    //                         </p>
    //                         <p className="message-subject">
    //                           {truncateUserMsg(message?.last_message)}
    //                         </p>
    //                       </div>
    //                     </div>
    //                     <div className="unread-count">
    //                       <p>4</p>
    //                     </div>
    //                   </div>
    //                 </Link>
    //               </div> */}
    //               <div
    //                 key={message.user_id}
    //                 className={`message-item ${
    //                   activeMessageId === message?.user_id ? "active" : ""
    //                 }`}
    //                 onClick={() => handleClick(message?.id)}
    //               >
    //                 <Link
    //                   state={{
    //                     users: message?.user_id,
    //                     img: message?.profile_image_path,
    //                     name: message?.name,
    //                   }}
    //                   to={`/messageView`}
    //                   className="message-link"
    //                 >
    //                   <div
    //                     style={{
    //                       display: "flex",
    //                       flexDirection: "row",
    //                       alignItems: "center",
    //                       justifyContent: "space-between",
    //                     }}
    //                   >
    //                     <div style={{ display: "flex", alignItems: "center" }}>
    //                       {message?.profile_image_path ? (
    //                         <img
    //                           src={message?.profile_image_path}
    //                           alt="user img"
    //                           style={{
    //                             margin: 0,
    //                             width: 50,
    //                             height: 50,
    //                             borderRadius: 100,
    //                           }}
    //                         />
    //                       ) : (
    //                         <div className="circle m-0">
    //                           {getInitial(message?.name)}
    //                         </div>
    //                       )}
    //                       <div style={{ marginLeft: 15 }}>
    //                         <p className="message-sender">
    //                           <Upper name={message?.name} />
    //                         </p>
    //                         <p className="message-subject">
    //                           {truncateUserMsg(message?.last_message)}
    //                         </p>
    //                       </div>
    //                     </div>

    //                     {message?.unread_count == 0 ||
    //                     message?.unread_count == null ? null : (
    //                       <div className="unread-count">
    //                         {" "}
    //                         {message?.unread_count}
    //                       </div>
    //                     )}
    //                   </div>
    //                 </Link>
    //               </div>
    //             </>
    //           ))}
    //         </div>
    //       ) : (
    //         <div>Message Not Found</div>
    //       )}
    //     </Col>
    //   </Row>
    // </Container>
    <Container className="mobile-container">
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <Row>
        {role === "Tutor" ? (
          // <Col md={12} style={{ padding: "0px 40px" }}>
          //   <h4>Message</h4>
          //   {!load && limitedMessageData.length === 0 ? (
          //     <div className="d-flex justify-content-center">
          //       {" "}
          //       <h6>Message Not Found</h6>
          //     </div>
          //   ) : (
          //     <div className="card shadow mt-3">
          //       {limitedMessageData.length > 0
          //         ? limitedMessageData?.map((message) => (
          //             <div
          //               key={message.user_id}
          //               className={`message-item ${
          //                 activeMessageId === message?.user_id ? "active" : ""
          //               }`}
          //               onClick={() => handleClick(message?.id)}
          //             >
          //               <Link
          //                 state={{
          //                   users: message?.user_id,
          //                   img: message?.profile_image_path,
          //                   name: message?.name,
          //                 }}
          //                 to={`/messageView`}
          //                 className="message-link"
          //               >
          //                 <div
          //                   style={{
          //                     display: "flex",
          //                     flexDirection: "row",
          //                     alignItems: "center",
          //                     justifyContent: "space-between",
          //                   }}
          //                 >
          //                   <div
          //                     style={{ display: "flex", alignItems: "center" }}
          //                   >
          //                     {message?.profile_image_path ? (
          //                       <img
          //                         src={message?.profile_image_path}
          //                         alt="user img"
          //                         style={{
          //                           margin: 0,
          //                           width: 50,
          //                           height: 50,
          //                           borderRadius: 100,
          //                         }}
          //                       />
          //                     ) : (
          //                       <div className="circle m-0">
          //                         {getInitial(message?.name)}
          //                       </div>
          //                     )}
          //                     <div style={{ marginLeft: 15 }}>
          //                       <p className="message-sender">
          //                         <Upper name={message?.name} />
          //                       </p>
          //                       <p className="message-subject">
          //                         {truncateUserMsg(message?.last_message)}
          //                       </p>
          //                     </div>
          //                   </div>
          //                   {message?.unread_count > 0 && (
          //                     <div className="unread-count">
          //                       {message?.unread_count}
          //                     </div>
          //                   )}
          //                 </div>
          //               </Link>
          //             </div>
          //           ))
          //         : !load && <div>Message Not Found</div>}
          //     </div>
          //   )}
          // </Col>

          remainingDays > 0 ? (
            <Col md={12} style={{ padding: "0px 40px" }}>
              <h4>Message</h4>
              {!load && limitedMessageData.length === 0 ? (
                <div className="d-flex justify-content-center">
                  <h6>No messages found</h6>
                </div>
              ) : (
                <div className="card shadow mt-3">
                  {limitedMessageData.length > 0
                    ? limitedMessageData?.map((message) => (
                        <div
                          key={message.user_id}
                          className={`message-item ${
                            activeMessageId === message?.user_id ? "active" : ""
                          }`}
                          onClick={() => handleClick(message?.id)}
                        >
                          <Link
                            state={{
                              users: message?.user_id,
                              img: message?.profile_image_path,
                              name: message?.name,
                            }}
                            to={`/messageView`}
                            className="message-link"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {message?.profile_image_path ? (
                                  <img
                                    src={message?.profile_image_path}
                                    alt="user img"
                                    style={{
                                      margin: 0,
                                      width: 50,
                                      height: 50,
                                      borderRadius: 100,
                                    }}
                                  />
                                ) : (
                                  <div className="circle m-0">
                                    {getInitial(message?.name)}
                                  </div>
                                )}
                                <div style={{ marginLeft: 15 }}>
                                  <p className="message-sender">
                                    <Upper name={message?.name} />
                                  </p>
                                  <p className="message-subject">
                                    {truncateUserMsg(message?.last_message)}
                                  </p>
                                </div>
                              </div>
                              {message?.unread_count > 0 && (
                                <div className="unread-count">
                                  {message?.unread_count}
                                </div>
                              )}
                            </div>
                          </Link>
                        </div>
                      ))
                    : !load && <div>No messages found</div>}
                </div>
              )}
            </Col>
          ) : (
            <Col md={12} style={{ padding: "0px 40px" }}>
              <h4>Message</h4>
              <div className="d-flex justify-content-center">
                <h6>No messages found</h6>
              </div>
            </Col>
          )
        ) : (
          <Col md={12} style={{ padding: "0px 40px" }}>
            <h4>Message</h4>
            {!load && messageData?.length === 0 ? (
              <div className="d-flex justify-content-center">
                {" "}
                <h6>Message Not Found</h6>
              </div>
            ) : (
              <div className="card shadow mt-3">
                {messageData.length > 0
                  ? messageData?.map((message) => (
                      <div
                        key={message.user_id}
                        className={`message-item ${
                          activeMessageId === message?.user_id ? "active" : ""
                        }`}
                        onClick={() => handleClick(message?.id)}
                      >
                        <Link
                          state={{
                            users: message?.user_id,
                            img: message?.profile_image_path,
                            name: message?.name,
                          }}
                          to={`/messageView`}
                          className="message-link"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {message?.profile_image_path ? (
                                <img
                                  src={message?.profile_image_path}
                                  alt="user img"
                                  style={{
                                    margin: 0,
                                    width: 50,
                                    height: 50,
                                    borderRadius: 100,
                                  }}
                                />
                              ) : (
                                <div className="circle m-0">
                                  {getInitial(message?.name)}
                                </div>
                              )}
                              <div style={{ marginLeft: 15 }}>
                                <p className="message-sender">
                                  <Upper name={message?.name} />
                                </p>
                                <p className="message-subject">
                                  {truncateUserMsg(message?.last_message)}
                                </p>
                              </div>
                            </div>
                            {message?.unread_count > 0 && (
                              <div className="unread-count">
                                {message?.unread_count}
                              </div>
                            )}
                          </div>
                        </Link>
                      </div>
                    ))
                  : !load && <div>Message Not Found</div>}
              </div>
            )}
          </Col>
        )}
      </Row>
    </Container>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};
export default MessageList;
