import { useState } from "react";
import { initiatePayment } from "./TelrService";
import "./Payment.css";

const Payment = () => {
  const [amount, setAmount] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);
  const [paymentUrl, setPaymentUrl] = useState("");

  const handlePayment = async (e) => {
    e.preventDefault();
    setError(null);

    const orderDetails = {
      order: {
        amount,
        currency: "AED", // Specify your currency
        description: "Payment description",
        // Example return URLs
        return: "http://localhost:3000/payment-success",
        decline: "http://localhost:3000/payment-failure",
        cancel: "http://localhost:3000/payment-cancel",
      },
      customer: {
        name,
        email,
        phone,
      },
    };

    try {
      const response = await initiatePayment(orderDetails);
      if (response.trace && response.trace.code === "OK") {
        setPaymentUrl(response.redirect_url);
      } else {
        setError("Payment initiation failed. Please try again.");
      }
    } catch (error) {
      setError(
        "An error occurred while processing your payment. Please try again."
      );
    }
  };

  return (
    <div className="payment-container">
      <h1>Make a Payment</h1>
      <form onSubmit={handlePayment}>
        <div>
          <label>Amount:</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Phone:</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <button type="submit">Pay Now</button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {paymentUrl && (
        <a href={paymentUrl} target="_blank" rel="noopener noreferrer">
          Proceed to Payment
        </a>
      )}
    </div>
  );
};
export default Payment;
