/** @format */

import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import "./WhyChooseTutor.css";

const WhyChoose = () => {
  const benefits = [
    {
      title: "No Commission Fees: Keep 100% of Your Earnings",
      description:
        "Unlike many platforms, we do not charge commission on your tutoring sessions. You earn what you deserve.",
    },
    {
      title:
        "Affordable Membership: Enjoy affordable membership options that give you access to a wide range of students",
      description:
        "100% our tutors who find students make their monthly payment back from delivering just 1-2 sessions to a student that they have found from FindMyTutor.",
    },
    {
      title: "Cancel your membership whenever suits you",
      description:
        "Cancel your membership whenever you want if you are fully booked up or no longer needing to find students and you will not be charged after the membership duration that you selected.",
    },
    {
      title:
        "Simple and User-Friendly: Easy to Use for Both Tutors and Students",
      description:
        "Our platform is designed to be straightforward and intuitive, ensuring a seamless experience for tutors and students alike.",
    },
    {
      title: "Wide Reach: Connect with Students All Across the UAE",
      description:
        "Reach a diverse student base seeking expertise in various subjects and levels of education in Dubai, Abu Dhabi, Sharjah, Al-Ain and many more!",
    },
    {
      title: "Profile Management: Showcase Your Expertise",
      description:
        "Create a comprehensive profile highlighting your qualifications, experience, and teaching style to attract potential students.",
    },
    {
      title: "Direct Communication: Connect Directly with Students",
      description:
        "Communicate directly with students to understand their needs and tailor your tutoring approach accordingly.",
    },
    {
      title: " Community Support: Dedicated Customer Support",
      description:
        "Benefit from our responsive customer support team ready to assist you with any questions or issues.",
    },
  ];
  const Benefits = () => {
    return (
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <h2 variant="h4" gutterBottom>
          Why Join FindMyTutor?
        </h2>

        <h6 gutterBottom>
          At FindMyTutor, we prioritize your success and ease of tutoring.
          Here’s why joining our platform is beneficial for tutors:
        </h6>
        <List>
          {benefits.map((benefit, index) => (
            <ListItem key={index} alignItems="flex-start">
              <CheckCircleOutline
                color="primary"
                style={{ marginRight: "10px" }}
              />
              <Box>
                <h6 className="fw-bold">{benefit.title}</h6>
                <p variant="body1">{benefit.description}</p>
              </Box>
            </ListItem>
          ))}
        </List>
        <h5 variant="h6" gutterBottom>
          Read through some of our platform’s tutor reviews to learn about their
          experiences with FindMyTutor
        </h5>
      </Container>
    );
  };
  return (
    <div>
      {" "}
      <div className="header-container">
        <h2 className="header-title4 fw-bold">
          Discover our unique benefits for tutors
        </h2>
      </div>
      <Benefits />
      <br />
      <br />
      <br />
    </div>
  );
};

export default WhyChoose;
