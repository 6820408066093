/** @format */

import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Col, Container, Image, Row, Table } from "react-bootstrap";
import "./TutorDashBoard.css";
import Images from "../../../Helper/Images";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { FaCheck, FaTimes } from "react-icons/fa";
import moment from "moment";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Rating,
  Typography,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { apiCallNew } from "../../../Network_Call/apiservices";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import Swal from "sweetalert2";
import { getUserdata } from "../../../Helper/Storage";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";

import dayjs from "dayjs";

const today = moment();
const startOfWeek = today.clone().startOf("week");
const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const renderDays = () => {
  return days?.map((day, index) => {
    const date = startOfWeek.clone().add(index, "days");
    const isToday = date.isSame(today, "day");
    return (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "55px",
          height: "80px",
          borderRadius: "40px",
          backgroundColor: isToday ? "blue" : "transparent",
          color: isToday ? "white" : "black",
        }}
      >
        <span
          style={{
            display: "block",
            textAlign: "center",
            // backgroundColor: isToday ? "blue" : "transparent",
          }}
        >
          {day}
        </span>
        <span
          style={{
            display: "block",
            backgroundColor: isToday ? "blue" : "transparent",
            color: isToday ? "white" : "#06241B",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            lineHeight: "20px",
            textAlign: "center",
          }}
        >
          {date.date()}
        </span>
      </div>
    );
  });
};

const ExampleComponent = () => {
  const classes = [
    {
      time: "14:00 - 15:00",
      subject: "Highschool Math",
      student: "Caleb Donaldson",
    },
    {
      time: "14:00 - 15:00",
      subject: "Highschool Math",
      student: "Anna Smith",
    },
    { time: "14:00 - 15:00", subject: "Highschool Math", student: "Sam" },
  ];

  return (
    <div>
      {classes?.slice(0, 3)?.map((classInfo, index) => (
        <div key={index}>
          <Row className="class-row">
            <Col lg={12} md={12}>
              <span className="time">{classInfo.time}</span>
            </Col>
            <Col lg={12} md={12}>
              <span className="subject">{classInfo.subject}</span>
            </Col>
            <Col lg={12} md={12}>
              <span className="time">
                Student{" "}
                <span style={{ color: "#6D39E9" }}>{classInfo.student}</span>
              </span>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};
const LineChart = ({ messageGraph }) => {
  const role = localStorage.getItem("role");
  const createGradient = (ctx, chartArea, startColor, midColor, endColor) => {
    if (!chartArea) {
      return null;
    }
    const gradient = ctx.createLinearGradient(
      chartArea.left,
      chartArea.top,
      chartArea.left,
      chartArea.bottom
    );
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(0.64, midColor);
    gradient.addColorStop(1, endColor);
    return gradient;
  };

  const [chartData, setChartData] = useState({
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "Accepted Requests",
        data: Array(7).fill(0),
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(
            chartArea.left,
            chartArea.top,
            chartArea.left,
            chartArea.bottom
          );
          gradient.addColorStop(0, "rgba(0, 119, 255, 1)");
          gradient.addColorStop(0.64, "rgba(0, 119, 255, 0.64)");
          gradient.addColorStop(1, "rgba(0, 119, 255, 0)");
          return gradient;
        },
        borderColor: "rgba(0, 119, 255, 1)",
        borderWidth: 2,
        tension: 0.4,
        pointStyle: "pointer",
      },
      {
        label: "Rejected Requests",
        data: Array(7).fill(0),
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          return createGradient(
            ctx,
            chartArea,
            "rgba(255, 99, 132, 1)",
            "rgba(255, 99, 132, 0.64)",
            "rgba(255, 99, 132, 0)"
          );
        },
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Pending Requests",
        data: Array(7).fill(0),
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          return createGradient(
            ctx,
            chartArea,
            "rgba(255, 206, 86, 1)",
            "rgba(255, 206, 86, 0.64)",
            "rgba(255, 206, 86, 0)"
          );
        },
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  });

  const dayIndex = (dateString) => {
    const date = new Date(dateString.split("-").reverse().join("-"));
    return date.getDay();
  };

  useEffect(() => {
    if (messageGraph) {
      const acceptedRequests = Array(7).fill(0);
      const rejectedRequests = Array(7).fill(0);
      const pendingRequests = Array(7).fill(0);

      messageGraph.forEach((item) => {
        const index = dayIndex(item.time_stamp);
        acceptedRequests[index] += parseInt(item.accepted_request);
        rejectedRequests[index] += parseInt(item.rejected_request);
        pendingRequests[index] += parseInt(item.pending_request);
      });

      setChartData((prevData) => ({
        ...prevData,
        datasets: [
          {
            ...prevData.datasets[0],
            data: acceptedRequests,
          },
          {
            ...prevData.datasets[1],
            data: rejectedRequests,
          },
          {
            ...prevData.datasets[2],
            data: pendingRequests,
          },
        ],
      }));
    }
  }, [messageGraph]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            size: window.innerWidth < 600 ? 10 : 12, // Smaller text on mobile
          },
        },
      },
      tooltip: {
        bodyFont: {
          size: window.innerWidth < 600 ? 10 : 12, // Adjust tooltip font size
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1, // Adjust the step size according to your data
        },
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div className="bg-white chart-container p-3">
      <Line className="w-100 h-100" data={chartData} options={options} />
    </div>
  );
};
const ShowMoreLess = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleShowMore = () => {
    setIsExpanded(!isExpanded);
  };

  if (text?.length <= maxLength) {
    return <p className="message-subject">{text}</p>;
  }

  return (
    <p className="message-subject">
      {isExpanded ? text : `${text?.slice(0, maxLength)}...`}
      <span
        onClick={toggleShowMore}
        style={{
          color: "blue",
          cursor: "pointer",
          marginLeft: "5px",
          fontSize: "12px",
        }}
      >
        {isExpanded ? "Show Less" : "Show More"}
      </span>
    </p>
  );
};
const ShowMoreLess1 = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleShowMore = () => {
    setIsExpanded(!isExpanded);
  };

  if (!text) {
    return null; // Don't render anything if text is null or undefined
  }

  if (text.length <= maxLength) {
    return <p className="message-subject">{text}</p>;
  }

  return (
    <p className="message-subject">
      {isExpanded ? text : `${text.slice(0, maxLength)}...`}
      <span
        onClick={toggleShowMore}
        style={{
          color: "blue",
          cursor: "pointer",
          marginLeft: "5px",
          fontSize: "12px",
        }}
      >
        {isExpanded ? "Show Less" : "Show More"}
      </span>
    </p>
  );
};

const TutorSlider = ({ tutors }) => {
  const isSingleTutor = tutors.length === 1;
  const settings = {
    dots: !isSingleTutor,
    infinite: !isSingleTutor,
    speed: 500,
    slidesToShow: 1,
    // slidesToScroll: 1,

    arrows: true,
  };

  return (
    <Slider {...settings}>
      {tutors?.map(
        (tutor, index) => (
          console.log("tutor>>>", tutor),
          (
            <Card
              key={index}
              className="tutor-cards text-center"
              style={{
                borderRadius: "15px",
                overflow: "hidden",
                backgroundColor: "#f4f4f4",
              }}
            >
              <CardMedia
                component="img"
                image={
                  tutor?.profile_image
                    ? tutor?.profile_image
                    : Images.FindMyTutor
                }
                className="img-fluid rounded-circle mx-auto mt-5"
                style={{
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                }}
              />
              <CardContent style={{ minHeight: "380px" }}>
                <h4 className="title1">{tutor?.name}</h4>
                <p className="paragrap2">{tutor.location}</p>

                <p className="paragrap4" mt={2}>
                  <ShowMoreLess1
                    text={tutor?.intro_yourself_to_student}
                    maxLength={200}
                  />
                </p>
                <div className="row col-12 ">
                  <p className="col-4"></p>
                  <h5 className="titlehour col-4" variant="h5" mt={2}>
                    AED {tutor?.hourly_rate}/Hr
                  </h5>
                  <p className="col-4"></p>
                </div>
                <Link
                  state={{
                    users: tutor?.id,
                    img: tutor?.image,
                    name: tutor?.name,
                  }}
                  to={`/messageView`}
                >
                  {" "}
                  <button className="subjectbutton mt-3">
                    Send Message
                    <ArrowRightAltIcon />
                  </button>
                </Link>
              </CardContent>
            </Card>
          )
        )
      )}
    </Slider>
  );
};

const tutors = [
  {
    id: 1,
    name: "Elizabeth Washington",
    location: "Denver, Co",
    rating: 5,
    reviews: 24,
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    hourlyRate: "$40/Hr",
    image: Images.HighRated,
  },
  {
    id: 1,
    name: "Kshetrapal",
    location: "Denver, Co",
    rating: 5,
    reviews: 24,
    description:
      "Experienced educator and lover of all things science and outdoors. Masters degree in elementary education.",
    hourlyRate: "$40/Hr",
    image: Images.HighRated,
  },
  // Add more tutor data here if needed
];

const TutorDashBoard = () => {
  const role = localStorage.getItem("role");
  const [tutorRequest, setTutorRequest] = useState([]);
  const [pkgStatus, setPkgStatus] = useState();
  const [dashCount, setDashCount] = useState([]);
  const [packageData, setPackageData] = useState({ package_view_users: 0 });
  const [boostedTutor, setBoostedTutor] = useState([]);
  const [week, setWeek] = useState("Current Week");
  const navigate = useNavigate();
  const user_id = localStorage.getItem("@userId");

  const [adverList, setAdverList] = useState([]);
  const [load, setLoad] = useState(false);
  const userData = getUserdata();
  // console.log("dashcount", dashCount);
  console.log("boostedTutor>>>", boostedTutor);
  useEffect(() => {
    AdvertiseList();
    Get_Pkg_Status();
    getBoostedTutor();
  }, []);
  useEffect(() => {
    if (role === "Tutor") {
      const checkPackageEndDate = () => {
        const currentDate = dayjs();
        const packageEndDate = dayjs(pkgStatus?.package_end_date);

        const isTomorrowEndDate = currentDate
          .add(1, "day")
          .isSame(packageEndDate, "day");

        if (isTomorrowEndDate) {
          Swal.fire({
            title: "Package Expiration",
            text: "Your package is expiring tomorrow. Would you like to renew or cancel?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Renew",
            cancelButtonText: "Cancel",
            confirmButtonColor: "#6d39e9",
            cancelButtonColor: "#aaaaaa",
          }).then((result) => {
            if (result.isConfirmed) {
              console.log(
                "Calling handlePayment with pkgStatus.package_id:",
                pkgStatus?.package_id
              );
              handlePayment(pkgStatus?.package_id);

              console.log("Renew clicked");
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // Handle cancel action
              console.log("Cancel clicked");
            }
          });
        }
      };

      checkPackageEndDate();
    }
  }, [pkgStatus]);

  useEffect(() => {
    ParentMessageReqList();
    Counts();
  }, [week]);

  const handleWeek = (e) => {
    setWeek(e.target.value);
  };
  const getBoostedTutor = async () => {
    const response = await apiCallNew("post", null, ApiEndPoints.BoostedTutor);
    console.log("boostedTutor>>>>", response);

    if (response?.success == true) {
      setBoostedTutor(response?.result);
    }
  };
  const Get_Pkg_Status = async () => {
    const response = await apiCallNew("get", null, ApiEndPoints.Pkg_Status);
    setPkgStatus(response?.result);
  };
  const handlePayment = async (item) => {
    console.log("item", item);
    setLoad(true);
    const payload = {
      package_id: item,
      boost_profile: 0,
      // message: item?.description,
    };
    try {
      const res = await apiCallNew("post", payload, ApiEndPoints.BuyPackage);
      console.log("item", res);

      if (res.success === true) {
        toast.success(res?.msg);
        setLoad(false);
        if (res?.result?.url) {
          window.location.href = res?.result?.url;
        }
      } else {
        setLoad(false);
        toast.error(res?.msg);
      }
    } catch (error) {
      setLoad(false);
      toast.error("An error occurred while processing your payment.");
      console.error("Payment error:", error);
    }
  };

  const ParentMessageReqList = () => {
    apiCallNew("post", null, ApiEndPoints.PMessageRequestList).then(
      (response) => {
        setTutorRequest(response?.result);
      }
    );
  };

  const getDates = () => {
    const currentDate = new Date();

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDayOfWeek = currentDate.getDay();

    // Calculate start and end dates for the current week
    const currentWeekStartDate = new Date(currentDate);
    currentWeekStartDate.setDate(currentDate.getDate() - currentDayOfWeek);
    const currentWeekEndDate = new Date(currentWeekStartDate);
    currentWeekEndDate.setDate(currentWeekStartDate.getDate() + 6);

    // Calculate start and end dates for the previous week
    const previousWeekEndDate = new Date(currentWeekStartDate);
    previousWeekEndDate.setDate(currentWeekStartDate.getDate() - 1);
    const previousWeekStartDate = new Date(previousWeekEndDate);
    previousWeekStartDate.setDate(previousWeekEndDate.getDate() - 6);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    return {
      current_week: {
        start_date: formatDate(currentWeekStartDate),
        end_date: formatDate(currentWeekEndDate),
      },
      previous_week: {
        start_date: formatDate(previousWeekStartDate),
        end_date: formatDate(previousWeekEndDate),
      },
    };
  };
  const dates = getDates();

  const Counts = () => {
    if (week == "Current Week") {
      const payload = {
        start_date: dates?.current_week?.start_date,
        end_date: dates?.current_week?.end_date,
      };
      console.log("curent week payload", payload);
      apiCallNew("post", payload, ApiEndPoints.DashboardCount).then(
        (response) => {
          setDashCount(response?.result);
        }
      );
    } else if (week == "last week") {
      const payload = {
        start_date: dates?.previous_week?.start_date,
        end_date: dates?.previous_week?.end_date,
      };
      console.log("last week payload", payload);
      apiCallNew("post", payload, ApiEndPoints.DashboardCount).then(
        (response) => {
          setDashCount(response?.result);
        }
      );
    }
  };

  const AdvertiseList = () => {
    const payload = {
      page: 0,
      limit: 5,
    };
    // setload(true);
    apiCallNew("post", payload, ApiEndPoints.TutorAdvertiseList).then((res) => {
      if (res.success == true) {
        console.log("resss", res);
        setAdverList(res?.result);
        // setload(false);
      } else {
        // setload(false);
      }
    });
  };
  const AcceptRequest = async (row) => {
    const { user_id, profile_image_path, name, message_id } = row;
    console.log("row", row);

    const data = {
      status: 1,
    };
    try {
      setLoad(true);
      const response = await apiCallNew(
        "post",
        data,
        `${ApiEndPoints.MessageRequest}${message_id}`
      );
      if (response.success == true) {
        setLoad(false);
        Counts();
        toast.success(response.msg);
        ParentMessageReqList();
        // setTimeout(() => {
        //   navigate("/messageView", {
        //     state: { users: user_id, img: profile_image_path, name: name },
        //   });
        // }, 1500);

        console.log("ACcept response", response);
      } else {
        setLoad(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const RejectRequest = async (row) => {
    const { user_id, profile_image_path, name, message_id } = row;
    Swal.fire({
      title: "Are you sure?",
      text: "You want to reject this request!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6d39e9",
      cancelButtonColor: "#aaaaaa",
      confirmButtonText: "Reject",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          status: 2,
        };
        try {
          setLoad(true);
          const response = await apiCallNew(
            "post",
            data,
            `${ApiEndPoints.MessageRequest}${message_id}`
          );
          if (response.success == true) {
            setLoad(false);
            toast.success(response.msg);
            ParentMessageReqList();
            Counts();
          } else {
            setLoad(false);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    });
  };
  const SendMessage = (row) => {
    const { user_id, profile_image_path, name, message_id } = row;
    navigate("/messageView", {
      state: { users: user_id, img: profile_image_path, name: name },
    });
  };
  const viewedLimitedUser = async (user) => {
    const subscribe_id = pkgStatus?.id;
    const viewed_user_id = user?.user_id;

    try {
      const response = await apiCallNew(
        "get",
        null,
        `${ApiEndPoints.PkgViewdUser}?subscribe_id=${subscribe_id}&viewed_user_id=${viewed_user_id}`
      );

      console.log("response of viewed>>>", response);
      if (response?.success) {
        toast.success(response?.message);

        // Navigate to the new route with state
        navigate("/messageView", {
          state: {
            users: user?.user_id,
            img: user?.image,
            name: user?.name,
          },
        });
      } else {
        toast.error(response?.message || "An error occurred");
      }
    } catch (error) {
      toast.error("An error occurred while processing your request.");
      console.error("API error:", error);
    }
  };
  const handleLimitedShow = (user) => {
    console.log("handleLimitedShow>>>>", user);
    if (
      pkgStatus?.package_viewed_users < pkgStatus?.package_view_users &&
      user?.is_seen === 0
    ) {
      viewedLimitedUser(user);
    } else if (user?.is_seen === 1) {
      console.log("inside ");
      navigate("/messageView", {
        state: {
          users: user?.user_id,
          img: user?.image,
          name: user?.name,
        },
      });
    } else {
      toast.error("Your Contact Details View Limit is Exeeded.");
    }
  };
  const message_graph = [
    {
      accepted_request: 15,
      rejected_request: 16,
      pending_request: 2,
    },
    {
      accepted_request: 20,
      rejected_request: 30,
      pending_request: 20,
    },
    {
      accepted_request: 25,
      rejected_request: 20,
      pending_request: 5,
    },
    {
      accepted_request: 30,
      rejected_request: 30,
      pending_request: 30,
    },
    {
      accepted_request: 35,
      rejected_request: 30,
      pending_request: 35,
    },
    {
      accepted_request: 40,
      rejected_request: 30,
      pending_request: 40,
    },
    {
      accepted_request: 28,
      rejected_request: 30,
      pending_request: 42,
    },
  ];
  const getInitial1 = (name) => name?.charAt(0)?.toUpperCase() + name?.slice(1);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You Need to subscribe for accepting this Request
    </Tooltip>
  );

  const limitedRequestData =
    pkgStatus?.package_all_contact_view === 1
      ? tutorRequest?.slice(0, 10)
      : tutorRequest?.slice(0, pkgStatus?.package_view_users);

  return (
    <div
      className="mt-2  container-fluid "
      style={{
        backgroundColor: "#f4f4f4",
        width: role === "Parent" ? "101%" : "100%",
      }}
    >
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <Container className="mobile-container " style={{ width: "95%" }}>
        <Row className="welContainer  justify-content-center">
          <Col className="align-content-center" sm={12} xs={12} md={12} lg={5}>
            <span className="welcome">
              Welcome back, {getInitial1(userData?.name)}
            </span>
            <p>
              {role == "Tutor"
                ? "Manage your profile and connect with parents/students in your dashboard"
                : "Manage your profile and connect with tutors in your dashboard"}
            </p>
          </Col>
          <Col className="" md={5} xs={12} lg={5}>
            <Row className="justify-content-start">
              <Col md={10} lg={10} xs={6}>
                <img
                  src={Images.Anna} // Adjust the path as needed
                  className="img-fluid  rounded-top anaa"
                  alt="Anna"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5 graph">
          <Col
            style={{ borderRadius: "20px" }}
            className="bg-white"
            lg={role === "Tutor" ? 9 : 8}
            xl={role === "Tutor" ? 9 : 8}
            xxx={role === "Tutor" ? 9 : 8}
            md={12}
            xs={12}
          >
            <Row className="mt-2 justify-content-center">
              <Col className="text-start" lg={8} md={6}>
                <h4 className="parent">
                  {role === "Tutor"
                    ? "Tuition Connections"
                    : "Tuition Connections"}
                </h4>
              </Col>
              <Col className="text-end " lg={4} md={6}>
                <span className="middle">Weekly Performance</span>
              </Col>

              <Col
                className=" d-flex justify-content-end  "
                lg={12}
                xl={12}
                md={12}
              >
                <div className="d-flex justify-content-end">
                  <select onChange={handleWeek} className="">
                    <option value="Current Week">Current Week</option>
                    <option value="last week">Last week</option>
                  </select>
                </div>
              </Col>
            </Row>

            <LineChart messageGraph={dashCount?.message_graph} />
            {/* <LineChart messageGraph={message_graph} /> */}
          </Col>
          {role === "Tutor" ? (
            <Col xl={3} xxl={3} lg={3} md={12}>
              <div className="card-content">
                <div className="card card1 mt-3">
                  <div className="card-icon-wrapper">
                    <img className="cardicon1" src={Images.cardicon1} alt="" />
                    <img
                      className="cardicon-overlay"
                      src={Images.user}
                      alt=""
                    />
                  </div>
                  <Row className="justify-content-between">
                    {/* <Col lg={3} md={3}></Col> */}
                    <Col className="text-end" lg={12} md={12}>
                      <p className="title mt-1 me-2">Total Parent Requests</p>
                    </Col>

                    <Col className="text-end " md={12} lg={12}>
                      <span>Count -</span>{" "}
                      <span className="me-2 number">
                        {dashCount?.request_count}
                      </span>
                    </Col>
                    {/* <hr /> */}
                    {/* <Col className="text-start" lg={12}>
                      <span className="percentage"> +5%</span> than last month
                    </Col> */}
                  </Row>
                </div>
                <div className="card card1 mt-5">
                  <div className="card-icon-wrapper">
                    <img className="cardicon1" src={Images.cardicon2} alt="" />
                    <img
                      className="cardicon-overlay"
                      src={Images.conn}
                      alt=""
                    />
                  </div>
                  <Row className="justify-content-around">
                    <Col className="text-end" lg={12} md={12}>
                      <p className="title mt-1 me-2">Accepted Request</p>
                    </Col>

                    <Col className="text-end  " md={12} lg={12}>
                      <span>Count -</span>{" "}
                      <span className="me-2 number">
                        {dashCount?.accepted_request_count}
                      </span>
                    </Col>

                    {/* <Col className="text-start" lg={12}>
                      <span className="percentage"> +4%</span> than last month
                    </Col> */}
                  </Row>
                </div>
                <div className="card card1 mt-5">
                  <div className="card-icon-wrapper">
                    <img className="cardicon1" src={Images.cardicon3} alt="" />
                    <img
                      className="cardicon-overlay"
                      src={Images.time}
                      alt=""
                    />
                  </div>
                  <Row className="justify-content-between">
                    <Col className=" " lg={12} md={12}>
                      <p className=" mt-1 me-2 title">Pending Request</p>
                    </Col>

                    <Col className="text-end   " md={12} lg={12}>
                      <span>Count -</span>{" "}
                      <span className="me-2 number">
                        {" "}
                        {dashCount?.pending_request_count}
                      </span>
                    </Col>

                    {/* <Col className="text-start" lg={12}>
                      <span className="percentage"> +60%</span> than last month
                    </Col> */}
                  </Row>
                </div>
              </div>
            </Col>
          ) : (
            <Col sm={12} xs={12} xl={4} xxl={4} lg={4} md={12}>
              <TutorSlider tutors={boostedTutor} />
            </Col>
            // <TutorSlider />
          )}
        </Row>
        <Row className=" mt-3">
          <Col
            style={{ borderRadius: "20px" }}
            className="mt-3      bg-white "
            lg={12}
            md={12}
          >
            <Row className="  mt-4 justify-content-between">
              <Col className="" xs={6} lg={6} md={4}>
                <h4 className="parent ms-2">
                  {role === "Tutor"
                    ? "Parent/Student Request"
                    : "Tutor Requests"}
                </h4>
              </Col>
              <Col lg={5} xs={5} md={4}>
                <Row className="justify-content-between">
                  <Col className="text-end" lg={11} md={6}>
                    <Link to={"/view-request"}>
                      {" "}
                      <span className="all">View All</span>
                    </Link>
                    <span className=" ms-1  ">
                      <Image
                        className="img-fluid mt-0   arrow "
                        alt="arrow"
                        src={Images.viewArrow}
                      />
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            {role === "Tutor" ? (
              <div className="table-responsive">
                <Table className="" hover>
                  <thead>
                    <tr key={"unique"}>
                      <th className=" text-start"> Parent/Student Name</th>
                      <th className=" text-center"> Message</th>
                      <th className="  text-center">Status</th>
                      <th className="  text-center">Send Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {limitedRequestData?.map((row) => (
                      <tr key={row.id}>
                        {row?.name == null ? (
                          <td className="align-items-center">
                            <img
                              src={
                                row?.profile_image_path
                                  ? row?.profile_image_path
                                  : null
                              }
                              alt={"N/A"}
                              className="table-img"
                            />
                            <span className="ms-3">N/A</span>
                          </td>
                        ) : (
                          <td className="name-cell2">
                            <img
                              src={
                                row?.profile_image_path
                                  ? row?.profile_image_path
                                  : Images.FindMyTutor
                              }
                              className="table-img2"
                            />
                            <span className="name-text2">
                              {getInitial1(row?.name)}
                            </span>
                          </td>
                        )}

                        <td className="middle2">
                          <ShowMoreLess
                            text={row?.last_message}
                            maxLength={10}
                          />
                        </td>

                        {row?.convo_sender_id == user_id ? (
                          row?.request_status == 1 ? (
                            <td className="middle2 text-center">
                              <span className="fw-bold">Accepted</span>
                            </td>
                          ) : row?.request_status == 0 ? (
                            <td className="middle2 text-center">
                              <span className="fw-bold">Request Sent</span>
                            </td>
                          ) : row?.request_status == 2 ? (
                            <td className="middle2 text-center">
                              <span className="fw-bold">Rejected</span>
                            </td>
                          ) : null
                        ) : row?.request_status == 1 ? (
                          <td className="middle2 text-center">
                            <span className="fw-bold">Accepted</span>
                          </td>
                        ) : row?.request_status == 2 ? (
                          <td className="middle2 text-center">
                            <span className="fw-bold">Rejected</span>
                          </td>
                        ) : (
                          <td className="text-center">
                            <div className="fa-icons1">
                              {pkgStatus?.length <= 0 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={renderTooltip}
                                >
                                  <div className="d-inline">
                                    <FaCheck
                                      className="text-muted text-success fa-check"
                                      style={{
                                        pointerEvents: "none",
                                        marginTop: "10px",
                                      }}
                                    />
                                  </div>
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>Accept</Tooltip>
                                  }
                                >
                                  <span>
                                    <FaCheck
                                      onClick={() => AcceptRequest(row)}
                                      className="text-success cursor-pointer fa-check"
                                      style={{ marginTop: "10px" }}
                                    />
                                  </span>
                                </OverlayTrigger>
                              )}
                              {pkgStatus?.length <= 0 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={renderTooltip}
                                >
                                  <FaTimes
                                    className="text-muted ms-4 fa-times"
                                    style={{
                                      pointerEvents: "none",
                                      alignItems: "center",
                                      color: "red",
                                      marginTop: "10px",
                                    }}
                                  />
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>Reject</Tooltip>
                                  }
                                >
                                  <span>
                                    <FaTimes
                                      onClick={() => RejectRequest(row)}
                                      className="ms-4 cursor-pointer fa-times"
                                      style={{
                                        alignItems: "center",
                                        color: "red",
                                        marginTop: "10px",
                                      }}
                                    />{" "}
                                  </span>
                                </OverlayTrigger>
                              )}
                            </div>
                          </td>
                        )}

                        {row?.request_status == 1 && pkgStatus?.status == 1 ? (
                          <td className="text-center">
                            <button
                              onClick={
                                pkgStatus?.package_all_contact_view == 0
                                  ? () => handleLimitedShow(row)
                                  : () => SendMessage(row)
                              }
                              className="middle mt-2 btn-blue"
                            >
                              Send Message
                            </button>
                          </td>
                        ) : (
                          <td className="middle text-center"></td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="table-responsive">
                <Table className hover>
                  <thead>
                    <tr>
                      <th className="text-start"> Tutor Name</th>
                      <th className="text-center">Message</th>

                      <th className="text-center">Status</th>
                      <th className="text-center">Send Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {limitedRequestData?.map((row) => (
                      <tr key={row.id}>
                        {row?.name == null ? (
                          <td className="name-cell2">
                            <img
                              src={
                                row?.profile_image_path
                                  ? row?.profile_image_path
                                  : null
                              }
                              alt={"N/A"}
                              className="table-img2"
                            />
                            {/* <span className="ms-3">{getInitial1(row?.name)}</span> */}
                            <span className="ms-3">N/A</span>
                          </td>
                        ) : (
                          <td className="name-cell2">
                            <img
                              src={
                                row?.profile_image_path
                                  ? row?.profile_image_path
                                  : Images.FindMyTutor
                              }
                              // alt={row.name}
                              className="table-img2"
                            />
                            <span className="name-text2">
                              {getInitial1(row?.name)}
                            </span>
                          </td>
                        )}
                        <td className="middle2 text-center">
                          {/* {row?.last_message} */}
                          <ShowMoreLess
                            text={row?.last_message}
                            maxLength={20}
                          />
                        </td>
                        {row?.convo_sender_id == user_id ? (
                          row?.request_status == 1 ? (
                            <td className="middle2 text-center">
                              <span className="fw-bold">Accepted</span>
                            </td>
                          ) : row?.request_status == 0 ? (
                            <td className="middle2 text-center">
                              <span className="fw-bold">Request Sent</span>
                            </td>
                          ) : row?.request_status == 2 ? (
                            <td className="middle2 text-center">
                              <span className="fw-bold">Rejected</span>
                            </td>
                          ) : null
                        ) : row?.request_status == 1 ? (
                          <td className="middle2 text-center">
                            <span className="fw-bold">Accepted</span>
                          </td>
                        ) : row?.request_status == 2 ? (
                          <td className="middle2 text-center">
                            <span className="fw-bold">Rejected</span>
                          </td>
                        ) : (
                          <>
                            <td className="text-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>Accept</Tooltip>
                                }
                              >
                                <span>
                                  <FaCheck
                                    onClick={() => AcceptRequest(row)}
                                    className="text-success cursor-pointer"
                                    style={{
                                      alignItems: "center",
                                      marginTop: "10px",
                                    }}
                                  />
                                </span>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>Reject</Tooltip>
                                }
                              >
                                <span>
                                  <FaTimes
                                    onClick={() => RejectRequest(row)}
                                    style={{
                                      alignItems: "center",
                                      color: "red",
                                      marginTop: "10px",
                                    }}
                                    className=" ms-4  cursor-pointer"
                                  />
                                </span>
                              </OverlayTrigger>
                            </td>
                          </>
                        )}
                        {row?.name != null && row?.request_status == 1 ? (
                          <td
                            style={{ alignItems: "center" }}
                            className="text-center"
                          >
                            <button
                              onClick={() => SendMessage(row)}
                              className="middle2 mt-2 btn-blue"
                            >
                              Send Message
                            </button>
                          </td>
                        ) : (
                          <td
                            style={{ alignItems: "center" }}
                            className="middle text-center"
                          ></td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Col>
        </Row>
        <br />
        {role === "Tutor" ? (
          <Row
            style={{ borderRadius: "20px" }}
            className="bg-white mt-4 justify-content-between"
          >
            <Col className="text-start mt-2" xs={6} sm={6} lg={4}>
              <h4 className="parent ms-2">Advertisements</h4>
            </Col>
            <Col className="text-lg-end me-2 mt-2" xs={6} sm={6} lg={2}>
              <Link to={"/advertise-list"}>
                <span className="all">View All</span>
              </Link>
              <span className=" ms-1  ">
                <Image
                  className="img-fluid mt-0   arrow "
                  alt="arrow"
                  src={Images.viewArrow}
                />
              </span>
            </Col>

            <Col lg={12} md={12} className="table-responsive">
              <div style={{ overflowX: "auto" }} className="table-responsive">
                <Table className="table hover">
                  <thead>
                    <tr>
                      <th className="text-start">Parent/Student Name</th>
                      <th className="text-center">Description</th>
                      <th className="text-center">Subject</th>
                      <th className="text-center">Class</th>
                      <th className="text-center">Date</th>
                      <th className="text-center">Method</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adverList?.map((row) => (
                      <tr key={row.id}>
                        <td className="name-cell2">
                          <img
                            src={row?.image ? row?.image : Images?.FindMyTutor}
                            alt={row?.name}
                            className="table-img2"
                          />
                          <span className="name-text2">
                            {getInitial1(row?.name)}
                          </span>
                        </td>
                        <td className="middle2">
                          <ShowMoreLess
                            text={row?.description}
                            maxLength={20}
                          />
                        </td>
                        <td className="middle2">
                          {row.subject_names ? row?.subject_names : "null"}
                        </td>
                        <td className="middle2">{row?.class_names}</td>
                        <td className="middle2">
                          {moment(row?.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td className="middle2">{row?.tutoring_method}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        ) : null}
      </Container>
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default TutorDashBoard;
