/** @format */

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Images from "../../../../Helper/Images";
import "./Step3.css";
import { FiChevronDown } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useForm } from "react-hook-form";
import ApiEndPoints from "../../../../Network_Call/ApiEndPoints";
import { toast } from "react-toastify";
import { apiCallNew } from "../../../../Network_Call/apiservices";
import { getToken } from "../../../../Helper/Storage";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import Select from "react-select";

const Step3 = () => {
  const navigate = useNavigate();

  const [subjectTypeList, setSubjectTypeList] = useState([]);
  const [cityList, setCitytList] = useState([]);
  const [city, setCity] = useState([]);
  const role = localStorage.getItem("role");
  const token = getToken();
  const [load, setLoad] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [catType, setCatType] = useState("");
  const [subcat, setSubcat] = useState([]);
  const [usersubcat, setusersubcat] = useState([]);
  const [cattypeError, setCatetypeError] = useState("");
  const [CityError, setCityError] = useState("");
  const [SubSubsubjectError, setSubSubsubjectError] = useState("");

  const handleCategoryChange = (event) => {
    console.log(">>>>", event.target.value);
    setCatType(event.target.value);
    getSubjecList(event.target.value);
    setSubcat([]);
  };
  const handleSubCategoryChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setusersubcat(selectedValues);
  };

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "rgb(233,236,239)", // Change this to your desired background color
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "black", // Change this to your desired text color
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "black", // Change this to your desired remove icon color
      ":hover": {
        backgroundColor: "#6D39E8", // Change this to your desired hover background color
        color: "white", // Change this to your desired hover text color
      },
    }),
    menu: (provided) => ({
      ...provided,
      height: "150px", // Adjust the maxHeight as needed
      overflowY: "scroll",
    }),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  useEffect(() => {
    GetSubjectTypeList();
    GetTutorProfile();
    GetCityList();
  }, []);
  const getSubjecList = async (id) => {
    const response = await apiCallNew(
      "get",
      null,
      `${ApiEndPoints.SubjectList}/${id}`
    );
    console.log("RESPONSEOF", response);
    if (response.success === true) {
      setSubcat(response.result);
    }
  };
  const filteredData = cityList.filter((element1) =>
    city?.some((element2) => element1.id == element2)
  );
  const filteredData1 = subcat.filter((element1) =>
    usersubcat?.some((element2) => element1.id == element2)
  );
  const validate = () => {
    let isValid = true;
    // if (!catType) {
    //   setCatetypeError("Subject type is required");
    //   isValid = false;
    // } else {
    //   setCatetypeError("");
    // }
    if (city == undefined || city?.length == 0) {
      setCityError("City is required");
      isValid = false;
    } else {
      setCityError("");
    }
    if (catType && usersubcat?.length === 0) {
      setSubSubsubjectError("Please select at least one sub-subject.");
      isValid = false;
    } else {
      setSubSubsubjectError("");
    }
    return isValid;
  };
  const GetTutorProfile = async () => {
    try {
      const response = await fetch(ApiEndPoints.Get_Tutor_Profile, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Include any other headers your API requires, e.g., authorization headers
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("responseTUTOR PROFILE>>>", data);

      if (data.success) {
        setValue("catType", data.result.subject_type_id);
        setCatType(data.result.subject_type_id);
        setValue("education", data.result.education);
        setValue("experience", data.result.experience);
        setValue("rate", data.result.hourly_rate);
        setCity(data.result.city_ids_arr);
        setusersubcat(data.result.subject_ids_arr);
        getSubjecList(data.result.subject_type_id);
      } else {
        // Handle the case where the response is not successful
        // toast.error("Failed to fetch tutor profile data.");
      }
    } catch (error) {
      console.error("Error fetching tutor profile:", error);
      // toast.error("An error occurred while fetching the tutor profile.");
    }
  };
  const GetCityList = async () => {
    try {
      const response = await apiCallNew("get", null, ApiEndPoints.CityList);
      if (response.success) {
        setCitytList(response.result);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("An error occurred while fetching the subject list.");
    }
  };
  const GetSubjectTypeList = async () => {
    try {
      const response = await apiCallNew(
        "get",
        null,
        ApiEndPoints.SubjectTypeList
      );
      console.log("responseSUBJECT TYPE>>>", response);
      if (response.success) {
        setSubjectTypeList(response.result);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("An error occurred while fetching the subject list.");
    }
  };
  const handlecityChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setCity(selectedValues);
    clearErrors("city");
  };
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const isFormValid = await validate();
    console.log("isFormValid", isFormValid);
    if (isFormValid) {
      SubjectUpdate1(data);
    } else {
      setIsSubmitting(false);
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log("value", value);
    if (/^[0-9]*$/.test(value)) {
      setValue("experience", value, { shouldValidate: true });
    }
  };
  const SubjectUpdate1 = async (data) => {
    setLoad(true);
    try {
      const Data = new FormData();
      city.forEach((city) => {
        Data.append("city_ids[]", city);
      });
      usersubcat.forEach((subcat) => {
        Data.append("subject_ids[]", subcat);
      });
      Data.append("education", data.education);
      Data.append("experience", data.experience);
      Data.append("hourly_rate", data.rate);
      Data.append("subject_type_id", catType);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        ApiEndPoints.SubjectUpdate,
        Data,
        config
      );
      console.log("MainRESPONSE", response);

      if (response.data.success) {
        setLoad(false);
        toast.success("Your Education Details have been updated successfully");
        setTimeout(() => {
          navigate("/step4");
        }, 1000);
      } else {
        setLoad(false);
        toast.error(response.data.msg);
      }
    } catch (error) {
      setLoad(false);
      // toast.error("An error occurred. Please try again.");
      setIsSubmitting(false);
    }
  };
  const subcatOptions = subcat.map((subject) => ({
    value: subject.id,
    label: subject.title,
  }));

  return (
    <div
      style={{
        backgroundColor: "#fff",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <div style={{ borderRadius: "8px", maxWidth: "1000px", width: "100%" }}>
        <Container style={{ width: "100%", padding: 0 }}>
          <Row className="justify-content-center text-center">
            <Col
              style={{
                backgroundColor: "#ebe6f5",
                textAlign: "left",
                padding: "20px",
                borderRadius: "8px",
              }}
              xs={12}
              md={6}
            >
              <div className="mt-2">
                <h3 className="">
                  {role === "Tutor"
                    ? "Sign Up - Tutor Profile"
                    : "Sign Up - Students/Parents"}
                </h3>
                <p>
                  Enter your education, experience and teach subject details to
                  continue
                </p>
              </div>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="subject" className="mb-3">
                  <Form.Label className="label">
                    Which subject would you like to teach?
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      as="select"
                      {...register("catType", {
                        required: "Subject type is required",
                      })}
                      onChange={handleCategoryChange}
                      value={catType}
                    >
                      <option value="">Select Subject</option>
                      {subjectTypeList?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.subject_type}
                        </option>
                      ))}
                    </Form.Control>
                    {errors.catType && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "smaller",
                          margin: "0px",
                        }}
                      >
                        {errors.catType.message}
                      </p>
                    )}
                    <FiChevronDown
                      className="gender-icon"
                      style={{
                        position: "absolute",
                        top: errors.catType ? "20px" : "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                  {catType && (
                    <div style={{ marginTop: "1rem" }}>
                      <Select
                        options={subcatOptions}
                        isMulti
                        onChange={handleSubCategoryChange}
                        onFocus={() => setSubSubsubjectError("")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select sub-subject..."
                        value={filteredData1.map((item) => ({
                          value: item.id,
                          label: item.title,
                        }))}
                      />
                    </div>
                  )}
                  {SubSubsubjectError && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        marginLeft: "4px",
                      }}
                    >
                      {SubSubsubjectError}
                    </span>
                  )}
                  {/* {SubSubsubjectError && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        marginLeft: "4px",
                      }}
                    >
                      {SubSubsubjectError}
                    </span>
                  )} */}
                  {/* {cattypeError && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        margin: "0px",
                        marginTop: "2px",
                      }}
                    >
                      {cattypeError}
                    </p>
                  )} */}
                </Form.Group>

                <Form.Group controlId="education" className="mb-3">
                  <Form.Label className="label">
                    Your Education and the institute it was obtained from{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your Education"
                    {...register("education", {
                      required: "Education is required",
                      validate: (value) => {
                        if (value.trim() === "") {
                          return "Education cannot be empty";
                        }
                        return true;
                      },
                    })}
                    onChange={() => clearErrors("education")}
                  />
                  {errors.education && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        margin: "0px",
                      }}
                    >
                      {errors.education.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group controlId="experience" className="mb-3">
                  <Form.Label className="label">Your Experience</Form.Label>
                  <span style={{ color: "red" }}> *</span>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your experience"
                    {...register("experience", {
                      required: "Experience is required",
                      validate: (value) => {
                        if (value.trim() === "") {
                          return "Experience cannot be empty";
                        }
                        return true;
                      },
                    })}
                  />
                  {errors.experience && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        margin: "0px",
                      }}
                    >
                      {errors.experience.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group controlId="rate" className="mb-3">
                  <Form.Label className="label">
                    What is your expected hourly rate in AED?
                  </Form.Label>
                  <span style={{ color: "red" }}> *</span>
                  <Form.Control
                    placeholder="Rate ex.100"
                    {...register("rate", {
                      required: "Rate is required",
                      validate: (value) =>
                        value > 0 || "charge cannot be a negative number",
                    })}
                  />
                  <p>
                    Most tutors charge from 200 AED to 500 AED per hour (it can
                    be more or less depending on qualifications/experience of
                    tutor)
                    {errors.rate && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "smaller",
                          margin: "0px",
                        }}
                      >
                        {errors.rate.message}
                      </p>
                    )}
                  </p>
                </Form.Group>
                <Form.Group controlId="subject" className="mb-3">
                  <Form.Label className="label">
                    In what area of UAE are you located?
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div style={{ position: "relative" }}>
                    <Select
                      options={cityList.map((item) => ({
                        value: item.id,
                        label: item.city_name,
                      }))}
                      value={filteredData.map((item) => ({
                        value: item.id,
                        label: item.city_name,
                      }))}
                      isMulti
                      onChange={handlecityChange}
                      // onBlur={handleBlur}
                      onFocus={() => setCityError("")}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select City..."
                      styles={customStyles}
                    />
                  </div>
                  {CityError && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        margin: "0px",
                        marginTop: "2px",
                      }}
                    >
                      {CityError}
                    </p>
                  )}
                </Form.Group>

                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="next-button"
                >
                  Next
                </Button>
              </Form>

              <br />
              <br />

              <a onClick={() => navigate(-1)}>
                <div className="prevBtn">
                  <IoIosArrowRoundBack className="pricon" />
                  <h1 className="prText mt-2">Previous</h1>
                </div>
              </a>
            </Col>

            <Col
              style={{
                background:
                  "linear-gradient(242.03deg, #6D39E9 -3.34%, #CCBAF7 72.34%)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
              xs={12}
              md={6}
            >
              <img
                src={role == "Tutor" ? Images.Step2img : Images.family}
                alt="Profile Step 1"
                className="img-fluid"
                style={{ borderRadius: "8px" }}
              />
              <div className="alreadyBtn">
                <span style={{ marginRight: "10px" }}>Already a Member</span>
                <Link to={"/login"}>
                  <Button variant="primary">Sign In</Button>
                </Link>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  padding: "5px 10px",
                  borderRadius: "8px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <span style={{ color: "white" }}>Step 3/7</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default Step3;
