import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { IoIosArrowRoundBack } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "axios";
import Images from "../../../../Helper/Images";
import ApiEndPoints from "../../../../Network_Call/ApiEndPoints";
import { getToken } from "../../../../Helper/Storage";
import "./Step5.css";
import { FiChevronDown } from "react-icons/fi";
import { FaInfoCircle } from "react-icons/fa";

const Step5 = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [self, setSelf] = useState("");
  const [experience, setExperience] = useState("");
  const [modeError, setModeError] = useState("");
  const [experienceError, setExperienceError] = useState("");

  const [mode, setMode] = useState("");
  const [selfError, setSelfError] = useState("");
  const role = localStorage.getItem("role");
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log("mode", mode);
  useEffect(() => {
    GetTutorProfile();
  }, []);
  const GetTutorProfile = async () => {
    try {
      const response = await axios.get(ApiEndPoints.Get_Tutor_Profile, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (response.data.success) {
        console.log("RESPONSE>>", response);
        setSelf(response.data.result.intro_yourself_to_student || "");
        setMode(response.data.result.preferred_teaching_method || "");
        setExperience(response.data.result.years_of_experience || "");
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      // toast.error("An error occurred while fetching the tutor profile.");
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const TutorSelf = async () => {
    if (!self) {
      setSelfError("This field is required");
      setIsSubmitting(false);
      return;
    }
    setLoad(true);
    try {
      const payload = {
        intro_yourself: self,
        preferred_teaching_method: mode,
        years_of_experience: experience,
      };

      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        ApiEndPoints.TutorIndroduce,
        payload,
        config
      );

      if (response.data.success === true) {
        toast.success(response.data.msg);

        // Delay the navigation by 1.5 seconds
        setTimeout(() => {
          navigate("/step7");
          setLoad(false);
          setIsSubmitting(false);
        }, 1500); // Adjust the delay time as needed
      } else {
        setLoad(false);
        setIsSubmitting(false);
        // toast.error(response.data.msg);
      }
    } catch (error) {
      setLoad(false);
      setIsSubmitting(false);
      console.error("Error uploading profile image:", error);
      // toast.error("An error occurred. Please try again.");
    }
  };

  // const onSubmit = (e) => {
  //   if (isSubmitting) return;
  //   setIsSubmitting(true);
  //   setSelfError(""); // Reset error state
  //   TutorSelf();
  // };
  const onSubmit = (e) => {
    // e.preventDefault();
    setSelfError(""); // Reset error state

    // Validation for the mode and experience fields
    let isValid = true;
    if (!mode) {
      setModeError("Please select your preferred teaching method.");
      isValid = false;
    } else {
      setModeError("");
    }

    if (!experience) {
      setExperienceError("Please select your years of experience.");
      isValid = false;
    } else {
      setExperienceError("");
    }

    if (!self) {
      setSelfError("This field is required.");
      isValid = false;
    } else {
      setSelfError("");
    }

    // Proceed with form submission if validation passes
    if (isValid) {
      setIsSubmitting(true);
      TutorSelf();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setMode(value);
  };
  const handleInputChange1 = (e) => {
    const value = e.target.value;
    setExperience(value);
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <div
        style={{
          borderRadius: "8px",
          maxWidth: "900px",
          width: "100%",
        }}
      >
        <Container style={{ width: "100%", padding: 0 }}>
          <Row className="justify-content-center">
            <Col
              style={{
                backgroundColor: "#ebe6f5",
                textAlign: "left",
                padding: "20px",
                borderRadius: "8px",
              }}
              xs={12}
              md={6}
            >
              {" "}
              <h3 className="">
                {role === "Tutor"
                  ? "Sign Up - Tutor Profile"
                  : "Sign Up - Students/Parents"}
              </h3>
              <div className="">
                <p className="  ">
                  This section can be completed later on in your profile details
                  once your account has been created if required.
                </p>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Form.Group controlId="subject" className="mb-3">
                  <Form.Label className="label">
                    What is your preferred method of teaching?
                  </Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      onChange={handleInputChange}
                      as="select"
                      value={mode}
                    >
                      <option value="">Select Method</option>
                      <option value="In-person">In-person</option>
                      <option value="Online">Online </option>
                      <option value="Either in-person or online">
                        Either in-person or online
                      </option>
                    </Form.Control>
                    <FiChevronDown
                      className="gender-icon"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                  {modeError && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        margin: "0px",
                        marginTop: "2px",
                      }}
                    >
                      {modeError}
                    </p>
                  )}
                </Form.Group>
                <Form.Group controlId="subject" className="mb-3">
                  <Form.Label className="label">
                    how many years of experience do you have in
                    teaching/tutoring?
                  </Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      onChange={handleInputChange1}
                      as="select"
                      defaultValue=""
                      value={experience}
                    >
                      <option value="">Experience</option>
                      <option value="Less than 1 year">Less than 1 year</option>
                      <option value="1 year">1 year </option>
                      <option value="2 year">2 years </option>
                      <option value="3 year">3 years </option>
                      <option value="4 year">4 years </option>
                      <option value="5 year">5 years </option>
                      <option value="6 year">6 years </option>
                      <option value="7 year">7 years </option>
                      <option value="8 year">8 years </option>
                      <option value="9 year">9 years </option>
                      <option value="10 years +">10 years +</option>
                    </Form.Control>
                    <FiChevronDown
                      className="gender-icon"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                  {experienceError && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        margin: "0px",
                        marginTop: "2px",
                      }}
                    >
                      {experienceError}
                    </p>
                  )}
                  <p className="mt-1 experience-description ">
                    <FaInfoCircle style={{ marginRight: "5px" }} /> If you are
                    not a previous teacher/tutor and are a professional in your
                    industry, you should choose the years of experience in your
                    respectable field instead
                  </p>
                </Form.Group>
                <h6 style={{ color: "#06241B", fontWeight: "500" }}>
                  Give details of your tutoring experience
                </h6>
                <Controller
                  name="characterInput"
                  control={control}
                  defaultValue=""
                  rules={{
                    maxLength: {
                      value: 5000,
                      message: "Character limit is 5000",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={5}
                      value={self}
                      onChange={(e) => {
                        setSelf(e.target.value);
                        setSelfError(""); // Clear error on change
                      }}
                      placeholder="Type about yourself"
                      // error={!!selfError || !!errors.characterInput}
                      // helperText={
                      //   selfError
                      //     ? selfError
                      //     : errors.characterInput
                      //     ? errors.characterInput.message
                      //     : ""
                      // }
                    />
                  )}
                />
                {selfError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "smaller",
                      margin: "0px",
                      marginTop: "2px",
                    }}
                  >
                    {selfError}
                  </p>
                )}
                <p className="experience-description ">
                  <FaInfoCircle style={{ marginRight: "5px" }} /> You should
                  share details about your background, teaching approach,
                  strengths, experience and qualifications. Providing
                  comprehensive information increases your likelihood of
                  attracting potential students.
                </p>
                <Row className="justify-content-between">
                  <Col lg={4}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      className="next-button "
                    >
                      Next
                    </Button>
                  </Col>
                  <Col lg={4}>
                    <Button
                      disabled={isSubmitting}
                      onClick={() => navigate("/step7")}
                      className="next-button "
                    >
                      Skip
                    </Button>
                  </Col>
                </Row>
              </Form>
              <br />
              <a onClick={() => navigate(-1)}>
                <div className="prevBtn">
                  <IoIosArrowRoundBack className="pricon" />
                  <h1 className="prText mt-2">Previous</h1>
                </div>
              </a>
            </Col>
            <Col
              style={{
                background:
                  "linear-gradient(242.03deg, #6D39E9 -3.34%, #CCBAF7 72.34%)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
              xs={12}
              md={6}
            >
              <img
                src={role === "Tutor" ? Images.Step2img : Images.family}
                alt="Profile Step 1"
                className="img-fluid"
                style={{ borderRadius: "8px" }}
              />
              <div className="alreadyBtn">
                <span style={{ marginRight: "10px" }}>Already a Member</span>
                <Link to="/login">
                  <Button variant="primary">Sign In</Button>
                </Link>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  padding: "5px 10px",
                  borderRadius: "8px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <span style={{ color: "white" }}>Step 5/7</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "#fff",
  },
};

export default Step5;
