import ApiEndPoints from "../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../Network_Call/apiservices";
import React, { useEffect, useState } from "react";
import TimeSetComponent from "./TimeSetComponent";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { FiPlus } from "react-icons/fi";
import { CircularProgress, TextField } from "@mui/material";
import { getToken } from "../../Helper/Storage";
import axios from "axios";

const EditSlotModel = ({
  showNestedModal1,
  handleClose,
  id,
  type,
  getSlote,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [timeModelShow, setTimeModelShow] = useState(false);
  const [show, setShow] = useState(false);
  const [availability, setAvailability] = useState("");
  const [availabilityError, setAvailabilityError] = useState("");
  const [startTime, setStartTime] = useState("");
  const [customDays, setCustomDays] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedOptionError, setSelectedOptionError] = useState("");
  const [startTimeError, setStartTimeError] = useState("");
  const [endTimeError, setEndTimeError] = useState("");
  const [startTimerError, setStartTimerError] = useState("");
  const [cardOpen, setCardOpen] = useState(false);
  const [slotType, setSlotType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [load, setload] = useState(false);
  console.log("ff", selectedOption);
  useEffect(() => {
    if (showNestedModal1 && id && type == "edit") {
      EditSlotGetApi(id);
    } else {
      setEndTime("");
      setStartTime("");
      setSelectedOption("");
      setSlotType("");
    }
    const handleFocus = () => {
      GetTimeSlot();
    };
    window.addEventListener("focus", handleFocus);
    return () => {
      // setDataArray([]);
      window.removeEventListener("focus", handleFocus);
    };
  }, [showNestedModal1, id]);

  const GetTimeSlot = () => {
    apiCallNew("get", null, ApiEndPoints.GetTutorSlot)
      .then((response: any) => {
        if (response.success === true) {
        } else {
          toast(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
      });
  };
  const EditSlotGetApi = (id) => {
    apiCallNew("get", null, `${ApiEndPoints.GetSingleTutorSlot}/${id}`)
      .then((response: any) => {
        if (response.success === true) {
          console.log("gg", response);
          setSelectedOption(response.result.day_slot);
          setStartTime(response.result.start_time);
          setEndTime(response.result.end_time);
          setSlotType(response.result.time_type);
          getSlote();
        } else {
          toast(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
      });
  };
  const handleState = () => {
    setSelectedOption("");
    setCustomDays("");
  };
  const UpdateSlotApi = () => {
    setload(true);
    const Data = new FormData();
    Data.append(
      "day_slot",
      selectedOption == "1" ? customDays : selectedOption
    );
    Data.append(`time_type`, selectedOption == "1" ? "1" : slotType);
    Data.append(`start_time`, startTime);
    Data.append(`end_time`, endTime);
    apiCallNew("post", Data, `${ApiEndPoints.UpdateTutorSlot}${id}`)
      .then((response) => {
        if (response.success === true) {
          getSlote();
          handleState();
          handleClose();
          toast.success(response.msg);
          setload(false);
        } else {
          toast.error(response.msg);
          setload(false);
        }
      })
      .finally((er) => {
        toast(er);
        setload(false);
      });
  };
  const AddSlotApi = async () => {
    setload(true);
    try {
      const Data = new FormData();
      Data.append(
        `time[${0}][day_slot]`,
        selectedOption == "1" ? customDays : selectedOption
      );
      Data.append(`time[${0}][time_type]`, selectedOption == "1" ? 1 : 0);
      Data.append(`time[${0}][start_time]`, startTime);
      Data.append(`time[${0}][end_time]`, endTime);

      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        ApiEndPoints.AddTutorSlot,
        Data,
        config
      );
      if (response.data.success === true) {
        handleState();
        handleClose();
        getSlote();
        toast.success(response.data.msg);
        setload(false);
      } else {
        setload(false);
        toast.error(response.data.msg);
      }
    } catch (error) {
      setload(false);
      console.error("Error cominggg:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleAvailability = () => {
    // if (!show) {
    //   // When opening the modal, clear the input and error states

    //   setStartTimeError("");
    //   setEndTimeError("");
    // }
    setShow(!show);
    setCardOpen(true);
  };
  const handleModalToggle = () => {
    setShowModal(false);
  };
  const validateTimeFormat = (time) => {
    const timeFormatRegex =
      /^((([01][0-9]|2[0-3]):[0-5][0-9])|((0[1-9]|1[0-2]):[0-5][0-9] ?([AaPp][Mm])))$/;
    return timeFormatRegex.test(time);
  };
  const handleModalSubmit = () => {
    // Validate start time and end time
    if (!validateTimeFormat(startTime)) {
      setStartTimeError("Please enter a valid start time (HH:MM AM/PM)");
      return;
    }
    if (!validateTimeFormat(endTime)) {
      setEndTimeError("Please enter a valid end time (HH:MM AM/PM)");
      return;
    }
    // If both start time and end time are valid, close the modal
    setShow(false);
  };
  const handleStartTimeChange = (e) => {
    const value = e.target.value;
    setStartTime(value);
    if (!validateTimeFormat(value)) {
      setStartTimeError("Invalid time format. Please use(HH:MM AM/PM)");
    } else {
      setStartTimeError("");
    }
    // If the modal is open and start time is invalid, don't close the modal
    if (!validateTimeFormat(value) && show) {
      setShowModal(true);
    }
  };
  const handleEndTimeChange = (e) => {
    const value = e.target.value;
    setEndTime(value);
    if (!validateTimeFormat(value)) {
      setEndTimeError("Invalid time format. Please use (HH:MM AM/PM)");
    } else {
      setEndTimeError("");
    }
    // If the modal is open and end time is invalid, don't close the modal
    if (!validateTimeFormat(value) && show) {
      setShowModal(true);
    }
  };

  const handleAvailChange = (e) => {
    const value = e.target.value;
    setAvailability(value);

    if (!value) {
      setAvailabilityError("Availability is required.");
    } else {
      setAvailabilityError("");
    }
  };
  const validateForm = async () => {
    let isValid = true;
    if (!selectedOption) {
      setSelectedOptionError("Select option is required");
      isValid = false;
    }
    return isValid;
  };
  const handleSubmit = async () => {
    const isFormValid = await validateForm();
    if (isFormValid) {
      if (type == "edit") {
        UpdateSlotApi();
      } else {
        AddSlotApi();
      }
    }
  };

  return (
    <Modal show={showNestedModal1} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{type == "edit" ? "Edit Slot" : "Add Slot"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {load && (
          <div style={styles.backdrop}>
            <CircularProgress style={styles.loader} />
          </div>
        )}
        <Form>
          <Form.Group controlId="availability" className="mb-3">
            <Form.Label style={{ margin: 0, padding: 0 }}>
              Your Availability
            </Form.Label>
            <Form.Select
              value={slotType === 1 ? slotType : selectedOption}
              onChange={handleDropdownChange}
              onFocus={() => setSelectedOptionError("")}
            >
              <option value="">Select an option</option>
              <option value="Does not repeat">Does not repeat</option>
              <option value="Daily">Daily</option>
              <option value="Weekly on Monday">Weekly on Monday</option>
              <option value="Weekly on Tuesday">Weekly on Tuesday</option>
              <option value="Weekly on Wednesday">Weekly on Wednesday</option>
              <option value="Weekly on Thursday">Weekly on Thursday</option>
              <option value="Weekly on Friday">Weekly on Friday</option>
              <option value="Weekly on Saturday">Weekly on Saturday</option>
              <option value="Every weekday (Monday To Friday)">
                Every weekday (Monday To Friday)
              </option>
              <option value="1">Custom...</option>
            </Form.Select>
            {selectedOptionError && (
              <span style={{ color: "red", fontSize: "smaller" }}>
                {selectedOptionError}
              </span>
            )}
          </Form.Group>

          {selectedOption == "1" || slotType == 1 ? (
            <Form.Group controlId="availability" className="mb-3">
              <label style={{ marginLeft: 2 }}>Custome Days</label>
              <Form.Control
                type="text"
                onChange={(e) => setCustomDays(e.target.value)}
                value={slotType === 1 ? selectedOption : customDays}
                placeholder="Custom Days"
              />
            </Form.Group>
          ) : null}
          <Form.Group controlId="availability" className="mb-3">
            <label style={{ marginLeft: 2 }}>Time</label>

            <div style={{ position: "relative" }}>
              <FiPlus
                style={{
                  position: "absolute",
                  top: "50%",
                  // left: "260px",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={handleAvailability}
              />
              <Form.Control
                type="text"
                disabled={true}
                onChange={handleAvailability}
                value={`Time: ${startTime} to ${endTime}`}
                placeholder="Add Availability"
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <TimeSetComponent
        handleAvailability={handleAvailability}
        handleModalToggle={handleModalToggle}
        handleStartTimeChange={handleStartTimeChange}
        handleEndTimeChange={handleEndTimeChange}
        // endTime={handleEndTimeChange}
        handleModalSubmit={handleModalSubmit}
        startTimeError={startTimeError}
        endTimeError={endTimeError}
        show={show}
        cardOpen={cardOpen}
      />
      <Modal.Footer>
        <Button
          variant="secondary"
          className="custom-button"
          onClick={() => {
            handleState();
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          variant="primary"
          className="custom-button"
          // disabled={!isFormValid}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};
export default EditSlotModel;
