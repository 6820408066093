import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Images from "../../../../Helper/Images";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { IoIosArrowRoundBack } from "react-icons/io";
import "./Step6.css";
import TimeSetComponent from "../../../../Components/Model/TimeSetComponent";
import { BsPlus } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import Screen from "../../../../Helper/Screen";
import {
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ApiEndPoints from "../../../../Network_Call/ApiEndPoints";
import { toast } from "react-toastify";
import { apiCallNew } from "../../../../Network_Call/apiservices";
import { getToken } from "../../../../Helper/Storage";
import axios from "axios";
const Step6 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showGenderOptions, setShowGenderOptions] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // const [days, setDays] = useState("");
  // const [customDays, setCustomDays] = useState("");
  const [show, setShow] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startTimeError, setStartTimeError] = useState("");
  const [endTimeError, setEndTimeError] = useState("");
  const [load, setload] = useState(false);
  const role = localStorage.getItem("role");
  const [currentInput, setCurrentInput] = useState({
    days: "",
    customDays: "",
  });
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(dataArray));
  }, [dataArray]);
  useEffect(() => {
    // Retrieve data from local storage when component mounts
    const savedDataArray = localStorage.getItem("dataArray");
    if (savedDataArray) {
      setDataArray(JSON.parse(savedDataArray));
    }
  }, []);
  const addData = (days, customDays, startTime, endTime) => {
    setDataArray((prevArray) => {
      const updatedArray = [
        ...prevArray,
        [days, customDays, startTime, endTime],
      ];
      localStorage.setItem("dataArray", JSON.stringify(updatedArray)); // Save to local storage
      return updatedArray;
    });
    setCurrentInput({ days: "", customDays: "" });
    setStartTime("");
    setEndTime("");
  };
  // const addData = (days, customDays, startTime, endTime) => {
  //   setDataArray((prevArray) => [
  //     ...prevArray,
  //     [days, customDays, startTime, endTime],
  //   ]);
  //   setCurrentInput({ days: "", customDays: "" });
  //   setStartTime("");
  //   setEndTime("");
  // };
  useEffect(() => {
    setIsFormValid(
      currentInput.days !== "" && startTime !== "" && endTime !== ""
    );
  }, [currentInput.days, startTime, endTime]);
  console.log("currentInput.days", currentInput.days);
  // const SubjectUpdate = async () => {
  //   try {
  //     const Data = new FormData();
  //     dataArray.forEach((slot, index) => {
  //       console.log("slot[1]", slot[1], "slot[0]", slot[0]);
  //       Data.append(
  //         `time[${index}][day_slot]`,
  //         currentInput.days == 0 ? slot[0] : slot[1]
  //       );
  //       Data.append(
  //         `time[${index}][time_type]`,
  //         currentInput.days == 0 ? slot[0] : slot[1]
  //       );
  //       Data.append(`time[${index}][start_time]`, slot[2]);
  //       Data.append(`time[${index}][end_time]`, slot[3]);
  //     });
  //     const token = getToken();

  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };
  //     const response = await axios.post(
  //       ApiEndPoints.AddTutorSlot,
  //       Data,
  //       config
  //     );
  //     if (response.data.success == true) {
  //       console.log("rrr", response.data);
  //       navigate("/step7");
  //       toast.success(response.data.msg);
  //     } else {
  //       toast.error(response.data.msg);
  //     }
  //   } catch (error) {
  //     console.error("Error cominggg:", error);
  //   }
  // };
  const SubjectUpdate = async () => {
    setload(true);
    try {
      const Data = new FormData();
      dataArray.forEach((slot, index) => {
        console.log("slot[1]", slot[1], "slot[0]", slot[0]);
        Data.append(
          `time[${index}][day_slot]`,
          currentInput.days == 0 ? slot[0] : slot[1]
        );
        Data.append(
          `time[${index}][time_type]`,
          currentInput.days == 0 ? slot[0] : slot[1]
        );
        Data.append(`time[${index}][start_time]`, slot[2]);
        Data.append(`time[${index}][end_time]`, slot[3]);
      });

      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        ApiEndPoints.AddTutorSlot,
        Data,
        config
      );

      if (response.data.success === true) {
        console.log("rrr", response.data);

        toast.success(response.data.msg);

        // Delay the navigation by 2 seconds (2000 milliseconds)
        setTimeout(() => {
          navigate("/step7");
          setload(false);
        }, 2000); // Adjust the delay time as needed
      } else {
        setload(false);
        toast.error(response.data.msg);
      }
    } catch (error) {
      setload(false);
      console.error("Error cominggg:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleChange1 = (event) => {
    setCurrentInput((prevState) => ({
      ...prevState,
      days: event.target.value,
    }));
  };

  const handleChange = (event) => {
    setCurrentInput((prevState) => ({
      ...prevState,
      customDays: event.target.value,
    }));
  };
  const updateIndex = (index, newData1, newData2, newData3) => {
    setDataArray((prevArray) => {
      if (index >= 0 && index < prevArray.length) {
        const updatedArray = [...prevArray];
        updatedArray[index] = [newData1, newData2, newData3];
        return updatedArray;
      }
      return prevArray;
    });
  };
  const deleteIndex = (index) => {
    setDataArray((prevArray) => {
      if (index >= 0 && index < prevArray.length) {
        return prevArray.filter((_, i) => i !== index);
      }
      return prevArray;
    });
  };
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const { days, customDays } = currentInput;
  //   addData(days, customDays, startTime, endTime);
  // };
  const handleSubmit = (event) => {
    event.preventDefault();
    const { days, customDays } = currentInput;
    addData(days, customDays, startTime, endTime);
    setCurrentInput({ days: "", customDays: "" });
    setStartTime("");
    setEndTime("");
  };

  const toggleGenderOptions = () => {
    setShowGenderOptions(!showGenderOptions);
  };
  const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  };
  const isTimeEarlier = (startTime, endTime) => {
    const start = convertTo24HourFormat(startTime);
    const end = convertTo24HourFormat(endTime);
    return start < end;
  };

  const handleModalToggle = () => {
    setShowModal(false);
  };
  const handleAvailability = () => {
    if (!show) {
      // When opening the modal, clear the input and error states
      setStartTime("");
      setEndTime("");
      setStartTimeError("");
      setEndTimeError("");
    }
    setShow(!show);
    setCardOpen(true);
  };
  const validateTimeFormat = (time) => {
    const timeFormatRegex =
      /^((([01][0-9]|2[0-3]):[0-5][0-9])|((0[1-9]|1[0-2]):[0-5][0-9] ?([AaPp][Mm])))$/;
    return timeFormatRegex.test(time);
  };
  // const handleModalSubmit = () => {
  //   // Validate start time and end time
  //   if (!validateTimeFormat(startTime)) {
  //     setStartTimeError("Please enter a valid start time (HH:MM AM/PM)");
  //     return;
  //   }
  //   if (!validateTimeFormat(endTime)) {
  //     setEndTimeError("Please enter a valid end time (HH:MM AM/PM)");
  //     return;
  //   }
  //   // If both start time and end time are valid, close the modal
  //   setShow(false);
  // };
  const handleModalSubmit = () => {
    if (!validateTimeFormat(startTime)) {
      setStartTimeError("Please enter a valid start time (HH:MM AM/PM)");
      return;
    }
    if (!validateTimeFormat(endTime)) {
      setEndTimeError("Please enter a valid end time (HH:MM AM/PM)");
      return;
    }
    if (startTime === endTime) {
      setStartTimeError("Start time and end time cannot be the same.");
      setEndTimeError("End time and start time cannot be the same.");
      return;
    }
    if (!isTimeEarlier(startTime, endTime)) {
      setStartTimeError("Start time cannot be later than end time.");
      setEndTimeError("End time cannot be earlier than start time.");
      return;
    }
    setShow(false);
  };
  // const handleStartTimeChange = (e) => {
  //   const value = e.target.value;
  //   console.log("valuess", value);

  //   setStartTime(value);
  //   setStartTimeError("");
  // };

  // const handleEndTimeChange = (e) => {
  //   const value = e.target.value;
  //   setEndTime(value);
  //   setEndTimeError("");
  // };

  // const handleStartTimeChange = (e) => {
  //   const value = e.target.value;
  //   setStartTime(value);
  //   if (!validateTimeFormat(value)) {
  //     setStartTimeError("Invalid time format. Please use HH:mm AM/PM.");
  //   } else {
  //     setStartTimeError("");
  //   }
  // };

  // const handleEndTimeChange = (e) => {
  //   const value = e.target.value;
  //   setEndTime(value);
  //   if (!validateTimeFormat(value)) {
  //     setEndTimeError("Invalid time format. Please use HH:mm AM/PM.");
  //   } else {
  //     setEndTimeError("");
  //   }
  // };
  // const handleStartTimeChange = (e) => {
  //   const value = e.target.value;
  //   setStartTime(value);
  //   if (!validateTimeFormat(value)) {
  //     setStartTimeError("Invalid time format. Please use(HH:MM AM/PM)");
  //   } else {
  //     setStartTimeError("");
  //   }
  //   // If the modal is open and start time is invalid, don't close the modal
  //   if (!validateTimeFormat(value) && show) {
  //     setShowModal(true);
  //   }
  // };
  // const handleEndTimeChange = (e) => {
  //   const value = e.target.value;
  //   setEndTime(value);
  //   if (!validateTimeFormat(value)) {
  //     setEndTimeError("Invalid time format. Please use (HH:MM AM/PM)");
  //   } else {
  //     setEndTimeError("");
  //   }
  //   // If the modal is open and end time is invalid, don't close the modal
  //   if (!validateTimeFormat(value) && show) {
  //     setShowModal(true);
  //   }
  // };
  const handleStartTimeChange = (e) => {
    const value = e.target.value;
    setStartTime(value);
    if (!validateTimeFormat(value)) {
      setStartTimeError("Invalid time format. Please use (HH:MM AM/PM)");
    } else if (value === endTime) {
      setStartTimeError("Start time and end time cannot be the same.");
    } else if (endTime && !isTimeEarlier(value, endTime)) {
      setStartTimeError("Start time cannot be later than end time.");
    } else {
      setStartTimeError("");
    }
    if (
      (!validateTimeFormat(value) ||
        value === endTime ||
        (endTime && !isTimeEarlier(value, endTime))) &&
      show
    ) {
      setShowModal(true);
    }
  };

  const handleEndTimeChange = (e) => {
    const value = e.target.value;
    setEndTime(value);
    if (!validateTimeFormat(value)) {
      setEndTimeError("Invalid time format. Please use (HH:MM AM/PM)");
    } else if (value === startTime) {
      setEndTimeError("End time and start time cannot be the same.");
    } else if (startTime && !isTimeEarlier(startTime, value)) {
      setEndTimeError("End time cannot be earlier than start time.");
    } else {
      setEndTimeError("");
    }
    if (
      (!validateTimeFormat(value) ||
        value === startTime ||
        (startTime && !isTimeEarlier(startTime, value))) &&
      show
    ) {
      setShowModal(true);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        // minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {" "}
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <div
        style={{
          borderRadius: "8px",
          maxWidth: "800px",
          width: "100%",
        }}
      >
        <Container style={{ width: "100%", padding: 0 }}>
          <Row className="justify-content-center">
            <Col
              style={{
                backgroundColor: "#ebe6f5",
                textAlign: "left",
                padding: "20px",
                borderRadius: "8px",
              }}
              xs={12}
              md={6}
            >
              <div className="mt-2">
                <h3 className="head">
                  {role == "Tutor"
                    ? "Sign Up - Tutor Profile"
                    : "Sign Up - Students/Parents"}
                </h3>
                <p style={{ fontWeight: "400", fontSize: "14px" }}>
                  {role == "Tutor"
                    ? "Please enter your availability day and time for classes you teach here"
                    : "Please enter your availability day and time for classes you learn here"}
                </p>
              </div>
              <h6 style={{ color: "#06241B", fontWeight: "500" }}>
                Your Availability<span style={{ color: "red" }}> *</span>
              </h6>
              {dataArray.length > 0 ? (
                <div>
                  {dataArray.map((dataSet, index) => {
                    console.log("dataArray", dataArray);
                    return (
                      <Card
                        key={index}
                        style={{
                          width: "100%",
                          backgroundColor: "#F6F6F6",
                          border: "0.5px solid #fff",
                          borderRadius: "25px 0px 25px 0px",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      >
                        <div style={{ marginLeft: 20, marginBottom: -10 }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              // backgroundColor: "red",
                            }}
                          >
                            <h5
                              style={{
                                color: "#35185A",
                                marginTop: 5,
                                fontWeight: "bolder",
                              }}
                            >
                              Slot-{index + 1}
                            </h5>
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => deleteIndex(index)}
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                          <div style={{ display: "flex", marginBottom: -10 }}>
                            <h6 style={{ fontSize: 13, fontWeight: "bold" }}>
                              Days:{" "}
                            </h6>
                            <p
                              style={{
                                color: "#6d39e9",
                                marginLeft: 5,
                                fontSize: 12,
                              }}
                            >
                              {dataSet[0] == "1" ? dataSet[1] : dataSet[0]}
                            </p>
                          </div>
                          <div style={{ display: "flex", marginBottom: -10 }}>
                            <h6 style={{ fontSize: 13, fontWeight: "bold" }}>
                              Time:{" "}
                            </h6>
                            <p
                              style={{
                                color: "#6d39e9",
                                marginLeft: 5,
                                fontSize: 12,
                              }}
                            >
                              {" "}
                              {dataSet[2]}{" "}
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                fontWeight: "bold",
                                marginLeft: 10,
                                marginRight: 2,
                              }}
                            >
                              to
                            </p>
                            <p
                              style={{
                                color: "#6d39e9",
                                marginLeft: 5,
                                fontSize: 12,
                              }}
                            >
                              {" "}
                              {dataSet[3]}
                            </p>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              ) : null}
              <br />
              <Form onSubmit={handleSubmit}>
                <FormControl required sx={{ width: "100%" }}>
                  <InputLabel id="label">Days</InputLabel>
                  <Select
                    sx={{ color: "#6d39e9" }}
                    labelId="demo-simple-select-required-label"
                    id="label"
                    value={currentInput.days || ""}
                    label="Days"
                    type="text"
                    onChange={handleChange1}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    <MenuItem value="Does not repeat">Does not repeat</MenuItem>
                    <MenuItem value="Daily">Daily</MenuItem>
                    <MenuItem value="Weekly on Monday">
                      Weekly on Monday
                    </MenuItem>
                    <MenuItem value="Weekly on Tuesday">
                      Weekly on Tuesday
                    </MenuItem>
                    <MenuItem value="Weekly on Wednesday">
                      Weekly on Wednesday
                    </MenuItem>
                    <MenuItem value="Weekly on Thursday">
                      Weekly on Thursday
                    </MenuItem>
                    <MenuItem value="Weekly on Friday">
                      Weekly on Friday
                    </MenuItem>
                    <MenuItem value="Weekly on Saturday">
                      Weekly on Saturday
                    </MenuItem>
                    <MenuItem value="Every weekday (Monday To Friday)">
                      Every weekday (Monday To Friday)
                    </MenuItem>
                    <MenuItem value="1">Custom...</MenuItem>
                  </Select>
                </FormControl>
                {currentInput.days === "1" && (
                  <TextField
                    sx={{ marginTop: 2, width: "100%" }}
                    id="custom"
                    label="Custom Days"
                    type="text"
                    value={currentInput.customDays}
                    onChange={handleChange}
                    InputProps={{
                      style: { color: "#6d39e9", borderColor: "#6d39e9" },
                    }}
                  />
                )}

                <TextField
                  sx={{ marginTop: 2, width: "100%" }}
                  id="outlined-password-input"
                  label="Time"
                  type="text"
                  value={`Time: ${startTime} to ${endTime}`}
                  // onChange={{}}
                  InputProps={{
                    style: { color: "#6d39e9", borderColor: "#6d39e9" },
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <IconButton
                          aria-label="add"
                          onClick={handleAvailability}
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TimeSetComponent
                  handleAvailability={handleAvailability}
                  handleModalToggle={handleModalToggle}
                  handleStartTimeChange={handleStartTimeChange}
                  handleEndTimeChange={handleEndTimeChange}
                  // endTime={handleEndTimeChange}
                  handleModalSubmit={handleModalSubmit}
                  startTimeError={startTimeError}
                  endTimeError={endTimeError}
                  show={show}
                  cardOpen={cardOpen}
                />

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button
                    disabled={!isFormValid}
                    type="submit"
                    className="mt-3 w-25 btn"
                  >
                    Add
                  </Button>
                  {/* <Link to={Screen.Step7}> */}
                  <Button
                    onClick={() => SubjectUpdate()}
                    className="mt-3 w-100 btn"
                    disabled={dataArray?.length <= 0}
                  >
                    Submit
                  </Button>
                  {/* </Link> */}
                </div>
              </Form>
              <br />
              <br />
              <a
                onClick={() => {
                  navigate(-1);
                }}
              >
                <div className="prevBtn">
                  <IoIosArrowRoundBack className="pricon" />
                  <h1 className="prText mt-2">Previous</h1>
                </div>
              </a>
            </Col>
            <Col
              style={{
                background:
                  "linear-gradient(242.03deg, #6D39E9 -3.34%, #CCBAF7 72.34%)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative", // Added for relative positioning
              }}
              xs={12}
              md={6}
            >
              <img
                src={role == "Tutor" ? Images.Step2img : Images.family}
                alt="Profile Step 1"
                className="img-fluid"
                style={{ borderRadius: "8px" }} // Ensures image also has rounded corners
              />
              <div className="alreadyBtn">
                <span style={{ marginRight: "10px" }}>Already a Member</span>
                <Link to={"/login"}>
                  <Button variant="primary" className="custom-button">
                    Sign In
                  </Button>
                </Link>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px", // Position at the top right of the Col
                  right: "10px",
                  // backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
                  padding: "5px 10px",
                  borderRadius: "8px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <span style={{ color: "white" }}>
                  {role == "Tutor" ? "Step 6/7" : "Step 4/5"}{" "}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default Step6;
