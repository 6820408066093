/** @format */

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Images from "../../../../Helper/Images";
import { Link, useNavigate } from "react-router-dom";
import "./AdvertisePostList.css";
import ApiEndPoints from "../../../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../../../Network_Call/apiservices";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import Upper from "../../../../Components/Uppecase";

const ShowMoreLess = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleShowMore = () => {
    setIsExpanded(!isExpanded);
  };

  if (text.length <= maxLength) {
    return <p className="message-subject">{text}</p>;
  }

  return (
    <p className="message-subject">
      {isExpanded ? text : `${text.slice(0, maxLength)}...`}
      <span
        onClick={toggleShowMore}
        style={{
          color: "blue",
          cursor: "pointer",
          marginLeft: "5px",
          fontSize: "12px",
        }}
      >
        {isExpanded ? "Show Less" : "Show More"}
      </span>
    </p>
  );
};
const AdvertisePostList = () => {
  const navigate = useNavigate();
  const [adverList, setAdverList] = useState([]);
  const [load, setload] = useState(false);
  const role = localStorage?.getItem("role");
  useEffect(() => {
    AdvertiseList();
  }, []);
  const AdvertiseList = () => {
    setload(true);
    apiCallNew("post", null, ApiEndPoints.AdvertiseList).then((res) => {
      console.log("advertise lisy", res);
      setAdverList(res?.result);
      setload(false);
    });
  };

  const AdvertiseDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Adevetisement!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6d39e9",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      // setload(true);
      if (result.isConfirmed) {
        apiCallNew("delete", null, `${ApiEndPoints.AdvertiseDelete}${id}`).then(
          (response) => {
            if (response.success === true) {
              setload(false);
              AdvertiseList();
              Swal.fire("Deleted!", response.msg, "success");
            } else {
              setload(false);
              Swal.fire("Error!", response.msg, "error");
            }
          }
        );
      }
    });
  };
  const truncateUserMsg = (userMsg) => {
    const maxLength = 25;
    if (userMsg?.length > maxLength) {
      return `${userMsg?.slice(0, maxLength)}...`;
    }
    return userMsg;
  };
  return (
    <Container fluid className="req-list" style={{ padding: "0px 43px" }}>
      <Row>
        {load && (
          <div style={styles.backdrop}>
            <CircularProgress style={styles.loader} />
          </div>
        )}
        <Col md={12} style={{ padding: "0px 52px" }}>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <h4>Advertisement</h4>
            <Link to={"/advertise-post"}>
              <button className="message-button">Add</button>
            </Link>
          </div>
          {!load && adverList?.length == 0 ? (
            <div className="d-flex   align-items-center justify-content-center">
              <h6> Advertisement Not Found</h6>
            </div>
          ) : (
            <div className="card   shadow mt-2">
              {adverList?.map((item) => (
                <>
                  <div
                    key={item.id}
                    className={`message-item 
                }`}
                  >
                    <Link className="message-link ">
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 10,
                          alignItems: "center",
                        }}
                      >
                        <Col md={1}>
                          <img
                            className="imgs"
                            src={item?.image ? item?.image : Images.FindMyTutor}
                            alt="user img"
                            style={{ margin: 0, height: 60, width: 60 }}
                          />
                        </Col>
                        <Col md={3}>
                          <div>
                            <p
                              className="message-sender"
                              style={{ color: "black", fontSize: 20 }}
                            >
                              <Upper name={item?.name} />
                            </p>
                            <ShowMoreLess
                              text={item?.description}
                              maxLength={25}
                            />
                          </div>
                        </Col>
                        <Col xs={12} md={5}>
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <p
                                className="message-sender"
                                style={{ color: "black", fontSize: 17 }}
                              >
                                Subject:
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "gray",
                                    marginLeft: 10,
                                  }}
                                >
                                  {item?.subject_names}
                                </span>
                              </p>
                              <p
                                className="message-sender"
                                style={{ color: "black", fontSize: 17 }}
                              >
                                Class:
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "gray",
                                    marginLeft: 10,
                                  }}
                                >
                                  {item?.class_names}
                                </span>
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          // md={2}
                          className="text-sm-end"
                          style={{
                            alignSelf: "normal",
                          }}
                        >
                          <div style={{ textAlign: "center", marginTop: 10 }}>
                            <button
                              className="message-button bg-danger"
                              onClick={() => AdvertiseDelete(item?.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </>
              ))}
              {/* // ))} */}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default AdvertisePostList;
