import React from "react";

const CookiesPolicy = () => {
  return (
    <div>
      <h1>Cookies Policy</h1>
      <p>
        This Cookies Policy explains what cookies are and how we use them...
      </p>
      {/* Add your detailed policy content here */}
    </div>
  );
};

export default CookiesPolicy;
