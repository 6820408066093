/** @format */

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Navbar,
  Nav,
  Button,
  Card,
  Col,
  Row,
  Container,
  Form,
  FormControl,
  Image,
} from "react-bootstrap";
import Screen from "../Helper/Screen";
import "./Header.css";
import Images from "../Helper/Images";
import { FaLock, FaSearch } from "react-icons/fa";
import { Logout, getToken } from "../Helper/Storage";
import ApiEndPoints from "../Network_Call/ApiEndPoints";
import { apiCallNew } from "../Network_Call/apiservices";
import {
  Box,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [search, setSearch] = useState("");
  const [searchList, setSerchList] = useState([]);
  const [showParentModal, setShowParentModal] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false); // Changed initial state to false
  const token = getToken();
  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen); // Toggle navbar state
  };

  const Log = () => {
    if (token) {
      Logout();
      navigate("/login");
    } else {
      navigate("/login");
    }
  };

  const Log1 = () => {
    if (token) {
      Logout();
      navigate("/step1");
    } else {
      navigate("/step1");
    }
  };

  useEffect(() => {
    const bootstrap = require("bootstrap");
    FindTutorList();
  }, [search, showParentModal]);

  const FindTutorList = async () => {
    const formData = new FormData();
    formData.append("page", 0);
    formData.append("keyword", search);
    try {
      const response = await apiCallNew(
        "post",
        formData,
        `${ApiEndPoints.FindTutor}`
      );
      if (response?.success === true) {
        setSerchList(response?.result);
      } else {
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleClickAway = () => {
    setShowParentModal(false);
    setSearch("");
  };

  const handlesearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    setShowParentModal(value !== "");
  };

  return (
    <header>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top"
        id="mainNav"
      >
        <div className="container-fluid">
          <Link className="navbar-brand js-scroll-trigger" to="/">
            <img
              // width={200}

              src={Images.FindMyTutor}
              alt="Logo"
              className="custom-logo"
            />
          </Link>
          <button
            className="navbar-toggler but"
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={navbarOpen} // Dynamically set aria-expanded
            aria-label="Toggle navigation"
            onClick={toggleNavbar} // Toggle navbar state on click
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${navbarOpen ? "show" : ""}`} // Dynamically add 'show' class
            id="navbarResponsive"
          >
            <ul className="navbar-nav ms-auto me-auto">
              <li onClick={toggleNavbar} className="nav-item fw-bold">
                <Link
                  className={`nav-link ${path === "/" ? "active" : ""}`}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li onClick={toggleNavbar} className="nav-item fw-bold">
                <Link
                  className={`nav-link ${path === "/about" ? "active" : ""}`}
                  to="/about"
                >
                  About Us
                </Link>
              </li>
              <li onClick={toggleNavbar} className="nav-item fw-bold">
                <Link
                  className={`nav-link ${path === "/contact" ? "active" : ""}`}
                  to="/contactus"
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item">
                <div className="search-container-header">
                  <input
                    type="text"
                    className="form-control1 search-input-header"
                    placeholder="What do you want to learn?"
                    value={search}
                    onChange={handlesearch}
                  />
                  <FaSearch className="search-icon-header cursor-pointer" />
                  {searchList?.length > 0 && showParentModal && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <div>
                        <Paper
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            right: 0,
                            zIndex: 1,
                            height: 200,
                            overflow: "hidden", // Ensure the height constraint is enforced
                          }}
                        >
                          <Box
                            style={{
                              height: "100%",
                              overflowY: "auto", // Add vertical scrolling
                            }}
                          >
                            <List>
                              {searchList?.map((result, index) => (
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "#6d39e9",
                                  }}
                                  state={result?.subject_id}
                                  to={"/findTutor"}
                                >
                                  <ListItem
                                    button
                                    key={index}
                                    onClick={handleClickAway}
                                  >
                                    <ListItemText
                                      primary={result?.subject_names}
                                    />
                                  </ListItem>
                                </Link>
                              ))}
                            </List>
                          </Box>
                        </Paper>
                      </div>
                    </ClickAwayListener>
                  )}
                </div>
              </li>
              <li onClick={toggleNavbar} className="nav-item fw-bold">
                <Link
                  className={`nav-link ${path === "/login" ? "active" : ""}`}
                  to="/login"
                >
                  <FaLock /> Login
                </Link>
              </li>
              <li onClick={toggleNavbar} className="nav-item fw-bold">
                <Link
                  className={`nav-link ${path === "/step1" ? "active" : ""}`}
                  to="/step1"
                >
                  <FaLock /> Sign Up
                </Link>
              </li>
              <li onClick={toggleNavbar} className="nav-item fw-bold">
                {" "}
                <Link to="/Findtutor">
                  {" "}
                  <button className="btn btn-primary ms-auto">
                    Find a Tutor
                  </button>
                </Link>
              </li>
              <li className="nav-item nav-item-right">
                <img
                  src={Images.navRightImg}
                  alt="Additional"
                  className="nav-right-img"
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
