/** @format */

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import SubscriptionDetails from "../Screens/TutorScreen/SubscriptionDetails/SubscriptionDetails";
import EditProfile from "../Screens/TutorScreen/EditProfile/EditProfile";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DashDrawer from "../Components/DashDrawer/DashDrawer";
import TutorDashBoard from "../Screens/TutorScreen/TutorDashBoard/TutorDashBoard";
import DashHeader from "../Components/DashHeader/DashHeader";
import FindTutor from "../Screens/TutorScreen/FindTutor/FindTutor";
import DashBoard from "../Screens/ParentsScreen/DashBoard/DashBoard";
import MessageView from "../Screens/PublicScreen/Message/MessageView";
import MessageList from "../Screens/PublicScreen/Message/MessageList";
import AdvertiseList from "../Screens/TutorScreen/Advertisement/AdvertiseList";
import ViewAllRequest from "../Screens/TutorScreen/ViewAllRequest/ViewAllRequest";
import Help from "../Screens/PublicScreen/Help/Help";
import SubscribPkgList from "../Screens/TutorScreen/SubscriptionDetails/SubscribPkgList";
import Payment from "../Screens/TutorScreen/Telr/Payment";
import PaymentSuccess from "../Screens/TutorScreen/Telr/PaymentStatus/PaymentSuccess/PaymentSuccess";
import PaymentFail from "../Screens/TutorScreen/Telr/PaymentStatus/PaymentFailed/PaymentFail";
import PaymentCancel from "../Screens/TutorScreen/Telr/PaymentStatus/PaymentCancel/PaymentCancel";
import axios from "axios";

const TutorRoute = () => {
  const [currentComponent, setCurrentComponent] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className="" style={{}}>
      <DashDrawer
        open={drawerOpen}
        toggleDrawer={toggleDrawer}
        variant={isMobile ? "temporary" : "permanent"}
        setCurrentComponent={setCurrentComponent}
        isMobile={isMobile}
      />
      <main
        style={{
          flexGrow: 1,
          padding: "16px 0px",
          marginTop: isMobile ? "0px" : "0px",
          marginLeft: isMobile ? "0" : "230px",
          transition: "margin-left 0.3s",
        }}
      >
        <DashHeader
          currentComponent={currentComponent}
          setCurrentComponent={setCurrentComponent}
          toggleDrawer={toggleDrawer}
          isMobile={isMobile}
        />

        <ToastContainer autoClose={1000} />

        <Routes>
          <Route path={"/"} element={<TutorDashBoard />} />
          <Route path={"/tutor"} element={<TutorDashBoard />} />
          <Route path="/subscription" element={<SubscriptionDetails />} />
          <Route path={"/editProfile"} element={<EditProfile />} />
          <Route path={"/findTutor"} element={<FindTutor />} />
          <Route path={"/messageList"} element={<MessageList />} />
          <Route path={"/messageView"} element={<MessageView />} />
          <Route path={"/advertise-list"} element={<AdvertiseList />} />
          <Route path={"/view-request"} element={<ViewAllRequest />} />
          <Route path={"/help"} element={<Help />} />
          <Route path={"/subscrib-Pkg-List"} element={<SubscribPkgList />} />
          <Route path={"/payment"} element={<Payment />} />
          <Route path={"/payment-success"} element={<PaymentSuccess />} />
          <Route path={"/payment-declined"} element={<PaymentFail />} />
          <Route path={"/payment-cancel"} element={<PaymentCancel />} />
        </Routes>
      </main>
    </div>
  );
};

export default TutorRoute;
