/** @format */

import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Images from "../../../Helper/Images";
import { FaCheck, FaTimes } from "react-icons/fa";
import { apiCallNew } from "../../../Network_Call/apiservices";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import Upper from "../../../Components/Uppecase";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "./ViewAllRequest.css";
import { toast } from "react-toastify";
const user_id = localStorage.getItem("@userId");

const ViewAllRequest = () => {
  const [tutorRequest, setTutorRequest] = useState([]);
  const [pkgStatus, setPkgStatus] = useState();
  const [load, setLoad] = useState(false);
  const role = localStorage.getItem("role");
  console.log("REQUEST LIST>>>>", tutorRequest);
  const navigate = useNavigate();

  useEffect(() => {
    ParentMessageReqList();
    Get_Pkg_Status();
  }, []);
  const Get_Pkg_Status = async () => {
    const response = await apiCallNew("get", null, ApiEndPoints.Pkg_Status);
    setPkgStatus(response?.result);
  };
  const ParentMessageReqList = () => {
    setLoad(true);
    apiCallNew("post", null, ApiEndPoints.PMessageRequestList).then(
      (response) => {
        setTutorRequest(response?.result);
        setLoad(false);
      }
    );
  };
  const ShowMoreLess = ({ text, maxLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleShowMore = () => {
      setIsExpanded(!isExpanded);
    };

    if (text.length <= maxLength) {
      return <p className="message-subject">{text}</p>;
    }

    return (
      <p className="message-subject">
        {isExpanded ? text : `${text.slice(0, maxLength)}...`}
        <span
          onClick={toggleShowMore}
          style={{
            color: "blue",
            cursor: "pointer",
            marginLeft: "5px",
            fontSize: "12px",
          }}
        >
          {isExpanded ? "Show Less" : "Show More"}
        </span>
      </p>
    );
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You Need to subscribe for accepting this Request
    </Tooltip>
  );
  const AcceptRequest = async (row) => {
    const { user_id, profile_image_path, name, message_id } = row;
    console.log("row", row);

    const data = {
      status: 1,
    };
    try {
      setLoad(true);
      const response = await apiCallNew(
        "post",
        data,
        `${ApiEndPoints.MessageRequest}${message_id}`
      );
      if (response.success == true) {
        setLoad(false);
        ParentMessageReqList();
        toast.success(response.msg);
      } else {
        setLoad(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const RejectRequest = async (row) => {
    const { user_id, profile_image_path, name, message_id } = row;
    Swal.fire({
      title: "Are you sure?",
      text: "You want to reject this request!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6d39e9",
      cancelButtonColor: "#aaaaaa",
      confirmButtonText: "Reject",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          status: 2,
        };
        try {
          setLoad(true);
          const response = await apiCallNew(
            "post",
            data,
            `${ApiEndPoints.MessageRequest}${message_id}`
          );
          if (response.success == true) {
            setLoad(false);
            toast.success(response.msg);
            ParentMessageReqList();

            console.log("reject response", response);
          } else {
            setLoad(false);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    });
  };
  const SendMessage = (row) => {
    const { user_id, profile_image_path, name, message_id } = row;
    navigate("/messageView", {
      state: { users: user_id, img: profile_image_path, name: name },
    });
  };
  const viewedLimitedUser = async (user) => {
    const subscribe_id = pkgStatus?.id;
    const viewed_user_id = user?.user_id;

    try {
      const response = await apiCallNew(
        "get",
        null,
        `${ApiEndPoints.PkgViewdUser}?subscribe_id=${subscribe_id}&viewed_user_id=${viewed_user_id}`
      );

      console.log("response of viewed>>>", response);
      if (response?.success) {
        toast.success(response?.message);

        // Navigate to the new route with state
        navigate("/messageView", {
          state: {
            users: user?.user_id,
            img: user?.image,
            name: user?.name,
          },
        });
      } else {
        toast.error(response?.message || "An error occurred");
      }
    } catch (error) {
      toast.error("An error occurred while processing your request.");
      console.error("API error:", error);
    }
  };
  const handleLimitedShow = (user) => {
    console.log("handleLimitedShow>>>>", user);
    if (
      pkgStatus?.package_viewed_users < pkgStatus?.package_view_users &&
      user?.is_seen === 0
    ) {
      viewedLimitedUser(user);
    } else if (user?.is_seen === 1) {
      console.log("inside ");
      navigate("/messageView", {
        state: {
          users: user?.user_id,
          img: user?.image,
          name: user?.name,
        },
      });
    } else {
      toast.error("Your Contact Details View Limit is Exeeded.");
    }
  };
  const limitedRequestData =
    pkgStatus?.package_all_contact_view === 1
      ? tutorRequest
      : tutorRequest?.slice(0, pkgStatus?.package_view_users);
  return (
    <div
      className="mt-2  container-fluid "
      // style={{ backgroundColor: "#f4f4f4" }}
    >
      <Container
        className="mobile-container"
        // style={{ backgroundColor: "#f4f4f4" }}
        style={{ width: "95%" }}
      >
        {load && (
          <div style={styles.backdrop}>
            <CircularProgress style={styles.loader} />
          </div>
        )}
        <Row className="">
          <Col
            // style={{ borderRadius: "20px", padding: "0px 25px" }}
            className="mt-0 v bg-white"
            lg={12}
            md={12}
          >
            <Row className="mt-4 justify-content-between">
              <Col className="" lg={6} md={4}>
                <h4 className="parent ms-2">
                  {role === "Tutor"
                    ? "Parent/Student Request"
                    : "Tutor Requests"}
                </h4>
              </Col>
              <Col lg={5} md={4}></Col>
            </Row>
            {role === "Tutor" ? (
              <div className="table-responsive">
                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-start">Parent/Student Name</th>
                      <th className="text-center">Message</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Send Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!load && limitedRequestData?.length === 0 ? (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    ) : (
                      limitedRequestData?.map((row) => (
                        <tr key={row.id}>
                          <td className="name-cell2">
                            <img
                              src={
                                row.profile_image_path
                                  ? row.profile_image_path
                                  : Images.FindMyTutor
                              }
                              alt="user img"
                              className="table-img2"
                            />
                            <span className="name-text2">
                              <Upper name={row.name} />
                            </span>
                          </td>
                          <td className="middle2 text-center">
                            {" "}
                            <ShowMoreLess
                              text={row?.last_message}
                              maxLength={10}
                            />
                          </td>
                          {row?.convo_sender_id == user_id ? (
                            row?.request_status == 1 ? (
                              <td className="middle2 text-center">
                                <span className="fw-bold">Accepted</span>
                              </td>
                            ) : row?.request_status == 0 ? (
                              <td className="middle2 text-center">
                                <span className="fw-bold">Request Sent</span>
                              </td>
                            ) : row?.request_status == 2 ? (
                              <td className="middle2 text-center">
                                <span className="fw-bold">Rejected</span>
                              </td>
                            ) : null
                          ) : row?.request_status == 1 ? (
                            <td className="middle2 text-center">
                              <span className="fw-bold">Accepted</span>
                            </td>
                          ) : row?.request_status == 2 ? (
                            <td className="middle2 text-center">
                              <span className="fw-bold">Rejected</span>
                            </td>
                          ) : (
                            <>
                              <td className="text-center">
                                {pkgStatus?.length <= 0 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip}
                                  >
                                    <div className="d-inline">
                                      <FaCheck
                                        className="text-muted text-success"
                                        style={{
                                          pointerEvents: "none",
                                          marginTop: "10px",
                                        }}
                                      />
                                    </div>
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-top`}>
                                        Accept
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      <FaCheck
                                        onClick={() => AcceptRequest(row)}
                                        className="text-success cursor-pointer"
                                        style={{ marginTop: "10px" }}
                                      />
                                    </span>
                                  </OverlayTrigger>
                                )}
                                {pkgStatus?.length <= 0 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip}
                                  >
                                    <FaTimes
                                      className="text-muted  ms-4"
                                      style={{
                                        pointerEvents: "none",
                                        alignItems: "center",
                                        color: "red",
                                        marginTop: "10px",
                                      }}
                                    />
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-top`}>
                                        Reject
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      <FaTimes
                                        onClick={() => RejectRequest(row)}
                                        style={{
                                          alignItems: "center",
                                          color: "red",
                                          marginTop: "10px",
                                        }}
                                        className=" ms-4  cursor-pointer"
                                      />
                                    </span>
                                  </OverlayTrigger>
                                )}
                              </td>
                            </>
                          )}
                          {row.request_status === 1 &&
                          pkgStatus?.status == 1 ? (
                            <td
                              style={{ alignItems: "center" }}
                              className="text-center"
                            >
                              <button
                                onClick={
                                  pkgStatus?.package_all_contact_view == 0
                                    ? () => handleLimitedShow(row)
                                    : () => SendMessage(row)
                                }
                                className="middle mt-2 btn-blue"
                              >
                                Send Message
                              </button>
                            </td>
                          ) : (
                            <td
                              style={{ alignItems: "center" }}
                              className="middle text-center"
                            ></td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="table-responsive">
                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-start">Tutor Name</th>
                      <th className="text-center">Message</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Send Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!load && tutorRequest?.length === 0 ? (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    ) : (
                      tutorRequest?.map((row) => (
                        <tr key={row.id}>
                          <td className="name-cell2">
                            <img
                              src={
                                row.profile_image_path
                                  ? row.profile_image_path
                                  : Images.FindMyTutor
                              }
                              alt="user img"
                              className="table-img2"
                            />
                            &nbsp;&nbsp;&nbsp;
                            <span className="text-center">{row.name}</span>
                          </td>
                          <td className="middle2 text-center">
                            {/* {row.last_message} */}
                            <ShowMoreLess
                              text={row?.last_message}
                              maxLength={10}
                            />
                          </td>
                          {row?.convo_sender_id == user_id ? (
                            row?.request_status == 1 ? (
                              <td className="middle2 text-center">
                                <span className="fw-bold">Accepted</span>
                              </td>
                            ) : row?.request_status == 0 ? (
                              <td className="middle2 text-center">
                                <span className="fw-bold">Request Sent</span>
                              </td>
                            ) : row?.request_status == 2 ? (
                              <td className="middle2 text-center">
                                <span className="fw-bold">Rejected</span>
                              </td>
                            ) : null
                          ) : row?.request_status == 1 ? (
                            <td className="middle2 text-center">
                              <span className="fw-bold">Accepted</span>
                            </td>
                          ) : row?.request_status == 2 ? (
                            <td className="middle2 text-center">
                              <span className="fw-bold">Rejected</span>
                            </td>
                          ) : (
                            <td className="text-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>Accept</Tooltip>
                                }
                              >
                                <span>
                                  <FaCheck
                                    onClick={() => AcceptRequest(row)}
                                    className="text-success cursor-pointer"
                                    style={{
                                      alignItems: "center",
                                      marginTop: "10px",
                                    }}
                                  />
                                </span>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>Reject</Tooltip>
                                }
                              >
                                <span>
                                  <FaTimes
                                    onClick={() => RejectRequest(row)}
                                    className="ms-4 cursor-pointer"
                                    style={{
                                      alignItems: "center",
                                      color: "red",
                                      marginTop: "10px",
                                    }}
                                  />
                                </span>
                              </OverlayTrigger>
                            </td>
                          )}
                          {row.request_status === 1 ? (
                            <td
                              style={{ alignItems: "center" }}
                              className="text-center"
                            >
                              <button
                                onClick={() => SendMessage(row)}
                                className="middle2 mt-2 btn-blue"
                              >
                                Send Message
                              </button>
                            </td>
                          ) : (
                            <td
                              style={{ alignItems: "center" }}
                              className="middle text-center"
                            ></td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default ViewAllRequest;
