// import React from "react";
// import { FaCheckCircle } from "react-icons/fa"; // Import an icon from react-icons (optional)
// import { Link } from "react-router-dom";

// const PaymentSuccess = () => {
//   return (
//     <div className="container d-flex flex-column align-items-center justify-content-center vh-100">
//       <FaCheckCircle className="text-success" size={80} />
//       <h1 className="text-success mt-3">Payment Successful!</h1>
//       <p className="text-muted text-center mt-3">
//         Thank you for your purchase. Your payment has been processed
//         successfully.
//       </p>
//       <div className="mt-4 d-flex">
//         <Link to={"/subscrib-Pkg-List"}>
//           <a href="/order-details" className="btn btn-success mr-3">
//             View Your Subscription
//           </a>
//         </Link>
//         <Link to={"/tutor"}>
//           {" "}
//           <a href="/" className="btn btn-outline-success">
//             Go to Dashboard
//           </a>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default PaymentSuccess;

// import React, { useEffect, useState } from "react";
// import { FaCheckCircle } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import Confetti from "react-confetti";
// import "./PaymentSuccess.css";

// const PaymentSuccess = () => {
//   const [timer, setTimer] = useState(5);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setTimer((prev) => (prev > 0 ? prev - 1 : 0));
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div
//       className="container d-flex flex-column align-items-center justify-content-center vh-100  position-relative"
//       style={{ overflow: "hidden" }} // Prevent scroll
//     >
//       <Confetti
//         width={window.innerWidth}
//         height={window.innerHeight}
//         numberOfPieces={150} // Adjust number of pieces to fit without scroll
//       />
//       <FaCheckCircle className="text-success" size={80} />
//       <h1 className="text-success mt-3 animate__animated animate__bounceIn">
//         Payment Successful!
//       </h1>
//       <p className="text-muted text-center mt-3 animate__animated animate__fadeIn">
//         Thank you for your purchase. Your payment has been processed
//         successfully.
//       </p>
//       <p className="text-center text-primary mt-2">
//         Redirecting in {timer} seconds...
//       </p>
//       <div className="mt-4 d-flex">
//         <Link to={"/subscrib-Pkg-List"} className="mr-3">
//           <button className="btn btn-success">View Your Subscription</button>
//         </Link>
//         <Link to={"/tutor"}>
//           <button className="btn btn-outline-success">Go to Dashboard</button>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default PaymentSuccess;

import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./PaymentSuccess.css"; // Import custom styles

const PaymentSuccess = () => {
  const [show, setShow] = useState(true);
  const [timer, setTimer] = useState(5);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/tutor");
    setShow(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        className="payment-success-modal"
      >
        <Modal.Header>
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="payment-success-container">
          <FaCheckCircle className="check-icon" />
          <h1 className="success-message">Payment Successful!</h1>
          <p className="text-muted text-center">
            Thank you for your purchase. Your payment has been processed
            successfully.
          </p>

          <div className="mt-4 d-flex justify-content-center">
            <Link to={"/subscrib-Pkg-List"} className="mr-3">
              <Button className="btn-custom btn-primary">
                View Your Subscription
              </Button>
            </Link>
            <Link to={"/tutor"}>
              <Button className="btn-custom btn-outline">
                Go to Dashboard
              </Button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentSuccess;
