/** @format */

import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Image } from "react-bootstrap";
import Images from "../../Helper/Images";
import { ArrowDropDown } from "@mui/icons-material";
import { Logout } from "../../Helper/Storage";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Screen from "../../Helper/Screen";
import ApiEndPoints from "../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../Network_Call/apiservices";
import { toast } from "react-toastify";
import "./DashHeader.css";
import {
  CircularProgress,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Paper,
  useMediaQuery,
} from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function DashHeader({
  setCurrentComponent,
  currentComponent,
  toggleDrawer,
  isMobile,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [userNmae, setUserNmae] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [searchList, setSerchList] = React.useState("");
  const [profileImage, setProfileImage] = React.useState("");
  const [load, setload] = React.useState(false);
  const [showParentModal, setShowParentModal] = React.useState(false);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const Logouts = () => {
    handleMenuClose();
    Logout();
    navigate("/login");
    window.location.reload();
  };
  React.useEffect(() => {
    UserProfile();
    FindTutorList();
  }, [userNmae, profileImage, search]);

  const UserProfile = async () => {
    // setload(true);
    apiCallNew(
      "get",
      null,
      role === "Tutor"
        ? ApiEndPoints.Tutor_Profile_Step
        : ApiEndPoints.Parent_Profile_Step
    )
      .then((response) => {
        if (response.success === true) {
          setload(false);
          setUserNmae(response?.result?.name);
          setProfileImage(response?.result?.profile_image);
        } else {
          // toast.error(response.msg);
        }
      })
      .finally((er) => {
        toast.error(er);
        setload(false);
      });
  };
  const FindTutorList = async () => {
    const formData = new FormData();
    formData.append("page", 0);
    formData.append("keyword", search);
    try {
      const response = await apiCallNew(
        "post",
        formData,
        `${ApiEndPoints.FindTutor}`
      );
      if (response?.success === true) {
        setSerchList(response?.result);
      } else {
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const handleClickAway = () => {
    setShowParentModal(false);
    setSearch("");
  };
  const handlesearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    setShowParentModal(value !== "");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMenuClose1 = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    setCurrentComponent("Profile");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const truncateUsername = (username) => {
    const maxLength = 10;
    if (username?.length > maxLength) {
      return `${username?.slice(0, maxLength)}...`;
    }
    return username;
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={Logouts}>Logout</MenuItem>
    </Menu>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={Logouts}
      >
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p style={{ margin: 0 }}>Logout</p>
      </MenuItem>
    </Menu>
  );
  const getInitial = (name) => name?.charAt(0).toUpperCase();
  const getInitial1 = (name) => name?.charAt(0).toUpperCase() + name.slice(1);
  return (
    <Box
      style={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: "white",
      }}
      sx={{
        flexGrow: 1,
      }}
    >
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      {/* <AppBar style={{ backgroundColor: "white" }} position="static"> */}
      <Toolbar
        style={{
          height: "70px",
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="black"
          aria-label="open drawer"
          onClick={toggleDrawer}
          // sx={{ mr: 2, display: { xs: "block", md: "none", sm: "none" } }}
          sx={{
            mr: 2,
            display: { xs: "block", sm: "block", md: "block", lg: "none" },
          }}
        >
          <MenuIcon />
        </IconButton>
        {/* <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          {currentComponent}
        </Typography> */}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          {/* <IconButton
            edge="start"
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
            className="mx-2"
          >
            <Badge badgeContent={48} color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          {profileImage ? (
            <Image
              className="mx-2 mt-2"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={profileImage}
            />
          ) : (
            <div
              className="circle"
              style={{
                width: "36px",
                height: "36px",
                alignSelf: "center",
              }}
            >
              {getInitial(userNmae)}
            </div>
          )}
          <p className="mx-2 mt-3">
            {userNmae ? truncateUsername(getInitial1(userNmae)) : null}
          </p>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <ArrowDropDown />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <hr />
      {/* </AppBar> */}
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}

const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};
// export default DashHeader;
