/** @format */

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./AdvertisePost.css";
import { apiCallNew } from "../../../../Network_Call/apiservices";
import ApiEndPoints from "../../../../Network_Call/ApiEndPoints";
import { toast } from "react-toastify";
import axios from "axios";
import { getToken } from "../../../../Helper/Storage";
import { FiChevronDown } from "react-icons/fi";
import Select from "react-select";
import { CircularProgress } from "@mui/material";

const AdvertisePost = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [showSubj, setShowSubj] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedSubgrade, setSelectedSubgrades] = useState([]);
  const [subjectError, setsubjectError] = useState("");
  const [catType, setCatType] = useState();
  const [gradeType, setGradeType] = useState();
  const [load, setload] = useState(false);
  const [subjectTypeList, setSubjectTypeList] = useState([]);
  const [gradeTypeList, setgradeTypeList] = useState([]);
  const [subcat, setSubcat] = useState([]);
  const [subgrade, setSubgrade] = useState([]);
  const [mode, setMode] = useState("");
  const [modeError, setmodeError] = useState();
  const [desc, setDesc] = useState("");
  const [fileName, setFileName] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [errors, setErrors] = useState({});
  const [descError, setdescError] = useState("");
  const [fileError, setFileError] = useState("");
  const role = localStorage.getItem("role");
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log("selectedSubCategories>>>", selectedSubCategories);

  useEffect(() => {
    const savedGrades = localStorage.getItem("selectedGrades");
    if (savedGrades) {
      setSelectedGrades(JSON.parse(savedGrades));
    }

    GetTutorProfile();
    GetSubjectTypeList();
    GetgradeTypeList();
  }, []);
  const GetSubjectTypeList = async () => {
    try {
      const response = await apiCallNew(
        "get",
        null,
        ApiEndPoints.SubjectTypeList
      );
      if (response.success) {
        setSubjectTypeList(response.result);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("An error occurred while fetching the subject list.");
    }
  };
  const GetgradeTypeList = async () => {
    try {
      const response = await apiCallNew(
        "get",
        null,
        ApiEndPoints.GradeTypeList
      );
      console.log("responsegrade TYPE>>>", response);
      if (response.success) {
        setgradeTypeList(response.result);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("An error occurred while fetching the subject list.");
    }
  };
  const getSubjecList = async (id) => {
    console.log("id", id);
    const response = await apiCallNew(
      "get",
      null,
      `${ApiEndPoints.SubjectList}/${id}`
    );
    if (response.success === true) {
      setSubcat(response.result);
    }
  };
  const getGradList = async (id) => {
    const response = await apiCallNew(
      "get",
      null,
      `${ApiEndPoints.GradeList}/${id}`
    );
    console.log("RESPONSEOFGrade", response);
    if (response.success === true) {
      setSubgrade(response.result);
    }
  };

  const GetTutorProfile = async () => {
    try {
      const response = await apiCallNew(
        "get",
        null,
        `${
          role === "Tutor"
            ? ApiEndPoints.Get_Tutor_Profile
            : ApiEndPoints.Parent_Profile_Step
        }`
      );
      console.log("responseTUTOR PROFILE>>>", response);
      if (response.success === true) {
        const subjectId = subjectList.find(
          (subject) => subject.title === response.result.subject_name
        )?.id;
      } else {
      }
    } catch (error) {
      toast.error("An error occurred while fetching the tutor profile.");
    }
  };

  const toggleSubjOptions = () => {
    setShowSubj(!showSubj);
  };

  const AdvertisePostCreate = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setload(true);
    try {
      const formData = new FormData();
      selectedSubCategories.forEach((subcat) => {
        formData.append("subject_ids[]", subcat);
      });
      selectedSubgrade.forEach((subcat) => {
        formData.append("grade_ids[]", subcat);
      });
      formData.append("grade_type_id", gradeType);
      formData.append("subject_type_id", catType);
      formData.append("tutoring_method", mode);
      formData.append("description", desc);
      if (selectedFile) {
        formData.append("image", selectedFile);
      }

      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        ApiEndPoints.AdvertiseCreate,
        formData,
        config
      );
      console.log("checkkkk", response);
      if (response?.data?.success == true) {
        navigate("/advertise-post-list");
        toast.success(response?.data?.msg);
        setload(false);
      } else {
        toast.error(response?.data?.msg);
        setload(false);
      }
    } catch (error) {
      setload(false);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const GradeListApi = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setload(true);
    try {
      const formData = new FormData();
      formData.append("grade_type_id", gradeType);
      selectedSubgrade.forEach((subcat) => {
        formData.append("grade_ids[]", subcat);
      });

      const token = getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        ApiEndPoints.GradeApi,
        formData,
        config
      );
      console.log("response:", response.data);

      if (response.data.success === true) {
        setload(false);
        toast.success("Your grade has been updated.");

        // Delay the navigation by 2 seconds (2000 milliseconds)
        setTimeout(() => {
          navigate("/step5");
        }, 1000); // Adjust the delay time as needed
      } else {
        setload(false);
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error:", error);
      setload(false);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoBack = () => {
    saveSelectedGradesToLocalStorage(selectedGrades);
    navigate(-1);
  };
  const saveSelectedGradesToLocalStorage = (grades) => {
    localStorage.setItem("selectedGrades", JSON.stringify(grades));
  };
  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;

    if (role === "Parent") {
      if (!catType) {
        tempErrors["catType"] = "Please select a Subject type.";
        isValid = false;
      }

      if (!mode) {
        tempErrors["mode"] = "Please select a preferred tutoring method.";
        isValid = false;
      }

      if (!desc) {
        tempErrors["desc"] = "Please provide a description.";
        isValid = false;
      } else {
        const wordCount = desc.trim().split(/\s+/).length;
        if (wordCount > 300) {
          tempErrors["desc"] = "Description cannot exceed 300 words.";
          isValid = false;
        }
      }

      if (!gradeType) {
        tempErrors["gradeType"] = "Please select a Grade Type.";
        isValid = false;
      }
      if (catType && selectedSubCategories?.length === 0) {
        tempErrors["selectedSubCategories"] =
          "Please select at least one sub-subject.";
        isValid = false;
      }
      if (gradeType && selectedSubgrade?.length === 0) {
        tempErrors["selectedSubGrade"] =
          "Please select at least one sub-grade.";
        isValid = false;
      }
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleNextClick = async () => {
    console.log("isvalid", handleValidation());
    const isvalid = handleValidation();
    console.log("isvalid", isvalid);

    // Validate the form fields first
    if (handleValidation()) {
      AdvertisePostCreate();

      // Based on the role, call the appropriate API
    }
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setCatType(value);
    getSubjecList(value);
    setSubcat([]);
  };
  const handleGradeChange = (event) => {
    const value = event.target.value;
    setGradeType(value);
    getGradList(value);
    setSubgrade([]);
  };
  const handleSubCategoryChange = (selectedOptions) => {
    setSelectedSubCategories(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };
  const handleSubgradeChange = (selectedOptions) => {
    setSelectedSubgrades(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };
  const subcatOptions = subcat.map((subject) => ({
    value: subject?.id,
    label: subject?.title,
  }));
  const subgradeOptions = subgrade.map((grade) => ({
    value: grade.id,
    label: grade.class_name,
  }));
  const handleParenttypeChange = (e) => {
    const value = e.target.value;
    setMode(value);

    if (!value) {
      setmodeError("Gender is required.");
    } else {
      setmodeError("");
    }
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    setDesc(value);
    if (!value) {
      setdescError("This is required.");
    } else {
      setdescError("");
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        setFileError("Only PNG, JPEG, and JPG files are allowed.");
        return;
      } else {
        setSelectedFile(file); // Set selected file
        setFileName(file.name);
      }
      // Update state with the file name
    } else {
      setSelectedFile(null); // Reset selected file
      setFileName(""); // Reset state if no file is selected
    }
    setFileError("");
  };
  const truncateFileName = (fileName, maxLength) => {
    if (fileName?.length <= maxLength) {
      return fileName;
    } else {
      return fileName?.slice(0, maxLength) + "...";
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const isvalid = handleValidation();
    console.log("isvalid", isvalid);

    if (isvalid) {
      console.log("Form is valid. Proceeding to call API.");
      AdvertisePostCreate();
    }
  };
  return (
    <Container fluid>
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <br />
      <Row>
        <Col md={1}></Col>
        <Col>
          <h4>Advertisement</h4>
        </Col>
        <Col md={1}></Col>
      </Row>
      <Row className="mt-4">
        <Col md={1}></Col>
        <Col md={10} className="Col">
          <div
            className="card2 card2-shadow"
            style={{
              borderWidth: 0,
              backgroundColor: "#ffffff",
            }}
          >
            <Form noValidate onSubmit={onSubmit}>
              <Row style={{ justifyContent: "space-between" }}>
                <Col lg={6} md={7} sm={12} xs={12}>
                  <h6 style={{ color: "#06241B", fontWeight: "500" }}>
                    Subject Type
                    <span style={{ color: "red" }}> *</span>
                  </h6>
                  <Form.Group controlId="gender" className="mb-3">
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        as="select"
                        required
                        value={catType}
                        onChange={handleCategoryChange}
                        onFocus={() => setErrors("")}
                        // onClick={toggleSubjOptions}
                      >
                        <option value="">Select</option>

                        {subjectTypeList?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.subject_type}
                          </option>
                        ))}
                      </Form.Control>
                      <FiChevronDown
                        className="gender-icon"
                        onClick={toggleSubjOptions}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                    {errors.catType && (
                      <p style={{ color: "red", fontSize: "smaller" }}>
                        {errors.catType}
                      </p>
                    )}
                    {/* {subcat?.length > 0 && ( */}
                    <div style={{ marginTop: "1rem" }}>
                      <Select
                        options={subcatOptions}
                        isMulti
                        onFocus={() => setErrors("")}
                        isDisabled={!catType}
                        onChange={handleSubCategoryChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select sub-subject..."
                        // style={{ width: "100%", height: 40 }}
                      />
                    </div>
                    {/* )} */}
                    {errors.selectedSubCategories && (
                      <p style={{ color: "red", fontSize: "smaller" }}>
                        {errors.selectedSubCategories}
                      </p>
                    )}
                  </Form.Group>
                  {subjectError && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        margin: "10px 0",
                      }}
                    >
                      {subjectError}
                    </p>
                  )}
                </Col>
                <Col lg={6} md={7} sm={12} xs={12}>
                  <h6>
                    {" "}
                    Grade Type
                    <span style={{ color: "red" }}>*</span>
                  </h6>
                  <Form.Group controlId="grade" className="mb-3">
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        as="select"
                        required
                        onFocus={() => setErrors("")}
                        value={gradeType}
                        onChange={handleGradeChange}
                        // onClick={toggleSubjOptions}
                      >
                        <option value="">Select</option>

                        {gradeTypeList?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.grade_type}
                          </option>
                        ))}
                      </Form.Control>
                      <FiChevronDown
                        className="gender-icon"
                        onClick={toggleSubjOptions}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                    {errors.gradeType && (
                      <p style={{ color: "red", fontSize: "smaller" }}>
                        {errors.gradeType}
                      </p>
                    )}
                    {/* {subgrade.length > 0 && ( */}
                    <div style={{ marginTop: "1rem" }}>
                      <Select
                        options={subgradeOptions}
                        isMulti
                        onFocus={() => setErrors("")}
                        isDisabled={!gradeType}
                        onChange={handleSubgradeChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select sub-grade..."
                        // style={{ width: "100%", height: 40 }}
                      />
                    </div>
                    {/* )} */}
                    {errors.selectedSubGrade && (
                      <p style={{ color: "red", fontSize: "smaller" }}>
                        {errors.selectedSubGrade}
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6} md={7} sm={12} xs={12}>
                  <h6>
                    {" "}
                    Tutoring methods
                    <span style={{ color: "red" }}>*</span>
                  </h6>
                  <Form.Group controlId="parentType" className="mb-3">
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        as="select"
                        placeholder="Select method"
                        onFocus={() => setErrors("")}
                        onChange={handleParenttypeChange}
                        value={mode}
                        // onFocus={() => setmodeError("")}
                      >
                        <option value="" disabled>
                          Select method
                        </option>
                        <option value="In-person">In-person</option>
                        <option value="Online">Online</option>
                        <option value="Either in-person or online">
                          Either in-person or online
                        </option>
                      </Form.Control>
                      <FiChevronDown
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                    {errors.mode && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "smaller",
                          marginLeft: "4px",
                        }}
                      >
                        {errors.mode}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6} md={7} sm={12} xs={12}>
                  <h6>
                    {" "}
                    Description
                    <span style={{ color: "red" }}>*</span>
                  </h6>
                  <Form.Group controlId="experience" className="mb-3">
                    <Form.Control
                      as="textarea"
                      rows={1} // Adjust the number of rows as needed
                      placeholder="Write here..."
                      onChange={handleInputChange}
                      onFocus={() => setErrors("")}
                    />

                    {errors.desc && (
                      <p style={{ color: "red", fontSize: "smaller" }}>
                        {errors.desc}
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6} md={7} sm={12} xs={12}>
                  <h6> Upload Image</h6>
                  <Form.Group controlId="formFile">
                    <div className="d-flex justify-content-between">
                      <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        className=" w-50"
                        style={{ display: "none" }}
                        id="file-input"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          border: "1px solid lightgray",

                          borderRadius: "4px",
                        }}
                      >
                        <label
                          style={{
                            padding: "5px",
                            borderRadius: "4px 0px 0px 4px",
                            // backgroundColor: "red",
                          }}
                        >
                          {fileName
                            ? truncateFileName(fileName, 20)
                            : "No file chosen"}
                        </label>
                        <label
                          htmlFor="file-input"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#e9ecef",
                            padding: "6px 4px 6px 4px",
                            alignSelf: "center",
                            borderRadius: "0px 4px 4px 0px",
                          }}
                        >
                          {" "}
                          Choose File
                        </label>
                      </div>
                    </div>

                    {fileError && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "smaller",
                          marginLeft: "4px",
                        }}
                      >
                        {fileError}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <div className="col-md-11" style={{ padding: 0, marginTop: 10 }}>
                <Button
                  type="submit"
                  className="btn, btn-primary m-0"
                  // onClick={AdvertisePostCreate}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
    </Container>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};
export default AdvertisePost;
