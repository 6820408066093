// import React from "react";
// import { FaTimesCircle } from "react-icons/fa"; // Import an icon from react-icons (optional)
// import { Button } from "react-bootstrap"; // Assuming you are using react-bootstrap
// import { Link } from "react-router-dom";

// const PaymentCancel = () => {
//   return (
//     <div className="container d-flex flex-column align-items-center justify-content-center vh-100">
//       <FaTimesCircle className="text-danger" size={80} />
//       <h1 className="text-danger mt-3">Payment Canceled</h1>
//       <p className="text-muted text-center mt-3">
//         Unfortunately, your payment was not processed. Please try again or
//         contact support if the issue persists.
//       </p>
//       <div className="mt-4 d-flex">
//         <Link to="/subscription">
//           {" "}
//           <Button href="/retry-payment" variant="danger" className="mr-3">
//             Retry
//           </Button>
//         </Link>
//         <Link to="/tutor">
//           {" "}
//           <Button href="/" variant="outline-danger">
//             Go to Dashboard
//           </Button>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default PaymentCancel;

// import React from "react";
// import { FaTimesCircle } from "react-icons/fa";
// import { Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import "./PaymentCancel.css"; // Import custom styles

// const PaymentCancel = () => {
//   return (
//     <div className="payment-cancel-container">
//       <FaTimesCircle className="cancel-icon" />
//       <h1 className="cancel-message">Payment Canceled</h1>
//       <p className="text-muted text-center mt-3">
//         Unfortunately, your payment was not processed. Please try again or
//         contact support if the issue persists.
//       </p>
//       <div className="mt-4 d-flex">
//         <Link to="/subscription" className="mr-3">
//           <Button variant="danger" className="retry-button">
//             Retry
//           </Button>
//         </Link>

//       </div>
//     </div>
//   );
// };

// export default PaymentCancel;
import React, { useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./PaymentCancel.css";

const PaymentCancel = () => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    navigate("/subscription");
  };

  return (
    <>
      <Modal
        show={show}
        // onHide={handleClose}
        centered
        className="payment-cancel-modal"
      >
        {" "}
        <Modal.Header>
          {" "}
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="payment-cancel-container">
          <FaTimesCircle className="cancel-icon" />
          <h1 className="cancel-message">Payment Canceled</h1>
          <p className="text-muted text-center mt-3">
            Unfortunately, your payment was not processed. Please try again or
            contact support if the issue persists.
          </p>
          <div className="mt-4 d-flex justify-content-center">
            <Link to="/subscription" className="mr-3">
              <Button variant="danger" className="retry-button">
                Retry
              </Button>
            </Link>
            {/* <Link to="/tutor">
              <Button variant="outline-danger" className="dashboard-button">
                Go to Dashboard
              </Button>
            </Link> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentCancel;
