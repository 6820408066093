/** @format */

const APPConfig = {
  // API_URL: "http://173.212.250.62/tutors-dev/tutor-admin/public/api",
  API_URL: "https://www.findmytutoruae.com/tutor-admin/public/api",
};

const ApiEndPoints = {
  Login: `${APPConfig.API_URL}/login`,
  TutorRegister: `${APPConfig.API_URL}/tutor-register`,
  ParentRegister: `${APPConfig.API_URL}/parent-register`,
  TutorSendOTPEmail: `${APPConfig.API_URL}/send-otp-email`,
  TutorVerifyOTPEmail: `${APPConfig.API_URL}/verify-email-otp`,
  Change_Password: `${APPConfig.API_URL}/change-password`,
  ForgetPassword: `${APPConfig.API_URL}/forgot-password`,
  ResetPassword: `${APPConfig.API_URL}/reset-password`,
  SocialLogin: `${APPConfig.API_URL}/social-login`,
  SubjectList: `${APPConfig.API_URL}/subjects-list`,
  GradeApi: `${APPConfig.API_URL}/tutor-update-grade`,
  GradeList: `${APPConfig.API_URL}/grades-list`,
  UpdateProfileImage: `${APPConfig.API_URL}/update-profileimage`,
  SubjectUpdate: `${APPConfig.API_URL}/tutor-update-subject`,
  TutorIndroduce: `${APPConfig.API_URL}/tutor-update-intro-yourself`,
  parentUpdateGrade: `${APPConfig.API_URL}/parent-update-grade`,
  AddTutorSlot: `${APPConfig.API_URL}/tutor-add-time-slot`,
  UpdateTutorSlot: `${APPConfig.API_URL}/tutor-update-time-slot/`,
  DeleteTutorSlot: `${APPConfig.API_URL}/tutor-delete-time-slot/`,
  GetTutorSlot: `${APPConfig.API_URL}/tutor-time-slots`,
  GetSingleTutorSlot: `${APPConfig.API_URL}/tutor-time-slot`,
  Tutor_Profile_Step: `${APPConfig.API_URL}/tutor-profile`,
  Parent_Profile_Step: `${APPConfig.API_URL}/parent-profile`,
  verify_email_mobile: `${APPConfig.API_URL}/verify-email-mobile`,
  Get_Tutor_Profile: `${APPConfig.API_URL}/tutor-profile`,
  Get_Parent_Profile: `${APPConfig.API_URL}/parent-profile`,
  Update_Tutor_Profile: `${APPConfig.API_URL}/update-tutor-profile`,
  Update_Parent_Profile: `${APPConfig.API_URL}/update-parent-profile`,
  Message_User_List: `${APPConfig.API_URL}/message-user-list`,
  Send_Message: `${APPConfig.API_URL}/send-message`,
  Get_Message_History: `${APPConfig.API_URL}/message-history/`,

  Subscriber: `${APPConfig.API_URL}/subscriber?`,
  FindTutor: `${APPConfig.API_URL}/tutors-list`,
  PackagesList: `${APPConfig.API_URL}/packages-list`,
  BlogsList: `${APPConfig.API_URL}/blogs-list`,
  SingleBlog: `${APPConfig.API_URL}/blog-slug/`,
  CityList: `${APPConfig.API_URL}/cities-list`,
  PrivacyPolicy: `${APPConfig.API_URL}/page/privacy_policy`,
  TermsCondition: `${APPConfig.API_URL}/page/terms_condition`,
  SubjectTypeList: `${APPConfig.API_URL}/subject-types-list`,
  GradeTypeList: `${APPConfig.API_URL}/grade-types-list`,
  PMessageRequestList: `${APPConfig.API_URL}/message-enquiry-list`,
  DashboardCount: `${APPConfig.API_URL}/dashboard`,
  MessageRequest: `${APPConfig.API_URL}/message-accept-reject/`,
  AdvertiseList: `${APPConfig.API_URL}/advertise-list`,
  // AdvertiseCreate: `${APPConfig.API_URL}/advertise-list`,
  AdvertiseCreate: `${APPConfig.API_URL}/create-advertise`,
  AdvertiseDelete: `${APPConfig.API_URL}/delete-advertise/`,
  TutorAdvertiseList: `${APPConfig.API_URL}/advertising-list`,
  BuyPackage: `${APPConfig.API_URL}/buy-subscription`,
  SubscriptionList: `${APPConfig.API_URL}/subscriptions-list`,
  msgReadByUser: `${APPConfig.API_URL}/message-read-by-user`,
  Pkg_Status: `${APPConfig.API_URL}/active-package`,
  Payment_Response: `${APPConfig.API_URL}/telr-payment-response`,
  PkgViewdUser: `${APPConfig.API_URL}/package-viewed-users`,
  BoostedTutor: `${APPConfig.API_URL}/boost-tutors-list`,
  CancelPolicy: `${APPConfig.API_URL}/page/cancellation_policy`,
  DeliveryPolicy: `${APPConfig.API_URL}/page/product_delivery_policy`,
  RefundPolicy: `${APPConfig.API_URL}/page/refund_policy`,
  ContactUs: `${APPConfig.API_URL}/contactus`,
};

export default ApiEndPoints;
