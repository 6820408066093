/** @format */

import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";

// const CardItem = ({ title, image, date, description, slug }) => (
//   <Card sx={{ maxWidth: 270, maxHeight: 380 }}>
//     {console.log("first", date)}
//     <CardMedia sx={{ height: 200 }} image={image} title={title} />
//     <CardContent>
//       <Typography
//         className="cardtitle truncate-text"
//         gutterBottom
//         component="div"
//         sx={{ fontWeight: "bold", fontFamily: "Lora", fontSize: "18px" }}
//       >
//         {title}
//       </Typography>
//       <Typography
//         className="truncate-text"
//         variant="body2"
//         color="text.secondary"
//         sx={{ fontFamily: "Lora", fontSize: "13px" }}
//         dangerouslySetInnerHTML={{ __html: description }}
//       ></Typography>
//     </CardContent>
//     <CardActions className="cardaction">
//       <Typography component="div" sx={{ fontFamily: "Lora", fontSize: "16px" }}>
//         {moment(date).format("DD-MMMM-YY")}
//       </Typography>
//       <Link
//         to="/singleBlog"
//         className="text-decoration-none"
//         state={{ slug: slug }}
//       >
//         <Typography
//           className="readmore"
//           component="div"
//           sx={{ fontWeight: "bold" }}
//         >
//           Read more
//         </Typography>
//       </Link>
//     </CardActions>
//   </Card>
// );
const CardItem = ({ title, image, date, description, slug }) => (
  <Grid container spacing={1}>
    <Card
      sx={{
        width: 270,
        height: 380,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardMedia
        sx={{ height: 200, objectFit: "cover" }}
        image={image}
        title={title}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography
          className="cardtitle truncate-text"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold", fontFamily: "Lora", fontSize: "18px" }}
        >
          {title}
        </Typography>
        <Typography
          className="truncate-text"
          variant="body2"
          color="text.secondary"
          sx={{ fontFamily: "Lora", fontSize: "13px" }}
          dangerouslySetInnerHTML={{ __html: description }}
        ></Typography>
      </CardContent>
      <CardActions
        className="cardaction"
        sx={{ justifyContent: "space-between" }}
      >
        <Typography
          component="div"
          sx={{ fontFamily: "Lora", fontSize: "16px" }}
        >
          {moment(date).format("DD-MMMM-YY")}
        </Typography>
        <Link
          to="/singleBlog"
          className="text-decoration-none"
          state={{ slug: slug }}
        >
          <Typography
            className="readmore"
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Read more
          </Typography>
        </Link>
      </CardActions>
    </Card>
  </Grid>
);

export default CardItem;
