/** @format */

import { Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactShowMoreText from "react-show-more-text";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../../Network_Call/apiservices";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const SingleBlog = () => {
  const location = useLocation();
  const singleslug = location.state;
  const [blogData, setBlogData] = useState([]);
  console.log("Blogdata", blogData);
  useEffect(() => {
    BlogDataApi();
  }, []);

  const BlogDataApi = () => {
    apiCallNew("get", null, ApiEndPoints.SingleBlog + singleslug.slug).then(
      (res) => {
        if (res?.success == true) {
          setBlogData(res?.result);
        } else {
          toast(res?.msg);
        }
      }
    );
  };
  const sanitizeHTML = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    // Remove <br /> tags
    const brTags = div.getElementsByTagName("br");
    while (brTags[0]) {
      brTags[0].parentNode.removeChild(brTags[0]);
    }
    // Replace &nbsp; with a single space
    div.innerHTML = div.innerHTML.replace(/&nbsp;/g, " ");
    return div.innerHTML;
  };
  const sanitizedDescription = sanitizeHTML(blogData?.description);
  // var plainString =
  var plainString = blogData?.description?.replace(/<[^>]+>/g, "");
  return (
    <div className="container mt-5 mb-5 w-100 row justify-content-center">
      <Card className="" sx={{ maxWidth: "70%" }}>
        <Link to={"/ourBlogs"}>
          <KeyboardBackspaceIcon
            sx={{
              fontSize: "40px",
              cursor: "pointer",
              color: "#6d39e9",
            }}
          />
        </Link>
        <Typography
          level="title-lg"
          className="cardtitle text-center"
          gutterBottom
          component="div"
          sx={{
            fontWeight: "bold",
            fontFamily: "Lora",
            fontSize: "22px",
            color: "#6D39E9",
          }}
        >
          {blogData?.title}
        </Typography>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={blogData?.image} alt="sdfsdfsfsfsfsfsf" width={"80%"} />
        </div>
        <CardContent>
          <ReactShowMoreText
            lines={3}
            more="Show more"
            less="Show less"
            className="content-css"
            anchorClass="show-more-less-clickable"
            // onClick={executeOnClick}
            expanded={false}
            truncatedEndingComponent={"... "}
          >
            {/* <Typography
              variant="body2"
              sx={{ fontFamily: "Lora", fontSize: "18px" }}
            > */}
            {/* {plainString} */}
            <div
              style={{
                fontFamily: "Lora",
                fontSize: "18px",
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
              dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
            />
            {/* </Typography> */}
          </ReactShowMoreText>
        </CardContent>
      </Card>
    </div>
  );
};

export default SingleBlog;
