/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Images from "../../../../Helper/Images";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./LogIn.css";
import { FcGoogle } from "react-icons/fc";
import { apiCallNew } from "../../../../Network_Call/apiservices";
import { toast } from "react-toastify";
import ApiEndPoints from "../../../../Network_Call/ApiEndPoints";
import { useForm } from "react-hook-form";
import {
  getToken,
  getUserdata,
  setToken,
  setUserData,
} from "../../../../Helper/Storage";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Label } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("rememberMe") === "true"
  );
  const [email, setEmail] = useState(localStorage.getItem("log-email") || "");
  const [password, setPassword] = useState(
    localStorage.getItem("log-password") || ""
  );
  const [googleData, setGoogleData] = useState("");
  const [socialID, setSocialID] = useState(Number);
  const token = getToken();
  const [profileStep, setProfileStep] = useState("");
  const [parentStep, setParentStep] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [user, setUser] = useState();
  const [load, setload] = useState(false);
  const role = localStorage.getItem("role");
  const itemRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit = (event) => {
    // event.preventDefault();
    if (validateForm(event)) {
      TutorLogin();
    }
  };
  console.log("role", role);

  const validateForm = (data) => {
    if (data.email === "") {
      setError("email", { type: "required", message: "Email is required" });
      return false;
    }
    return true;
  };

  const TutorLogin = async () => {
    setload(true);
    const payload = {
      email: email,
      password: password,
    };
    apiCallNew("post", payload, ApiEndPoints.Login)
      .then((response: any) => {
        if (response.success === true) {
          console.log("login response", response);
          setToken(response?.result?.api_token);
          setUserData(JSON?.stringify(response?.result));
          localStorage.setItem("@userId", response?.result?.id);
          localStorage.setItem("role", response?.result?.user_type);
          toast.success("You are successfully logged in.");
          console.log(response.msg);
          setload(false);

          if (response.result.user_type === "Tutor") {
            setload(false);
            handleTutorNavigation(response.result.tutor_profile_stage);
          } else {
            setload(false);
            handleParentNavigation(response.result.parent_profile_stage);
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setload(false);
        } else {
          setload(false);
          toast.error(response.msg);
        }
      })
      .finally((er) => {
        // toast(er);
        setload(false);
      });
  };
  // const handleTutorNavigation = (stage) => {
  //   if (stage === 1) {
  //     navigate("/step3");
  //   } else if (stage === 2) {
  //     navigate("/step4");
  //   } else if (stage === 3) {
  //     navigate("/step5");
  //   } else if (stage === 4) {
  //     navigate("/step7");
  //   } else if (stage === 5) {
  //     navigate("/tutor");
  //   } else {
  //     navigate("/tutor");
  //     window.location.reload();
  //   }
  // };
  const handleTutorNavigation = (stage) => {
    // Set the delay time in milliseconds (e.g., 1000ms = 1 second)
    const delay = 1000; // 1 second delay

    setTimeout(() => {
      if (stage === 1) {
        navigate("/step3");
      } else if (stage === 2) {
        navigate("/step4");
      } else if (stage === 3) {
        navigate("/step5");
      } else if (stage === 4) {
        navigate("/step7");
      } else if (stage === 5) {
        navigate("/tutor");
      } else {
        navigate("/tutor");
        window.location.reload();
      }
    }, delay);
  };

  const handleParentNavigation = (stage) => {
    const delay = 1000;
    setTimeout(() => {
      if (stage === 1) {
        navigate("/step4");
      } else if (stage === 2) {
        navigate("/parent");
      } else {
        navigate("/parent");
        window.location.reload();
      }
    }, delay);
  };

  const GoogleDataGet = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        if (res.status == 200) {
          setGoogleData(res.data);
          setSocialID(res.data?.sub);
        } else {
          toast.error("Google login failed. Please try again.");
        }
      } catch (err) {
        console.error("Failed to fetch user info", err);
        toast.error(err.message || "Failed to fetch user info");
      }
    },
    onFailure: (error) => {
      console.error("Google login failed", error);
      toast.error("Google login failed. Please try again.");
    },
  });

  useEffect(() => {
    if (googleData && socialID) {
      GooglePostApi();
    }
  }, [googleData, socialID]);

  const GooglePostApi = async () => {
    if (!googleData || !socialID) {
      console.error("Missing googleData or socialID");
      return;
    }
    const formData = new FormData();
    formData.append("email", googleData.email);
    formData.append("name", googleData.name);
    formData.append("social_id", socialID);
    formData.append("user_type", "Tutor");

    try {
      const response = await apiCallNew(
        "post",
        formData,
        ApiEndPoints.SocialLogin
      );
      if (response.success === true) {
        setToken(response?.result?.api_token);
        // console.log("hhhhh", response?.result?.user_type);
        setToken(response?.result?.api_token);
        setUserData(JSON?.stringify(response?.result));
        localStorage.setItem("role", response?.result?.user_type);
        toast.success(response.msg);
        if (response?.result?.user_type === "Tutor") {
          navigate("/tutor");
        }
        if (response?.result?.user_type === "Parent") {
          navigate("/parent");
        }
        window.location.reload();
      } else {
        console.log("login failed", response);
        toast.error(response.msg);
      }
    } catch (error) {
      console.error("An error occurred", error);
      toast.error(error.message || "An error occurred");
    }
  };

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem("log-email", email);
      localStorage.setItem("log-password", password);
    }
  }, [email, password, rememberMe]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCheckBox = () => {
    const newRememberMe = !rememberMe;
    setRememberMe(newRememberMe);
    if (newRememberMe) {
      localStorage.setItem("log-email", email);
      localStorage.setItem("log-password", password);
      localStorage.setItem("rememberMe", "true");
    } else {
      localStorage.removeItem("log-email");
      localStorage.removeItem("log-password");
      localStorage.setItem("rememberMe", "false");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        // minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
      className="log-in"
    >
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <div
        style={{
          borderRadius: "8px",
          maxWidth: "800px",
          width: "100%",
        }}
      >
        <Container style={{ width: "100%", padding: 0 }}>
          <Row className="justify-content-center">
            <Col
              style={{
                backgroundColor: "#ebe6f5",
                textAlign: "left",
                padding: "20px",
                borderRadius: "8px",
              }}
              xs={12}
              md={6}
            >
              <div className="mt-2">
                <h3>Sign In</h3>
                <p>Hello! Welcome back. </p>
              </div>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <label style={{ marginLeft: 2 }}>
                  Email<span style={{ color: "red" }}> *</span>
                </label>

                <Form.Group controlId="email" className="mb-3">
                  <Form.Control
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    type="text"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => {
                      handleEmailChange(e);
                      register("email").onChange(e); // Ensures react-hook-form's onChange is also called
                    }}
                    // className={errors.email ? "is-invalid" : null}
                  />
                  {errors.email && (
                    <p style={{ color: "red" }} className=" mt-0.8">
                      {errors.email.message}
                    </p>
                  )}
                </Form.Group>
                <label style={{ marginLeft: 2 }}>
                  Password<span style={{ color: "red" }}> *</span>
                </label>
                <Form.Group controlId="password" className="mb-3">
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password must be at least 8 characters",
                        },
                        pattern: {
                          value: /^(?=.*[0-9])(?=.*[!@#$%^&*])/,
                          message:
                            "Password must include at least one number and one special character",
                        },
                      })}
                      value={password}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      onChange={(e) => {
                        handlePasswordChange(e);
                        register("password").onChange(e); // This ensures the react-hook-form's onChange is also called
                      }}
                      // className={errors.email ? "is-invalid" : ""}
                    />
                    {!showPassword ? (
                      <AiOutlineEyeInvisible
                        className="password-icon"
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          top: errors.password ? "31%" : "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <AiOutlineEye
                        className="password-icon"
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          top: errors.password ? "31%" : "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    {errors.password && (
                      <p className="" style={{ color: "red" }}>
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "99%",
                    // backgroundColor: "red",
                  }}
                >
                  <label style={{ marginLeft: 5 }}>
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={handleCheckBox}
                      style={{ marginRight: "5px", backgroundColor: "#6d39e9" }}
                    />
                    Remember me
                  </label>
                  <Link to={"/forgetPassword"}>
                    <h6 className="forgot">Forgot Password?</h6>
                  </Link>
                </div>

                <Button
                  type="submit"
                  // variant="primary"
                  className="mt-3 w-100 m-0 custom-button"
                >
                  Sign In
                </Button>
                <div className="mt-2 text-center ">Or</div>
                {/* <Link to={Screen.Step4}> */}
                <Button
                  onClick={() => GoogleDataGet()}
                  className="mt-2 w-100 m-0 custom-button"
                >
                  <FcGoogle
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                  />
                  <span> Sign In with Google</span>
                </Button>
                {/* </Link> */}
              </Form>
            </Col>
            <Col
              style={{
                background:
                  "linear-gradient(242.03deg, #6D39E9 -3.34%, #CCBAF7 72.34%)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",

                // Added for relative positioning
              }}
              // className="mob"
              xs={12}
              md={6}
            >
              <img
                src={Images.Step2img}
                alt="Profile Step 1"
                className="img-fluid"
                style={{ borderRadius: "8px" }} // Ensures image also has rounded corners
              />
              <div
                style={{ textAlign: "center", width: "86%" }}
                className="alreadyBtn"
              >
                <span style={{ marginRight: "10px" }}>
                  I haven’t got an account
                </span>
                <Link to={"/step1"}>
                  <Button variant="primary" className="custom-button">
                    Sign Up
                  </Button>
                </Link>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px", // Position at the top right of the Col
                  right: "10px",
                  // backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
                  padding: "5px 10px",
                  borderRadius: "8px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                {/* <span style={{ color: "white" }}>Step 5/5</span> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default Login;
