import axios from "axios";

const TELR_API_URL = "https://secure.telr.com/gateway/api_quicklink.json";
const STORE_ID = "YOUR_STORE_ID";
const AUTH_KEY = "YOUR_AUTH_KEY";

export const initiatePayment = async (orderDetails) => {
  const payload = {
    method: "create",
    store: 1234,
    auth: "mykey1234",
    // Example order details
    ...orderDetails,
  };

  try {
    const response = await axios.post(TELR_API_URL, payload);
    console.log("Payment initiation response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error initiating payment:", error);
    throw error;
  }
};
