/** @format */

import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import "./WhyBcomeTutor.css";
import { Button, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Images from "../../../Helper/Images";
const benefits = [
  {
    title: "High Demand and Popularity: ",
    description:
      "The UAE boasts a robust education sector with a growing demand for quality tutoring services. Students in the UAE, from primary to tertiary levels, often seek additional academic support to excel in their studies. As a result, tutoring is highly valued and respected in the local community. The demand for tutors in Dubai, Abu Dhabi and Sharjah are increasing vastly.",
  },
  {
    title: "Lucrative Opportunities:",
    description:
      "Tutors in the UAE enjoy competitive tutoring rates due to the high demand for qualified educators. Whether you specialise in STEM subjects, languages, SAT/ACT preparation, or specialised exams, there is a lucrative market waiting for your expertise.",
  },
  {
    title: "Cultural Diversity and Global Exposure: ",
    description:
      "Tutoring in the UAE provides an enriching cultural experience. You'll work with students from diverse backgrounds, including local Emiratis and expatriates from around the world. This exposure not only broadens your teaching skills but also enhances your cultural awareness and global perspective.",
  },
  {
    title: "Supportive Educational Environment:",
    description:
      "The UAE prioritises education and invests heavily in its educational infrastructure. With state-of-the-art schools, universities, and learning centres, tutors have access to modern facilities and resources that enhance teaching effectiveness.",
  },
  {
    title: "Professional Growth and Networking:",
    description:
      "Joining our platform connects you with a network of educators and professionals in the UAE. Collaborate, share insights, and learn from industry leaders to elevate your teaching career.",
  },
];
const WhyBcomeTutor = () => {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    localStorage.setItem("role", "Tutor");
    window.localStorage.removeItem("@userToken");
    navigate("/step2", { replace: true });
  };
  const Benefits = () => {
    return (
      <Container maxWidth="md" className="mt-1">
        <div className="image-containerd">
          <Image
            style={{ width: "100%", height: "auto" }}
            src={Images.WhyImg}
            alt="Benefits"
            layout="responsive"
            objectFit="cover"
            className="imgd"
          />
        </div>
        <h2 className="mt-2" variant="h4" gutterBottom>
          Unlock Your Potential as a Tutor in a Thriving Educational Hub
        </h2>

        <h6 gutterBottom>
          Are you passionate about your field and education and are eager to
          make a lasting impact? Joining our platform as a tutor in the UAE
          offers a unique opportunity to thrive in one of the world's most
          dynamic educational landscapes.
        </h6>
        <h2 variant="h4" gutterBottom>
          Why Choose Tutoring in the UAE?
        </h2>
        <List>
          {benefits.map((benefit, index) => (
            <ListItem key={index} alignItems="flex-start">
              <CheckCircleOutline
                color="primary"
                style={{ marginRight: "10px" }}
              />
              <Box>
                <h6 className="fw-bold">{benefit.title}</h6>
                <p variant="body1">{benefit.description}</p>
              </Box>
            </ListItem>
          ))}
        </List>

        <h4 gutterBottom>Why Choose FindMyTutor?</h4>
        <p variant="body1">
          FindmyTutor is dedicated to connecting talented tutors and
          professionals with motivated students across the UAE. With a
          user-friendly interface, comprehensive student matching, secure
          payment systems, and ongoing support, we ensure a seamless
          tutor-student connection experience that maximises your potential and
          impact.
        </p>
        <h4 gutterBottom>
          Join Us Today and Shape the Future of Education in the UAE!
        </h4>
        <p variant="body1">
          Take the next step in your tutoring career by joining our platform.
          Whether you're an experienced educator or a passionate graduate
          looking to share your knowledge, tutoring in the UAE promises a
          rewarding journey of professional growth, cultural enrichment, and
          financial success.
        </p>
        <h4 gutterBottom>Start Your Tutoring Journey with Us Today!</h4>
        <p variant="body1">
          Join our community of tutors making a difference in the UAE's
          educational landscape. Together, let's inspire and empower the next
          generation of learners to achieve their academic dreams.
        </p>
        <Button
          type="button"
          className="btn m-0 custom-button"
          onClick={handleSignUpClick}
        >
          Join as a Tutor
        </Button>
      </Container>
    );
  };
  return (
    <div>
      {" "}
      <div className="header-container">
        <h2 className="header-title4 fw-bold">
          Why become a tutor in the UAE?
        </h2>
      </div>
      <Benefits />
      <br />
      <br />
      <br />
    </div>
  );
};

export default WhyBcomeTutor;
