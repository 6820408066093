/** @format */

import React, { useEffect, useState } from "react";
import "./ourblogs.css";
import { CircularProgress, Pagination } from "@mui/material";
import CardItem from "./CardItem";
import { apiCallNew } from "../../../Network_Call/apiservices";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";

const OurBlogs = () => {
  const itemsPerPage = 8;
  const [page, setPage] = useState(1);
  const [blogsData, setBlogsData] = useState([]);
  const [load, setload] = useState(false);
  console.log("blogsData", blogsData);

  useEffect(() => {
    getBlogs();
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const currentItems = blogsData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const getBlogs = async () => {
    try {
      setload(true);
      apiCallNew("post", null, ApiEndPoints.BlogsList)
        .then((response) => {
          console.log("first", response);
          if (response.success === true) {
            setBlogsData(response?.result);
            setload(false);
          }
        })
        .catch((error) => {
          console.log(">>>>", error);
        });
    } catch (error) {
      setload(false);
    }
  };
  return (
    <div className="body mb-3">
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <div className="header-container">
        <h2 className="header-title2">Our Blogs</h2>
      </div>
      <div class="container mt-5">
        <div>
          <div className="row mx-0 mt-0">
            {currentItems.map(
              (card, index) => (
                console.log("card", card),
                (
                  <div className="col-md-3 mb-4" key={index}>
                    <CardItem
                      title={card.title}
                      image={card.image_thumbnail}
                      date={card.created_at}
                      description={card.description}
                      slug={card.slug}
                    />
                  </div>
                )
              )
            )}
          </div>
          <Pagination
            count={Math.ceil(blogsData.length / itemsPerPage)}
            page={page}
            onChange={handleChange}
            shape="rounded"
            className="d-flex justify-content-end mt-4"
          />
        </div>
      </div>
    </div>
  );
};

export default OurBlogs;
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};
