/** @format */

import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Col, Container, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import "./SubscriptionDetails.css";
import Images from "../../../Helper/Images";
import { apiCallNew } from "../../../Network_Call/apiservices";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { getUserdata } from "../../../Helper/Storage";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const ShowMoreText = ({ text, maxLines }) => {
  const [showMore, setShowMore] = React.useState(false);
  const lines = text.split("\n");

  return (
    <div>
      {lines.slice(0, showMore ? lines.length : maxLines).map((line, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "start", marginBottom: "5px" }}
        >
          <div className="d-flex">
            <img
              src={Images.subTick}
              alt="tick icon"
              style={{
                height: "18px",
                width: "18px",
                marginRight: "10px",
                marginTop: "5px",
                justifyContent: "start",
              }}
            />
          </div>
          <p>{line}</p>
        </div>
      ))}
      {lines.length > maxLines && (
        <button
          onClick={() => setShowMore(!showMore)}
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "#007bff",
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          {showMore ? "Show Less" : "Show More"}
        </button>
      )}
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{}}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function SubscriptionDetails() {
  const [value, setValue] = React.useState(0);
  const [subscriptionType, setSubscriptionType] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subscriptionData, setSubscriptionData] = React.useState([]);
  const [subSctinList, setSubSctinList] = React.useState([]);
  const [pkgStatus, setPkgStatus] = React.useState();

  const [load, setload] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("pkgStatus", pkgStatus);

  React.useEffect(() => {
    SubscriptiomList();
    SubscriptionList();
    Get_Pkg_Status();
  }, []);

  const SubscriptiomList = () => {
    setload(true);
    apiCallNew("get", null, ApiEndPoints.PackagesList).then((res) => {
      if (res.success === true) {
        console.log("res of packages", res);
        setload(false);
        setSubscriptionData(res?.result);
        setSubscriptionType(res.result);
      } else {
        setload(false);
      }
    });
  };
  const SubscriptionList = () => {
    const payload = {
      page: 0,
    };
    apiCallNew("post", payload, ApiEndPoints.SubscriptionList).then(
      (response) => {
        console.log("rrrr", response);
        if (response.success == true) {
          console.log("rrrrdd", response);
          setSubSctinList(response.result);
        }
      }
    );
  };
  const Get_Pkg_Status = async () => {
    const response = await apiCallNew("get", null, ApiEndPoints.Pkg_Status);
    setPkgStatus(response?.result);
  };
  const isPackagePurchased = (packageId) => {
    return subSctinList?.some(
      (plan) => plan?.package_id === packageId && plan?.status === 1
    );
  };
  const getButtonLabel = (packageId) => {
    if (isPackagePurchased(packageId)) {
      return "Subscribed";
    } else if (isPackageExpired(packageId)) {
      return "Renew";
    } else {
      return "Get Started";
    }
  };
  const isPackageExpired = (packageId) => {
    return subSctinList?.some(
      (plan) =>
        plan?.package_id === packageId &&
        plan?.status === 0 &&
        plan?.payment_status === "success" &&
        new Date(plan?.package_end_date) < new Date()
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handlePayment = async (item) => {
    // Check if the user has already purchased this package
    if (isPackagePurchased(item?.id)) {
      Swal.fire({
        title: "Package Already Purchased",
        text: "You have already subscribed to this package. Do you want to extend the duration of this subscription?",
        showCancelButton: true,
        confirmButtonColor: "#6d39e9",
        cancelButtonColor: "#aaaaaa",
        confirmButtonText: "Extend Subscription",
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Proceed with extending the subscription
          await processPayment(item);
        } else {
          console.log("Extension Cancelled");
        }
      });
      return; // Stop further execution if the SweetAlert is shown
    }

    // Check if the user has another active subscription
    const existingSubscription = subSctinList?.find(
      (plan) => plan?.status === 1 && plan?.package_id !== item?.id
    );

    if (existingSubscription) {
      Swal.fire({
        title: "Switch Subscription",
        text: "You already have an active subscription. Do you want to switch to this new subscription?",
        showCancelButton: true,
        confirmButtonColor: "#6d39e9",
        cancelButtonColor: "#aaaaaa",
        confirmButtonText: "Yes, Switch",
        cancelButtonText: "No, Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Proceed with the payment for the new subscription
          await processPayment(item);
        } else {
          console.log("Switch Cancelled");
        }
      });
    } else {
      // No existing subscription, proceed with the payment
      await processPayment(item);
    }
  };
  // const handlePayment = async (item) => {
  //   if (isPackagePurchased(item?.id)) {
  //     Swal.fire({
  //       title: "Package Already Purchased",
  //       text: "You have already Subscribed this package. Do you want to extend duration of this subscription?",
  //       // icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Continue",
  //       cancelButtonText: "Cancel",
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         // Proceed with the payment
  //         await processPayment(item);
  //       } else {
  //         // Handle the cancellation if needed
  //         console.log("Cancelled");
  //       }
  //     });
  //     return; // Stop execution if SweetAlert is shown
  //   }

  //   await processPayment(item);
  // };
  const processPayment = async (item) => {
    console.log("item", item);
    setload(true);
    const payload = {
      package_id: item?.id,
      boost_profile: 0,
    };
    try {
      const res = await apiCallNew("post", payload, ApiEndPoints.BuyPackage);
      console.log("item", res);

      if (res.success === true) {
        toast.success("Please proceed to make payment.");
        setload(false);
        if (res?.result?.url) {
          window.location.href = res?.result?.url;
        }
      } else {
        setload(false);
        toast.error(res?.msg);
      }
    } catch (error) {
      setload(false);
      toast.error("An error occurred while processing your payment.");
      console.error("Payment error:", error);
    }
  };

  // const handlePayment = async (item) => {
  //   if (isPackagePurchased(item?.id)) {

  //   }
  //   console.log("item", item);
  //   setload(true);
  //   const payload = {
  //     package_id: item?.id,
  //     boost_profile: 0,
  //     // message: item?.description,
  //   };
  //   try {
  //     const res = await apiCallNew("post", payload, ApiEndPoints.BuyPackage);
  //     console.log("item", res);

  //     if (res.success === true) {
  //       toast.success(res?.msg);
  //       setload(false);
  //       if (res?.result?.url) {
  //         window.location.href = res?.result?.url;
  //       }
  //     } else {
  //       setload(false);
  //       toast.error(res?.msg);
  //     }
  //   } catch (error) {
  //     setload(false);
  //     toast.error("An error occurred while processing your payment.");
  //     console.error("Payment error:", error);
  //   }
  // };

  return (
    // <Container fluid>
    //   <br />
    //   <div style={{ textAlign: "-webkit-center" }}>
    //     {load && (
    //       <div style={styles.backdrop}>
    //         <CircularProgress style={styles.loader} />
    //       </div>
    //     )}
    //     <p
    //       style={{
    //         fontSize: "30px",
    //         fontWeight: "bold",
    //         color: "#6D39E9",
    //         fontFamily: "Inter",
    //       }}
    //     >
    //       Choose Your Plan
    //     </p>
    //   </div>

    //   <div
    //     className="row justify-content-center"
    //     style={{
    //       textAlign: "-webkit-center",
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         bgcolor: "background.paper",
    //       }}
    //     >
    //       <AppBar
    //         position="static"
    //         style={{
    //           backgroundColor: "#C0A8F9",
    //           padding: 5,
    //           width: isMobile ? "100%" : "60%",
    //           justifyContent: "space-evenly",
    //         }}
    //       >
    //         <Tabs
    //           value={value}
    //           onChange={handleChange}
    //           indicatorColor="white"
    //           variant={isMobile ? "scrollable" : "fullWidth"}
    //           aria-label="full width tabs example"
    //           scrollButtons={isMobile ? "auto" : "off"}
    //         >
    //           <Tab
    //             label={packagesType ? packagesType["1"] : "Loading..."}
    //             style={{
    //               backgroundColor: value === 0 ? "#C0A8F9" : "white",
    //               color: "#034F75",
    //               width: isMobile ? "auto" : "20%",
    //               borderRadius: 5,
    //               fontWeight: "600",
    //               minWidth: 0, // Ensures proper width on mobile
    //               margin: isMobile ? "0 5px" : "0",
    //             }}
    //             {...a11yProps(0)}
    //           />
    //           <Tab
    //             label={packagesType ? packagesType["2"] : "Loading..."}
    //             style={{
    //               backgroundColor: value === 1 ? "#C0A8F9" : "#ffffff",
    //               color: "#034F75",
    //               width: isMobile ? "auto" : "20%",
    //               borderRadius: 5,
    //               fontWeight: "600",
    //               minWidth: 0, // Ensures proper width on mobile
    //               margin: isMobile ? "0 5px" : "0 6px 0 6px",
    //             }}
    //             {...a11yProps(1)}
    //           />
    //           <Tab
    //             label={packagesType ? packagesType["3"] : "Loading..."}
    //             style={{
    //               backgroundColor: value === 2 ? "#C0A8F9" : "#ffffff",
    //               color: "#034F75",
    //               width: isMobile ? "auto" : "20%",
    //               borderRadius: 5,
    //               fontWeight: "600",
    //               minWidth: 0, // Ensures proper width on mobile
    //               margin: isMobile ? "0 5px" : "0 6px 0 0",
    //             }}
    //             {...a11yProps(2)}
    //           />
    //         </Tabs>
    //       </AppBar>
    //       <SwipeableViews
    //         axis={theme.direction === "rtl" ? "x-reverse" : "x"}
    //         index={value}
    //         onChangeIndex={handleChangeIndex}
    //       >
    //         <TabPanel value={value} index={0} dir={theme.direction}>
    //           <section>
    //             <div>
    //               <div className="row">
    //                 {subscriptionData[0]?.map((ed, index) => {
    //                   return (
    //                     <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
    //                       <div
    //                         style={{
    //                           backgroundColor:
    //                             index == 0
    //                               ? "#637ced"
    //                               : index == 1
    //                               ? "#EC539C"
    //                               : "#5BC587",
    //                           borderWidth: 2,
    //                           height: 5,
    //                           borderTopLeftRadius: 100,
    //                           borderTopRightRadius: 100,
    //                         }}
    //                       />
    //                       <div className="card card2-shadow">
    //                         <div className="card-body">
    //                           <h5 className="card-title">{ed.title}</h5>
    //                           <img
    //                             src={Images.subscript}
    //                             style={{
    //                               height: "40%",
    //                               width: "80%",
    //                             }}
    //                           />

    //                           <p
    //                             className="card-text"
    //                             style={{
    //                               textAlign: "center",
    //                               width: "70%",
    //                               color: "#000",
    //                               fontSize: "16px",
    //                               fontWeight: "400",
    //                               fontFamily: "inter",
    //                             }}
    //                           >
    //                             Essential Tools for New Ventures
    //                           </p>
    //                           <div style={{ alignSelf: "center" }}>
    //                             <p
    //                               style={{
    //                                 color: "#637CED",
    //                                 fontSize: "40px",
    //                                 alignSelf: "center",
    //                                 fontWeight: "600",
    //                                 fontFamily: "inter",
    //                               }}
    //                             >
    //                               $ {ed.price}
    //                               <span
    //                                 style={{
    //                                   color: "#000",
    //                                   fontSize: "20px",
    //                                 }}
    //                               >
    //                                 / {ed.days} Days
    //                               </span>
    //                             </p>
    //                           </div>

    //                           <div
    //                             style={{
    //                               display: "flex",
    //                               alignSelf: "center",
    //                               flexDirection: "row",
    //                               width: "95%",
    //                             }}
    //                           >
    //                             <img
    //                               src={Images.subTick}
    //                               style={{
    //                                 height: "18px",
    //                                 width: "18px",
    //                               }}
    //                             />
    //                             <p
    //                               style={{
    //                                 margin: 0,
    //                                 marginLeft: "10px",
    //                                 alignItems: "center",
    //                                 color: "#000",
    //                               }}
    //                             >
    //                               {ed.description}
    //                             </p>
    //                           </div>

    //                           <Button
    //                             style={{
    //                               color: "#fff",
    //                               width: "100%",
    //                               marginTop: 10,
    //                             }}
    //                             className="custom-button"
    //                             onClick={() => handlePayment(ed)}
    //                           >
    //                             Get Started
    //                           </Button>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   );
    //                 })}
    //               </div>
    //             </div>
    //           </section>
    //         </TabPanel>
    //         <TabPanel value={value} index={1} dir={theme.direction}>
    //           <section>
    //             <div>
    //               <div className="row">
    //                 {subscriptionData[1]?.map((ed, index) => {
    //                   return (
    //                     <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
    //                       <div
    //                         style={{
    //                           backgroundColor:
    //                             index == 0
    //                               ? "#637ced"
    //                               : index == 1
    //                               ? "#EC539C"
    //                               : "#5BC587",
    //                           borderWidth: 2,
    //                           height: 5,
    //                           borderTopLeftRadius: 100,
    //                           borderTopRightRadius: 100,
    //                         }}
    //                       />
    //                       <div className="card card2-shadow">
    //                         <div className="card-body">
    //                           <h5 className="card-title">{ed.title}</h5>
    //                           <img
    //                             src={Images.subscript}
    //                             style={{
    //                               height: "40%",
    //                               width: "80%",
    //                             }}
    //                           />

    //                           <p
    //                             className="card-text"
    //                             style={{
    //                               textAlign: "center",
    //                               width: "70%",
    //                               color: "#000",
    //                               fontSize: "16px",
    //                               fontWeight: "400",
    //                               fontFamily: "inter",
    //                             }}
    //                           >
    //                             Essential Tools for New Ventures
    //                           </p>
    //                           <div style={{ alignSelf: "center" }}>
    //                             <p
    //                               style={{
    //                                 color: "#637CED",
    //                                 fontSize: "40px",
    //                                 alignSelf: "center",
    //                                 fontWeight: "600",
    //                                 fontFamily: "inter",
    //                               }}
    //                             >
    //                               $ {ed.price}
    //                               <span
    //                                 style={{
    //                                   color: "#000",
    //                                   fontSize: "20px",
    //                                 }}
    //                               >
    //                                 / {ed.days} Days
    //                               </span>
    //                             </p>
    //                           </div>
    //                           <div
    //                             style={{
    //                               display: "flex",
    //                               alignSelf: "center",
    //                               flexDirection: "row",
    //                               width: "95%",
    //                             }}
    //                           >
    //                             <img
    //                               src={Images.subTick}
    //                               style={{
    //                                 height: "18px",
    //                                 width: "18px",
    //                               }}
    //                             />
    //                             <p
    //                               style={{
    //                                 margin: 0,
    //                                 marginLeft: "10px",
    //                                 alignItems: "center",
    //                                 color: "#000",
    //                               }}
    //                             >
    //                               {ed.description}
    //                             </p>
    //                           </div>

    //                           <Button
    //                             style={{
    //                               color: "#fff",
    //                               width: "100%",
    //                               marginTop: 10,
    //                             }}
    //                             className="custom-button"
    //                             onClick={() => handlePayment(ed)}
    //                           >
    //                             Get Started
    //                           </Button>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   );
    //                 })}
    //               </div>
    //             </div>
    //           </section>
    //         </TabPanel>
    //         <TabPanel value={value} index={2} dir={theme.direction}>
    //           <section>
    //             <div>
    //               <div className="row">
    //                 {subscriptionData[2]?.map((ed, index) => {
    //                   return (
    //                     <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
    //                       <div
    //                         style={{
    //                           backgroundColor:
    //                             index == 0
    //                               ? "#637ced"
    //                               : index == 1
    //                               ? "#EC539C"
    //                               : "#5BC587",
    //                           borderWidth: 2,
    //                           height: 5,
    //                           borderTopLeftRadius: 100,
    //                           borderTopRightRadius: 100,
    //                         }}
    //                       />
    //                       <div className="card card2-shadow">
    //                         <div className="card-body">
    //                           <h5 className="card-title">{ed.title}</h5>
    //                           <img
    //                             src={Images.subscript}
    //                             style={{
    //                               height: "40%",
    //                               width: "80%",
    //                             }}
    //                           />

    //                           <p
    //                             className="card-text"
    //                             style={{
    //                               textAlign: "center",
    //                               width: "70%",
    //                               color: "#000",
    //                               fontSize: "16px",
    //                               fontWeight: "400",
    //                               fontFamily: "inter",
    //                             }}
    //                           >
    //                             Essential Tools for New Ventures
    //                           </p>
    //                           <div style={{ alignSelf: "center" }}>
    //                             <p
    //                               style={{
    //                                 color: "#637CED",
    //                                 fontSize: "40px",
    //                                 alignSelf: "center",
    //                                 fontWeight: "600",
    //                                 fontFamily: "inter",
    //                               }}
    //                             >
    //                               $ {ed.price}
    //                               <span
    //                                 style={{
    //                                   color: "#000",
    //                                   fontSize: "20px",
    //                                 }}
    //                               >
    //                                 / {ed.days} Days
    //                               </span>
    //                             </p>
    //                           </div>

    //                           <div
    //                             style={{
    //                               display: "flex",
    //                               alignSelf: "center",
    //                               flexDirection: "row",
    //                               width: "95%",
    //                             }}
    //                           >
    //                             <img
    //                               src={Images.subTick}
    //                               style={{
    //                                 height: "18px",
    //                                 width: "18px",
    //                               }}
    //                             />
    //                             <p
    //                               style={{
    //                                 margin: 0,
    //                                 marginLeft: "10px",
    //                                 alignItems: "center",
    //                                 color: "#000",
    //                               }}
    //                             >
    //                               {ed.description}
    //                             </p>
    //                           </div>

    //                           <Button
    //                             style={{
    //                               color: "#fff",
    //                               width: "100%",
    //                               marginTop: 10,
    //                             }}
    //                             className="custom-button"
    //                             onClick={() => handlePayment(ed)}
    //                           >
    //                             Get Started
    //                           </Button>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   );
    //                 })}
    //               </div>
    //             </div>
    //           </section>
    //         </TabPanel>
    //       </SwipeableViews>
    //     </Box>
    //   </div>
    // </Container>
    <Container fluid>
      <br />
      <div style={{ textAlign: "-webkit-center" }}>
        {load && (
          <div style={styles.backdrop}>
            <CircularProgress style={styles.loader} />
          </div>
        )}
        <p
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            color: "#6D39E9",
            fontFamily: "Inter",
            marginTop: "-25px",
          }}
          className="mt-0"
        >
          Choose Your Plan
        </p>
      </div>
      <Row>
        <Col md={12} style={{ padding: "10px 52px" }}>
          <div
            style={{ backgroundColor: "#fdc856" }}
            className="alert  d-flex justify-content-between align-items-center"
          >
            <span style={{ color: "black" }} className="fw-bold">
              Choose the plan that suits you best! Each plan is a one-time
              payment for the selected duration and will expire automatically at
              the end of that period. If you wish to continue accessing student
              contact details after your plan expires, simply purchase a new
              plan to renew your membership.
            </span>
          </div>
        </Col>
      </Row>

      <div
        className="row justify-content-center"
        style={{
          textAlign: "-webkit-center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <AppBar
            position="static"
            style={{
              backgroundColor: "#C0A8F9",
              padding: 5,
              width: isMobile ? "100%" : "60%",
              justifyContent: "space-evenly",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="white"
              variant={isMobile ? "scrollable" : "fullWidth"}
              aria-label="full width tabs example"
              scrollButtons={isMobile ? "auto" : "off"}
            >
              {subscriptionData.map((pkgType, index) => (
                <Tab
                  key={pkgType.id}
                  label={pkgType.package_type_name}
                  style={{
                    backgroundColor: value === index ? "#C0A8F9" : "white",
                    color: "#034F75",
                    width: isMobile ? "auto" : "20%",
                    borderRadius: 5,
                    fontWeight: "600",
                    minWidth: 0, // Ensures proper width on mobile
                    margin: isMobile ? "0 5px" : "0",
                  }}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {subscriptionData.map((pkgType, index) => (
              <TabPanel
                key={pkgType.id}
                value={value}
                index={index}
                dir={theme.direction}
              >
                <section>
                  <div>
                    <div className="row">
                      {pkgType.packages.map((ed, idx) => (
                        <div
                          key={ed.id}
                          className="col-lg-4 col-md-6 col-sm-12 mt-2"
                        >
                          <div
                            style={{
                              backgroundColor:
                                idx == 0
                                  ? "#637ced"
                                  : idx == 1
                                  ? "#EC539C"
                                  : "#5BC587",
                              borderWidth: 2,
                              height: 5,
                              borderTopLeftRadius: 100,
                              borderTopRightRadius: 100,
                            }}
                          />
                          <div className="card   card2-shadow">
                            <div className="card-body ">
                              <h5 className="card-title">{ed.title}</h5>
                              {ed.days == 90 ? (
                                <Row>
                                  <Col sm={12} xs={12}>
                                    <div
                                      className=""
                                      style={{
                                        backgroundColor: "#FFD700", // Yellow background
                                        color: "#000", // Black text
                                        padding: "2px 8px",
                                        borderRadius: "3px",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        display: "inline-block",
                                        fontSize: "12px", // Smaller font size
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Best Seller
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col sm={12} xs={12}>
                                    <div
                                      style={{
                                        // Yellow background
                                        color: "white", // Black text
                                        padding: "2px 8px",
                                        borderRadius: "3px",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        display: "inline-block",
                                        fontSize: "12px", // Smaller font size
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Best Seller
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <img
                                src={Images.subscript}
                                style={{
                                  height: "40%",
                                  width: "80%",
                                }}
                              />

                              <div style={{ alignSelf: "center" }}>
                                <p
                                  style={{
                                    color: "#637CED",
                                    fontSize: "30px",
                                    alignSelf: "center",
                                    fontWeight: "600",
                                    fontFamily: "inter",
                                  }}
                                >
                                  {ed.price} AED
                                  <span
                                    style={{
                                      color: "#000",
                                      fontSize: "20px",
                                    }}
                                  >
                                    / {ed.days} Days
                                  </span>
                                </p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignSelf: "center",
                                  flexDirection: "row",
                                  width: "95%",
                                }}
                              >
                                <div style={{ textAlign: "left" }}>
                                  <ShowMoreText
                                    text={ed.description}
                                    maxLines={1}
                                  />
                                </div>
                              </div>

                              <Button
                                disabled={isPackagePurchased(ed.id)}
                                style={{
                                  color: "#fff",
                                  width: "100%",
                                  marginTop: 10,
                                }}
                                className="fw-bold ms-auto"
                                onClick={() => handlePayment(ed)}
                              >
                                {getButtonLabel(ed.id)}
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </TabPanel>
            ))}
          </SwipeableViews>
        </Box>
      </div>
    </Container>
  );
}
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};
