/** @format */

import React, { useEffect, useRef, useState } from "react";
import "./AdvertiseList.css";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import Images from "../../../Helper/Images";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { apiCallNew } from "../../../Network_Call/apiservices";
import Upper from "../../../Components/Uppecase";
const ShowMoreLess = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleShowMore = () => {
    setIsExpanded(!isExpanded);
  };

  if (text.length <= maxLength) {
    return <p className="message-subject">{text}</p>;
  }

  return (
    <p className="message-subject">
      {isExpanded ? text : `${text.slice(0, maxLength)}...`}
      <span
        onClick={toggleShowMore}
        style={{
          color: "blue",
          cursor: "pointer",
          marginLeft: "5px",
          fontSize: "12px",
        }}
      >
        {isExpanded ? "Show Less" : "Show More"}
      </span>
    </p>
  );
};

const AdvertiseList = () => {
  const navigate = useNavigate();
  const [adverList, setAdverList] = useState([]);
  const [load, setload] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [pkgStatus, setPkgStatus] = useState();
  const popupShownRef = useRef(false);
  console.log("package>>>>>>", pkgStatus);
  const isProorPriority = "pro" || "priority";
  const showSubscriptionAlert = () => {
    Swal.fire({
      title: "Subscription Required",
      text: "Need Parent Contact Details or Want to Message Them on the Platform? Browse and Purchase an Affordable Subscription to Unlock Full Access",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Subscribe",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#6d39e9",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/subscription");
      }
    });
  };
  // const isProorPriority = "";
  useEffect(() => {
    AdvertiseList(true);
    Get_Pkg_Status();
  }, []);
  useEffect(() => {
    if (pkgStatus && pkgStatus.length === 0 && !popupShownRef.current) {
      showSubscriptionAlert();
      popupShownRef.current = true; // Set the flag to true after showing the popup
    }
  }, [pkgStatus]);

  const viewedUsersPostApi = async (user) => {
    const subscribe_id = await pkgStatus?.id;
    const viewed_user_id = await user?.user_id;

    const response = await apiCallNew(
      "get",
      null,
      `${ApiEndPoints.PkgViewdUser}?subscribe_id=${subscribe_id}&viewed_user_id=${user?.user_id}`
    );
    console.log("response of viewed>>>", response);
    if (response?.success == true) {
      toast.success(response?.message);
    }
  };
  const viewedLimitedUser = async (user) => {
    const subscribe_id = pkgStatus?.id;
    const viewed_user_id = user?.user_id;

    try {
      const response = await apiCallNew(
        "get",
        null,
        `${ApiEndPoints.PkgViewdUser}?subscribe_id=${subscribe_id}&viewed_user_id=${viewed_user_id}`
      );

      console.log("response of viewed>>>", response);
      if (response?.success) {
        toast.success(response?.message);

        // Navigate to the new route with state
        navigate("/messageView", {
          state: {
            users: user?.user_id,
            img: user?.image,
            name: user?.name,
          },
        });
      } else {
        toast.error(response?.message || "An error occurred");
      }
    } catch (error) {
      toast.error("An error occurred while processing your request.");
      console.error("API error:", error);
    }
  };

  const handleClose = () => {
    setShow(false);
    AdvertiseList(false);
    // AdvertiseList();
    Get_Pkg_Status();
  };
  const handleShow = (user) => {
    if (
      pkgStatus?.package_viewed_users < pkgStatus?.package_view_users &&
      user?.is_seen == 0
    ) {
      viewedUsersPostApi(user);
      setSelectedUser(user);
      setShow(true);
    } else if (user?.is_seen == 1) {
      setShow(true);
      setSelectedUser(user);
    } else if (pkgStatus?.package_all_contact_view == 1) {
      setShow(true);
      setSelectedUser(user);
      viewedUsersPostApi(user);
    } else {
      toast.error("Your Contact Details View Limit is Exeeded.");
    }
  };
  const handleLimitedShow = (user) => {
    console.log("handleLimitedShow>>>>", user);
    if (
      pkgStatus?.package_viewed_users < pkgStatus?.package_view_users &&
      user?.is_seen == 0
    ) {
      viewedLimitedUser(user);
    } else if (user?.is_seen === 1) {
      console.log("Gusaaa");
      navigate("/messageView", {
        state: {
          users: user?.user_id,
          img: user?.image,
          name: user?.name,
        },
      });
    } else {
      toast.error("Your Contact Details View Limit is Exeeded.");
    }
  };
  const AdvertiseList = (showLoader = true) => {
    const payload = {
      page: 0,
      limit: 0,
    };
    if (showLoader) setload(true);
    apiCallNew("post", payload, ApiEndPoints.TutorAdvertiseList).then((res) => {
      if (res.success == true) {
        setAdverList(res?.result);
        setload(false);
      } else {
        setload(false);
      }
    });
  };
  const Get_Pkg_Status = async () => {
    const response = await apiCallNew("get", null, ApiEndPoints.Pkg_Status);
    setPkgStatus(response?.result);
  };
  const truncateUserMsg = (userMsg) => {
    const maxLength = 25;
    if (userMsg?.length > maxLength) {
      return `${userMsg?.slice(0, maxLength)}...`;
    }
    return userMsg;
  };
  const isPackageExpired = (endDate) => {
    const currentDate = new Date();
    const packageEndDate = new Date(endDate);
    return packageEndDate < currentDate;
  };

  return (
    <Container fluid className="mobile-container">
      <Row>
        {pkgStatus?.length <= 0 && (
          <Col md={12} style={{ padding: "10px 52px" }}>
            <div
              style={{ backgroundColor: "#fdc856" }}
              className="alert  d-flex justify-content-between align-items-center"
            >
              <span style={{ color: "black" }} className="fw-bold">
                To Unlock Contact Details and Message on the Platform, You Need
                To Upgrade Your Plan by Paying a One-Time, Small Subscription
                Fee.
              </span>
            </div>
          </Col>
        )}
        {load && (
          <div style={styles.backdrop}>
            <CircularProgress style={styles.loader} />
          </div>
        )}
        <Col className="req-list" md={12} style={{ padding: "0px 52px" }}>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <h4>Send Request</h4>
          </div>
          {pkgStatus?.package_all_contact_view == 0 ? (
            <p
              style={{
                fontSize: "1.2rem", // Adjust size as needed
                fontWeight: "bold", // Ensure it's bold like a heading
                margin: "20px 0", // Adjust margin for spacing
              }}
              className="text-danger"
            >
              Note: You have viewed {pkgStatus?.package_viewed_users ?? 0} out
              of {pkgStatus?.package_view_users} contact details. To view all
              contact details, you need to upgrade to a pro or priority
              membership.
            </p>
          ) : null}

          {!load && adverList?.length === 0 ? (
            <div>Advertisement Not Found</div>
          ) : (
            // <div className="card shadow mt-2">
            //   {adverList?.map((item) => (
            //     <div key={item.id} className="message-item">
            //       {/* <Link className="message-link"> */}
            //       <Row style={{ alignItems: "center" }}>
            //         <Col
            //           className="d-flex  justify-content-start align-items-start text-center text-sm-center"
            //           lg={1}
            //           xs={12}
            //           sm={6}
            //           md={1}
            //           xl={1}
            //           xxl={1}
            //         >
            //           <img
            //             className="imgs"
            //             src={item?.image ? item?.image : Images.FindMyTutor}
            //             alt="user img"
            //             style={{ margin: 0, height: 50, width: 50 }}
            //           />
            //         </Col>
            //         <Col
            //           className="msg   text-md-center text-lg-start  "
            //           lg={3}
            //           xs={6}
            //           sm={6}
            //           md={3}
            //           xl={3}
            //           xxl={5}
            //         >
            //           <div>
            //             <p
            //               className="message-sender"
            //               style={{ color: "black", fontSize: 20 }}
            //             >
            //               <Upper name={item?.name} />
            //             </p>

            //             <ShowMoreLess text={item?.description} maxLength={18} />
            //           </div>
            //         </Col>
            //         <Col
            //           className="text-md-center"
            //           xs={6}
            //           lg={6}
            //           sm={6}
            //           md={6}
            //           xl={6}
            //           xxl={3}
            //         >
            //           <div
            //             style={{
            //               display: "flex",
            //               flexDirection: "column",
            //               alignItems: "flex-start text-lg-center ",
            //             }}
            //           >
            //             <div style={{ display: "flex", alignItems: "center" }}>
            //               <p
            //                 className="message-sender"
            //                 style={{
            //                   color: "black",
            //                   fontSize: 17,
            //                   margin: 0,
            //                 }}
            //               >
            //                 Subject:
            //               </p>
            //               <span
            //                 style={{
            //                   fontSize: 15,
            //                   color: "gray",
            //                   marginLeft: 10,
            //                 }}
            //               >
            //                 {item?.subject_names ? item?.subject_names : "null"}
            //               </span>
            //             </div>
            //             <div
            //               style={{
            //                 display: "flex",
            //                 alignItems: "center",
            //                 marginTop: 5,
            //               }}
            //             >
            //               <p
            //                 className="message-sender"
            //                 style={{
            //                   color: "black",
            //                   fontSize: 17,
            //                   margin: 0,
            //                 }}
            //               >
            //                 Class:
            //               </p>
            //               <span
            //                 style={{
            //                   fontSize: 15,
            //                   color: "gray",
            //                   marginLeft: 10,
            //                 }}
            //               >
            //                 {item?.class_names}
            //               </span>
            //             </div>
            //           </div>
            //         </Col>

            //         <Col
            //           className="text-lg-center   text-xl-center"
            //           xs={6}
            //           lg={2}
            //           sm={6}
            //           md={2}
            //           xl={2}
            //           xxl={3}
            //         >
            //           {isPackageExpired(pkgStatus?.package_end_date) ? (
            //             <Link
            //               state={{
            //                 users: item?.user_id,
            //                 img: item?.image,
            //                 name: item?.name,
            //               }}
            //               to={`/subscription`}
            //             >
            //               <button className="message-button">Subscribe</button>
            //             </Link>
            //           ) : (
            //             <>
            //               {pkgStatus?.length <= 0 ? (
            //                 <Link
            //                   state={{
            //                     users: item?.user_id,
            //                     img: item?.image,
            //                     name: item?.name,
            //                   }}
            //                   to={`/subscription`}
            //                 >
            //                   <button className="message-button">
            //                     Subscribe
            //                   </button>
            //                 </Link>
            //               ) : pkgStatus?.package_all_contact_view == 0 ? (
            //                 <Button
            //                   variant="secondary"
            //                   onClick={() => handleLimitedShow(item)}
            //                 >
            //                   {item?.is_seen === 1
            //                     ? "Message Sent"
            //                     : "Send Message"}
            //                 </Button>
            //               ) : (
            //                 <div>
            //                   <button
            //                     onClick={() => handleShow(item)}
            //                     className="message-button"
            //                     style={{ width: "120px" }}
            //                   >
            //                     {item?.is_seen === 1 ? "Viewed" : "View Detail"}
            //                   </button>
            //                 </div>
            //               )}
            //             </>
            //           )}
            //         </Col>
            //       </Row>
            //     </div>
            //   ))}
            // </div>
            <div className="card shadow mt-2">
              {adverList?.map((item) => (
                <div key={item.id} className="message-item">
                  <Row className="align-items-center">
                    <Col
                      className="d-flex justify-content-center align-items-start mb-2 mb-lg-0"
                      lg={1}
                      xs={3} // Adjusted for better alignment on mobile
                    >
                      <img
                        className="imgs"
                        src={item?.image ? item?.image : Images.FindMyTutor}
                        alt="user img"
                        style={{ margin: 0, height: 50, width: 50 }}
                      />
                    </Col>
                    <Col
                      className="text-lg-start mb-2 mb-lg-0"
                      lg={3}
                      xs={9} // Adjusted to take more space on mobile
                    >
                      <div>
                        <p
                          className="message-sender"
                          style={{ color: "black", fontSize: 18 }}
                        >
                          <Upper name={item?.name} />
                        </p>
                        <ShowMoreLess text={item?.description} maxLength={18} />
                      </div>
                    </Col>
                    <Col
                      className="text-center text-lg-start mb-2 mb-lg-0"
                      lg={6}
                      xs={12} // Full width on mobile
                    >
                      <div className="d-flex flex-column align-items-lg-start">
                        <div className="d-flex align-items-center">
                          <p
                            className="message-sender"
                            style={{ color: "black", fontSize: 16, margin: 0 }}
                          >
                            Subject:
                          </p>
                          <span
                            style={{
                              fontSize: 15,
                              color: "gray",
                              marginLeft: 10,
                            }}
                          >
                            {item?.subject_names || "null"}
                          </span>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <p
                            className="message-sender"
                            style={{ color: "black", fontSize: 16, margin: 0 }}
                          >
                            Class:
                          </p>
                          <span
                            style={{
                              fontSize: 15,
                              color: "gray",
                              marginLeft: 10,
                            }}
                          >
                            {item?.class_names}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col
                      className="text-center text-lg-center mb-2 mb-lg-0"
                      lg={2}
                      xs={12} // Full width on mobile
                    >
                      {isPackageExpired(pkgStatus?.package_end_date) ? (
                        <Link
                          state={{
                            users: item?.user_id,
                            img: item?.image,
                            name: item?.name,
                          }}
                          to={`/subscription`}
                        >
                          <button className="message-button w-100">
                            Subscribe
                          </button>
                        </Link>
                      ) : (
                        <>
                          {pkgStatus?.length <= 0 ? (
                            <Link
                              state={{
                                users: item?.user_id,
                                img: item?.image,
                                name: item?.name,
                              }}
                              to={`/subscription`}
                            >
                              <button className="message-button w-100">
                                Subscribe
                              </button>
                            </Link>
                          ) : pkgStatus?.package_all_contact_view == 0 ? (
                            <Button
                              variant="secondary"
                              onClick={() => handleLimitedShow(item)}
                              className="w-100 ms-auto"
                            >
                              {item?.is_seen === 1
                                ? "Message Sent"
                                : "Send Message"}
                            </Button>
                          ) : (
                            <button
                              onClick={() => handleShow(item)}
                              className="message-button w-100"
                            >
                              {item?.is_seen === 1 ? "Viewed" : "View Detail"}
                            </button>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>
      <Modal
        centered
        dialogClassName="modal-90w"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Name:</strong> {selectedUser?.name}
          </p>
          <p>
            <strong>Email:</strong> {selectedUser?.email}
          </p>
          <p>
            <strong>Phone:</strong> {selectedUser?.mobile_number}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link
            state={{
              users: selectedUser?.user_id,
              img: selectedUser?.image,
              name: selectedUser?.name,
            }}
            to={`/messageView`}
          >
            {" "}
            <Button variant="secondary" onClick={handleClose}>
              Send Message
            </Button>{" "}
          </Link>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default AdvertiseList;
