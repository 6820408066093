// /** @format */

// import React, { useState } from "react";
// import {
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Collapse,
//   IconButton,
//   useMediaQuery,
// } from "@mui/material";
// import {
//   Dashboard as DashboardIcon,
//   Person as PersonIcon,
//   Search as SearchIcon,
//   Message as MessageIcon,
//   Subscriptions as SubscriptionsIcon,
//   Help as HelpIcon,
//   Settings as SettingsIcon,
//   ExitToApp as ExitToAppIcon,
//   Menu as MenuIcon,
//   ExpandLess,
//   ExpandMore,
// } from "@mui/icons-material";
// import { Link, useNavigate } from "react-router-dom";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import { Logout } from "../../Helper/Storage";
// import { FiPlus } from "react-icons/fi";
// import CampaignIcon from "@mui/icons-material/Campaign";
// import img from "../../Assets/Images/logoNew.png";

// const DashDrawer = ({
//   toggleDrawer,
//   open,
//   variant,
//   setCurrentComponent,
//   isMobile,
// }) => {
//   const [openRequest, setOpenRequest] = useState(false);
//   const [openSubscription, setOpenSubscription] = useState(false);
//   console.log("varriant", variant, open, toggleDrawer);
//   const navigate = useNavigate();
//   const role = localStorage.getItem("role");
//   console.log("role", role);

//   const handleRequestClick = () => {
//     setOpenRequest(!openRequest);
//   };

//   const handleSubscriptionClick = () => {
//     setOpenSubscription(!openSubscription);
//   };
//   const Logouts = () => {
//     Logout();
//     navigate("/login");
//     window.location.reload();
//   };

//   return (
//     <>
//       <Drawer
//         variant={variant}
//         open={open}
//         onClose={toggleDrawer}
//         ModalProps={{ keepMounted: true }}
//         sx={{
//           "& .MuiDrawer-paper": {
//             backgroundColor: "#6d39e9", // Change this color to your desired background color
//             // width: "240px",
//             width: isMobile ? "240px" : "240px",
//           },
//         }}
//       >
//         <div
//           className="d-flex justify-content-center align-items-center"
//           style={{ width: "100%", height: "auto" }} // Set width to 100% and height to auto
//         >
//           <img
//             className="img-fluid"
//             src={img}
//             alt=""
//             style={{
//               width: "100%", // Make the image take up the full width
//               height: "auto", // Maintain aspect ratio
//               objectFit: "contain", // Ensure the image fits well within its container
//             }}
//           />
//         </div>
//         {/* <h4 className="text-white ms-5 mt-5">FindMyTutor</h4> */}
//         {/* <hr className="" /> */}
//         <List>
//           <Link
//             style={{ textDecoration: "none" }}
//             to={role === "Tutor" ? "/tutor" : "/parent"}
//           >
//             {" "}
//             <ListItem
//               className="text-white"
//               button
//               onClick={() => setCurrentComponent("Dashboard")}
//             >
//               <ListItemIcon className="text-white">
//                 <DashboardIcon />
//               </ListItemIcon>
//               <ListItemText primary="Dashboard" />
//             </ListItem>
//           </Link>
//           <ListItem onClick={handleRequestClick} className="text-white" button>
//             <ListItemIcon>
//               <SearchIcon className="text-white" />
//             </ListItemIcon>
//             <ListItemText
//               primary={role === "Tutor" ? "Tuition requests" : "Find Tutor"}
//             />
//             {openRequest ? <ExpandLess /> : <ExpandMore />}
//           </ListItem>

//           <Collapse in={openRequest} timeout="auto" unmountOnExit>
//             <List component="div" disablePadding>
//               <Link
//                 style={{ textDecoration: "none" }}
//                 to={role === "Tutor" ? "/view-request" : "/findTutor"}
//               >
//                 {" "}
//                 <ListItem
//                   onClick={() =>
//                     role === "Tutor"
//                       ? setCurrentComponent("View Search request")
//                       : setCurrentComponent("View/search tutor")
//                   }
//                   button
//                   className="text-white"
//                 >
//                   <ListItemText
//                     inset
//                     primary={
//                       role === "Tutor"
//                         ? "View/Search request"
//                         : "View/search tutor"
//                     }
//                   />
//                 </ListItem>{" "}
//               </Link>
//               {role === "Tutor" ? (
//                 <Link style={{ textDecoration: "none" }} to={"/advertise-list"}>
//                   <ListItem
//                     onClick={() => setCurrentComponent("Send request")}
//                     button
//                     className="text-white"
//                   >
//                     <ListItemText
//                       inset
//                       primary={role === "Tutor" ? "Send request" : null}
//                     />
//                   </ListItem>
//                 </Link>
//               ) : null}
//             </List>
//           </Collapse>
//           <Link className="text-decoration-none" to={"/messageList"}>
//             {" "}
//             <ListItem
//               onClick={() => setCurrentComponent("msg")}
//               className="text-white"
//               button
//             >
//               <ListItemIcon className="text-white">
//                 <MessageIcon />
//               </ListItemIcon>
//               <ListItemText primary="Message" />
//             </ListItem>
//           </Link>
//           {role === "Tutor" ? null : (
//             <Link className="text-decoration-none" to={"/advertise-post-list"}>
//               {" "}
//               <ListItem className="text-white" button>
//                 <ListItemIcon className="text-white">
//                   <CampaignIcon />
//                 </ListItemIcon>
//                 <ListItemText primary={"Advertisement"} />
//               </ListItem>
//             </Link>
//           )}
//           {role === "Tutor" ? (
//             <Link style={{ textDecoration: "none" }} to="/subscription">
//               {" "}
//               <ListItem
//                 className="text-white"
//                 button
//                 onClick={() => setCurrentComponent("Subscription")}
//               >
//                 <ListItemIcon className="text-white">
//                   {/* <SubscriptionsIcon /> */}
//                   <FiPlus
//                     style={{
//                       // position: "absolute",
//                       // top: "50%",
//                       // left: "260px",
//                       // right: "10px",
//                       // transform: "translateY(-50%)",
//                       cursor: "pointer",
//                       width: "23px",
//                       height: "40px",
//                     }}
//                   />
//                 </ListItemIcon>
//                 <ListItemText primary="Subscription" />
//                 {/* {openSubscription ? <ExpandLess /> : <ExpandMore />} */}
//               </ListItem>
//             </Link>
//           ) : null}

//           <Link className="text-decoration-none" to={"/help"}>
//             <ListItem
//               onClick={() => setCurrentComponent("Help")}
//               className="text-white "
//               button
//             >
//               <ListItemIcon className="text-white">
//                 <HelpIcon />
//               </ListItemIcon>
//               <ListItemText primary="Help" />
//             </ListItem>
//           </Link>
//         </List>
//       </Drawer>
//     </>
//   );
// };

// export default DashDrawer;

/** @format */

import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Search as SearchIcon,
  Message as MessageIcon,
  Help as HelpIcon,
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "../../Helper/Storage";
import { FiPlus } from "react-icons/fi";
import CampaignIcon from "@mui/icons-material/Campaign";
import img from "../../Assets/Images/Newl.PNG";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const DashDrawer = ({
  toggleDrawer,
  open,
  variant,
  setCurrentComponent,
  isMobile,
}) => {
  const [openRequest, setOpenRequest] = useState(false);
  const [openSubscription, setOpenSubscription] = useState(false);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const handleRequestClick = () => {
    setOpenRequest(!openRequest);
  };

  const handleSubscriptionClick = () => {
    setOpenSubscription(!openSubscription);
  };

  const Logouts = () => {
    Logout();
    navigate("/login");
    window.location.reload();
  };
  const handleItemClick = (componentName) => {
    setCurrentComponent(componentName);
    toggleDrawer();
  };

  return (
    <>
      <Drawer
        variant={variant}
        open={open}
        onClose={toggleDrawer}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#6d39e9",
            width: isMobile ? "240px" : "240px",
            borderRight: "1px solid #f7f1f1",
          },
        }}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", height: "auto", backgroundColor: "#fff" }}
        >
          <img
            className="img-fluid"
            src={img}
            alt=""
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </div>
        <List>
          <Link
            style={{ textDecoration: "none" }}
            to={role === "Tutor" ? "/tutor" : "/parent"}
          >
            <ListItem
              className="text-white"
              button
              onClick={() => handleItemClick("Dashboard")}
            >
              <ListItemIcon className="text-white">
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
          <ListItem onClick={handleRequestClick} className="text-white" button>
            <ListItemIcon>
              <SearchIcon className="text-white" />
            </ListItemIcon>
            <ListItemText
              primary={role === "Tutor" ? "Tuition requests" : "Find Tutor"}
            />
            {openRequest ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openRequest} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                style={{ textDecoration: "none" }}
                to={role === "Tutor" ? "/view-request" : "/findTutor"}
              >
                <ListItem
                  onClick={() =>
                    handleItemClick(
                      role === "Tutor"
                        ? "View Search request"
                        : "View/search tutor"
                    )
                  }
                  button
                  className="text-white"
                >
                  <ListItemText
                    inset
                    primary={
                      role === "Tutor"
                        ? "View/Search request"
                        : "View/search tutor"
                    }
                  />
                </ListItem>
              </Link>
              {role === "Tutor" ? (
                <Link style={{ textDecoration: "none" }} to={"/advertise-list"}>
                  <ListItem
                    onClick={() => handleItemClick("Send request")}
                    button
                    className="text-white"
                  >
                    <ListItemText
                      inset
                      primary={role === "Tutor" ? "Advertisements" : null}
                    />
                  </ListItem>
                </Link>
              ) : null}
            </List>
          </Collapse>

          <Link className="text-decoration-none" to={"/messageList"}>
            <ListItem
              onClick={() => handleItemClick("msg")}
              className="text-white"
              button
            >
              <ListItemIcon className="text-white">
                <MessageIcon />
              </ListItemIcon>
              <ListItemText primary="Message" />
            </ListItem>
          </Link>
          {role === "Tutor" ? null : (
            <Link className="text-decoration-none" to={"/advertise-post-list"}>
              <ListItem
                onClick={() => handleItemClick("Advertisements")}
                className="text-white"
                button
              >
                <ListItemIcon className="text-white">
                  <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary="Advertisements" />
              </ListItem>
            </Link>
          )}
          {/* {role === "Tutor" ? (
            <Link className="text-decoration-none" to={"/advertise-list"}>
              <ListItem
                onClick={() => handleItemClick("Advertisements")}
                className="text-white"
                button
              >
                <ListItemIcon className="text-white">
                  <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary="Advertisements" />
              </ListItem>
            </Link>
          ) : null} */}
          {role == "Tutor" ? null : (
            <Link className="text-decoration-none" to={"/advertise-post"}>
              <ListItem
                onClick={() => handleItemClick("msg")}
                className="text-white"
                button
              >
                <ListItemIcon className="text-white">
                  <AddCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Post advertisement" />
              </ListItem>
            </Link>
          )}
          {role === "Tutor" ? (
            <Link style={{ textDecoration: "none" }} to="/subscription">
              <ListItem
                className="text-white"
                button
                onClick={() => handleItemClick("Subscription")}
              >
                <ListItemIcon className="text-white">
                  <FiPlus
                    style={{
                      cursor: "pointer",
                      width: "23px",
                      height: "40px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Subscription" />
              </ListItem>
            </Link>
          ) : null}

          <Link className="text-decoration-none" to={"/editProfile"}>
            <ListItem
              onClick={() => handleItemClick("msg")}
              className="text-white"
              button
            >
              <ListItemIcon className="text-white">
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Update Profile" />
            </ListItem>
          </Link>

          {role === "Tutor" ? (
            <Link style={{ textDecoration: "none" }} to="/subscrib-Pkg-List">
              <ListItem
                className="text-white"
                button
                onClick={() => handleItemClick("Subscription")}
              >
                <ListItemIcon className="text-white">
                  <AccountBalanceWalletIcon
                    style={{
                      cursor: "pointer",
                      width: "23px",
                      height: "40px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Current Plan" />
              </ListItem>
            </Link>
          ) : null}

          <Link className="text-decoration-none" to={"/help"}>
            <ListItem
              onClick={() => handleItemClick("Help")}
              className="text-white"
              button
            >
              <ListItemIcon className="text-white">
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItem>
          </Link>
        </List>
      </Drawer>
    </>
  );
};

export default DashDrawer;
