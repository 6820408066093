/** @format */

import React, { useEffect, useState } from "react";
import "./Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Images from "../../../Helper/Images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCalendarAlt,
  FaPlus,
  FaMinus,
} from "react-icons/fa";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Carousel,
  Image,
} from "react-bootstrap";
import { apiCallNew } from "../../../Network_Call/apiservices";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Screen from "../../../Helper/Screen";
import { v4 as uuidv4 } from "uuid";
import ladki from "../../../Assets/Images/img8.png";
import classNames from "classnames";
import Metatags from "../../../Components/Metatags/Metatags";

const testimonials = [
  {
    image: Images.family,
    text: "I found an incredible mathematics tutor on this platform who explained tough concepts clearly. My grades shot up, and I gained confidence in math!",
    name: "Emily, Abu Dhabi",
    class: "High School",
  },
  {
    image: Images.HomeBanner,
    text: "Our son struggled with science and math until we found a patient tutor on this website that was based in Dubai. His grades vastly improved, and he enjoys learning again",
    name: "Salman, Dubai",
    class: "Parent",
  },
  {
    image: Images.HomeBanner,
    text: "My daughter needed to boost her SAT scores for college applications. With a dedicated tutor from this platform, her scores improved significantly and she got into her dream university!",
    name: "Sara, Sharjah",
    class: "Parent",
  },
  {
    image: Images.HomeBanner,
    text: "My son struggled with Science until we easily found an amazing tutor on this platform that was near me in Abu Dhabi. They made science fun and understandable for him. His grades improved, and he actually looks forward to science classes now!",
    name: "Rana, Abu Dhabi",
    class: "Parent",
  },
  {
    image: Images.HomeBanner,
    text: "I was stressed about my AP Chemistry exam, but the tutor I found here turned things around. They broke down complex topics into manageable parts and provided great study resources. Thanks to their help, I not only passed but exceeded my own expectations.",
    name: "Alex, Dubai",
    class: "High School Senior",
  },
  {
    image: Images.HomeBanner,
    text: "Finding a tutor through this platform was the best decision for our son, who needed help with essay writing. The tutor provided clear guidance and constructive feedback that improved his writing skills significantly. His grades in English class have improved, and he feels more confident expressing himself in writing.",
    name: "Zhan, Fujairah",
    class: "Parent ",
  },
  {
    image: Images.HomeBanner,
    text: "I needed assistance with statistics for my psychology major in Dubai, and this platform connected me with an incredible tutor who explained concepts in a way that made sense to me. Their patience and expertise helped me grasp difficult concepts, and my grades have improved steadily since.",
    name: "Yusuf, Dubai",
    class: "College  ",
  },
  {
    image: Images.HomeBanner,
    text: "Our daughter needed help with French, and this platform connected us with a tutor who made a real difference. Lily's confidence in speaking and understanding French grew rapidly,and her grades improved noticeably. We're so pleased with the personalised attention and progress she's made.",
    name: "Khadija, Abu Dhabi",
    class: "Parent",
  },
];
const tutors = [
  {
    name: "Mustafa Ahmed",
    stream: "Mathematics, Dubai",
    image: Images.Mustafa, // Add your tutor images here
    rating: 5,
    review:
      "Joining FindMyTutor was the best decision I have made in my tutoring career, it has allowed me to find multiple students and best of all, it doesn’t take any commission in comparison to other websites which I have had experience with that takes a huge fee.",
  },
  {
    name: "John Slater",
    stream: "Science, Abu Dhabi",
    image: Images.john,
    rating: 5,
    review:
      "FindMyTutor has increased my tutor portfolio vastly, there’s always new students looking for tuition and I am now fully booked up! I like that I can cancel whenever I want and I don’t have to pay anymore until I rejoin as a premium member if I am looking for any more students in the future.",
  },
  {
    name: "Faizah Begum",
    stream: "Mathematics, Sharjah",
    image: Images.Faizah,
    rating: 5,
    review:
      "As a math tutor, this platform has been a game-changer for me. I've found committed students who want to improve. It's a great way to earn and make a real difference in students' lives.",
  },
  {
    name: "Lujain Salmah",
    stream: "Art, Dubai",
    image: Images.Lujain,
    rating: 5,
    review:
      "This platform has been exactly what I’ve been looking for in order to find new students. I love that payments are handled by me directly, I made my monthly membership fee back in just one session, highly recommend.",
  },

  {
    name: "Harry Eccersely",
    stream: "English, Abu Dhabi",
    image: Images.harry,
    rating: 5,
    review:
      "Teaching English online to students found on this platform has been fantastic. I've connected with lots of students in the UAE, helping them improve their English skills and prepare for exams. It's a flexible way to earn very well while making a meaningful impact.",
  },
  {
    name: "Daniel Chen",
    stream: "Computer science, Dubai",
    image: Images.Daniel,
    rating: 5,
    review:
      "I am a qualified software engineer and I joined this platform as a programming tutor and it has been highly rewarding. I've helped students master coding skills, and the platform's ability to match me with serious students ensures a steady income. It's a great opportunity to grow professionally and financially.",
  },
  {
    name: "Maria Lopez",
    stream: "Physics, Fujairah",
    image: Images.maria,
    rating: 5,
    review:
      "This is the best platform I have found in the UAE for tutors as no commission is taken and is actually affordable, I literally made my monthly membership fee back in 1.5 sessions that I scheduled from the website. Would definitely recommend.",
  },
  // Add more tutor objects here
];
const AccordianData = [
  {
    id: 1,
    heading: "How do I find a tutor on your platform?",
    para: "You can find a tutor by posting an advert for free with specifications of what you require in terms of subject, subject level, location etc and an expert tutor will contact you to gather more details and you can finalise details with them directly.You can also find a tutor by browsing our database of qualified educators. Use our search filters to narrow down tutors based on subjects, location, availability, and qualifications. Once you find a suitable tutor, you can contact them directly through our messaging system to discuss your needs.",
  },
  {
    id: 2,
    heading: "What qualifications do your tutors have?",
    para: "Our tutors come from diverse backgrounds and possess a range of qualifications. Many hold degrees in their respective fields, while some may have specialized certifications or extensive teaching experience. Each tutor profile on our platform includes detailed information about their qualifications and teaching style.",
  },
  {
    id: 3,
    heading: "Does FindMyTutor charge any commission?",
    para: "FindMyTutor doesn’t charge ANY commission, the tutors keep whatever they charge. Payments for tutoring sessions are made directly to the tutor. Our platform facilitates communication between students and tutors but does not handle financial transactions between both parties. Payment methods and terms are agreed upon between the student and the tutor.",
  },
  {
    id: 4,
    heading: "What subjects do your tutors cover?",
    para: "Our tutors cover a wide range of subjects, including but not limited to mathematics, science, english, languages, humanities, and test preparation (e.g., SAT, IELTS). You can find tutors for primary school, secondary school, university-level courses, and specialised professional subjects.",
  },
  {
    id: 5,
    heading: "Can I schedule tutoring sessions based on my availability?",
    para: "Yes, you can schedule tutoring sessions at times that are convenient for you and the tutor. Some tutors offer flexible scheduling options to accommodate varying student needs.",
  },
  {
    id: 6,
    heading: "How much do tutoring sessions cost?",
    para: "Tutoring rates vary depending on the tutor's qualifications, subject taught, and location. You can find specific pricing information on each tutor's profile. Rates are typically listed per hour, and some tutors may offer package deals or discounts for multiple sessions.",
  },
  {
    id: 7,
    heading: "What if I'm not satisfied with a tutoring session?",
    para: "If you're not satisfied with a tutoring session, please contact us to discuss your concerns. We value your feedback and strive to ensure a positive learning experience for every student. We may offer alternative tutor recommendations or address any issues promptly.",
  },
  {
    id: 8,
    heading: "Is my information secure on your platform?",
    para: "We prioritise the security and privacy of all users on our platform. Your personal information is encrypted and stored securely. We adhere to strict privacy policies and do not share your information with third parties without your consent.",
  },
];
const WorkStudent = [
  {
    id: 1,
    title: "Post an Advert for free",
    decr: "Parents/students create a detailed advert specifying the subject, level, preferred schedule, and any specific requirements for the tutor.",
  },
  {
    id: 2,
    title: "Tutors Find Your Advert",
    decr: "Qualified tutors browse through the posted adverts and reach out to parents with their profiles, experience, and availability.",
  },
  {
    id: 3,
    title: "Review & Connect",
    decr: "Parents review and compare the tutor profiles and message the suitable tutors to discuss further details and finalize the tutoring arrangement.",
  },
  {
    id: 4,
    title: "Start Learning",
    decr: "Once a tutor is selected, sessions begin, and parents can track their childs progress and success.",
  },
];
const WorkTutor = [
  {
    id: 1,
    title: "Sign up",
    decr: "Join as a tutor",
  },
  {
    id: 2,
    title: "Browse",
    decr: "Browse the vast amount of student adverts that have been posted on the platform",
  },
  {
    id: 3,
    title: "Connect",
    decr: "Reach out to students/parents requiring tutoring directly via their personal contact details or through the platform’s messaging system",
  },
  {
    id: 4,
    title: "Start tutoring and earning!",
    decr: "Add students to your tutoring portfolio and join others who are earning money doing what they love. FindMyTutor will not charge any commission fees, you keep whatever you charge!",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const uniqueKey = uuidv4();
  const [formData, setFormData] = useState([]);

  const [show, setShow] = useState(false);
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [blogsData, setBlogsData] = useState([]);
  const [citytList, setCitytList] = useState([]);

  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };
  useEffect(() => {
    GetSubjectList();
    GetCityList();
  }, []);

  const GetSubjectList = async () => {
    apiCallNew("get", null, ApiEndPoints.SubjectTypeList)
      .then((response: any) => {
        if (response.success === true) {
          setFormData(response?.result);

          // toast(response.msg);
        } else {
          toast(response.msg);
        }
      })
      .finally((er) => {
        toast(er);
      });
  };
  const GetCityList = async () => {
    try {
      const response = await apiCallNew("get", null, ApiEndPoints.CityList);
      if (response.success) {
        setCitytList(response.result);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      toast.error("An error occurred while fetching the subject list.");
    }
  };

  const CategoryCard = ({ bgColor, textColor, imageSrc, title }) => {
    return (
      <div className="col-xxl-3 col-lg-3 col-md-6 mb-4">
        <div
          className="card card-2"
          style={{
            backgroundColor: bgColor,
            color: textColor,
            margin: "0 10px", // Adding left and right margin
            boxSizing: "border-box",
          }}
        >
          <div className="row mt-4">
            <div
              className="col-lg-3 col-md-2 col-sm-2 col-2"
              style={{ paddingLeft: "20px" }}
            >
              <img src={imageSrc} alt="" />
            </div>
            <div className="col-lg-6 mt-1 col-md-7 col-sm-7 col-7">
              <h5>{title}</h5>
            </div>
            <div className="col-lg-3 col-md-2 col-sm-2 col-2">
              <div className="curve">
                <img
                  src="ArrowUpRight.png"
                  alt=""
                  style={{ marginTop: "7px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleCityClick = (city) => {
    localStorage.setItem("role", "Tutor");
    localStorage.setItem("city", city);
    window.localStorage.removeItem("@userToken");
    navigate("/step2", { replace: true });
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    apiCallNew("post", null, ApiEndPoints.BlogsList)
      .then((response) => {
        console.log("firstBloggg", response);
        if (response.success === true) {
          setBlogsData(response?.result);
        }
      })
      .catch((error) => {
        console.log(">>>>", error);
      });
  };
  const ImageCard = ({ image }) => (
    <Card className="image-card">
      <div className="image-container1">
        <img src={image.src} alt={image.name} className="card-image" />
      </div>
      <div className="card-details">
        <div className="card-left">
          <h5>{image.name}</h5>
          <p>{image.subject}</p>
        </div>
        <div className="card-right mb-4">
          <p>{image.fees}</p>
        </div>
      </div>
    </Card>
  );

  const BlogCard = ({ image }) => (
    <Card
      className="image-card"
      style={{
        padding: "revert",
        borderWidth: 0,
      }}
    >
      <div className="image-container1">
        <img src={image.image_thumbnail} className="card-image" />
      </div>
      <Link
        style={{ textDecoration: "none" }}
        state={{ slug: image?.slug }}
        to={"/singleBlog"}
      >
        <div>
          <h6 style={{ fontWeight: "bold", marginTop: 10 }}>{image.title}</h6>
        </div>
      </Link>
      <div className="mt-2">
        <span>
          <FaCalendarAlt className="me-2 yellow-icon" />
          <span>{image?.publish_date}</span>
        </span>
      </div>
    </Card>
  );
  const CityCard = ({ image }) => (
    <Card
      className="image-card"
      style={{
        padding: "revert",
        borderWidth: 0,
      }}
    >
      <div className="image-container1">
        <img src={image?.image} className="card-image" />
      </div>
      {/* <Link style={{ textDecoration: "none" }} to={"/step2"}> */}
      <div style={{ alignSelf: "center", alignItems: "center", marginTop: 15 }}>
        <a
          onClick={() => handleCityClick(image.id)}
          style={{
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {image.city_name}
        </a>
      </div>
    </Card>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow`}
        style={{ ...style, display: "block", right: "-25px", color: "red" }}
        onClick={onClick}
      >
        <FaArrowRight />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow`}
        style={{ ...style, display: "block", left: "-25px" }}
        onClick={onClick}
      >
        <FaArrowLeft />
      </div>
    );
  };
  const settingss = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // This targets iPad Pro screens
        settings: {
          slidesToShow: 2, // Show 2 slides on iPad Pro
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // This will apply to lg devices (992px and above)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const BlogGallery = ({ items }) => (
    <Slider {...settingss}>
      {blogsData.map((item) => (
        <div key={item.id}>
          <BlogCard image={item} />
        </div>
      ))}
    </Slider>
  );
  const Citiesgallery = ({ items }) => (
    <Slider {...settingss}>
      {citytList.map((item) => (
        <div key={item.id}>
          <CityCard image={item} />
        </div>
      ))}
    </Slider>
  );

  const ImageGallery = ({ images }) => (
    <Slider {...settings}>
      {images.map((image) => (
        <div key={image.id}>
          <ImageCard image={image} />
        </div>
      ))}
    </Slider>
  );

  const handleSignUpClick = () => {
    localStorage.setItem("role", "Tutor");
    window.localStorage.removeItem("@userToken");
    navigate("/step1", { replace: true });
    window.location.reload();
  };

  const handleWhyClick = () => {
    navigate("/Discover");
  };

  return (
    <>
      <Metatags />
      <Container fluid className="p-1">
        <Container fluid className="p-0">
          <Row className="justify-content-end m-0 position-relative">
            <Col
              md={7}
              sm={12}
              lg={12}
              xs={12}
              xl={7}
              className="pt-5 m ps-md-5 position-relative"
            >
              <div className="image-container">
                <img
                  src={Images.Sparkle}
                  alt="Small Image 1"
                  className="small-image"
                />
              </div>
              <h1 className="heading" style={{ fontSize: 42 }}>
                Connect with expert{" "}
                <span style={{ color: "#6D39E9" }}>tutors in the UAE</span>
                <span className="tutors-text">
                  <div className="image-container-tutors">
                    <img
                      src={Images.dot}
                      alt="Small Image 2"
                      className="small-image-tutors"
                    />
                  </div>
                  <div className="image-container-800plus">
                    <img
                      src={Images.line}
                      alt="Small Image 2"
                      className="small-image-800plus"
                    />
                  </div>
                </span>
              </h1>
              <div className="banner-content mt-3">
                <p>
                  Find the right tutor for any subject, anytime. Post your tutor
                  requirements and connect with UAE’s best tutors that will
                  guarantee improved and successful results.
                </p>
              </div>

              <Row className="justify-content-start">
                <Col className="text-start" md={4} lg={4}>
                  <Link to={"/step1"}>
                    <Button
                      style={{ width: "max-content" }}
                      className="reqBtn  fw-bold"
                    >
                      Find a tutor for free
                    </Button>
                  </Link>
                </Col>
                <Col className="text-start discover me-5" lg={7}>
                  <Button
                    className="joinBtn position-relative fw-bold"
                    onClick={handleSignUpClick}
                  >
                    Join as a tutor for free
                  </Button>
                  <Row className="   justify-content-center">
                    <Col className="" xxl={12} lg={12}>
                      <button
                        className="discover-btn  fw-bold"
                        onClick={handleWhyClick}
                      >
                        Discover our unique benefits for tutors
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              md={5}
              sm={12}
              lg={12}
              xl={5}
              xs={12}
              className="d-flex justify-content-end p-0 m-0 position-relative"
            >
              <img
                src={ladki}
                alt="homeimage"
                className="img-fluid p-0 m-0 w-75 homeimg"
                key={uniqueKey}
              />
              <div className="image-container-homeimg">
                <img
                  src={Images.dot}
                  alt="Small Image 4"
                  className="small-image-homeimg"
                />
              </div>
            </Col>
          </Row>
        </Container>

        <br />
        <Container className="mobile-container">
          <section>
            <Row className="justify-content-between position-relative">
              <Col lg={9} className="position-relative">
                <h1 className="mt-2">
                  Most{" "}
                  <span className="tutors-text" style={{ color: "#6D39E9" }}>
                    Popular
                    <div className="image-container-popular">
                      <img
                        src={Images.line}
                        alt="Small Image 2"
                        className="small-image-popular"
                      />
                    </div>
                  </span>{" "}
                  Subjects
                </h1>
              </Col>
            </Row>
            <Row style={{ justifyContent: "space-between", marginTop: 5 }}>
              <Col md={9}>
                <p className="most ">
                  Discover the subjects that our students require tutoring in
                  the most. Whether you're looking to improve your grades,
                  prepare for exams, or learn something new, our top subjects
                  offer the best tutors and resources to help you succeed. Here
                  are some of the most sought-after subjects among our students.
                </p>
              </Col>
              <Col className="text-center  " md={6} sm={12} xs={12} lg={3}>
                <Button
                  className="mt-2 w-100 w-lg-auto ms-0 discover-btn fw-bold"
                  onClick={handleSignUpClick}
                  style={{ width: "200px" }}
                >
                  Find a tutor for free
                </Button>
                <Link to="/subjects">
                  {" "}
                  <button
                    style={{ width: "200px" }}
                    className="mt-2 w-100 w-lg-auto ms-0 discover-btn fw-bold"
                  >
                    Browse all subjects
                  </button>
                </Link>
              </Col>
            </Row>
            <Row className="mt-4 justify-content-start">
              {formData?.slice(0, 10)?.map((category, index) => {
                return (
                  <Col
                    key={index}
                    xxl={3}
                    xl={4}
                    lg={6}
                    md={6}
                    xs={12}
                    className="mb-4"
                  >
                    <div className="card card-2 h-100 d-flex  justify-content-center">
                      <Row className="align-items-center">
                        <Col
                          lg={3}
                          md={2}
                          sm={2}
                          xs={2}
                          className="d-flex justify-content-center"
                        >
                          {category.image && (
                            <img
                              style={{ width: "38px", height: "38px" }}
                              src={category.image}
                              // alt={category?.image}
                              // className="ms-2"
                            />
                          )}
                        </Col>
                        <Col
                          lg={9}
                          md={10}
                          sm={7}
                          xs={7}
                          className="d-flex justify-content-start"
                        >
                          <h5>{category?.subject_type}</h5>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </section>
        </Container>
        <Container className="mobile-container">
          <Row className="position-relative">
            <Col className="position-relative" lg={8}>
              <h1 className="rating">
                <span className="tutors-text" style={{ color: "#6d39e9" }}>
                  {" "}
                  How it Works
                  <div className="image-container-work">
                    <img
                      src={Images.line}
                      alt="Small Image 2"
                      className="small-image-work"
                    />
                  </div>
                </span>{" "}
                for Students and Parents?
              </h1>
            </Col>

            <Col lg={10} className="mt-4">
              <p className="most">
                It’s simple and easy to connect with the best tutors in the UAE
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center d-flex">
            {WorkStudent.map((item, index) => {
              return (
                <Col className="text-center" lg={3}>
                  <div
                    className={index % 2 === 0 ? "border-div" : "border-div1"}
                  >
                    <div
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#fdb61e" : "#6d39e9",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        margin: "0 auto",
                      }}
                    >
                      {index + 1}
                    </div>
                    <h5 className="mt-3">{item?.title}</h5>
                    <p>{item?.decr}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col xs={12} lg={12} className="text-center mt-5">
              <Link to="/step1">
                {" "}
                <Button className="discover-btn ms-auto fw-bold">
                  Start Now
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <br />
        <section className="expert">
          <Container className=" mobile-container ">
            <Row>
              <Col xl={12}>
                <Row
                  style={{
                    backgroundColor: "rgba(109, 57, 233, 0.1)",
                    borderRadius: "16px",
                    boxShadow: "0 10px 8px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #6D39E9",
                  }}
                  className="position-relative"
                >
                  <Col className="text-lg-center" xl={5} lg={12} xxl={5}>
                    <img
                      src={Images.request}
                      className="img-fluid"
                      alt="Request Tutor"
                    />
                  </Col>
                  <Col
                    className="position-relative text-lg-center text-xl-start"
                    lg={12}
                    xl={7}
                    xxl={7}
                  >
                    <div className="content    mt-5">
                      <h1>
                        Find an expert&nbsp;
                        <span
                          className="tutors-text"
                          style={{ color: "#6D39E9" }}
                        >
                          tutor
                          <div className="image-container-work">
                            <img
                              src={Images.line}
                              alt="Small Image 2"
                              className="small-image-work"
                            />
                          </div>
                        </span>
                        &nbsp;{"near you"}
                      </h1>
                      <p className="mt-3">
                        Use UAE’s best platform to search for a tutor to help
                        you achieve the results you are striving for
                      </p>
                      <Link to={"/step1"}>
                        <Button
                          className="discover-btn w-lg-50 near mb-sm-2 mb-md-2 ms-md-auto ms-sm-auto fw-bold"
                          type="button"
                        >
                          Find a tutor
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <Container className="mobile-container">
          <Row className="position-relative">
            <Col className="position-relative" lg={8}>
              <h1 className="rating">
                <span className="tutors-text" style={{ color: "#6d39e9" }}>
                  {" "}
                  How it works
                  <div className="image-container-work">
                    <img
                      src={Images.line}
                      alt="Small Image 2"
                      className="small-image-work"
                    />
                  </div>
                </span>{" "}
                for a tutor?
              </h1>
            </Col>

            <Col lg={10} className="mt-4">
              <p className="most">
                It’s simple and easy to connect with the best tutors in the UAE
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center d-flex">
            {WorkTutor.map((item, index) => {
              return (
                <Col className="text-center" lg={3}>
                  <div
                    className={index % 2 === 0 ? "border-div" : "border-div1"}
                  >
                    <div
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#fdb61e" : "#6d39e9",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        margin: "0 auto",
                      }}
                    >
                      {index + 1}
                    </div>
                    <h5 className="mt-3">{item?.title}</h5>
                    <p>{item?.decr}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col lg={12} className="text-center mt-5">
              <Link to="/step1">
                {" "}
                <Button className="discover-btn ms-auto fw-bold">
                  Start Now
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container className="mt-4 bc mobile-container">
          <Row className="justify-content-between">
            <Col xl={12}>
              <Container
                fluid
                style={{
                  backgroundColor: "rgba(253, 182, 30, 0.1)",
                  borderRadius: "16px",
                  boxShadow: "0 10px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #FDB61E",
                  border: "0.5",
                }}
              >
                {/* <Row className="justify-content-between">
                  <Col xl={7}>
                    <Container>
                      <Row className="position-relative">
                        <Col className="position-relative spreme" xl={12}>
                          <h1 className="text-center mt-5 rating">
                            Become a{" "}
                            <span
                              className="tutors-text"
                              style={{ color: "#6D39E9" }}
                            >
                              Tutor
                              <div className="image-container-work">
                                <img
                                  src={Images.line}
                                  alt="Small Image 2"
                                  className="small-image-work"
                                />
                              </div>
                            </span>
                          </h1>
                        </Col>
                        <Col xl={12} className="mt-4 spreme">
                          <p>
                            Join thousands of other professionals who earn doing
                            what they love.
                          </p>
                        </Col>
                        <Col xl={12} lg={12} className="mt-4 spreme">
                          <Button
                            className="btn  discover-btn fw-bold custom-button"
                            onClick={handleSignUpClick}
                          >
                            Join as a tutor for free
                          </Button>
                          <Link to={"/whyBecomeTutor"}>
                            <Button className="btn discover-btn fw-bold custom-button">
                              Why become a tutor in the UAE?
                            </Button>
                          </Link>
                        </Col>
                        <Col xs={12} xl={12} className="mt-0 spreme">
                          <Button
                            onClick={handleWhyClick}
                            className="why fw-bold "
                          >
                            {" "}
                            Discover our unique benefits for tutors
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col xl={5}>
                    <img
                      src={Images.BecomeTutor}
                      className="img-fluid"
                      alt="Become a Tutor"
                    />
                  </Col>
                </Row> */}
                <Row className="justify-content-between">
                  <Col xl={5}>
                    <img
                      src={Images.BecomeTutor}
                      className="img-fluid become-a-tutor"
                      alt="Become a Tutor"
                    />
                  </Col>
                  <Col xl={7}>
                    <Container>
                      <Row className="position-relative">
                        <Col className="position-relative spreme" xl={12}>
                          <h1 className="text-center mt-5 rating">
                            Become a{" "}
                            <span
                              className="tutors-text"
                              style={{ color: "#6D39E9" }}
                            >
                              Tutor
                              <div className="image-container-work">
                                <img
                                  src={Images.line}
                                  alt="Small Image 2"
                                  className="small-image-work"
                                />
                              </div>
                            </span>
                          </h1>
                        </Col>
                        <Col xl={12} className="mt-4 spreme">
                          <p>
                            Join thousands of other professionals who earn doing
                            what they love.
                          </p>
                        </Col>
                        <Col xs={12} xl={12} lg={12} className="mt-4  ">
                          <Button
                            className="btn   discover-btn fw-bold   custom-button"
                            onClick={handleSignUpClick}
                          >
                            Join as a tutor for free
                          </Button>
                        </Col>
                        <Col xs={12} md={12} xl={12} className="mt-1   ">
                          <Link to={"/whyBecomeTutor"}>
                            <button className="why w-lg100 fw-bold near1 discover-btn ">
                              Why become a tutor in the UAE?
                            </button>
                          </Link>
                        </Col>
                        <Col xs={12} xl={12} className="mt-0 mb-2  ">
                          <button
                            onClick={handleWhyClick}
                            className="why fw-bold near1 discover-btn"
                          >
                            Discover our unique benefits for tutors
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5 mobile-container   ">
          <Row className="justify-content-between position-relative">
            <Col className="position-relative" lg={6}>
              <h1 className="rating">
                Find expert tutors anywhere in&nbsp;
                <span className="tutors-text" style={{ color: "#6d39e9" }}>
                  the UAE here
                  <div className="image-container-work">
                    <img
                      src={Images.line}
                      alt="Small Image 2"
                      className="small-image-work"
                    />
                  </div>
                </span>
              </h1>
            </Col>
            <p className="mt-3">
              If you’re looking for the best tutors in the UAE, FindMyTutor is
              the perfect platform to help you connect with top private tutors
              that have achieved outstanding results. Find tutors all across the
              UAE, this includes tutors in Dubai, Abu Dhabi, Sharjah, Al Ain and
              many more!
            </p>
          </Row>
          <br />
          <div>
            <Citiesgallery city={citytList} />
          </div>
        </Container>
        <Container className="position-relative mobile-container mt-5">
          <h1 className="text-center mb-4 rating">
            What Our{" "}
            <span className="tutors-text" style={{ color: "#6d39e9" }}>
              Students
              <div className="image-container-student">
                <img
                  src={Images.line}
                  alt="Small Image 2"
                  className="small-image-student"
                />
              </div>
            </span>{" "}
            Say About Us
          </h1>

          <Slider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="text-center d-flex flex-column justify-content-center align-items-center"
              >
                {/* <img
                  className="d-block mx-auto rounded-circle"
                  src={testimonial.image}
                  alt={`Student ${index + 1}`}
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                  }}
                /> */}
                <div className="text-container mt-3 text-center">
                  <p className="most">{testimonial.text}</p>
                  <h5>{testimonial.name}</h5>
                  <p>{testimonial.class}</p>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
        <Container className="mt-5 mobile-container">
          <Row className="justify-content-between position-relative">
            <Col className="position-relative" lg={6}>
              <h1 className="rating">
                <span className="tutors-text" style={{ color: "#6d39e9" }}>
                  {" "}
                  Tutor
                </span>{" "}
                Rating & Reviews
                <div className="image-container-rating">
                  <img
                    src={Images.line}
                    alt="Small Image 2"
                    className="small-image-rating"
                  />
                </div>
              </h1>
            </Col>
            <Col lg={3}>
              <Image className="mb-2" src={Images.star}></Image>
              <Image className="mb-2" src={Images.star}></Image>
              <Image className="mb-2" src={Images.star}></Image>
              <Image className="mb-2" src={Images.star}></Image>
              <Image className="mb-2" src={Images.star}></Image>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                <span style={{ color: "#6d39e9", fontWeight: "bold" }}>
                  {" "}
                  4.8/5
                </span>{" "}
                Average Rating
              </span>
            </Col>
            <Col lg={10} className="mt-4">
              <p>
                Read some of our reviews from tutors on our platform and learn
                about their student connection experiences on FindMyTutor.
              </p>
            </Col>
          </Row>

          <Slider {...settingss}>
            {tutors.map((tutor, index) => (
              <div key={index} className="slid">
                <div className="card  tutor-review d-flex flex-column justify-content-center align-items-center text-center p-4">
                  <div style={{ textAlign: "left" }}>
                    <div className="d-flex align-items-center mb-3">
                      <img
                        src={tutor.image}
                        alt={tutor.name}
                        className="rounded-circle"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "15px",
                        }}
                      />
                      <div className="text-left">
                        <h5 className="mb-1">{tutor.name}</h5>
                        <p className="text-muted mb-3 stream-description">
                          {tutor.stream}
                        </p>
                      </div>
                    </div>

                    <p className="mb-3 review-description">{tutor.review}</p>
                  </div>

                  <div className="d-flex justify-content-end mt-auto w-100">
                    {[...Array(tutor.rating)].map((_, i) => (
                      <img
                        key={i}
                        src={Images.star} // Add your star image here
                        alt="star"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
        <Container className="mt-5 mobile-container   ">
          <Row className="justify-content-between position-relative">
            <Col className="position-relative" lg={6}>
              <h1 className="rating">
                Our Latest{" "}
                <span className="tutors-text" style={{ color: "#6d39e9" }}>
                  Blogs
                  <div className="image-container-work">
                    <img
                      src={Images.line}
                      alt="Small Image 2"
                      className="small-image-work"
                    />
                  </div>
                </span>
              </h1>
            </Col>
            <Col lg={2}>
              <Link to={"/ourBlogs"}>
                {" "}
                <Image className="view" src={Images.view} />
              </Link>
            </Col>
            <Col lg={10} className="mt-4">
              <p className="">
                Explore Our Educational Blogs written by experts in each field
              </p>
            </Col>
          </Row>

          <div>
            <BlogGallery images={blogsData} />
          </div>
        </Container>

        <Container
          fluid
          className="bg-light  mt-5 justify-content-center align-content-center align-items-md-center"
        >
          <Container className="mt-3 mobile-container">
            <Row className="position-relative">
              <Col lg={6}>
                <h1 className="fr">
                  <span>
                    Frequently Asked{" "}
                    <span className="tutors-text" style={{ color: "#6d39e9" }}>
                      Questions
                      <div className="image-container-question">
                        <img
                          src={Images.line}
                          alt="Small Image 2"
                          className="small-image-question"
                        />
                      </div>
                    </span>
                  </span>
                </h1>
              </Col>
            </Row>
            <br />
            {AccordianData.map((item, index) => (
              <Row className="justify-content-between " key={item.id}>
                <Col md={8} xs={10}>
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: "500",
                      fontFamily: "Jost",
                    }}
                  >
                    {item.heading}
                  </span>
                </Col>
                <Col xs={2} md={1} className="mt-2">
                  <Image
                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                    onClick={() => toggleAccordion(item.id)}
                    src={
                      openAccordionId === item.id ? Images.minus : Images.plus
                    }
                  />
                </Col>

                {openAccordionId === item.id && (
                  <Col className="mt-3" md={12} lg={11} xl={11}>
                    <p>{item.para}</p>
                  </Col>
                )}
                <hr className="mt-3" />
              </Row>
            ))}
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default Home;
