import React from "react";
import { Helmet } from "react-helmet";

const Metatags = () => {
  return (
    <>
      <Helmet>
        {/* Meta Tags Set 1 */}
        <title>FindMyTutor - Connect with the Best Tutors in the UAE</title>
        <meta
          name="description"
          content="FindMyTutor is the leading platform in the UAE for students to connect with expert tutors. Browse profiles, view contact details, and start learning today!"
        />
        <meta
          name="keywords"
          content="tutors, UAE tutors, online tutoring, private tutoring, find a tutor, UAE education, tutor platform, student-tutor connection"
        />

        {/* Meta Tags Set 2 */}
        <meta
          property="og:title"
          content="Find a Tutor in the UAE - Expert Tutors for Every Subject"
        />
        <meta
          property="og:description"
          content="Looking for a tutor in the UAE? FindMyTutor connects students with qualified tutors in various subjects. Explore tutor profiles, view contact details, and start learning today!"
        />
        <meta
          property="og:keywords"
          content="find a tutor in UAE, UAE tutors, tutoring services UAE, online tutoring, private tutoring UAE"
        />

        {/* Meta Tags Set 3 */}
        <meta
          property="og:title"
          content="Find Students in the UAE - Connect with Learners Today"
        />
        <meta
          property="og:description"
          content="Are you a tutor in the UAE? FindMyTutor helps you connect with students seeking your expertise. Browse student inquiries, access contact details, and grow your tutoring business."
        />
        <meta
          property="og:keywords"
          content="find students in UAE, tutor for students UAE, UAE tutoring platform, connect with students UAE"
        />

        {/* Meta Tags Set 4 */}
        <meta
          property="og:title"
          content="Become a Tutor in the UAE - Join FindMyTutor Today"
        />
        <meta
          property="og:description"
          content="Start your journey as a tutor in the UAE with FindMyTutor. Create your profile, connect with students, and expand your tutoring services with ease. Join our community now!"
        />
        <meta
          property="og:keywords"
          content="become a tutor in UAE, tutor jobs UAE, join tutoring platform UAE, start tutoring in UAE"
        />
      </Helmet>

      {/* Rest of your component's content */}
    </>
  );
};

export default Metatags;
