/** @format */

import React, { useEffect } from "react";
import Images from "../../../Helper/Images";
import "./About.css";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import illus from "../../../Assets/Images/illu.png";
import tables from "../../../Assets/Images/table.png";
import Home from "../../../Assets/Images/home1.png";
// import community from "../../../Assets/Images/Groups.png";
// import tutorss from "../../../Assets/Images/Mask group.png";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from "axios";
import { CheckCircleOutline } from "@mui/icons-material";
import { Box, List, ListItem } from "@mui/material";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow`}
      style={{ ...style, display: "block", right: "-25px", color: "red" }}
      onClick={onClick}
    >
      <FaArrowRight />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow`}
      style={{ ...style, display: "block", left: "-25px" }}
      onClick={onClick}
    >
      <FaArrowLeft />
    </div>
  );
};
const tutors = [
  {
    title: "Quality Tutors",
    image: Home,
    review:
      "We carefully vet each tutor to ensure they meet our high standards of expertise, professionalism, and commitment to student success",
  },
  {
    title: "Wide Range of Subjects",
    image: Home,
    review:
      "Whether you need help with mathematics, english, sciences, languages, or specialised test preparation, our diverse pool of tutors covers a broad spectrum of subjects and educational levels",
  },
  {
    title: "Convenience",
    image: Home,
    review:
      "Our platform allows you to browse tutor profiles, read reviews, and compare qualifications, making it easy to find the right tutor based on your specific needs and schedule.",
  },
  {
    title: "Flexibility",
    image: Home,
    review:
      "We understand that every student is unique, which is why our tutors offer flexible scheduling options to accommodate varying study routines and preferences.",
  },
  {
    title: "Transparent Pricing",
    image: Home,
    review:
      "We provide clear and transparent pricing information, so you can make informed decisions about your tutoring investment without hidden costs.",
  },
  {
    title: "As a tutor",
    image: Home,
    review:
      "Find a vast amount of students looking for tuition to add to your portfolio and join thousands of others who are earning lucratively in the UAE.",
  },
  {
    title: "No commission taken from tutors",
    image: Home,
    review:
      "Tutors take 100% of whatever they choose to charge and have earnt!",
  },
];
const studenttutors = [
  {
    title: "Post an Advert for free",
    image: Home,
    review:
      "Parents/students create a detailed advert specifying the subject, level, preferred schedule, and any specific requirements for the tutor.",
  },
  {
    title: "Tutors Find Your Advert",
    image: Home,
    review:
      "Qualified tutors browse through the posted adverts and reach out to parents with their profiles, experience, and availability.",
  },
  {
    title: "Review & Connect",
    image: Home,
    review:
      "Parents review and compare the tutor profiles and message the suitable tutors to discuss further details and finalise the tutoring arrangement.",
  },
  {
    title: "Start Learning",
    image: Home,
    review:
      " Once a tutor is selected, sessions begin, and parents can track their child's progress and success.",
  },
];
const tutor = [
  {
    title: "Sign up:",
    image: Home,
    review: "Join as a tutor",
  },
  {
    title: "Browse",
    image: Home,
    review:
      "Browse the vast amount of student adverts that have been posted on the platform.",
  },
  {
    title: "Connect",
    image: Home,
    review:
      "Reach out to students/parents requiring tutoring directly via their personal contact details or through the platform’s messaging system.",
  },
  {
    title: "Start tutoring and earning!",
    image: Home,
    review:
      " Add students to your tutoring portfolio and join others who are earning money doing what they love.",
  },
];
const settingss = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const About = () => {
  const navigate = useNavigate();
  const handleTutorClick = () => {
    localStorage.setItem("role", "Tutor");
    navigate("/step2", { replace: true });
  };
  const handleParentsClick = () => {
    localStorage.setItem("role", "Parent");
    navigate("/step2", { replace: true });
  };
  return (
    <div className="body w-100  container-fluid">
      <div className="header-container1">
        <h2 className="header-title">About us</h2>
      </div>
      <div className="container  mt-2 mb-5">
        <div
          className="col-lg-12 col-md-12  col-sm-12 row p-3"
          style={{ backgroundColor: "#ddd1fa", borderRadius: "16px" }}
        >
          <div
            className="col-lg-8 col-md-8 col-sm-12"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <h3 style={{ fontSize: "60px" }}>About FindMyTutor</h3>
              <p>
                Welcome to FindMyTutor, your premier platform for connecting
                students with expert tutors across the UAE. Whether you're
                seeking academic support, exam preparation, or enrichment in
                various subjects, we're here to help you find the perfect tutor
                to meet your educational goals.
              </p>

              <Button className="sign-up-t" onClick={handleTutorClick}>
                Sign up as tutor
              </Button>

              <Button className="sign-up-s" onClick={handleParentsClick}>
                Sign up as a student/parent
              </Button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <img
              src={illus}
              alt="illus"
              width={"80%"}
              style={{ float: "right" }}
            />
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h2 className="text-center">Why Choose Us?</h2>

          <List>
            {tutors.map((benefit, index) => (
              <ListItem key={index} alignItems="flex-start">
                <CheckCircleOutline
                  color="primary"
                  style={{ marginRight: "10px" }}
                />
                <Box>
                  <h6 className="fw-bold">{benefit.title}</h6>
                  <p variant="body1">{benefit.review}</p>
                </Box>
              </ListItem>
            ))}
          </List>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
          <h2 className="text-center">How It Works As a student</h2>
          {/* <Slider {...settingss}>
            {studenttutors.map((tutor, index) => (
              <div className="col-lg-12 col-md-12 col-sm-12 row p-3 mt-4 d-flex">
                <div
                  key={index}
                  className="col-lg-12 col-md-12 col-sm-12 text-center">
                  <div className="d-flex justify-content-center">
                    <img
                      src={tutor.image}
                      alt="img"
                      width="50"
                      style={{
                        backgroundColor: "#007AFF",
                        borderRadius: "50%",
                        padding: "10px",
                      }}
                    />
                  </div>
                  <h5 className="mt-3">{tutor.title}</h5>
                  <p>{tutor.review}</p>
                </div>
              </div>
            ))}
          </Slider> */}
          <Row className="justify-content-center d-flex">
            {studenttutors.map((item, index) => {
              return (
                <Col className="text-center" lg={3}>
                  <div
                    className={index % 2 === 0 ? "border-div" : "border-div1"}
                  >
                    <div
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#fdb61e" : "#6d39e9",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        margin: "0 auto",
                      }}
                    >
                      {index + 1}
                    </div>
                    <h5 className="mt-3">{item?.title}</h5>
                    <p>{item?.review}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
          <h2 className="text-center">How It Works As a tutor</h2>

          <Row className="justify-content-center d-flex">
            {tutor.map((item, index) => {
              return (
                <Col className="text-center" lg={3}>
                  <div
                    className={index % 2 === 0 ? "border-div" : "border-div1"}
                  >
                    <div
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#fdb61e" : "#6d39e9",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        margin: "0 auto",
                      }}
                    >
                      {index + 1}
                    </div>
                    <h5 className="mt-3">{item?.title}</h5>
                    <p>{item?.review}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        <div
          className="col-lg-12 col-md-12 col-sm-12 row p-3 mt-4"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div
            className="col-lg-6 col-md-6 col-sm-12"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <h2>Our Mission</h2>
              <p>
                At FindMyTutor, our mission is to empower students by providing
                access to qualified tutors who are dedicated to fostering
                academic excellence and confidence in their learning journey. We
                believe that personalised tutoring can make a significant
                difference in students' understanding and achievement, equipping
                them with the skills they need to succeed academically and
                beyond.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img
              src={tables}
              alt="tables"
              width={"40%"}
              style={{ float: "right" }}
            />
          </div>
        </div>
        <br />
        <div>
          <h2>Get Started Today</h2>
          <p>
            Join thousands of students in the UAE who have already improved
            their academic performance with the help of dedicated tutors.
            Whether you're preparing for exams, mastering challenging subjects,
            wanting to pick up a new skill or seeking academic enrichment,
            FindMyTutor is here to support you every step of the way. For
            inquiries, support, or to start your tutoring journey, please
            contact us or explore our website to find the perfect tutor for your
            needs.
          </p>
        </div>
        <div>
          <h2>Contact Information</h2>
          <h4>FindMyTutor</h4>
          <h6 className="fw-bold">
            Email:{" "}
            <span style={{ fontWeight: "normal" }}>
              support@findmytutoruae.com
            </span>
          </h6>
          <h6 className="fw-bold">
            Phone: <span style={{ fontWeight: "normal" }}>+971502475166</span>
          </h6>
          <h6 className="fw-bold">
            Follow us on{" "}
            <span style={{ fontWeight: "normal" }}>tutor@inst.com</span>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default About;
