/** @format */

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Images from "../../../../Helper/Images";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import "./Step2.css";
import Screen from "../../../../Helper/Screen";
import { apiCallNew } from "../../../../Network_Call/apiservices";
import ApiEndPoints from "../../../../Network_Call/ApiEndPoints";
import { getToken, setToken, setUserData } from "../../../../Helper/Storage";
import { CircularProgress } from "@mui/material";

const Step2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showGenderOptions, setShowGenderOptions] = useState(false);
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [otpVerify, setOtpVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const [fullname, setFullname] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [mobile, setMobile] = useState("");
  const [googleData, setGoogleData] = useState("");
  const [socialID, setSocialID] = useState(Number);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [fullnameError, setFullnameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [dobError, setDobError] = useState("");
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [parentType, setParentType] = useState("");
  const [parentTypeError, setParentTypeError] = useState("");
  const [load, setload] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = getToken();
  useEffect(() => {
    GetTutorProfile();
  }, []);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const role = localStorage.getItem("role");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  useEffect(() => {
    if (otp.length === 4) {
      VerifyOtpEmail();
    }
  }, [otp]);

  const GoogleDataGet = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        if (res.status == 200) {
          setGoogleData(res.data);
          setSocialID(res.data?.sub);
        } else {
          toast.error("Google login failed. Please try again.");
        }
      } catch (err) {
        console.error("Failed to fetch user info", err);
        toast.error(err.message || "Failed to fetch user info");
      }
    },
    onFailure: (error) => {
      console.error("Google login failed", error);
      toast.error("Google login failed. Please try again.");
    },
  });
  // console.log("Form Data:", formData);
  useEffect(() => {
    if (googleData && socialID) {
      GooglePostApi();
    }
  }, [googleData, socialID]);
  const GooglePostApi = async () => {
    if (!googleData || !socialID) {
      console.error("Missing googleData or socialID");
      return;
    }
    const formData = new FormData();
    formData.append("email", googleData.email);
    formData.append("name", googleData.name);
    formData.append("social_id", socialID);
    formData.append("user_type", role);

    try {
      const response = await apiCallNew(
        "post",
        formData,
        ApiEndPoints.SocialLogin
      );
      if (response.success === true) {
        setUserData(JSON?.stringify(response?.result));
        setToken(response?.result?.api_token);
        toast.success(response.msg);
        if (response?.result?.user_type === "Tutor") {
          navigate("/tutor");
        }
        if (response?.result?.user_type === "Parent") {
          navigate("/parent");
        }
        console.log("SOCIAL RESPONSEEEEE", response);

        window.location.reload();
      } else {
        console.log("Registration failed", response);
        toast.error(response.msg);
      }
    } catch (error) {
      console.error("An error occurred", error);
      toast.error(error.message || "An error occurred");
    }
  };
  const SendOtpEmail = async () => {
    if (!emailRegex.test(email)) {
      setEmailError("The email field must be a valid email address.");
      return;
    }
    setload(true);

    const payload = {
      email: email,
    };

    apiCallNew("post", payload, ApiEndPoints.TutorSendOTPEmail)
      .then((response: any) => {
        console.log("eeee", response);

        if (response.success === true) {
          setload(false);
          toast.success(response.msg);
          setLoading(false);
        } else {
          setload(false);
          // toast.error(response.msg);
          setLoading(false);
        }
      })
      .finally((er) => {
        toast.error(er);
        setLoading(false);
        setload(false);
      });
  };
  const VerifyOtpEmail = async () => {
    if (!otp) {
      setOtpError("Please enter OTP");
      return;
    }
    const payload = {
      email: email,
      otp: JSON.parse(otp),
    };
    console.log("pppp", payload);
    setLoading(true);
    apiCallNew("post", payload, ApiEndPoints.TutorVerifyOTPEmail)
      .then((response: any) => {
        console.log("rrr", response);
        if (response.success === true) {
          toast.success(response.msg);
          setOtpVerify(true);
          setOtpError("");
          setLoading(false);
        } else {
          setOtpError("Please enter a valid OTP");
          setLoading(false);
        }
      })
      .finally((er) => {
        toast.error(er);
        setLoading(false);
      });
  };
  // const TutorRegistrationApi = async () => {
  //   setload(true);
  //   const payload = {
  //     email: email,
  //     password: password,
  //     name: fullname,
  //     mobile_number: mobile,
  //     gender: gender,
  //     dob: dob,
  //   };
  //   const payload1 = {
  //     email: email,
  //     password: password,
  //     name: fullname,
  //     mobile_number: mobile,
  //     gender: gender,
  //     dob: dob,
  //     parent_type: parentType,
  //   };

  //   const chosenPayload = role === "Tutor" ? payload : payload1;
  //   const apiUrl =
  //     role === "Tutor"
  //       ? ApiEndPoints.TutorRegister
  //       : ApiEndPoints.ParentRegister;
  //   apiCallNew("post", chosenPayload, apiUrl)
  //     .then((response: any) => {
  //       console.log("rrr", response.msg[0]);
  //       if (response.success === true) {
  //         toast.success("Your Personal Information has been Updated");
  //         setToken(response?.result?.api_token);
  //         {
  //           role == "Tutor"
  //             ? setTimeout(() => {
  //                 navigate("/step3");
  //                 setload(false);
  //               }, 2000)
  //             : setTimeout(() => {
  //                 navigate("/step4");
  //                 setload(false);
  //               }, 2000);
  //         }
  //       } else {
  //         // toast.error(response.msg[0]);
  //         // toast.error(response.msg[1]);
  //       }
  //     })
  //     .finally((er) => {
  //       setload(false);
  //     });
  // };
  const TutorRegistrationApi = async () => {
    setload(true);
    const payload = {
      email: email,
      password: password,
      name: fullname,
      mobile_number: mobile,
      gender: gender,
      dob: dob,
    };
    const payload1 = {
      email: email,
      password: password,
      name: fullname,
      mobile_number: mobile,
      gender: gender,
      dob: dob,
      parent_type: parentType,
    };

    const chosenPayload = role === "Tutor" ? payload : payload1;
    const apiUrl =
      role === "Tutor"
        ? ApiEndPoints.TutorRegister
        : ApiEndPoints.ParentRegister;

    console.log("API URL:", apiUrl);
    console.log("Payload:", chosenPayload);

    try {
      const response = await apiCallNew("post", chosenPayload, apiUrl);
      console.log("API Response:", response);

      if (response.success) {
        toast.success("Your Personal Information has been Updated");
        setToken(response?.result?.api_token);
        setTimeout(() => {
          navigate(role === "Tutor" ? "/step3" : "/step4");
          setload(false);
        }, 2000);
      } else {
        // Handle API error response
        console.error("API Error Response:", response.msg);
        toast.error(response.msg.join("\n"));
      }
    } catch (error) {
      console.error("API Call Error:", error);
    } finally {
      setload(false);
    }
  };
  const toggleGenderOptions = () => {
    setShowGenderOptions(!showGenderOptions);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = async (email) => {
    try {
      const response = await axios.post(ApiEndPoints.verify_email_mobile, {
        type: "email",
        email: email,
      });
      return response;
    } catch (error) {
      console.error("Error validating email:", error);
      return false;
    }
  };

  const validateMobile = async (mobile) => {
    try {
      const response = await axios.post(ApiEndPoints.verify_email_mobile, {
        type: "mobile",
        mobile: mobile,
      });
      console.log("mobileresponse", response);
      return response;
    } catch (error) {
      console.error("Error validating mobile:", error);
      return false;
    }
  };

  const handleEmailBlur = async () => {
    const emailExists = await validateEmail(email);
    if (emailExists && emailExists.data && emailExists.data.success) {
      setEmailError("");
    } else if (
      emailExists &&
      emailExists.data &&
      emailExists.data.result &&
      emailExists.data.result.email
    ) {
      console.log(emailExists.data.result.email[0]);
      setEmailError(emailExists.data.result.email[0]);
    } else {
      setEmailError("An error occurred while validating the email.");
    }
  };

  const handleMobileBlur = async () => {
    const mobileExists = await validateMobile(mobile);
    if (mobileExists && mobileExists.data && mobileExists.data.success) {
      setMobileError("");
    } else if (
      mobileExists &&
      mobileExists.data &&
      mobileExists.data.result &&
      mobileExists.data.result.mobile
    ) {
      console.log("mobile", mobileExists.data.result.mobile[0]);
      setMobileError(mobileExists.data.result.mobile[0]);
    } else {
      setMobileError("An error occurred while validating the mobile number.");
    }
  };

  const handleFullnameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setFullname(value);
      setFullnameError("");
    } else {
      setFullnameError(
        "Full Name should contain only alphabetical characters(A to Z)."
      );
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    const passwordPattern =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordPattern.test(value)) {
      setPasswordError(
        "Password must be at least 8 characters   and contain letters(A-z), numbers(0-9), and special characters(@$!%*?&)."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleDobChange = (e) => {
    const value = e.target.value;
    setDob(value);

    if (!value) {
      setDobError("Date of Birth is required.");
    } else if (value > getCurrentDate()) {
      setDobError("Date of Birth cannot be in the future.");
    } else {
      setDobError("");
    }
  };

  const handleTermsChange = (e) => {
    const checked = e.target.checked;
    setTerms(checked);

    if (!checked) {
      setTermsError("You must agree to the terms and conditions.");
    } else {
      setTermsError("");
    }
  };
  const handleGenderChange = (e) => {
    const value = e.target.value;
    setGender(value);

    if (!value) {
      setGenderError("Gender is required.");
    } else {
      setGenderError("");
    }
  };
  const handleParenttypeChange = (e) => {
    const value = e.target.value;
    setParentType(value);

    if (!value) {
      setParentTypeError("Gender is required.");
    } else {
      setParentTypeError("");
    }
  };
  console.log("parentType", parentType);

  const validateForm = async () => {
    let isValid = true;

    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else {
      const emailExists = await validateEmail(email);
      if (!emailExists || !emailExists.data || !emailExists.data.success) {
        setEmailError(emailExists?.data?.result?.email[0]);
        isValid = false;
      }
    }
    if (!gender) {
      setGenderError("Gender is required.");
      isValid = false;
    }

    if (role !== "Tutor" && !parentType) {
      setParentTypeError("Parent Type is required.");
      isValid = false;
    }

    if (!fullname) {
      setFullnameError("Full Name is required");
      isValid = false;
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else {
      const passwordPattern =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordPattern.test(password)) {
        setPasswordError(
          "Password must be at least 8 characters  and contain letters(A-z), numbers(0-9), and special characters(@$!%*?&)."
        );
        isValid = false;
      }
    }

    if (!mobile) {
      setMobileError("The mobile field is required.");
      isValid = false;
    }
    if (mobile.length < 8) {
      setMobileError("The mobile number must be at least 8 digits long.");
      isValid = false;
    } else {
      const mobileExists = await validateMobile(mobile);
      if (!mobileExists || !mobileExists.data || !mobileExists.data.success) {
        setMobileError("");
        isValid = false;
      } else {
        setMobileError(""); // Clear any existing error message if validation is successful
      }
    }
    if (!dob) {
      setDobError("Date of Birth is required.");
      isValid = false;
    }
    if (dob > getCurrentDate()) {
      setDobError("Date of Birth cannot be in the future.");
      isValid = false;
    }

    if (!terms) {
      setTermsError("You must agree to the terms and conditions.");
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent multiple submissions

    setIsSubmitting(true); // Set the submitting state

    const isFormValid = await validateForm();
    console.log("isFormValid", isFormValid);
    if (isFormValid) {
      console.log("Form is valid. Proceeding to call API.");
      TutorRegistrationApi();
    } else {
      console.log("Form is invalid. Submission halted.");
      setIsSubmitting(false); // Reset submitting state if form is invalid
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  //   apiCallNew(
  //     "get",
  //     null,
  //     `${
  //       role === "Tutor"
  //         ? ApiEndPoints.Get_Tutor_Profile
  //         : ApiEndPoints.Parent_Profile_Step
  //     }`
  //   ).then((response) => {
  //     console.log("responseTUTOR PROFILE>>>", response);
  //     if (response.success === true) {
  //       setFullname(response?.result?.name);
  //       // setQualification(response?.result?.education);
  //       // setExperience(response?.result?.experience);
  //       // setSubject(response?.result?.subject_name);
  //       // // setAvailability(response?.result?.availability)
  //       setEmail(response?.result?.email);
  //       // setGrades(response?.result?.grade_ids_arr);
  //       // setSelectedFile(response?.result?.profile_image);
  //       setGender(response?.result?.gender);
  //       setMobile(response?.result?.mobile_number);
  //       setDob(response?.result?.dob);
  //     }
  //   });
  // };

  const GetTutorProfile = () => {
    const url =
      role === "Tutor"
        ? ApiEndPoints.Get_Tutor_Profile
        : ApiEndPoints.Parent_Profile_Step;

    // const token = "your-auth-token"; // Replace with your actual token

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("responseTUTOR PROFILE>>>", response);
        if (response.success === true) {
          setFullname(response?.result?.name);

          setEmail(response?.result?.email);

          setGender(response?.result?.gender);
          setMobile(response?.result?.mobile_number);
          setDob(response?.result?.dob);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile:", error);
      });
  };
  return (
    <div
      style={{
        backgroundColor: "#fff",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {load && (
        <div style={styles.backdrop}>
          <CircularProgress style={styles.loader} />
        </div>
      )}
      <div
        style={{
          borderRadius: "8px",
          maxWidth: "900px",
          width: "100%",
        }}
      >
        <Container style={{ width: "90%", padding: 0 }}>
          <Row className="justify-content-center">
            <Col
              style={{
                backgroundColor: "#ebe6f5",
                textAlign: "left",
                padding: "20px",
                borderRadius: "8px",
              }}
              xs={12}
              md={6}
            >
              <div className="mt-2">
                {role === "Tutor" ? (
                  <h3 className="">Sign Up - Tutor Profile</h3>
                ) : (
                  <h3 className="">Sign Up - Students/Parents</h3>
                )}
                <p>
                  Enter your email address and following details to get started
                </p>
              </div>

              <Form noValidate onSubmit={onSubmit}>
                <Form.Group controlId="email" className="mb-3">
                  <label style={{ marginLeft: 2 }}>
                    Email<span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => setEmailError("")}
                    onBlur={handleEmailBlur}
                  />
                  {emailError && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        margin: "0px",
                      }}
                    >
                      {emailError}
                    </p>
                  )}
                  {/* for Add Data we Comment This Code */}
                  <div className="ms-auto" style={{ textAlign: "right" }}>
                    <a
                      onClick={() => {
                        SendOtpEmail();
                        setStatus("otpSend");
                      }}
                    >
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          color: "#6d39e9",
                          fontWeight: "500",
                        }}
                      >
                        Send code to verify email
                      </span>
                    </a>
                  </div>
                  {/* for Add Data we Comment This Code */}
                </Form.Group>

                {status === "otpSend" && email ? (
                  <Form.Group controlId="otp" className="mb-3">
                    <label style={{ marginLeft: 2, marginBottom: "2px" }}>
                      Enter OTP<span style={{ color: "red" }}> *</span>
                    </label>

                    <div className="otp-container">
                      <Form.Control
                        type="text"
                        placeholder="OTP"
                        onChange={(e) => setOtp(e.target.value)}
                        value={otp}
                        maxLength={4}
                        className="otp-input"
                      />
                      {loading && (
                        <Spinner
                          animation="border"
                          variant="success"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="input-spinner"
                        />
                      )}
                    </div>
                    {otpError && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "smaller",
                          marginTop: "4px",
                          marginLeft: "4px",
                        }}
                      >
                        {otpError}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: "smaller",
                        marginTop: "4px",
                        marginLeft: "2px",
                      }}
                    >
                      (Please check your spam/junk folder if you do not receive
                      an email)
                    </p>
                  </Form.Group>
                ) : null}
                {role === "Tutor" ? null : (
                  <Form.Group controlId="parentType" className="mb-3">
                    <label style={{ marginLeft: 2 }}>
                      Are you the parent/guardian or the student?
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <Form.Control
                        as="select"
                        placeholder="Are you the parent/guardian"
                        onChange={handleParenttypeChange}
                        value={parentType}
                        onFocus={() => setParentTypeError("")}
                      >
                        <option value="" disabled hidden>
                          Select an option
                        </option>
                        <option value="Parent/guardian">Parent/guardian</option>
                        <option value="Student">Student</option>
                      </Form.Control>
                      <FiChevronDown
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                    {parentTypeError && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "smaller",
                          marginLeft: "4px",
                        }}
                      >
                        {parentTypeError}
                      </span>
                    )}
                  </Form.Group>
                )}

                <Form.Group controlId="fullname" className="mb-3">
                  <label style={{ marginLeft: 2 }}>
                    Full Name<span style={{ color: "red" }}> *</span>
                  </label>
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    value={fullname}
                    onChange={handleFullnameChange}
                  />
                  {fullnameError && (
                    <span style={{ color: "red", fontSize: "smaller" }}>
                      {fullnameError}
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="gender" className="mb-3">
                  <label style={{ marginLeft: 2 }}>
                    Gender<span style={{ color: "red" }}> *</span>
                  </label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      as="select"
                      onChange={handleGenderChange}
                      value={gender}
                      onFocus={() => setGenderError("")}
                    >
                      <option value="">Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Control>
                    <FiChevronDown
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  {genderError && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        marginLeft: "4px",
                      }}
                    >
                      {genderError}
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="password" className="mb-3">
                  <label style={{ marginLeft: 2 }}>
                    Password<span style={{ color: "red" }}> *</span>
                  </label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {!showPassword ? (
                      <AiOutlineEyeInvisible
                        className="password-icon"
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          top: passwordError ? "25%" : "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <AiOutlineEye
                        className="password-icon"
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          top: passwordError ? "25%" : "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    {passwordError && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "smaller",
                          marginLeft: "4px",
                        }}
                      >
                        {passwordError}
                      </span>
                    )}
                  </div>
                </Form.Group>
                <Form.Group controlId="mobile" className="mb-3">
                  <label style={{ marginLeft: 2 }}>
                    Mobile No<span style={{ color: "red" }}> *</span>
                  </label>
                  <Form.Control
                    type="tel"
                    // maxLength={13}
                    value={mobile}
                    placeholder="Mobile"
                    onChange={(e) => setMobile(e.target.value)}
                    onFocus={() => setMobileError("")}
                    onBlur={handleMobileBlur}
                  />
                  {mobileError && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        marginLeft: "4px",
                      }}
                    >
                      {mobileError}
                    </p>
                  )}
                </Form.Group>
                <Form.Group controlId="dob" className="mb-3">
                  <label style={{ marginLeft: 2 }}>
                    Date of Birth<span style={{ color: "red" }}> *</span>
                  </label>
                  <Form.Control
                    type="date"
                    placeholder="Date of Birth*"
                    value={dob}
                    onChange={handleDobChange}
                    onBlur={handleDobChange}
                    max={getCurrentDate()}
                    required={false}
                  />
                  {dobError && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        marginLeft: "4px",
                      }}
                    >
                      {dobError}
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="terms" className="mb-3">
                  <label className="mt-0" style={{ marginLeft: 2 }}>
                    Terms & Conditions<span style={{ color: "red" }}> *</span>
                  </label>
                  <Form.Check
                    type="checkbox"
                    className="mt-2"
                    label="I agree to the terms and conditions"
                    checked={terms}
                    onChange={handleTermsChange}
                  />
                  {termsError && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "smaller",
                        marginLeft: "4px",
                      }}
                    >
                      {termsError}
                    </span>
                  )}
                </Form.Group>
                <Button
                  type="submit"
                  // onClick={TutorRegistrationApi}
                  className="mt-3 ms-auto w-100 signup custom-button"
                  disabled={!otpVerify || isSubmitting}
                  style={{
                    backgroundColor: "#6d39e9",
                    borderColor: "#6d39e9",
                  }}
                >
                  Sign Up and Continue
                </Button>
                <div className="mt-2 text-center">Or</div>
                <Button
                  onClick={() => GoogleDataGet()}
                  className="mt-2 ms-auto w-100 custom-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FcGoogle
                    style={{
                      marginRight: "10px",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                  />
                  Sign Up with Google
                </Button>
              </Form>
            </Col>
            {/* {loading && <CircularProgress color="#6d39e9" />} */}

            <Col
              style={{
                background:
                  "linear-gradient(242.03deg, #6D39E9 -3.34%, #CCBAF7 72.34%)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative", // Added for relative positioning
              }}
              xs={12}
              md={6}
            >
              <img
                src={role === "Tutor" ? Images.Step2img : Images.family}
                alt="Profile Step 1"
                className="img-fluid"
                style={{ borderRadius: "8px" }} // Ensures image also has rounded corners
              />
              <div className="alreadyBtn">
                <span style={{ marginRight: "10px" }}>Already a Member</span>
                <Link to={"/login"}>
                  <Button className="custom-button" style={{ width: "100px" }}>
                    Sign In
                  </Button>
                </Link>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",

                  padding: "5px 10px",
                  borderRadius: "8px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <span style={{ color: "white" }}>
                  {" "}
                  {role == "Tutor" ? "Step 2/7" : "Step 2/4"}{" "}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "white",
  },
};

export default Step2;
