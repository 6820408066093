/** @format */

import React, { useEffect, useRef, useState } from "react";
import "./MessageView.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Container } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { apiCallNew } from "../../../Network_Call/apiservices";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
import { toast } from "react-toastify";
import { getToken, getUserdata } from "../../../Helper/Storage";
import moment from "moment";
import axios from "axios";

const MessageView = () => {
  const user = useLocation();
  console.log("user", user);
  const { users, img, name } = user?.state || {};
  console.log("userId,img,name", users, img, name);
  const [senderMsg, setSenderMsg] = useState([]);
  const [replyMsg, setReplyMsg] = useState("");
  const replyer = localStorage.getItem("@userId");
  const messagesEndRef = useRef(null);
  const token = getToken();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1); // Go back one step in the history
  };
  useEffect(() => {
    scrollToBottom();
  }, [senderMsg]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    MessageHistoryApi();
  }, []);
  useEffect(() => {
    if (senderMsg.length > 0) {
      Msg_Read_User();
    }
  }, [senderMsg]);

  const SendMessage = () => {
    const payload = {
      to_id: users,
      message: replyMsg,
    };
    apiCallNew("post", payload, ApiEndPoints.Send_Message).then((res) => {
      if (res.success == true) {
        MessageHistoryApi();
        setReplyMsg("");
        // toast.success(res.msg);
      } else {
        toast.error(res.msg[0]);
      }
    });
  };

  const MessageHistoryApi = () => {
    apiCallNew(
      "post",
      null,
      `${ApiEndPoints.Get_Message_History}${users}`
    ).then((res) => {
      if (res.success == true) {
        setSenderMsg(res?.result);
        // Msg_Read_User();

        console.log("msg history", res?.result);

        // toast.success(res.msg)
      } else {
        toast.success(res.msg);
      }
    });
  };
  const Msg_Read_User = async () => {
    try {
      const formdata = new FormData();

      senderMsg?.forEach((msg) => formdata.append("message_ids[]", msg?.id));
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        ApiEndPoints.msgReadByUser,
        formdata,
        config
      );
      console.log("response of msg read", response);
    } catch (error) {
      console.log("error while msg read", error);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (replyMsg.trim() !== "") {
        SendMessage();
      }
    }
  };
  const getInitial = (name) => name?.charAt(0).toUpperCase();
  return (
    <Container className="mobile-container" style={{ padding: "0px 0px" }}>
      <section className="message-area card">
        <div className="m-3">
          <Link className="text-decoration-none " onClick={handleBackClick}>
            <KeyboardBackspaceIcon
              // className="mt-5"
              // className="back"
              sx={{
                fontSize: "40px",
                marginRight: 2,
                cursor: "pointer",
                color: "#6d39e9",
              }}
            />
          </Link>
          <div className="row">
            <div className="col-12">
              <div className="chat-area">
                <div className="chatbox">
                  <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="msg-head">
                        <div className="row">
                          <div className="col-8">
                            <div className="d-flex align-items-center ">
                              {/* <img
                                className="imgs"
                                src={img}
                                alt="user img"
                                style={{ height: 50, width: 50, margin: 0 }}
                              /> */}
                              {img ? (
                                <img
                                  className="imgs"
                                  src={img}
                                  alt="user img"
                                  style={{ margin: 0, width: 50, height: 50 }}
                                />
                              ) : (
                                <div className="circle">{getInitial(name)}</div>
                              )}
                              <div className="flex-grow-1 ms-3 ">
                                <h3>{name}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="msg-body">
                          <ul>
                            {senderMsg?.map((item) => {
                              const isSender =
                                item.from_id == replyer ? true : false;

                              return (
                                <>
                                  <li
                                    key={item.id}
                                    className={!isSender ? "sender " : "repaly"}
                                  >
                                    <p>{item.message}</p>
                                    <br />
                                    <span className="time">
                                      {moment(item.created_at).format("h:mm a")}
                                    </span>
                                  </li>
                                </>
                              );
                            })}
                            <div ref={messagesEndRef}></div>
                          </ul>
                        </div>
                      </div>
                      <div className="send-box">
                        <form action="" style={{ margin: 0 }}>
                          <input
                            type="text"
                            className="form-control"
                            aria-label="message…"
                            placeholder="Write message…"
                            value={replyMsg}
                            onChange={(e) => setReplyMsg(e.target.value)}
                            onKeyDown={handleKeyDown}
                          />

                          <button
                            type="button"
                            style={{ height: 36, marginBottom: 1 }}
                            disabled={replyMsg == "" ? true : false}
                            className={
                              replyMsg !== ""
                                ? "custom-button1"
                                : "bg-secondary"
                            }
                            onClick={SendMessage}
                          >
                            Send
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default MessageView;
