/** @format */

import React, { useEffect, useState } from "react";
import "./PrivacyPolicy.css";
import { Container } from "react-bootstrap";
import { apiCallNew } from "../../../Network_Call/apiservices";
import ApiEndPoints from "../../../Network_Call/ApiEndPoints";
const PrivacyPolicy = () => {
  const [policyData, setPolicyData] = useState("");

  useEffect(() => {
    PolicyApi();
  }, []);

  const PolicyApi = () => {
    apiCallNew("get", null, ApiEndPoints.PrivacyPolicy).then((res) => {
      setPolicyData(res?.result);
    });
  };
  return (
    <div className="bodyprivacy">
      <div className="header-privacy">
        <h2 className="header-privacy">Privacy Policy</h2>
      </div>
      <Container>
        {/* <div className="row">
          <div className="col-lg-7 mt-5">
            <div className="row">
              <div className="col-md-12">
                <div className="spacer"></div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-lg-11">
                <p>
                  {policyData?.content
                    ?.replace(/<[^>]+>/g, "")
                    .replace(/&nbsp;/g, " ")}
                </p>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div> */}
        <div dangerouslySetInnerHTML={{ __html: policyData?.content }} />
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
